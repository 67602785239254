// background color

$font-familym: 'Montserrat', sans-serif;
$font-familyos: 'Open Sans', sans-serif;
$font-familyp: 'Poppins', sans-serif;
$font-familyr: 'Poppins', sans-serif;

$primary-color: #6fb253;
$color4848: #484848;
$color2d6:#0083d8;
$color507:#005072;
$footerbg: #181f23;
$color000: #000000;
$colornav: #111111;
$colortext: #333333;
$color20e:#06620e;
$colord41: #1e7125;
$color6f9:#228848;
$color736: #046736;
$color0b7: #0b7d3f;
$color82b: #ff982b;
$colororange:orange;
$color3a3a: #3e494d;
$color9a9a: #666;
$colorfff: #fff;
$colorc0c:#c0c0c0;
$colore2e2:#e2e2e2;
$colore2a3: #2a363b;
$color2a3: #2a363b; 
$colora32: #006a32; 
$coloreae: #eaeaea;
$colore4f: #e4f0e9; 
$colorf8f: #f8f8f8;
$colord3d: #d3d3d3;

$color7070: rgba(112,112,112, .18);
$color878: #878787;
$color0c5: #25a0c5;
$color888: #888888;
$colorfbf: #fbfbfb;
$color565: #565656;
$colorf1f: #f1f1f1;
$color762: #762b71;
$color9AB: #7939AB;
// fotn weight 
$font-light: 300;
$font-regular: 400;
$font-medium:500;
$font-sami-bold:600;
$font-bold: 700;
@mixin border-radius($radius) {
    border-radius: $radius !important;
    -webkit-border-radius: $radius !important;
    -moz-border-radius: $radius !important;
}

@mixin transform($rotate) {
    -webkit-transform: rotate($rotate);
    -moz-transform: rotate($rotate);
    -ms-transform: rotate($rotate);
    -o-transform: rotate($rotate);
    transform: rotate($rotate);
}

@mixin white-box-shadow() {
    -moz-box-shadow: 0px 2px 12px #d9dde9;
    -webkit-box-shadow: 0px 2px 12px #d9dde9;
    box-shadow: 0px 2px 12px #d9dde9;
}
@mixin white-shadow() {
    -moz-box-shadow: 0 2px 35px 0 rgba(145, 145, 145, 0.28);
    -webkit-box-shadow: 0 2px 35px 0 rgba(145, 145, 145, 0.28);
    box-shadow: 0 2px 35px 0 rgba(145, 145, 145, 0.28);
}
@mixin whitebox-shadow() {
    -moz-box-shadow: 0 22px 34px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 22px 34px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 22px 34px 0 rgba(0, 0, 0, 0.15);
}

@mixin purple-box-shadow() {
    -moz-box-shadow: 2px 2px 32px rgba(155,36,99,.3);
    -webkit-box-shadow: 2px 2px 32px rgba(155,36,99,.3);
    box-shadow: 2px 2px 32px rgba(155,36,99,.3);
}
@mixin goldGradient() {
    background: rgb(185,150,46);
    background: -moz-linear-gradient(left,  rgba(185,150,46,1) 0%, rgba(251,230,122,1) 44%, rgba(220,206,90,1) 97%);
    background: -webkit-linear-gradient(left,  rgba(185,150,46,1) 0%,rgba(251,230,122,1) 44%,rgba(220,206,90,1) 97%);
    background: linear-gradient(to right,  rgba(185,150,46,1) 0%,rgba(251,230,122,1) 44%,rgba(220,206,90,1) 97%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b9962e', endColorstr='#dcce5a',GradientType=1 );
}

@mixin goldTextGradient() {
    background: rgb(220,196,76);
    background: -moz-linear-gradient(left,  rgba(220,196,76,1) 0%, rgba(220,196,76,1) 50%, rgba(185,150,46,1) 100%);
    background: -webkit-linear-gradient(left,  rgba(220,196,76,1) 0%,rgba(220,196,76,1) 50%,rgba(185,150,46,1) 100%);
    background: linear-gradient(to right,  rgba(220,196,76,1) 0%,rgba(220,196,76,1) 50%,rgba(185,150,46,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dcc44c', endColorstr='#b9962e',GradientType=1 );
}
@mixin silverGradient() {
    background: rgb(166,166,166);
    background: -moz-linear-gradient(left,  rgba(166,166,166,1) 0%, rgba(240,240,240,1) 44%, rgba(209,209,209,1) 97%);
    background: -webkit-linear-gradient(left,  rgba(166,166,166,1) 0%,rgba(240,240,240,1) 44%,rgba(209,209,209,1) 97%);
    background: linear-gradient(to right,  rgba(166,166,166,1) 0%,rgba(240,240,240,1) 44%,rgba(209,209,209,1) 97%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6a6a6', endColorstr='#d1d1d1',GradientType=1 );
}

@mixin silverTextGradient() {
    background: rgb(136,136,136);
    background: -moz-linear-gradient(left,  rgba(136,136,136,1) 0%, rgba(201,201,201,1) 36%, rgba(161,161,161,1) 61%, rgba(160,160,160,1) 100%);
    background: -webkit-linear-gradient(left,  rgba(136,136,136,1) 0%,rgba(201,201,201,1) 36%,rgba(161,161,161,1) 61%,rgba(160,160,160,1) 100%);
    background: linear-gradient(to right,  rgba(136,136,136,1) 0%,rgba(201,201,201,1) 36%,rgba(161,161,161,1) 61%,rgba(160,160,160,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#888888', endColorstr='#a0a0a0',GradientType=1 );
}

@mixin blueLightGradient() {
    background: #3fb8e2;
    background: -webkit-linear-gradient(to left, #3fb8e2 0%, #0083d8 100%);
    background: -moz-linear-gradient(to left, #3fb8e2 0%, #0083d8 100%);
    background: linear-gradient(to left, #3fb8e2 0%, #0083d8 100%);
}

@mixin blueDarkGradient() {
    background: #005072;
    background: -webkit-linear-gradient(to left, #005072 0%, #488af9 100%);
    background: -moz-linear-gradient(to left, #005072 0%, #488af9 100%);
    background: linear-gradient(to left, #005072 0%, #488af9 100%);
}

@mixin greenGradient() {
    background: #4c8564;
    background: -webkit-linear-gradient(to left, #4c8564 0%, #046736 100%);
    background: -moz-linear-gradient(to left, #4c8564 0%, #046736 100%);
    background: linear-gradient(to left, #4c8564 0%, #046736 100%);
}

@mixin grayGradient() {
    background: #858585;
    background: -moz-linear-gradient(left,  #858585 0%, #4f4f4f 100%); 
    background: -webkit-linear-gradient(left,  #858585 0%,#4f4f4f 100%); 
    background: linear-gradient(to right,  #858585 0%,#4f4f4f 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#858585', endColorstr='#4f4f4f',GradientType=1 );
}

@mixin purple1Gradient() {
    background: #a23967;
    background: -moz-linear-gradient(left,  #a23967 0%, #72246c 100%); 
    background: -webkit-linear-gradient(left,  #a23967 0%,#72246c 100%); 
    background: linear-gradient(to right,  #a23967 0%,#72246c 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a23967', endColorstr='#72246c',GradientType=1 ); 
}
@mixin purple2Gradient() {
    background: rgb(97,0,97);
    background: -moz-linear-gradient(top,  rgba(97,0,97,1) 0%, rgba(162,0,162,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(97,0,97,1) 0%,rgba(162,0,162,1) 100%);
    background: linear-gradient(to bottom,  rgba(97,0,97,1) 0%,rgba(162,0,162,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#610061', endColorstr='#a200a2',GradientType=0 );
}
@mixin purple3Gradient() {
    background: #682d8b;
    background: -moz-linear-gradient(45deg,  #682d8b 46%, #983a72 100%);
    background: -webkit-linear-gradient(45deg,  #682d8b 46%,#983a72 100%);
    background: linear-gradient(45deg,  #682d8b 46%,#983a72 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#682d8b', endColorstr='#983a72',GradientType=1 );
}


@mixin blueGradient() {
background: #25a0c5;
background: -moz-linear-gradient(left,  #25a0c5 0%, #117997 100%); 
background: -webkit-linear-gradient(left,  #25a0c5 0%,#117997 100%);
background: linear-gradient(to right,  #25a0c5 0%,#117997 100%); 
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#25a0c5', endColorstr='#117997',GradientType=1 ); 
}
// Switch button
$height: 16 / 16 * 1em;
$width: 48 / 16 * 1em;
$color-white: #fefefe;
$color-clouds: #ecf0f1;
$color-silver: #c0c0c0;
$color-peter-river: #72246c;
$transition-default: 0.25s ease-out 0.10s;
/// old 
$disable-button:#d4e5b2;
$colorYellow:#f8e71c;
$colorYellowl:#fcf0c9;
$darkBlue-color:#06357a;
$greadBlue-light:#508ea0;
$round-circle:#4f7e6f;
$round-circley:#e9b532;
$Blue-light:#0092d5;
$bgcolor:#f3f5fb;
$lightBlack-color: #5b5f6d;
$base-color: #242731;
$black-color: #000;
$gray-color: #989ba6;
$grayColor: #747474;
$graylight-color:#e8e8e8;
$grayDark-color:#e5e8f0;
$red-color: #ef5555;
$ahover:none;
$priGreenTowhite: #a6cc46;
$tableViewDivThbg:#fafafa;
$tableViewDivBorder:#e7e7e7;
$whiteTo2a2a2a:#fff;
$whiteTo1c1c1e:#fff;
$whiteToblack:#fff;
$blackTowhite:#000;
$grayTowhite:#5b5f6d;
$f3f5fbTo1c1c1e:#f3f5fb;
$f3f5fbTo000000:#f3f5fb;
$f3f5fbTo2a2a2a:#f3f5fb;
$dbtext:#242731;
$darkThpagebg:#f3f5fb;
$darkThborder:#cacddf;
$piyanoCieclebg:#4f7e6f;
$piyanoCieclebor:#ffffff;
$rgbcolor:rgba(0, 0, 0, 0.5);
$reddis:#e9ecef;
$reddiscolor:#989ba6;
$graybgcolorTo303030:#ebeef6;
// font size
$size-exs: 10px;
$size-xs: 11px;
$size-sm: 12px;
$size-md: 14px;
$size-lg: 16px;
$size-17: 17px;
$size-20:20px;
$size-exl:22px;
$size-24:24px;
$size-26: 26px;
$size-30: 30px;
$size-36: 36px;
// padding
$p0: 0px;
$p5:5px;
$p10: 10px;
$p12:12px;
$p15: 15px;
$p20: 20px;
$p25:25px;
$p30: 30px;
$p40: 40px;
// margin
$m0: 0px;
$m10: 10px;
$m15: 15px;
$m20: 20px;
$m30: 30px;
$m40: 40px;
// text Transform
$textUpper: uppercase;
$textLower: lowarcase;
$rotate:45deg;

/*images */

$callicon:url(/assets/base/images/callicon.svg) no-repeat 0 0;
$smnextArrow:url(/assets/base/images/PPP-icons.svg) no-repeat -15px 0;
$smprevArrow:url(/assets/base/images/PPP-icons.svg) no-repeat 0 0;
$inputSearchIcon:url(/assets/base/images/PPP-icons.svg) no-repeat 0 -32px;
$lockIcon:url(/assets/base/images/PPP-icons.svg) no-repeat 0 -57px;
$selectArrow:url(/assets/base/images/down-arrow.svg) no-repeat right 18px;
$shortingBoth:url(/assets/base/images/PPP-icons.svg) no-repeat -136px -4px;
$shortingAsc:url(/assets/base/images/PPP-icons.svg) no-repeat -136px -4px;
$shortingDesc:url(/assets/base/images/PPP-icons.svg) no-repeat -136px -10px;
$maplocation:url(/assets/base/images/PPP-icons.svg) no-repeat -26px 0;
$custom-selectPosition:93% 9px;
$calendarIcon: url(/assets/base/images/PPP-icons.svg) no-repeat -151px -33px;
$pppCircleArrowIcon: transparent url(/assets/base/images/PPP-icons.svg) no-repeat -296px -6px;
/* Ripple Out */
@-webkit-keyframes hvr-ripple-out {
    100% {
      top: -12px;
      right: -12px;
      bottom: -12px;
      left: -12px;
      opacity: 0;
    }
  }
  @keyframes hvr-ripple-out {
    100% {
      top: -12px;
      right: -12px;
      bottom: -12px;
      left: -12px;
      opacity: 0;
    }
  }



$global-border-color:#9FA3A6;
$global-border-color2:#CFD1D2;
$font-sami-bold:600;
$text-color:#101820;
$text-color2:#40464D;
$colorC4C: #C4C4C4;

// pro sba 
$global-border-color:#9FA3A6;
$global-border-color2:#CFD1D2;
$dropdown-list-bg-color:#FBFBFB;
$dropdown-list-on-hover:#fafafa;
$aggrid-odd-row-color:#FAFAFB;
$aggrid-all-border-color:#F3F3F4;
$aggrid-header-row-color:#F3F3F4;
$bottom-margin: 50px;
$line-width: 1px;
$line-width-h:1px;
$line-color: black;
$bg-color: white;
$field-border-color:#CFD1D2;
$submited: #4B4B4B;
$irgbshadow:rgba(42, 81, 53,.25);
$rgb:#eeeeee;
$rgb2:rgba(61, 61, 61, 0.08);

$color4a4: #1F3858;
$parent-accordion-bg-color:#FBFBFB;
$document-page-border:#F5F5F5;

$secondary-color:#4093C3;
$colorf58: #618fe4;
$rgb2:rgba(61, 61, 61, 0.08);
$color288:#288E4C;
$color616:#616161;
$colorfce:#FCE7E6;
$colore96:#E96D6D;
$colorfcd:#fcd29b9c;
$colorec8:#ec8e41;
$colorc7e:#C7EAD4;
$colora57:#A57B0D;
$colord1f:#D1FEBE;
$color3e6:#3E682C;
