@media (min-width: 1200px) {
    .container {
        max-width: 1260px;
    }
    .yourBusinessTable {
        .order-2.col-xl-7 {
          flex: 0 0 66.6666666667%;
          max-width: 66.6666666667%;
        }
      }
}
.ownersBusinessTitle {
    position: relative;
    padding: 0 0 0 190px;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 180px;
      height: 126px;
      background: url(../images/sprite-icons.svg) no-repeat -434px -13px;
    }
  }

@media (max-width: 1300px) {
    .countryCode {
        max-width: 13.667%;
    }
    .emibg {
        .text36 {
            font-size: 30px;
        }
    }
}
@media (max-width: 1250px) {
.cblock{ position: unset; width: 100%!important}
.opportunity .owl-carousel .owl-nav .owl-next { margin-right: -11px; }
.opportunity .owl-carousel .owl-nav .owl-prev { margin-left: -11px; }
}
@media (max-width: 1024px) {
    .fs50 {font-size: 36px !important; line-height: normal; }
    .fs40 {
        font-size: 30px !important;
    }
    .fs20 {
        font-size: 16px !important;
    }
    .lh {
        line-height: 23px;
    }
    .fs26 {
        font-size: 21px;
    }
    .fs24 {
        font-size: 19px;
    }
    .fs18 { font-size: 14px !important; }
    .lh36 { line-height: 24px !important; }
    .dashboardContent {
        &.acl {
            > .container {
                >.row {
                    &.justify-content-md-center {
                         .col-10 { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
                    }
                }
            }

            .user-mgmt-section {
                .row {
                    &.justify-content-md-center  {
                        .col-7 {
                            h2 { font-size: 18px; }
                        }
                        .col-5 { padding-left: 0;
                            &.text-right {
                                .btn { font-size: 11px; padding: 9px 10px; min-width: 0; margin-top: 5px; }
                            }
                        }
                    }
                }
            }
        }
    }
    .opportunity .owl-carousel .owl-nav .owl-next { margin-right: -31px; }
    .opportunity .owl-carousel .owl-nav .owl-prev { margin-left: -31px; }
}

@media (max-width: 767px) {
    .commonTitle {
        padding: 0;
        text-align: center;
        .page-title {
          padding: 140px 0 0 0 !important;
          font-size: 20px;
        }
        &::after {
          margin-left: -76px;
          left: 50%;
        }
        &.onlyTitle {
          .page-title {
            padding: 140px 0 0 0 !important;
            font-size: 20px;
          }
          .page-title + .fs14.fwm {
            display: none;
          }
          &::after {
            margin-left: -76px;
            left: 50%;
            width: 160px;
          }
        }
      }
    .mainWraper { padding-bottom: 0; }
    .logoRow {
        .justify-content-end {
            .py-2 {
                padding-top: 19px!important;
            }
        }
    }
    .bookAppoint {
        &::after {
            top: 7px;
            right: 39px;
        }
        .btn {
            .dobicon {
                &::after {
                    top: 10px;
                    right: 27px;
                }
            }
        }
    }
    .businessFunForm .form-control {
        padding: 6px !important;
        font-size: 16px;}
        
        .businessFunForm .infoTip { top: 7px; }
        .dashRightBtn{
            .btn{
                margin-bottom: 10px;
            }
        }

    .PfsDate .calendar {
        top: 23px;
        // right: 3px;
      }
        
    .yodleeWidget .yodleeWrapper { display: block !important; }
    .opportunity .owl-carousel .owl-nav .owl-next { margin-right: -11px; font-size: 0; }
    .opportunity .owl-carousel .owl-nav .owl-prev { margin-left: -11px; font-size: 0; }
}

@media (max-width: 640px) {
    .wbg { padding: 15px }
    .btn {
        padding: 7px 20px!important;
        font-size: 14px!important;
    }
    .lh {
        line-height: 21px;
    }
    .fs14 {
        font-size: 12px !important;
    }
    .fs18 {
        font-size: 14px !important;
    }
    .fs26 {
        font-size: 16px !important;
    }
    .fs24 {
        font-size: 14px !important;
    }
    
    .user-mgmt-section {
        .row {
            &.justify-content-md-center  {
                .col-7 {
                    h2 { font-size: 18px; }
                }
                .col-5 { padding-left: 0;
                    &.text-right {
                        .btn { font-size: 11px !important; padding: 9px 10px !important; min-width: 0; margin-top: 5px; }
                    }
                }
            }
        }
    }
}

@media (max-width: 486px) {
    .logoRow {
        .justify-content-end {
            .py-2 {
                padding-top: 11px!important;
            }
        }
    }
    
    .dashboardContent {
        &.acl {
            > .container {
                >.row { margin: 0;
                    &.justify-content-md-center {
                         .col-10 { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
                    }
                }
            }
        }
    }
}
@media (min-width: 400px) {
    .selfServiceHead{
        .headerinner{
            .row.logoRow{
                .col-12.col-sm{
                    flex: 0 0 66.666667%;
                    max-width: 66.666667%;
                }
                .col-md-12.col-lg-6.ml-auto.text-lg-right.pl-sm-0.text-center{
                    flex: 0 0 33.333333%;
                    max-width: 33.333333%;
                }
            }
        }
    }
}