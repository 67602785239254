.sba_libs  { background: $colorfff; height: auto; min-height: 100vh;
    input::-ms-reveal, input::-ms-clear { display: none; }
    // .dropdown-menu { max-height: 200px; overflow: auto; }
    .btn { border-width: .1em; }
    .loanRequestTitle { position: relative; padding: 0 0 0 220px;
        .page-title { font-size: 22px; font-weight: 600; }
        &:after{ content: ""; position: absolute; top: 0; left: 0; width: 220px; height: 126px; background:url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat  -16px -13px; }
    }
    
    .uploadDocTitle{ position: relative; padding: 0 0 0 220px;
        &:after{ content: ""; position: absolute; top: 0; left: 0; width: 200px; height: 126px; background:url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat  -21px -166px; }
    }
    .businessdetailTitle { position: relative; padding: 0 0 0 220px;
        .page-title { font-size: 22px; font-weight: 600; }
        &:after{ content: ""; position: absolute; top: 0; left: 0; width: 200px; height: 126px; background:url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat  -721px -13px; }
    }
    .ownersdetailTitle { position: relative; padding: 0 0 0 220px;
        .page-title { font-size: 22px; font-weight: 600; }
        &:after{ content: ""; position: absolute; top: 0; left: 0; width: 200px; height: 126px; background:url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat  -268px -13px; }
    }
    .eligibilityquestionsTitle { position: relative; padding: 0 0 0 220px;
        .page-title { font-size: 22px; font-weight: 600; }
        &:after{ content: ""; position: absolute; top: -10px; left: 0; width: 200px; height: 126px; background:url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat  -495px -13px; }
    }
    .ownersBusinessTitle { position: relative; padding: 0 0 0 220px;
        .page-title { font-size: 22px; font-weight: 600; }
        &:after { content: ""; position: absolute; top: 0; left: 0; width: 185px; height: 126px; background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -722px -13px; }
    }
    .affiliateInformationTitle  { position: relative; padding: 0 0 0 220px;
        .page-title { font-size: 22px; font-weight: 600; }
        &:after { content: ""; position: absolute; top: 0; left: 0; width: 185px; height: 126px; background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -722px -13px; }
    }
    .additionalOwnerDetailsTitle  { position: relative; padding: 0 0 0 220px;
        .page-title { font-size: 22px; font-weight: 600; }
        &:after { content: ""; position: absolute; top: 0; left: 0; width: 185px; height: 126px; background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -722px -13px; }
    }
    .fundaccountinfoTitle { position: relative; padding: 0 0 0 220px;
        .page-title { font-size: 22px; font-weight: 600; }
        &:after { content: ""; position: absolute; top: 0; left: 0; width: 185px; height: 126px; background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -252px -166px; }
    }
    .pfsTitle {
      position: relative;
      padding: 0 0 0 220px;
      &:after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0;
        width: 180px;
        height: 126px;
        background: url(../images/sprite-icons-assisted-journey.svg)  no-repeat -480px -155px;
      }
    }

    .collateralDetailsTitle {
      position: relative;
      padding: 0 0 0 220px;
      &:after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0;
        width: 180px;
        height: 126px;
        background: url(../images/sprite-icons-assisted-journey.svg)  no-repeat -728px -157px;
      }
  }
    .sbaform { position: relative; padding: 0 0 0 220px; min-height: 150px;
        .page-title { font-size: 22px; font-weight: 600; }
        &:after { content: ""; position: absolute; top: 0; left: 0; width: 185px; height: 126px; background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -947px -10px; }
    }

    .treeIcon { cursor: pointer; display: inline-block; background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -236px -413px; width: 25px; height: 25px; }
    .refreshIcon{background: url(../images/refresh.svg) no-repeat 0 0;width: 25px;height: 25px;cursor: pointer;background-size: 24px;}
    ng-select::-ms-expand { display: none; }
    ng-select, ng-select.form-control { padding: 0; background:$colorfff url(../images/down-arrow1.svg) no-repeat right 8px center; 
        -webkit-appearance: none; -moz-appearance: none; -ms-appearance: none; -o-appearance: none;
        .ng-value-label,.ng-placeholder{ padding-left: 10px; }
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option { white-space: nowrap!important; }
    .normalClass .ng-dropdown-panel .ng-dropdown-panel-items .ng-option { white-space: normal!important; }
    .ng-select >.ng-select-container .ng-value{padding-right: 33px!important;}
    .ng-select .ng-clear-wrapper{margin-right: 20px; margin-top: 3px;}

    .ng-select.ng-select-single .ng-select-container { height: auto; border: none; background: transparent;min-height: 100%;
        .ng-clear-wrapper{ padding-right: 30px!important;}
        .ng-value-container {    
            .ng-input { top: auto; }   
        }
    }
    .ng-select .ng-arrow-wrapper { display: none; }
    .form-control {
        &:disabled { opacity: 0.7; cursor: not-allowed; }
        
    }
    .ng-select-disabled { background-color: #eeeeee !important;
        opacity: 0.7;
        cursor: not-allowed;
    }
    .calendar { background: url(../images/sprite-icons-assisted-journey.svg) no-repeat -320px -330px; height: 20px; width: 16px; border: none; position: absolute; top: 39px; right: 28px; }
    .remove-link, .deleteIcon { border: none; background:url(../images/sprite-icons-assisted-journey.svg) no-repeat -424px -310px !important;  top: 18px !important; width:51px;height: 51px; cursor: pointer; 
        &.btn-primary:not(:disabled):not(.disabled):active:focus { border: none; }
    }
    
    sba-primary {
        .addIcon { background: url(../images/sprite-icons-assisted-journey.svg) no-repeat -807px -333px; width: 13px; height: 13px; display: inline-block;
            position: relative; margin-right: 3px; top: 1px; }
    }
        
    .totalAmount { font-size: 16px; font-weight: $font-sami-bold; color: $text-color; border-bottom: 1px solid $global-border-color;
        &.amount{ color: $color6f9; padding-left: 20px; }
    }
    .noteText { padding-bottom: 10px; font-size: 14px; font-weight:$font-regular; line-height: 21px;
        .note { font-weight: $font-sami-bold; background: none; padding: 0; }
    }
    loan-type {
        .bottomPartition { margin-top: 20px; }
    }

   
    .checWrap {
        [type="checkbox"] {
            &:not(:checked) { position: absolute; left: -9999px;
                + {
                    label.clt { position: relative; padding-left: 2.25em; cursor: pointer;
                        &:before { content: ''; position: absolute; left: 0; top: 0px; width: 1.4em; height: 1.4em; border: 0.1em solid $text-color; background: #fff; border-radius: 2px; box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1); }
                        &:after { content: '\2713\0020'; position: absolute; top: .15em; left: .22em; font-size: 1.3em; line-height: 0.8; color: $text-color; transition: all .2s; font-family: 'Arial Unicode MS', Arial; opacity: 0;  transform: scale(0); }
                    }
                }
            }
            &:checked { position: absolute; left: -9999px;
                + {
                    label.clt { position: relative; padding-left: 2.25em; cursor: pointer; font-weight: 600;
                        &:before { content: ''; position: absolute; left: 0; top: 0px; width: 1.4em; height: 1.4em; border: 0.1em solid $text-color; background: #fff; border-radius: 2px; box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1); }
                        &:after { content: '\2713\0020'; position: absolute;top: .35em; left: .22em; font-size: 1.0em; line-height: 0.8; color: $text-color; transition: all .2s; font-family: $font-familyp; opacity: 1; font-weight: $font-sami-bold; transform: scale(1); }
                    }
                }
            }
            &:disabled {
                &:not(:checked) {
                    + {
                        label.clt {
                            &:before { box-shadow: none; border-color: #bbb; background-color: #ddd; }
                        }
                    }
                }
                &:checked {
                    + {
                        label.clt {
                            &:before { box-shadow: none; border-color: #bbb; background-color: #ddd; }
                            &:after { color: #999; }
                        }
                    }
                }
                + {
                    label.clt {
                        color: #aaa;
                    }
                }
            }
        }
        label {
            &:hover {
                &:before { border: 2px solid $text-color !important; }
            }
        }
        &.check-sm {
            [type="checkbox"] {
                &:not(:checked) {
                    + {
                        label.clt {
                            &:before { width: 1.5em; height: 1.5em; }
                            &:after { font-size: 1.3em; }
                        }
                    }
                }
                &:checked {
                    + {
                        label.clt {
                            &:before { width: 1.5em; height: 1.5em; }
                            &:after { font-size: 1.3em; }
                        }
                    }
                }
            }
        }
    }


    .radioWrap {
        >div{
            &:first-child {        
                .rlt{margin-left:0;}
            }
        }
        .form-check-label { background: $coloreae; padding: 4px 6px 3px 7px; margin-right: 10px; @include border-radius(2px); margin-bottom: 10px; }
        [type="radio"] {
            &:checked { position: absolute; left: -9999px;
                ~ {
                    .rlt { position: relative; padding-left:23px; padding-right:0px; cursor: pointer; line-height: 20px; display: inline-block;  font-weight: 600; color: $text-color; font-size: 14px; margin-bottom: 0;
                        &:before { content: ''; position: absolute; left: 0; top: 2px; width: 16px; height: 16px; border: 4px solid $text-color; border-radius: 100%; background: $text-color; -webkit-transition: all 0.2s ease; transition: all 0.2s ease; opacity: 1; }
                        &:after { content: ''; width: 8px; height: 8px; background: $colorfff; position: absolute; top: 6px; left: 4px; border-radius: 100%; -webkit-transform: scale(1); transform: scale(1); }
                    }
                }
                &:focus {
                    +div+
                    span.rlt{
                        &:before { box-shadow:0 0 0 0.1rem $primary-color!important; }
                    }
                }
        }
            &:not(:checked) { position: absolute; left: -9999px;
                ~ {
                    .rlt { position: relative; padding-left:23px; padding-right:0px; cursor: pointer; line-height: 20px; display: inline-block; color: $text-color; font-size: 14px;
                        &:before { content: ''; position: absolute; left: 0; top: 2px; width: 16px; height: 16px; border: 1px solid $text-color;
                            border-radius: 100%; background: $colorfff; }
                        &:after { content: ''; width: 12px; height: 12px; background: $text-color; position: absolute; top: 6px; left: 4px; border-radius: 100%; 
                            -webkit-transition: all 0.2s ease; transition: all 0.2s ease; opacity: 0; -webkit-transform: scale(0); transform: scale(0); }
                    }
                }
                &:focus {
                    +div+
                    span.rlt{
                        &:before { box-shadow:0 0 0 0.1rem $primary-color!important; }
                    }
                }
            }
            &:disabled {
                &:not(:checked) {
                    ~ {
                        .rlt {
                            &:before { box-shadow: none; border-color: $global-border-color; background-color: $dropdown-list-bg-color; opacity: 0.5; cursor: not-allowed; }
                        }
                    }
                }
                &:checked {
                    + {
                        .rlt {
                            &:before { box-shadow: none; border-color: $global-border-color; background-color: $dropdown-list-bg-color; opacity: 0.5; cursor: not-allowed; }
                            &:after { color: $reddiscolor; }
                        }
                    }
                }
                + {
                    .rlt { color: $global-border-color; }
                }
            }
        }
    }
    .backIcon{ padding: 3px 0; position: relative; padding-left: 12px!important; font-size: 16px !important; font-weight: $font-medium !important; 
        line-height: 24px !important; color: $color6f9 !important; background: none;
        &:focus { box-shadow: none; }
        &::after{ background: url(../images/sprite-icons-assisted-journey.svg) no-repeat  -382px -383px; content: ""; position: absolute; top:9px;left: 0; 
            width: 8px; height: 12px; }
    }

    
.loanPurDetails { 
    rubicon-group {
      .loanBlockRow { position: relative;
        &:first-child {
          .remove-link { position: absolute; right: 83px; top: 24px; }
        }
        .remove-link { position: absolute; right: 83px; top: 24px; }
      }
    }
    .addMoreBtn { position: absolute; bottom: 19px; right: 15px; width: 76px !important;
      .btn { font-size: 14px; }
    }
    .onError {
        .addMoreBtn {
          bottom: 45px ;
        }
    }
  }
  .form-group .input-group .input-group-prepend .input-group-text { top: 13px; left: 15px; }
  .form-group .input-group .form-control { padding-left: 25px; }

  .ag-theme-balham {
    // @include ag-theme-balham((
    //     odd-row-background-color: $colore5e
    // ));

      .ag-header-cell-text {
          color:$text-color; font-weight: $font-sami-bold;font-size: 14px; 
      }
};
.ag-theme-balham .ag-row-hover{
    background: transparent;
}
.ag-row-odd{
    background-color:$aggrid-odd-row-color!important;
}
.ag-theme-balham { border-radius: 10px 10px 0 0; overflow: hidden; background: $primary-color; font-size: 13px; position: relative; z-index: 1; }
.ag-theme-balham .ag-root-wrapper { border-top: none !important; }
.ag-theme-balham .ag-cell-value, .ag-theme-balham .ag-group-value { overflow: visible !important;text-overflow: unset; white-space: normal;}
.ag-theme-balham .ag-header-cell::after, .ag-theme-balham .ag-header-group-cell::after { content: none !important; }
.ag-theme-balham .ag-row { padding:0; font-size: 13px; color: $text-color; min-width: 100%;border-bottom: 1px solid $aggrid-all-border-color;
    >div{ padding: 10px 10px;}
}
.ag-theme-balham .ag-row-selected{background-color: transparent!important;}
.ag-center-cols-container, .ag-header-container { min-width: 100%; }
.ag-center-cols-viewport{ border-left: 1px solid $aggrid-all-border-color;border-right: 1px solid $aggrid-all-border-color;border-bottom: 1px solid $aggrid-all-border-color;}
.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell{ padding-left: 15px; padding-right: 0;}

// .ag-row-focus{
//     bs-tooltip-container{ z-index: 1;}
//         .dropdown-menu{ display: none; }
// }
.ag-row-focus+.ag-row-no-focus{
    bs-tooltip-container{ z-index: 9;}
}
.ag-row-hover{ z-index:10;
    .dropdown-menu{
        &.show{ display: block; }
    }
}
@media (min-width: 1200px) {
    .ag-root.ag-layout-auto-height, .ag-body-viewport.ag-layout-auto-height, .ag-body-viewport-wrapper.ag-layout-auto-height, .ag-header-viewport, .ag-floating-top-viewport, .ag-body-viewport, .ag-center-cols-viewport, .ag-floating-bottom-viewport, .ag-body-horizontal-scroll-viewport, .ag-virtual-list-viewport, .ag-center-cols-clipper, .ag-root-wrapper, .ag-theme-balham{
        overflow:visible; }
}
.ag-body-horizontal-scroll{ z-index: -1;}
.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value { line-height: 33px;}
.ag-theme-balham .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected)  { border-color: transparent !important; }

.datatext { max-width: 180px; display: inline-block; }
.ng-select .ng-select-container { min-height: 32px; }
.ellpstext {  overflow: hidden; text-overflow: unset; max-width: none; display: inline-block; }
.bNamefix { max-width: 200px!important; font-size: 16px; font-weight: $font-sami-bold; color: $text-color2; }
.ng-select .ng-select-container .ng-value-container .ng-placeholder { font-size: 14px; }
.ag-theme-balham .ag-cell br, .ag-theme-balham .ag-cell { line-height: normal; }

.ag-cell { overflow:visible;  }
.ag-root.ag-layout-auto-height,
.ag-body-viewport.ag-layout-auto-height,
.ag-body-viewport-wrapper.ag-layout-auto-height,
.ag-theme-balham .ag-row-odd { background: $colorfff; }
.ag-root-wrapper { border-radius: 10px 10px 0 0; }
.gridView {
    .tableHeadWrap { border-radius: 5px 5px 0 0; }
    ag-grid-angular { border-radius: 0 0 5px 5px !important; }
}
#myGrid.ag-theme-balham {  
    .ag-header { background-color: $aggrid-header-row-color; border-bottom: 1px solid $aggrid-all-border-color; }
    .ag-root-wrapper { overflow: hidden; border: none; border-radius: 0 0 5px 5px !important;  }
  }
  app-your_business_info {
    rubicon-radio {
        .tooltip {
            .tooltip-inner { min-width: 250px; }
        }
    }  
    .tooltip {
          .tooltip-inner { min-width: 0px; }
      }
  }

.onerListDropdown1 {
    .btn-primary { background: $colorfff !important; border-color: $color6f9 !important; color: $color6f9 !important; padding: 7px 20px;  }
}
.btnMultib {
    .btn-default { background: $colorfff !important; border-color: $color6f9 !important; color: $color6f9 !important; }
}
.btn-primary:not(:disabled):not(.disabled):active:focus { border-width: .1em; }


.hv-wrapper { display: flex;
    .hv-item { display: flex; flex-direction: column; margin: auto; margin-top: 0.5px;
      .hv-item-parent { margin-bottom: $bottom-margin; position: relative; display: flex; justify-content: center;
        p { font-weight: bold; color: $text-color; }
        .ownerTreeprimaryBusinessHeight { color: $text-color !important; }
        &:after { position: absolute; content: ''; width: $line-width; height: $bottom-margin / 2; bottom: 0; left: 50%; background-color: $line-color; transform: translateY(100%); }
      }
      .hv-item-children { display: flex;
        justify-content: center;
        .hv-item-child { padding: 0 5px; position: relative;
          &:only-child:after { display: none; }
          &:before, &:after { content: ''; position: absolute; background-color: $line-color; left: 0; }
          &:before { left: 50%; top: 0; transform: translateY(-100%); width: $line-width; height: $bottom-margin / 2; }
          &:after { top: -$bottom-margin / 2; transform: translateY(-100%); height: $line-width-h; width: 100%; }
          &:first-child:after { left: 50%; width: 50%; }
          &:last-child:after { width: calc(50% + 1px); }
        }
        .coborrower {
          &::before { display: none; }
          &::after { background-color: white; height: 10px; top: -22px; left: 5px; }
        }
      }
  
    }
  
    // Reverse View
    // Flexbox makes the magic here. We just reverse flex-direction
    // and move the linse to the opposite side. That`s it
    &.reverse {
      .hv-item { flex-direction: column-reverse;
        .hv-item-parent { margin-top: $bottom-margin; margin-bottom: 0;
          &:after { top: 0; transform: translateY(-100%); }
        }
        .hv-item-child { display: flex; align-items: flex-end;
          &:before { top: auto; bottom: 0; transform: translateY(100%); }
          &:after { top: auto; bottom: -$bottom-margin / 2; transform: translateY(100%); }
        }
      }
    }
  }
  
    .hv-item-parent {
        .firstParent { margin-left: -1px; background: $color6f9; padding: 7px 15px 22px 15px; display: block; font-size: 14px; font-weight: 400; border-radius: 9px 9px 0 0;
        color: $colorfff; margin-bottom: -15px; position: relative; z-index: 0; }
        .parnetinner { background: $colorfff; padding: 10px; position: relative; z-index: 2; display: block; border-radius: 10px 10px 10px 10px; }
    }

  .childBox  { position: relative; padding: 0px; color: $text-color !important;
        &::before { content: ""; position: absolute; top: 0; left: 0; background: $color6f9; display: block; width: 100%; height: 20px; border-radius: 9px 9px 0 0; }
        .child-heading { background: $colorfff; color: $text-color; position: relative; z-index: 1; margin-top: 13px; padding: 15px 10px 10px 10px; font-size: 16px; border-radius: 9px 9px 0 0; font-weight: 400 !important; }
        .chartinnerdetails { padding: 10px; display: block; }
    }

    .piyanoNav {
        &::after {  height: 88% !important; }
    }

    .gridView { border-bottom: 1px solid $aggrid-all-border-color;
        .headRow { @include border-radius(5px 5px 0 0); }
        .headRow + .contentRow {
        #myGrid.ag-theme-balham { @include border-radius(0 0 10px 10px);
            .ag-header { color: $text-color; @include border-radius(0); }
        }
        }
        .ag-cell-wrap-text,
        .ag-cell-wrapper { white-space: normal; word-break: normal; }
    }

    .treeStructure { max-width: 1240px; 
        .modal-header { color: $text-color; border: none; background: none; 
            .close { color: $text-color; }
        }
        .coborrowerTrue{ overflow-y: hidden; overflow-x: auto; padding-bottom: 20px; display: flex; align-items: center; justify-content: center; }
        .icon {
            &.icon-company { background: url(../images/sprite-icons-assisted-journey.svg) -182px -377px no-repeat; margin-right: 5px; vertical-align: middle; width: 30px; height: 30px; display: inline-block; }
            &.iconindividual { background: url(../images/sprite-icons-assisted-journey.svg) -236px -328px no-repeat; margin-right: 5px; margin-bottom: 3px; vertical-align: middle; width: 25px; height: 22px; display: inline-block; }
            &.iconbusiness { background: url(../images/sprite-icons-assisted-journey.svg) -621px -328px no-repeat; margin-right: 5px; margin-bottom: 3px; vertical-align: middle; width: 25px; height: 22px; display: inline-block; }
            &.confirmed { padding-left: 30px; position: relative; font-size: 12px;
            &::after { background: url(../images/sprite-icons-assisted-journey.svg) -297px -377px no-repeat; content: ""; position: absolute; top: -4px; left: 0px; width: 22px; height: 22px; }
            }
        }
    }
    .editIcon2, .deleteIcon2, .infoicon-blue, .infoicon-blue, .treeIcon { cursor: pointer; display: inline-block; }
    .editIcon2 { background: url(../images/sprite-icons-assisted-journey.svg) no-repeat -587px -332px; width: 16px; height: 15px; position: relative; top: 2px }
    .deleteIcon2 { background: url(../images/sprite-icons-assisted-journey.svg) no-repeat -396px -332px; width: 16px; height: 15px; }
    .deleteIcon3 { background: url(../images/sprite-icons-assisted-journey.svg) no-repeat -396px -329px; width: 16px; height: 15px; }
    .lock2 { background: url(../images/sprite-icons-assisted-journey.svg) no-repeat -426px -380px; width: 21px; height: 20px; }
    .sendConsentIcon{background: url(../images/sprite-icons-assisted-journey.svg) no-repeat -1038px -328px; width: 17px;height: 21px; cursor: pointer; display: inline-block; padding-left: 20px;}


    .statusIcon, .statusIcon1 { padding-left: 30px; position: relative; font-size: 13px; white-space: nowrap; color: $color0c5; text-decoration: none !important;
        &.confirmed:after { background: url(../images/sprite-icons-assisted-journey.svg) -767px -329px no-repeat; content: ""; position: absolute; top: -2px; left: 0; width: 20px; height: 20px; }
        &.sent:after { background: url(../images/sprite-icons-assisted-journey.svg) -838px -327px no-repeat; content: ""; position: absolute; top: -2px; left: 0; width: 22px; height: 22px; }
    }
    .nrIcon { position: relative; font-size: 13px; white-space: nowrap; color: $color0c5; text-decoration: none !important;
        &.confirmed:after { background: url(../images/sprite-icons-assisted-journey.svg) -767px -329px no-repeat; content: ""; position: absolute; top: -2px; left: 0; width: 20px; height: 20px; }
        &.sent:after { background: url(../images/sprite-icons-assisted-journey.svg) -838px -327px no-repeat; content: ""; position: absolute; top: -2px; left: 0; width: 22px; height: 22px; }
    }

    .threeDote { padding: 0 5px;  position: absolute; top: -5px; right: 20px; cursor: pointer;
        .dote { background-color: $color6f9; margin-bottom: 2px; width: 4px; height: 4px; @include border-radius (20px); display: block; }
    }
    app-your_business_info .dropleft .threeDote { top: 12px; }
    platform-core-action-cell-renderer{
        .threeDote+.dropdown-menu { padding: 0; top: -2px !important;
          .dropdown-item { padding: 5px; }
        }
      }
      app-your_business_info { 
        platform-core-action-cell-renderer {
          .deleteIcon2 { background-position: -395px -330px; margin: 0 5px 0 2px; }
          .sendConsentIcon { margin-right: 5px;}
        }
      }

      .thankyouWrap { padding-bottom: 30px;
        .mainTitle { color: $color6f9 !important; font-size: 36px !important; }
          .subTitle { font-size: 22px !important; }
          .contentBlock { font-size: 22px !important; color: $text-color; }
            .btn-primary { background: $primary-color !important; border-color:$primary-color !important; 
                &:hover { background: $primary-color !important; border-color: $primary-color !important; }
                &:focus { background: $primary-color !important; border-color: $primary-color !important; box-shadow: none; }
                &:active { background: $primary-color !important; border-color: $primary-color !important; box-shadow: none!important; }
              }
          }
          .countryOne {
            rubicon-text { display: flex; }
          }
        
        // .submitWrap {
        //     .img-col {
        //         padding-right: 100px;
        //         img {
        //             max-width: 250px;
        //             }
            
        //     }
        // }
        
    //----- START SBA-FORM-1919 CSS -----//
        .notewrap { max-width: 100%; border-radius: 10px !important; -webkit-border-radius: 10px !important;
            -moz-border-radius: 10px !important; border: 1px solid #4093C3; background: rgba(61, 61, 61, 0.08); padding: 25px; width: 325px; }

        h1.page-title, .page-title { font-family: "Poppins", sans-serif; font-size: 22px; font-weight: 600; color: #101820; }
        .estimatedRow rubicon-amount { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }
        .p-label { color: #101820; font-size: 13px; font-weight: 400; font-family: "Poppins", sans-serif; line-height: 21px; margin-bottom: 3px; }

        .countriesExport {
            > rubicon-group {
            display: flex !important;
            > .sba-primarycountries_export_arr:first-child {
                .form-group { width: 535px;
                    .col-11.ml-auto { flex: 0 0 33.33333333%; max-width: 33.33333333%; }
                }
                .p-label { display: block !important; padding-right: 0; }
            }
            }
        }
     

        .positionset { position: relative;
            .deleteIcon { position: absolute; right: 15px; top: -5px !important; }
            &:not(:first-child) {
                .forHide { visibility: hidden; }
            }
        }
    //----- END SBA-FORM-1919 CSS -----//
    //---- KB and Chetan css change move on custom.scss to sbapro ----//
      about-business {
        .btnMultib .btn-default {  background-color:transparent !important; border-color: $color6f9 !important; color: $color6f9!important; }
      }
      borrower-information {
        .btnMultib .btn-default {  background-color:transparent !important; border-color: $color6f9 !important; color: $color6f9!important; }
      }

      application-view {
        h2 { font-size: 15px; font-weight: 600;
            button { border: none;font-size: 15px; font-weight: 600; background: none; }
        }
        .accordion {  
            .card { margin-bottom: 10px; border: none;
                .card-header { background-color: #E9F7EF; border: 1px solid #CFCFCF; border-radius: 5px;
                    button { padding: 0; color: $text-color !important; text-decoration: none;
                        &::after { content: ""; position: absolute; right: 10px; top: 17px; width: 20px; height: 10px; display: inline-block; background: url(../images/sprite-icons-assisted-journey.svg) -58px -332px no-repeat; transform: rotate(180deg); } 
                        &.collapsed {
                            &::after {  display: inline-block; background: url(../images/sprite-icons-assisted-journey.svg) -58px -332px no-repeat; transform: rotate(0deg); }
                        }
                     }
                }
            }
        }
    }

    .search-result{ top: 75px; width: 96%; background: $colorfff; @include white-shadow(); @include border-radius(0 0 10px 10px); margin-top: -3px;
        .srList{ 
            // max-height: 400px; overflow-x: hidden; overflow-y: auto;
            li{ padding: 5px 10px; font-size: 14px;line-height: 30px; border-bottom: 1px solid $field-border-color; cursor: pointer;
                a{ font-size: 14px; color: $text-color; cursor: pointer;line-height: 30px;}
                &:last-child{ border-bottom: none; }
                &:hover { background-color: $dropdown-list-on-hover; }
            }
        }
    }

    application-view .backendAccordion, .uploadDocTitle + .backendAccordion,.uploadDocTitle  + .docfilterMandatory + .backendAccordion {
        padding-top: 1.5rem !important;
        .wbg.docWgtMain{ box-shadow: none;  background: $parent-accordion-bg-color; padding: 0;
            .dockFname{ padding: 10px 0;}
           
            .ptc.fs13.dflex1{ font-size: 14px!important; color: $text-color!important;padding-right: 165px;}
            .docFilesWgtInner{ padding-left: 30px; }
            .docFiles1{background: $colorfff;border: 1px solid $document-page-border; border-radius: 0 0 2px 2px;
                .docFiles2{
                    .docFiles7:last-child{ border-bottom: none!important; }
                }
            }        
        }
        .docFilesWgtInner4 { font-size: 12px !important; padding-top: 0 !important; padding-left: 12px!important;}
        .card { border: none; background: none;
            .card-body { padding: 0px 0px 15px 0px; border: none; }
            .card-header { background: none; border-bottom: none; padding-left: 0px; padding-right: 0px; 
                .plusMinusIcon { position: absolute; right: 16px; top: -3px;font-size: 35px; color:$colorfff; font-size: 0px; }
                &::after { content: none; }
            }
        }
    
        .sub-accordion {
            .card {
                .card-body { border:none; }
                .card-header { background: transparent; padding: 10px 15px; border-radius: 0px !important;
                    .plusMinusIcon { position: absolute; right: 0; top: -3px;font-size: 35px; color:$primary-color; font-size: 0px; }
                }
            }
        }
        .docAccorWgtPanel {
            .docAccorWgtRow {
                .col-12.col-lg.d-flex { padding-right: 0px !important;
                    .attachment-doc { top: 0px; }
                  }
                  .col-12.col-lg-auto.text-right {
                    .btn.docUploadWgt2 { margin-left: 0px !important; display: none !important;}
                  }
            }
        }
    }    

/* for ducument */ 
.attachment-doc { float: right; color: $color878; background: none; border: 0.1em solid $color878; border-radius: 50px !important; font-size: 14px; font-weight: $font-sami-bold; border-radius: 5px;position: absolute;
    right: 0px; top: 0; padding: 3px 15px 3px 10px; cursor: pointer;
    span { margin-right: 3px; background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -823px -379px; width: 20px; height: 20px; display: inline-block; vertical-align: middle; }
}
.docEmptyClass { margin-right: 10px; margin-top: 4px; display: inline-block; width: 25px; height: 25px; background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -494px -326px; }
.docUploadedClass { margin-right: 10px; margin-top: 3px; display: inline-block; width: 25px; height: 25px; background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -191px -326px; }
.dflex1 {flex: 1; position: relative;padding-right: 130px;}
lib-document-listing-item{ padding: 0px 0px !important; display: block;}
.no_child_docs{
    .attachment-doc{ display: none !important; }
}
.no_child_docs.attachment-doc{ display: none !important; }
.uploadBtnWgt { padding: 3px 20px; font-weight: 500;  font-size: 14px;
    span { margin: 0 !important; }
}
.btn {
    &.docUploadWgt2  { font-size: 12px !important;  padding: 4px 15px 5px 10px !important; font-weight: $font-medium !important; background: $secondary-color!important;border: $secondary-color!important;margin-left: 10px; display: none !important;
        .docUploadWgt3 { margin-left: 0 !important;
            .link_bank_icon { margin-right: 3px; background: url(/assets/base/images/sprite-icons.svg) no-repeat -282px -153px; border-right: 1px solid $colorfff; width: 23px; height: 20px; display: inline-block; vertical-align: middle; }
        }
    }
}
    .dragndropdocWgt { background-color: $rgb2!important; border:1px dashed $secondary-color!important; @include border-radius(7px);
        .dragndropdocWgtWrapper2{ overflow: hidden;
            .dragndropdocWgtIcon{width: 39%;justify-content: flex-end !important;display: flex !important; float: left;
                .dragndropdocWgtIcon2 { width: 30px; height: 28px; margin: 13px 30px 0 0; display: inline-block; background:url(../images/sprite-icons-assisted-journey.svg) no-repeat -663px -329px; }
            }
            .rightBodyDragDrop{ float: left;width: 50%;
                .dragndropdocWgtheading{ font-size: 14px; font-weight: $font-sami-bold; color: $secondary-color; padding:10px 0 2px; margin: 0;}
                .dragndropdocWgtLabel{ font-size: 12px; font-weight:$font-regular; color: $secondary-color;margin-bottom: 8px; pointer-events:none;}
            }
        }
    }
    .common-file{ background:url(../images/sprite-icons.svg) no-repeat -284px -102px;width: 11px;height: 14px;padding-left: 11px;margin-top: 6px; }
    .connectBanker{ background:url(../images/sprite-icons.svg) no-repeat -233px -300px;width:23px;height: 27px;}
    .downloadIcon{background: url(../images/sprite-icons.svg) no-repeat -358px -102px;width: 16px;height: 14px;cursor: pointer; display: inline-block;}

.docDeleteWgtModal3 {
    .btn-md { padding: 5px 20px }
}
backend-document-upload {
    .btn-default { background-color: transparent!important; border-color: $color20e; }
    .tooltip-inner { min-width: 80px; }
}

.list-styled { list-style: disc; margin-left: 10px; }

    
    .ag-body-horizontal-scroll { max-height: 0px !important; height: 0px !important; min-height: 0px !important; }
    //---- KB and Chetan sba journey css change move on custom.scss to sbapro ----//
    @media (min-width: 1200px){            
        // .ag-center-cols-viewport{ overflow: hidden !important; }
    }
    @media (max-width: 992px){            
        .order-1.sidebar-r { display: none; }
    }
    @media (max-width: 767px){ 
        .commonTitle { padding: 0; text-align: center;
            .page-title { padding: 140px 0 0 0!important;font-size: 20px; }
            &::after { margin-left: -96px;left: 50%; }
            &.onlyTitle{
                .page-title { padding: 140px 0 0 0!important;font-size: 20px; }    
                .page-title+.fs14.fwm { display: none; }
                &::after{ margin-left: -100px;left: 50%; width: 200px; }
            }
        }
        .btnMultib .btn-default { margin-bottom: 5px; }
        .backIcon::after { top: 13px; }
        .thankyouWrap .contentBlock { font-size: 16px !important; }
        .loanPurDetails #purpose_arr_rubiconField {
            rubicon-group {
              .loanBlockRow {
                .deleteIcon { right: 0 !important; width: 41px; }
                .form-control { max-width: 90%; }
              }
        
              .addMoreBtn { position: relative; right: 0; top: 0px; width: 75px !important; }
            }
          }
    }

    .eyeIcon { position: relative;
        .form-control{ padding-right: 28px;}
        .eye { position: absolute; right: 30px; top: 37px; cursor: pointer; background: url(../images/sprite-icons.svg) no-repeat -534px -147px; width: 20px; height: 20px; display: inline-block; 
            &.eyeclose { background-position:-580px -147px  }
        }
    }
    .passpolicywrap .passpolicy{ display: none; }

    .secureInfo { background: $colorfff; border: 1px solid $primary-color;        
        .lock2{background:url(../images/sprite-icons-assisted-journey.svg) no-repeat -425px -378px; width: 23px;height: 22px; vertical-align: top; } 
    }

    .agreeTncCheck{
        div.fs14{ order : 2; cursor: pointer;
            .agreeConsentModal { color: #0303c1 !important; font-weight: 500; font-style: italic; font-size: 14px;    text-decoration: underline; }
        }
    } 

    .tooltip { pointer-events: all !important; }
    .consentReceived { min-height: calc(100vh - 180px); }

    affiliate-information {
        .tooltip-inner { min-width: 150px; }
    }
    app-owner-details {
        .tooltip-inner { min-width: 250px; }
    }
    .mainWraper { min-height: calc(100vh - 80px); }
    .lexis_nexis_visible {
        .tooltip-inner { min-width: 250px; } 
    }
    
    .doclupload  {
        .eye { top: 0px; right: -21px; }
    }
    application-view .backendAccordion {
        .downloadIcon { margin-right: 30px; }
    }
}

.pfsAccordion {
    .singlelinefield {
      rubicon-amount {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
      }
    }
  
    .nav-tabs {
      margin-bottom: 30px;
      display: block;
      text-align: center;
      border-width: 2px;
  
      .nav-item {
        display: inline-block;
        margin-bottom: -1px;
  
        .nav-link {
          border: none;
          pointer-events: none;
          color: $text-color;
          padding: 0.6rem 2rem;
          font-size: 16px;
  
          &.active {
            border-bottom: 2px solid $primary-color;
            color: $primary-color;
            font-size: 16px;
            font-weight: 500;
            padding: 0.6rem 2rem;
          }
        }
      }
    }
  
    accordion-group.panel {
      margin-bottom: 1rem;
      //   border: 1px solid $primary-color;
  
      .card {
        // background-color: $rgb;
  
        .card-header {
          background-color: transparent;
  
          .plusMinusIcon {
            top: -5px;
          }
        }
  
        .card-body {
          border: none;
          padding: 5px 0 15px 0;
        }
      }
    }
  
    .pfsSpace {
      padding: 15px 15px;
      margin-bottom: 0 !important;
    }
  
    .hideLabel {
      display: none;
    }
  
    .pfs-row-grey {
      background-color: $irgbshadow;
      background: $colorfff;
    }
  
    .border,
    .border-bottom,
    .border-top,
    .border-left,
    .border-right {
      border-color: $rgb !important;
    }
  
    .actionDelete {
      .form-control {
        width: 73%;
      }
    }
    .actionDelete {
      #account_type_1680612981895 {
        width: 73%;
      }
    }

  
    .borderSpousede {
      border: 1px solid $primary-color !important;
      background-color: $colorfff;
    }
  
    .borderp {
      rubicon-group {
        .deletePfs,
        .positionset {
          border-right: 1px solid $primary-color !important;
          border-left: 1px solid $primary-color !important;
          background-color: $colorfff;
        }
  
        .form-group {
          margin-bottom: 0;
        }
      }
      .laliwrap {
        border-right: 1px solid $primary-color !important;
        border-bottom: 1px solid $primary-color !important;
        background-color: $colorfff;
        > rubicon-group {
          display: flex;
          flex-wrap: wrap;
          margin-right: -15px;
          margin-left: -15px;
        }
        .deletePfs {
          border-right: none !important;
        }
        .addMoreBtn {
          width: 100%;
          border-top: 1px solid $global-border-color !important;
          margin-top: -1px;
          border-right: none !important;
          border-bottom: none;
        }
        .deletePfs:nth-child(10) {
          border-bottom: none !important;
        }
      }
    }
    .infoText {
      cursor: pointer;
      margin-top: -2px;
      background: url(../images/sprite-icons.svg) no-repeat -233px -266px;
      width: 21px;
      height: 20px;
      display: inline-block;
    }
    .tab-content {
      .tooltip-inner {
        text-align: left;
      }
      .radioWrap {
        display: flex !important;
        margin: 0;
        padding-right: 0;
        padding-left: 0;
      }
    }
    .in12nopadd {
      rubicon-number > {
        .col-12 {
          padding: 0;
        }
      }
    }
    .notePayable {
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
  
      .maxwidth {
        width: 1640px;
  
        .col-2 {
          flex: 0 0 16.66%;
          max-width: 18.999%;
        }
      }
    }
    .stocksBonds {
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
  
      .maxwidth {
        width: 1640px;
  
        .col-2 {
          flex: 0 0 16.66%;
          max-width: 18.999%;
        }
      }
    }
    .scrollxRealEsta {
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
  
      .maxwidth {
        width: 2500px;
  
        .col-2 {
          flex: 0 0 9.999%;
          max-width: 9.999%;
        }
      }
    }
    @media (max-width: 640px) {
      .scrollxResponsive {
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
  
        .maxwidth {
          width: 1000px;
  
          .col-lg-2 {
            flex: 0 0 10.666667%;
            max-width: 10.666667%;
          }
        }
      }
    }
  
    .deletePfs {
      & + .addMoreBtn {
        border: 1px solid $primary-color;
        border-top: none;
        padding-bottom: 15px;
        padding-right: 15px;
        background: $colorfff;
      }
    }
  
    .deletePfs {
      position: relative;
  
      .deleteIcon {
        position: absolute;
        right: 10px;
        top: 2px !important;
  
        &::after {
          display: none;
          content: "";
          position: absolute;
          left: -19px;
          top: -15px;
          width: 1px;
          height: 177%;
          background: $rgb !important;
        }
      }
    }
  
    .notesPayForm,
    .stockBondForm {
      .deleteIcon {
        right: 10px;
  
        &::after {
          display: none;
        }
      }
    }
  
    .positionset {
      position: relative;
  
      .deleteIcon {
        position: absolute;
        right: -60px;
        top: 0px;
      }
  
      &:not(:first-child) {
        .forHide {
          visibility: hidden;
        }
      }
    }
    .notesPayForm,
    .stockBondForm,
    .uppaidForm {
      .actionDelete {
        .form-control {
          width: 73%;
        }
      }
      .deleteIcon {
        right: 10px;
        &::after {
          display: none;
        }
      }
    }
  }

  .pfsSpace {
    padding: 15px 15px;
    margin-bottom: 0 !important;
  }

  platform-core-pfs {
    .card-header.panel-enabled{cursor: default;}
    .accordionwrap {
        .pfsrow  {
          border-bottom: 1px solid #dee2e6!important;
           > form {
            > .form-group { margin-bottom: 0px; }
           }
        }
        .accordionLavel1 { margin-bottom: 0 !important;
            .accLavelHedding1 {
                &:before { background-position: -549px -98px; }

             }
             .accordionLavel2  { margin-top: 0px !important; margin-bottom: 0 !important;
                .infoTip { top: -3px; }
            }
             .card-body { padding-bottom: 0 !important; }
             &.panel-open { border-bottom: 1px solid 1px solid rgba(0,0,0,.125);}
        }
    }
    .accordionwrap .accordionLavel1 .accordionLavel2 .accLavelHedding2{
      padding-left: 0;
      &:before{ display: none;}
  }
  }

  .stocksBonds {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    .maxwidth {
      width: 1640px;

      .col-2 {
        flex: 0 0 16.66%;
        max-width: 18.999%;
      }
    }
  }

  .notesPayForm,
  .stockBondForm {
    .deleteIcon {
      right: 10px;

      &::after {
        display: none;
      }
    }
  }

  .greenbg {
    background: $primary-color;
  }

  .PfsDate {
    position: relative;
    .calendar {
      top: 26px;
    }
  }

  .pfs-row-height {
    // height: 60%;
    border-bottom: 1px solid $global-border-color2;
  }

  .accordionwrap {
    .panel {
      .card {
        border: 0;
        padding: 10px 0;
        .card-header {
          background: $colorfff;
          border: 0;
          padding: 0px;
        }
        .card-body {
          padding: 10px 0px 0px 0px;
        }
      }
    }
    .accordionLavel1 {
      .accLavelHedding1 {
        font-size: 14px;
        color: $text-color;
        font-weight: $font-medium;
        padding-left: 2.2rem;
        // display: inline-block;
        &.no_toggle_icon {
          padding-left: 0px !important;
          &::before {
            display: none;
          }
        }
        &.disable_cursor {
          cursor: default;
          &::before {
            cursor: not-allowed;
          }
        }
        .infoTip {
          position: relative;
          top: -1px;
        }
        &::before {
          content: "";
          transition: all 0.5s;
          background: url(../images/sprite-icons.svg) no-repeat -538px -327px;
          width: 23px;
          height: 23px;
          position: absolute;
          left: 0;
          top: 8px;
          // -ms-transform: rotate(-180deg); /* IE 9 */
          // transform: rotate(-180deg);
        }
      }
      &.panel-open {
        .accLavelHedding1 {
          &::before {
            -webkit-transform: rotate(-180deg);
            -moz-transform: rotate(-180deg);
            transform: rotate(-180deg);
            background: url(../images/sprite-icons.svg) no-repeat -536px -326px;
          }
        }
      }
      &:first-child {
        margin-top: 15px;
      }
      &:last-child {
        margin-bottom: 10px;
      }
      .accordionLavel2 {
        .accLavelHedding2 {
          font-size: 14px;
          color: $text-color;
          font-weight: $font-medium;
          padding-left: 2.2rem;
          // display: inline-block;
          .infoTip {
            position: relative;
            top: -1px;
          }
          &::before {
            content: "";
            transition: all 0.5s;
            background: url(../images/sprite-icons.svg) no-repeat -538px -327px;
            width: 23px;
            height: 23px;
            position: absolute;
            left: 0;
            top: 8px;
            // -ms-transform: rotate(-180deg); /* IE 9 */
            // transform: rotate(-180deg);
          }
        }
        &.panel-open {
          .accLavelHedding2 {
            &::before {
              -webkit-transform: rotate(-180deg);
              -moz-transform: rotate(-180deg);
              transform: rotate(-180deg);
              background: url(../images/sprite-icons.svg) no-repeat -536px -326px;
            }
          }
        }
        &:first-child {
          margin-top: 15px;
        }
        &:last-child {
          margin-bottom: 10px;
        }
      }
    }
  }
  .notePayable {
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        max-height: 85px !important;
      }
    } 
  }
  installment-account {
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        max-height: 85px !important;
      }
    }
  }

  .sba_libs .collDropdown .btn-primary {
    background: #fff !important;
    border-color: #228848 !important;
    color: #228848 !important;
}


.deleteIconWrap {
  position: absolute;
  top: 11px;
  right: 0;
}

 rubicon-group .deletePfs:nth-of-type(10) {

  .ng-dropdown-panel { top: auto; bottom: 100%; border-radius: 10px 10px 0 0 !important; }

 }