/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&family=Open+Sans:wght@600&family=Poppins:wght@400;500;600;700&display=swap');
@import "./scss/app.scss";
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

.ag-theme-alpine {
    --ag-header-background-color: #3E494D;
    --ag-header-cell-hover-background-color: #E3EDF0;
    --ag-header-cell-moving-background-color: #E3EDF0;
    --ag-header-cell-moving-color: #3C474B;
}
html,
body { 
    background: #fbfbfb;
    height: 100%;
    font-family: $font-familym;
    font-weight: $font-regular;
    font-size: 14px;
    color: $colortext;
    counter-reset: my-sec-counter;
}
.modal .modal-header .close{
    font-size: 30px;
    top: -11px!important;
    font-weight: 300;
}

.docAccorWgtPanel .bank_statements_business_six_months, .docAccorWgtPanel .bank_statement_personal_six_months{
    display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    .attachment-doc {
        margin-right: -15px;
    }
}

.docDownloadWgt3.btn-md {
    padding: 4px 20px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
}

$aggrid-box-border-color: #1F3858;
$aggrid-header-row-color: #EFF0F1;
$aggrid-all-border-color: #CCCDCC;
$aggrid-row-bottom-border-color: #EFF0F1;
$aggrid-second-row-color: #f3f3f4;
$aggrid-odd-row-color: #FAFAFB;
$aggrid-selected-row-color: #CFD1D2;
.ag-theme-alpine .ag-row {
    font-size: 12px !important;
}
.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected){
    border: none;
}
 .agCustomFilDropdown {
     padding: 10px;
     min-width: 300px;

     .filterBtn {
            background-color: #FFF;
            border: 1.5px solid;
            text-align: center;
            color: #1e1e1e;
            font-weight: 500;
            border-radius: 4px;
            padding: 4px 10px;

         .btnLink {
             &.active {
                 background: #3E494D;
                 color: #FFF;
             }

             &.item0 {
                 border-radius: 3px 0 0 3px;
             }

             &.item1 {
                 border-radius: 0 3px 3px 0;
             }
         }
     }
 }
 .colWrap .bottomText .statusText {
     max-width: calc(100% - 30px);
 }

         .assigneTooltipWrap {
             .tooltipTitle {
                 font-size: 14px;
                 border-bottom: 1px solid #979797;
                 margin: 0 -10px;
                 padding: 0 10px 10px;
             }
    
             .stageAssiWrap {
                 padding-top: 4px;
                 display: inline-block;
    
                 .stageAssiText {
                     padding: 3px 0 0;
                     font-size: 14px;
                 }
             }
    
             .tooltip-inner {
                 max-width: 430px;
                 min-width: 50px;
                 text-align: left;
                 padding: 7px 10px 10px 10px;
             }
         }

                   .colWrap {
                       .topName {
                           font-size: 12px;
                       }
        
                       .bottomText {
                           font-size: 12px;
                           padding-top: 5px;
        
                           .statusText {
                               display: inline-block;
                               max-width: calc(100% - 32px);
                           }
        
                           .appid {
                               font-size: 12px;
                               cursor: pointer;
                           }
        
                           .daysDefault {
                               font-size: 12px;
                               position: relative;
                               display: inline-block;
                               margin-left: 15px;
                               line-height: 18px;
        
                               &:after {
                                   position: absolute;
                                   content: '';
                                   left: -7px;
                                   top: 2px;
                                   height: 13px;
                                   width: 0.5px;
                               }
                           }
        
                           .daysAlert {
                               padding-left: 15px;
        
                               &::before {
                                   content: "";
                                   position: absolute;
                                   left: 0;
                                   width: 15px;
                                   top: 1.5px;
                                   height: 15px;
                               }
                           }
                       }
        
                       &.businessColWrap {
                           .bottomText {
                               padding-top: 0;
                           }
        
                           .topName {
                               display: inline-block;
                           }
        
                           .tooltip-inner {
                               text-align: left;
                           }
                       }
                   }

 .ag-theme-alpine .ag-menu {
     background-color: #FFF;
 }
.ag-theme-alpine .ag-row-hover {
    background-color: #fff !important;
}
.ag-theme-alpine .ag-tooltip{
    color: #FFF;
    border-radius: 12px;
    width: 180px;
    font-weight: 100;
}
.gridView {
    .ag-center-cols-container {
        max-width: 100%;
    }

    .border {
        border-color: $aggrid-all-border-color !important;
    }

    .ag-header {
        border-top: none !important;
    }

    .ag-row {
        border-right: none;
        margin-left: 0;
        border-left: none;
    }

    // border-bottom: 1px solid $aggrid-all-border-color;
    .headRow {
        border-radius: "5px 5px 0 0";
    }

    .headRow+.contentRow {
        #myGrid.ag-theme-balham {
            border-radius: "0 0 10px 10px";
        }
    }

    .addCollateralBtn {
        //     padding: 5px 13px!important;
        // font-size: 12px!important;
    }

    .ag-cell-wrap-text {
        white-space: normal;
        word-break: normal;
    }
}

.ag-theme-balham {

    .ag-header-cell-text {
        color: "#000";
        font-weight: "semibold";
        font-size: 12px;
    }
}

.ag-theme-balham .ag-row-hover {
    background: transparent;
}

.ag-row {
    border-right: 1px solid $aggrid-all-border-color;
    margin-left: -1px;
    border-left: 1px solid $aggrid-all-border-color;
}

.ag-row-odd {
    // background-color: $aggrid-odd-row-color !important;
}

.ag-theme-balham {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    background: $primary-color;
    font-size: 13px;
    // position: relative;- // ag-filter issue
    z-index: 1;
}

.ag-theme-balham .ag-root-wrapper {
    border-top: none !important;
}

.ag-theme-balham .businessCell,
.ag-theme-balham .connectToBankerCell,
.ag-theme-balham .contactEmailCell {

    .ag-cell-value,
    .ag-group-value {
        overflow: hidden !important;
        text-overflow: ellipsis;
    }
}

.ag-theme-balham .ag-cell-value,
.ag-theme-balham .ag-group-value {
    overflow: visible !important;
    text-overflow: unset;
    white-space: normal;
}

.ag-theme-balham .ag-header-cell::after,
.ag-theme-balham .ag-header-group-cell::after {
    content: none !important;
}
.apptype {
    border-radius: 3px;
    background: #F8F8F8;
    padding: 6px 10px;
    margin: 6px auto;
    line-height: normal;
    width: 110%;
    text-align: center;
}

.ag-theme-balham .ag-row {
    padding: 0;
    font-size: 14px;
    min-width: 100%;
    border-bottom: 1px solid $aggrid-row-bottom-border-color;

    >div {
        padding: 10px 15px;
    }

    &.ag-row-last {
        border-bottom: 1px solid $aggrid-all-border-color;
    }

    .ag-cell:first-child {
        border-left: 1px solid $aggrid-row-bottom-border-color;
    }
}

element.style {
    transform: translateY(126px);
    height: 42px;
}

.ag-theme-alpine .ag-row {
    font-size: 12px !important;
}

.ag-theme-alpine .ag-row {
    font-size: 14px;
}

.ag-theme-alpine .ag-row-odd {
    background-color: #ffffff;
}

.ag-center-cols-container,
.ag-header-container {
    min-width: 100%;
}
// .dashboardTextHidden{
//     .dropdown-menu.commonDropList{
//         height: auto;
//     }
// }
// .dashboardTextHidden{
//    .ag-center-cols-viewport {
//         overflow-x: visible !important;
//     }
//     .ag-center-cols-container {
//         .ag-row{
//             &:nth-last-child(-n + 5) {
//                 .dash3doteHover .dropdown-menu {
//                     top: auto; bottom: 40px;
//                 }
//                 .dropdown-menu.commonDropList:before {
//                     bottom: -12%;
//                     transform: rotate(180deg);
//                 }
//             }
//         }
//     }
// }
.TableWrapper{
    font-family: $font-familyp;
    box-shadow: 0px 0px 4px 0px #00000040;
    border-radius: 15px;
    padding-top: 3px; position: relative; z-index: 99;
    min-height: 150px;
    background-color: #FFFFFF;
    &.tlBr-0 { border-radius: 0 15px 15px !important;}
    .filterSearch{
        margin: 15px;
    }
    .ag-header-container{
        background-color: #E3EDF0;
        color: #3C474B
    }
    .ag-theme-alpine .ag-row{
        border-right: none !important;
        border-bottom: none !important;
        border-top: 1px solid #dde2eb;
        border-left: none !important;
    }
    .ag-theme-alpine .ag-root-wrapper {
        border: none !important;
        border-color: #babfc7;
        border-radius: 0 0 15px 15px;
    }
    .ag-center-cols-clipper,
    .ag-center-cols-viewport{
        border-radius: 0 0 15px 15px;
    }
    .ag-theme-alpine .ag-row {
        background-color: transparent !important;
    }
    .ag-theme-alpine {
        font-family: $font-familyp;
    .ag-body-horizontal-scroll-container{
        max-width: 100% !important;
    }
}
}

.no-border-aggrid{
    border: none;
}
.ag-header-container {
    .ag-cell-label-container {
        justify-content: flex-end;

        .ag-header-cell-label {
            flex: unset;
        }

        .ag-icon {
            margin-top: 1px;
        }
    }

    .ag-header-cell-sortable {
        .ag-header-cell-text {
            margin-right: 4px;
        }

        &.ag-header-cell:not(.ag-right-aligned-header) .ag-header-label-icon {
            margin-left: 0;
        }
    }
}

.ag-center-cols-viewport {
    border-left: 1px solid $aggrid-all-border-color;
    border-right: 1px solid $aggrid-all-border-color;
    border-bottom: 1px solid $aggrid-all-border-color;
}

// ag-grid-angular{
// 	.ag-cell-wrapper{
// 		.ellpstext{padding: 2px 0;}
// 	}
// }
// .ag-row.ag-row-hover{z-index:2!important;}
// .ag-row.ag-row-focus{z-index:1;}
// .ag-row.ag-row-no-focus{z-index:0;}
// .ag-header-row{min-height: 41px;min-width: 100%;}
// .ag-header-row{
//         // background: #3E494D !important;
//         color: #FFF !important;
// }
.ag-row-focus {
    bs-tooltip-container {
        z-index: 1;
    }

    .dropdown-menu {
        display: none;
    }
}

.ag-row-focus+.ag-row-no-focus {
    bs-tooltip-container {
        z-index: 9;
    }
}

.ag-row-hover {
    z-index: 10;

    .dropdown-menu {
        &.show {
            display: block;
        }
    }
}

@media (min-width: 1200px) {

    .ag-root.ag-layout-auto-height,
    .ag-body-viewport.ag-layout-auto-height,
    .ag-center-cols-viewport,
    .ag-body-viewport-wrapper.ag-layout-auto-height,
    .ag-header-viewport,
    .ag-floating-top-viewport,
    .ag-body-viewport,
    .ag-floating-bottom-viewport,
    .ag-body-horizontal-scroll-viewport,
    .ag-virtual-list-viewport,
    .ag-center-cols-clipper,
    .ag-root-wrapper,
    .ag-theme-balham {
        overflow: visible; // add .ag-center-cols-viewport "Owners of your business" ag-grid
    }
}

.ag-body-horizontal-scroll {
    z-index: -1;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    line-height: 33px;
}

// .ag-theme-balham .ag-header-icon { color: $colorfff; }
.ag-theme-balham .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border-color: transparent !important;
}

.datatext {
    max-width: 180px;
    display: inline-block;
}

.ng-select .ng-select-container {
    min-height: 32px;
}

.ellpstext {
    overflow: hidden;
    text-overflow: unset;
    max-width: none;
    display: inline-block;
}

.bNamefix {
    max-width: 100% !important;
    font-size: 16px;
}

.datatext.fwb {
    font-weight: 600 !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    font-size: 14px;
}

.ag-theme-balham .ag-cell br,
.ag-theme-balham .ag-cell {
    line-height: normal;
}

.ag-cell {
    overflow: visible;
}

// .ag-row { z-index: 0; }
// .ag-row.ag-row-focus { z-index: 1; }
.ag-root.ag-layout-auto-height,
.ag-body-viewport.ag-layout-auto-height,
.ag-body-viewport-wrapper.ag-layout-auto-height,
.ag-header-viewport,
.ag-floating-top-viewport,
.ag-body-viewport,
.ag-floating-bottom-viewport,
.ag-body-horizontal-scroll-viewport,
.ag-virtual-list-viewport,
.ag-center-cols-clipper,
.ag-root-wrapper,
.ag-theme-balham {
    overflow: visible;
}

// .ag-center-cols-viewport, add scroll
.ag-row {
    border-right: 1px solid $aggrid-all-border-color;
    margin-left: -1px;
    border-left: 1px solid $aggrid-all-border-color;
}
.ag-theme-alpine .ag-icon {
    color: #2d2c2c;
}
.ag-paging-page-summary-panel .ag-icon{
    color: #6FB253;
}

.ag-row-odd {
    // background-color: $aggrid-odd-row-color !important;
}

.ag-root-wrapper {
    border-radius: 10px 10px 0 0;
}

/* tooltip fix table of agrid */
platform-core-manage-affiliates,
platform-core-collateral-table,
platform-core-manage-pfs {
    .ag-row.ag-row-no-focus {
        z-index: 1;
    }
}
#myGrid.ag-theme-balham .ag-root-wrapper {
    overflow: visible;
}

.ag-header-cell-label {
    .ag-header-cell-text {
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        color: #2d2c2c;
    }
}
.parentPaginationContainer{
    position: relative;
    margin-bottom: 110px;
}
.paginationIndex{
    position: absolute;
    bottom: -110px;
    right: 13px;
}

.bi.bi-info-circle{
    position: relative;
    top: 4px;
    left: 10px;
}

.new_flag_container {
    padding: 3px;border-radius: 8px;background: #F9E1C5;width: 40px; height: 20px;
    font-family: Montserrat;font-style: normal;position: relative;margin-left: 4px;
}
.new_flag_text {
    color: #C36900;font-size: 11px;font-style: normal;
    font-weight: 600;text-transform: uppercase;position: relative;
}