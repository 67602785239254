* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    &:before {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    &:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

ul {
    margin: $p0;
    padding: $p0;
    list-style: none;
}

a { color: $primary-color;
    &:hover {
        text-decoration: $ahover;
        opacity: 0.9;
    }
}

.form-control {
    &:disabled {
        background-color: $reddis !important; color: $color4848;
    }
}

.form-control[readonly] {
    background-color: $reddis !important;
}

::-webkit-input-placeholder {
    /* Edge */
    color: $rgbcolor !important;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $rgbcolor !important;
}

::placeholder {
    color: $rgbcolor !important;
}

input[readonly]::-webkit-input-placeholder {
    /* Edge */
    color: $reddiscolor !important;
}

input[readonly]:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $reddiscolor !important;
}

input[readonly]::placeholder {
    color: $reddiscolor !important;
}

button:focus {
    outline: none;
}

.mainCont {
    position: relative;
}

.uline {
    text-decoration: underline !important;
}

.uline:hover {
    text-decoration: none !important;
}

.fwl {
    font-weight: $font-light !important;
}

.fwr {
    font-weight: $font-regular !important;
}

.fwm {
    font-weight: $font-medium !important;
}

.fwsb {
    font-weight: $font-sami-bold !important;
}

.fwb {
    font-weight: $font-bold !important;
}

.fwbder {
    font-weight: 800 !important;
}
.lh {
    line-height: 25px !important;
}

.fs0 {
    font-size: 0px !important;
}
.fs10 {
    font-size: 10px !important;
}

.fs11 {
    font-size: 11px !important;
}

.fs12 {
    font-size: 12px !important;
}

.fs13 {
    font-size: 13px !important;
}

.fs14 {
    font-size: 14px !important;
}

.fs15 {
    font-size: 15px !important;
}

.fs16 {
    font-size: 16px !important;
}

.fs17 {
    font-size: 17px !important;
}

.fs18 {
    font-size: 18px !important;
}

.fs19 {
    font-size: 19px !important;
}

.fs20 {
    font-size: 20px!important;
}

.fs21 {
    font-size: 21px !important;
}

.fs22 {
    font-size: 22px !important;
}

.fs23 {
    font-size: 23px !important;
}

.fs24 {
    font-size: 24px !important;
}

.fs25 {
    font-size: 25px;
}

.fs26 {
    font-size: 26px !important;
}
.fs28 {
    font-size: 28px !important;
}

.fs30 {
    font-size: 30px !important;
}

.fs32 {
    font-size: 32px !important;
}

.fs36 {
    font-size: 36px !important;
}

.fs40 {
    font-size: 40px !important;
}
.fs45 {
    font-size: 45px !important;
}
.fs60 {
    font-size: 60px !important;
}
.fs64 {
    font-size: 64px !important;
}
.fs50 {
    font-size: 50px !important; line-height: 60px;
}

.ffp {
    font-family: $font-familyp;
}

.ffm {
    font-family: $font-familym;
}

.ptc {
    color: $primary-color!important;
}
.pdtc {
    color: $color6f9!important;
}
.gtc {
    color: $color4848!important;
}
.graytc{ color: $colortext !important; }
.bltc { color: $color0c5 !important; }
.gbc { background: $colorf1f; }
.gtColor { color: $grayColor; }
.grtc {
    color: $color6f9!important;
}
.stc {
    color: $color3a3a!important;
}
.batc{ color: $colore2a3; }

.bdtc {
    color: $colornav !important;
}

.btc {
    color: $color2d6!important;
}

.btc2 {
    color: $color507!important;
}

.wtc {
    color: $colorfff!important;
}
.gltc { color: $color888; }
.baltc { color: $color565; }
.cp {
    cursor: pointer;
}
.pptc { color: $color762; }
.lgbc { background-color: $colorfbf; }
.lgtc { color: $colorc0c !important; }
.colOrg { color: $color82b !important; }
.wbc { background: $colorfff; }
.modal-xl { max-width: 1000px !important; }
.modal-md {max-width: 600px !important; }
.modal-md-sm {max-width: 480px !important; }
.modal-backdrop {
    background-color: $colorfff;
    opacity: .8!important;
}

.modal {
    .modal-backdrop.fade {
        background-color: $colorfff;
    }
    .modal-content {
        background-color: $colorfff;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);
        border: 1px solid #f3f3f3;
        // @include border-radius (6px);
    }
    .modal-content .app_type_two{
        background-color: $colorfff;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);
        border: 1px solid #f3f3f3;
        width: 110%;
    }
    .OTPVeriModal {
        max-width: 407px;
        ng-otp-input {
            input {
                border: none;
                border-bottom: 1px solid #001e5d;
                border-radius: 0!important;
                font-size: 22px;
                color: $primary-color;
                font-weight: $font-regular;
                font-family: $font-familyp;
                margin: 0 10px;
                &:focus {
                    outline: none;
                }
            }
        }
        .btn {
            padding: 10px 50px;
        }
    }
    .signupFAQ {
        .card {
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, .125);
            border-radius: 0!important;
            .card-header {
                padding: 20px 0 20px;
            }
            .sbaFAQcontents {
                .card-body {
                    padding: 0 0 0 30px;
                    .answ {
                        position: absolute;
                        left: 0;
                    }
                }
            }
            &.last-card {
                border-bottom: none;
            }
        }
        .modal-body {
            padding: 10px 30px;
            // max-height: 400px;
            // overflow-y: auto;
            // overflow-x: hidden;
        }
        max-width: 783px;
        .questionText {
            position: relative;
            display: block;
            padding: 0 30px 0 30px;
            &:after {
                color: #3a3a3a!important;
                content: "--";
                position: absolute;
                right: 2px;
                top: -9px;
                letter-spacing: -4px;
                font-size: 26px;
                font-weight: 500;
            }
            &.collapsed {
                &:after {
                    content: "+";
                    position: absolute;
                    top: -7px;
                }
            }
            span.pr-3 {
                position: absolute;
                left: 0;
            }
        }
    }
    .bookAppoint {
        max-width: 833px;
        .dobicon {
            input {
                z-index: 1;
                padding: 10px 40px;
                 ::placeholder {
                    color: $color507 !important;
                }
            }
            position: relative;
            &::after {
                top: 10px;
                right: 61px;
                z-index: 0;
            }
        }
        .dateTime {
            position: relative;
            select {
                option {
                    padding: 10px;
                }
            }
            .btn {
                padding-left: 25px !important;
                padding-right: 46px!important;
                z-index: 1;
                .dobicon {
                    &::after {
                        top: 10px;
                        right: 27px;
                    }
                }
            }
        }
    }
    .paymentCard {
        max-width: 407px;
        .border-bottom2 {
            border-bottom: 2px solid $primary-color;
        }
    }
}

.lbluebg {
    background-color: rgba(5, 119, 205, 0.07);
}

.dbluebg {
    background-color: $color2d6!important;
}

.dgreenbg {
    background-color: $color6f9!important;
}
.fileUp {
    overflow: hidden;
    position: relative;
    input {
        position: absolute;
        opacity: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}

.fileName {
    padding-left: 30px;
}

.signpage.radius0,
.keyppp.radius0 {
    @include border-radius(0px);
}

.freeOnlineAss {
    background: url(/assets/base/images/PPP-icons.svg) no-repeat 0 -183px;
    height: 117px;
    width: 117px;
    display: inline-block;
}

.bookanAppoi {
    background: url(/assets/base/images/PPP-icons.svg) no-repeat 0 -309px;
    height: 117px;
    width: 117px;
    display: inline-block;
}

.funded {
    background: url(/assets/base/images/PPP-icons.svg) no-repeat -130px -309px;
    height: 117px;
    width: 117px;
    display: inline-block;
}

.fileIcon {
    background: url(/assets/base/images/PPP-icons.svg) no-repeat -156px -95px;
    height: 21px;
    width: 16px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: bottom;
    margin-left: -27px;
}

.uploadIcon {
    background: url(/assets/base/images/PPP-icons.svg) no-repeat -106px -95px;
    height: 21px;
    width: 22px;
    display: inline-block;
    margin-right: 5px;
    vertical-align: bottom;
}

.appliIcon {
    background: url(/assets/base/images/PPP-icons.svg) no-repeat 0 0;
    height: 35px;
    width: 35px;
}

.mobileIcon {
    background: url(/assets/base/images/PPP-icons.svg) no-repeat 2px -74px;
    height: 65px;
    width: 65px;
    margin: 20px auto;
}

.downArrow {
    background: url(/assets/base/images/PPP-icons.svg) no-repeat -155px -7px;
    height: 12px;
    width: 12px;
    display: inline-block;
    cursor: pointer;
    &.open {
        @include transform(180deg);
    }
}

.deleteIcon {
    background: url(/assets/base/images/PPP-icons.svg) no-repeat -156px -53px;
    height: 15px;
    width: 12px;
    display: inline-block;
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
}

.deleteIconAffiliate {
    background: url(/assets/base/images/PPP-icons.svg) no-repeat -156px -53px;
    height: 15px;
    width: 12px;
    display: inline-block;
    cursor: pointer;
    margin-top: 3px;
    &:hover {
        opacity: 0.9;
    }
}

.eyeIconAffiliate{
    background: url(/assets/base/images//PPP-icons.svg) no-repeat -162px -176px;
    height: 21px;
    width: 22px;
    display: inline-block;
    cursor: pointer;
    margin-top: -2px;
    &:hover {
        opacity: 0.9;
    }
}

.btn-link {
    color: $color3a3a !important;
    display: inline-block;
    font-size: $size-sm;
}

.btn-link:hover {
    text-decoration: none;
    opacity: 0.9;
}

.text-upper {
    text-transform: $textUpper !important;
}
.radius20 {
    @include border-radius(20px);
}
.radius27 {
    @include border-radius(27px);
}
.radius20 {
    @include border-radius(20px);
}
.radius6 {
    @include border-radius(8px);
}
.radius4 {
    @include border-radius(4px);
}

.brrbn { border-bottom-right-radius: 0px!important; }

.brlbn { border-bottom-left-radius: 0px!important; }
.radiusrb27 { border-bottom-right-radius: 27px!important; }
body {
    .radius4 {
        @include border-radius(4px);
    }
}

.radius0 {
    @include border-radius(0px);
}

.ortag {
    span {
        background-color: rgba(5, 119, 205, 0.07);
        @include border-radius(50px);
        padding: 10px;
        font-size: 12px;
        color: $color507;
        font-weight: $font-medium;
        display: inline-block;
        border: 1px solid rgba(5, 119, 205, 0.1);
        @include white-box-shadow();
    }
}


/* Fade */

.btn {
    @include border-radius(50px);
    font-size: 16px;
    padding: 10px 20px;
    font-family: $font-familyp;
    font-weight: $font-sami-bold;
    // text-transform: uppercase;
    &:hover {
        opacity: 0.9;
    }
    &.btn-sm{
    padding: 4px 20px!important;
    font-weight: $font-medium!important;
    font-size: 14px!important;
    line-height: 1.5!important;
    }
    &.btn-xs{
    padding: 2px 10px!important;
    font-weight: $font-medium!important;
    font-size: 12px!important;
    line-height: 1.5!important;
    }
}

.btn.isBtnDisabledCls{
    &:hover{
        opacity: 0.5 !important;
        cursor: not-allowed;
        color: lightgrey !important;
    }
}

.btn-lg {
    padding: 10px 30px;
}

.btn14 {
    font-size: 14px;
    text-transform: unset!important;
}

.btn-primary {
    background: $primary-color !important;
    border-color:$primary-color !important;
    color: $colorfff!important;
    &:hover {
        background: $primary-color !important;
        border-color: $primary-color !important;
    }
    &:focus {
        background: $primary-color !important;
        border-color: $primary-color !important;
        box-shadow: none;
    }
    &:active {
        background: $primary-color !important;
        border-color: $primary-color !important;
        box-shadow: none!important;
    }
}

.btn-primaryGrey {
    background: $color2a3 !important;
    border-color:$color2a3 !important;
    color: $colorfff!important;
    &:hover {
        background: $color2a3 !important;
        border-color: $color2a3 !important;
    }
    &:focus {
        background: $color2a3 !important;
        border-color: $color2a3 !important;
        box-shadow: none;
    }
    &:active {
        background: $color2a3 !important;
        border-color: $color2a3 !important;
        box-shadow: none!important;
    }
}
.btn-primaryDark {
    background: $color6f9 !important;
    border-color:$color6f9 !important;
    color: $colorfff!important;
    &:hover {
        background: $color6f9 !important;
        border-color: $color6f9 !important;
    }
    &:focus {
        background: $color6f9 !important;
        border-color: $color6f9 !important;
        box-shadow: none;
    }
    &:active {
        background: $color6f9 !important;
        border-color: $color6f9 !important;
        box-shadow: none!important;
    }
    .radius4 { @include border-radius(4px); }
    &:disabled { background: $color-silver !important; border-color: $color-silver !important; }
}


.btn-secondary {
    background-color:transparent !important;
    border-color: $color6f9 !important;
    color: $color6f9!important;
    &:hover {
        background-color:transparent !important;
        border-color: $primary-color;
    }
    &:focus {
        background-color: transparent !important;
        opacity: 1 !important;
        border-color: $primary-color;
        box-shadow: none;
    }
    &:active {
        background-color: transparent !important;
        opacity: 1 !important;
        border-color: $primary-color;
        box-shadow: none!important;
    }
}

// .btn-default {
//     background-color: $color507 !important;
//     border-color: $color507;
//     color: $colorfff!important;
//     &:hover {
//         background-color: $color507 !important;
//         border-color: $color507;
//     }
//     &:focus {
//         background-color: $color507 !important;
//         opacity: 1 !important;
//         border-color: $color507;
//         box-shadow: none;
//     }
//     &:active {
//         background-color: $color507 !important;
//         opacity: 1 !important;
//         border-color: $color507;
//         box-shadow: none!important;
//     }
// }

// .btn-info {
//     background-color: transparent !important;
//     border-color: $primary-color;
//     color: $primary-color!important;
//     &:hover {
//         background-color: transparent !important;
//         border-color: $primary-color;
//     }
//     &:focus {
//         background-color: transparent !important;
//         opacity: 1 !important;
//         border-color: $primary-color;
//         box-shadow: none;
//     }
//     &:active {
//         background-color: transparent !important;
//         opacity: 1 !important;
//         border-color: $primary-color;
//         box-shadow: none!important;
//     }
// }

.btn-success {
    background-color: $color6f9 !important;
    border-color: $colorfff;
    color: $colorfff!important;
    &:hover {
        background-color: $color6f9 !important;
        border-color: $colorfff;
    }
    &:focus {
        background-color: $color6f9 !important;
        opacity: 1 !important;
        border-color: $colorfff;
        box-shadow: none;
    }
    &:active {
        background-color: $color6f9 !important;
        opacity: 1 !important;
        border-color: $colorfff;
        box-shadow: none!important;
    }
}

.btn-blue-line {
    background: $colorfff !important;
    border: 0.15rem solid $color0c5 !important;
    color: $color0c5!important;
    &:hover {
        background: $color0c5 !important;
        border-color: $color0c5 !important;
        color: $colorfff!important;
    }
    &:focus {
        background: $color0c5 !important;
        border-color: $color0c5 !important;
        box-shadow: none;
        color: $colorfff!important;
    }
    &:active {
        background: $color0c5 !important;
        border-color: $color0c5 !important;
        box-shadow: none!important;
        color: $colorfff!important;
    }
}
.btn-blue {
    background: $color0c5 !important;
    border-color: $color0c5 !important;
    color: $colorfff!important;
    &:hover {
        background: $color0c5 !important;
        border-color: $color0c5 !important;
        color: $colorfff!important;
    }
    &:focus {
        background: $color0c5 !important;
        border-color: $color0c5 !important;
        box-shadow: none;
        color: $colorfff!important;
    }
    &:active {
        background: $color0c5 !important;
        border-color: $color0c5 !important;
        box-shadow: none!important;
        color: $colorfff!important;
    }
}

button.btn {
    &:focus {
        // outline: 1px dashed rgba(0, 0, 0, 0.1) !important;
        // outline-offset: 2px !important;
    }
}

a.btn {
    &:focus {
        // outline: 1px dashed rgba(0, 0, 0, 0.1) !important;
        // outline-offset: 2px !important;
    }
}

.btn-primary {
    &:not(:disabled) {
        &:not(.disabled).active {
            &:focus {
                box-shadow: none;
                border: 1px dotted $gray-color;
                background: $primary-color;
            }
            box-shadow: none;
            border: 1px dotted $gray-color;
            background: $primary-color;
        }
        &:not(.disabled) {
            &:active {
                &:focus {
                    box-shadow: none;
                    border: 1px dotted $gray-color;
                    background: $color2d6;
                }
                box-shadow: none;
            }
        }
    }
}

.btn-secondary {
    &:not(:disabled) {
        &:not(.disabled).active {
            &:focus {
                box-shadow: none;
                border: 1px dotted $gray-color;
                background: $colorfff;
                color: $primary-color;
            }
            box-shadow: none;
            border: 1px dotted $gray-color;
            background: $colorfff;
            color: $primary-color;
        }
        &:not(.disabled) {
            &:active {
                &:focus {
                    box-shadow: none;
                    border: 1px dotted $gray-color;
                    background: $colorfff;
                    color: $primary-color;
                }
                box-shadow: none;
                border: 1px dotted $gray-color;
                background: $colorfff;
                color: $primary-color;
            }
        }
    }
}

.pr {
    position: relative;
}

.calicon {
    background: transparent;
    padding-right: 40px !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
    &::after {
        background: $calendarIcon;
        position: absolute;
        content: "";
        top: 13px;
        right: 8px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

select,
select.form-control {
    // background: $whiteTo2a2a2a $selectArrow;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    padding: 11px 30px 11px 11px;
}

input[type="checkbox"],
input[type="radio"],
.form-check-input {
    margin-left: 0px;
    position: static;
}

.wbg {
    background: $colorfff;
    @include border-radius(10px);
    padding: $p30;
    @include white-box-shadow();
}

.graybg {
    background: $graybgcolorTo303030;
    @include border-radius(0);
    padding: $p10;
}


/*  */

.newTag {
    @include border-radius(5px);
    background: $colorYellow;
    font-size: 10px;
    color: $black-color;
    padding: 3px 5px;
    display: inline-block;
    margin-left: 5px;
}

.greenTag {
    @include border-radius(50px);
    background: $primary-color;
    font-size: 12px;
    color: $colorfff;
    padding: 5px 8px;
    display: inline-block;  min-width: 28px; text-align: center;
}

.yellowTag {
    @include border-radius(50px);
    background: $colorYellowl;
    font-size: 10px;
    color: $base-color;
    padding: 3px 8px;
    display: inline-block;
    margin-left: 5px;
}

.tag-1 {
    @include border-radius(50px);
    background: $primary-color;
    font-size: 10px;
    color: $color-white;
    padding: 3px 8px;
    display: inline-block;
    margin-left: 5px;
}
.tag-2 {
    @include border-radius(50px);
    background: $colorYellowl;
    font-size: 10px;
    color: $base-color;
    padding: 3px 8px;
    display: inline-block;
    margin-left: 5px;
}

.primary-label {
    font-size: 14px;
    color: $colornav;
    font-weight: $font-sami-bold;
    display: block;
}

.pdfHeight{
    height: 79px;
}

.blueulList {
    padding-left: 15px;
    list-style-type: disc;
    li {
        font-size: 12px;
        font-weight: $font-medium;
        padding-bottom: 5px;
    }
}

.grayulList {
    padding-left: 15px;
    list-style-type: disc;
    li {
        font-size: 12px;
        color: $color9a9a;
        padding-bottom: 5px;
    }
}
.alList {
    padding-left: 25px;
    list-style-type:lower-alpha;
    li {
        font-size: 12px;
        color: $color9a9a;
        padding-bottom: 5px;
        padding-left: 7px;
    }
}

.squareIcon {
    width: 17px;
    height: 17px;
    border: 1px solid $color507;
    @include border-radius(2px);
    &.darki {
        background: url(/assets/base/images/PPP-icons.svg) no-repeat -204px -10px;
    }
}

.quesIcon {
    @include border-radius(20px);
    background-color: $colorfff;
    width: 10px;
    height: 10px;
    color: $color507;
    font-size: 10px;
    display: inline-block;
    text-align: center;
    line-height: 12px;
    font-weight: 700;
    cursor: pointer;
}

.tableui {  
    border-radius: 10px 10px 8px 8px;
    background: $colorfff;
    tr {
        th {
            background-color: $color6f9;
            font-size: 14px;
            font-weight: $font-regular;
            color: $colorfff;
            padding: 22px 25px; border: 0 !important;
            &:first-child {
                border-radius: 8px 0 0 0;
            }
            &:last-child {
                border-radius: 0 8px 0 0;
            }
            border: none;
        }
    }
    tr {
        td {
            padding: 22px 25px;
            font-size: 14px;
            font-weight: $font-regular;
            vertical-align: middle;
            color: $colornav;
            border-bottom: 1px solid rgba(151, 151, 151, 0.13);
            a {
                color: $colornav;
                font-weight: $font-medium;
            }
        }
        // &:first-child {
        //     background-color: #e2e2e2;
        // }
        // &:nth-child(even) {
        //     background-color: $colorfff;
        // }
        &:last-child {
            td {
                border: none;
            }
            td:first-child {
                border-radius: 0 0 0 8px;
            }
            td:last-child {
                border-radius: 0 0 8px 0;
            }
        }
    }
    tfoot {
        tr {
            border-top: 1px solid $colorc0c;
            border-bottom: 1px solid $colorc0c;
            &:nth-child(odd) {
                background: none;
            }
            &:first-child { border: 0; }
            
        }
    }
    .textSelect {
        background: url(/assets/base/images/drop-down-arrow.svg) no-repeat 98% 6px;
        display: inline-block;
        cursor: pointer;
        padding-right: 15px!important;
        background-size: 18% auto;
        &:focus {
            outline: none;
        }
    }
    &.table-sm {
        background: none;
        tr {
            background: none;
            th {
                padding: 12px 15px;
                font-size: 12px;
            }
            td {
                padding: 12px 15px;
                font-size: 12px;
            }
            &:first-child {
                background-color: #e2e2e2;
            }
        }
        tfoot {
            tr {
                &:first-child {
                    background: none;
                }
            }
        }
    }
}

.form-control {
    color: $primary-color;
    font-size: 14px;
    font-weight: $font-medium;
    @include border-radius(4px);
    background: $colorfff;
    border-color: $colorc0c;
    height: auto;
}

.inputbotline {
    border: none;
    border-bottom: 1px solid $colorc0c;
    &:focus {
        outline: none;
    }
}

.form-control:focus {
    box-shadow: none;
    // outline: 1px dashed rgba(0, 0, 0, 0.3) !important;
    // outline-offset: 2px !important;
    background-color: $whiteTo2a2a2a;
    border-color: $darkThborder;
    color: $dbtext;
}

.input-group-text {
    color: $blackTowhite;
    font-size: $size-md;
    font-weight: $font-medium;
    background: $whiteTo2a2a2a;
    border-right: none;
    @include border-radius(2px);
    border-color: $darkThborder;
    margin-right: -2px;
}

.input-group {
    .form-control {
        border-left: none;
        padding-left: 0;
    }
}

.form-text.text-muted {
    text-decoration: none;
    font-size: $size-xs;
    color: $grayTowhite !important;
}

.boxShadow {
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.09);
    border: none;
    @include border-radius(8px);
}

.toggle.ios,
.toggle-on.ios,
.toggle-off.ios {
    border-radius: 20px;
}

.toggle.ios {
    .toggle-handle {
        border-radius: 20px;
    }
}

.disable {
    cursor: not-allowed !important;
    opacity: 0.5;
    input {
        color: #666 !important;
    }
}

.alert-warning {
    color: $base-color;
    background-color: $colorYellowl;
    border-color: $colorYellow;
}

input[type="password"] {
    &:hover {
        +div.passpolicy {
            display: block;
        }
    }
}


/* OWL carousel */

.owl-theme {
    .owl-controls {
        margin-top: 10px;
        text-align: center;
        .owl-page {
            display: inline-block;
            zoom: 1;
            *display: inline;
            span {
                display: block;
                width: 8px;
                height: 8px;
                margin: 5px 7px;
                filter: Alpha(Opacity=50);
                opacity: 0.5;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                border-radius: 20px;
                width: 8px;
                height: 8px;
                background: $gray-color;
                margin: 5px 10px;
            }
        }
        .owl-page.active {
            span {
                filter: Alpha(Opacity=100);
                opacity: 1;
                background-color: $primary-color;
                background: $primary-color;
            }
        }
    }
    .owl-controls.clickable {
        .owl-page {
            &:hover {
                span {
                    filter: Alpha(Opacity=100);
                    opacity: 1;
                    background-color: $primary-color;
                }
            }
        }
    }
}

// .sticky {
//     position: fixed;
//     padding: 7px 0 0 0 !important;
//     top: 0;
//     left: 0;
//     width: 100%;
//     @include gradient-light-darkgreen;
//     z-index: 1049;
//     height: 50px;
//     transform-origin: top center;
//     -webkit-transition: height 0.3s;
//     -moz-transition: height 0.3s;
//     transition: height 0.3s;
//     margin: 0;
//     box-shadow: 0 1px 3px $piyanoCieclebg;
//     &::after {
//         content: "";
//         position: absolute;
//         bottom: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         transform: scaleY(0);
//         transform-origin: top center;
//         background: rgba(144, 180, 83, 0.05);
//         z-index: -1;
//         transition: transform 0.3s;
//     }
// }
label.required {
    color: $red-color;
    font-size: $size-xs;
    font-style: italic;
}

label.error,
div.error {
    color: $red-color;
    font-size: $size-xs;
    font-style: italic;
    font-weight: $font-medium;
}
.loaderWrap,.widgetLoaderWrapper {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 99999;
    text-align: center;
    top: 0;
    left: 0;
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        content: "";
        background: $rgbcolor;
    }
    .lds-ripple {
        display: inline-block;
        position: absolute;
        width: 80px;
        height: 80px;
        z-index: 9;
        margin-top: -40px;
        top: 50%;
        left: 50%;
        margin-left: -40px;
    }
    .lds-ripple div {
        position: absolute;
        border: 4px solid $primary-color;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }
}


/* 404 css */

.page404 {
    .container {
        max-width: 100%;
    }
    .bggradient {
        border-radius: 0;
    }
    .notfoundWrap {
        position: relative;
        height: 84vh;
        .notfound {
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-40%, -80%);
            -ms-transform: translate(-40%, -80%);
            transform: translate(-40%, -45%);
            max-width: 560px;
            width: 100%;
            padding-left: 160px;
            line-height: 1.1;
            .notfound-404 {
                position: absolute;
                left: 0;
                top: 0;
                display: inline-block;
                width: 140px;
                height: 140px;
                background-image: url(/assets/base/images/404.svg);
                background-size: cover;
                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    -webkit-transform: scale(2.4);
                    -ms-transform: scale(2.4);
                    transform: scale(2.4);
                    border-radius: 50%;
                    background-color: $f3f5fbTo1c1c1e;
                    box-shadow: 0px 1px 0 #d9dde9;
                    -moz-box-shadow: none;
                    -webkit-box-shadow: 0px 1px 0 #d9dde9;
                    z-index: -1;
                }
            }
            h1 {
                font-size: $size-36;
                font-weight: 700;
                margin-top: 0;
                margin-bottom: 10px;
                color: $blackTowhite;
                text-transform: uppercase;
            }
            h2 {
                font-size: $size-exl;
                font-weight: 400;
                margin: 0;
                text-transform: uppercase;
                color: $blackTowhite;
            }
            p {
                font-size: $size-md;
                color: $grayTowhite;
                font-weight: 400;
            }
            a {
                display: inline-block;
                font-weight: 700;
                text-decoration: none;
                color: $primary-color;
            }
        }
    }
}

// rubicon-slider {
//     .ng5-slider {
//         .ng5-slider-bar {
//             background: $graybgcolorTo303030;
//             height: 10px;
//             @include border-radius(8px);
//         }
//         .ng5-slider-pointer {
//             background: $primary-color;
//             outline: none;
//             top: -9px;
//             width: 25px;
//             height: 25px;
//             &:before {
//                 background: url(/assets/base/images/icon-sprite.svg) no-repeat -12px -11px;
//                 width: 10px;
//                 height: 11px;
//                 left: 12px;
//                 top: 8px;
//                 content: "";
//                 position: absolute;
//             }
//             &:after {
//                 background: url(/assets/base/images/icon-sprite.svg) no-repeat 0 -11px;
//                 width: 10px;
//                 height: 11px;
//                 left: 4px;
//                 top: 8px;
//             }
//             &:hover::after {
//                 background: url(/assets/base/images/icon-sprite.svg) no-repeat 0 -11px;
//             }
//         }
//         .ng5-slider-pointer.ng5-slider-active:after {
//             background: url(/assets/base/images/icon-sprite.svg) no-repeat 0 -11px;
//         }
//     }
// }

/* slider text hide */

.amountSlider {
    .ng5-slider {
        .ng5-slider-model-value {
            display: none;
        }
        margin: 20px 0 45px;
        .ng5-slider-tick {
            width: 1px;
            top: 20px;
            background: #6c757d !important;
            @include border-radius(0px);
            left: 1px;
        }
        .ng5-slider-tick-legend {
            font-size: $size-exs;
            width: 100px;
            max-width: 100px;
            color: $dbtext;
        }
        .ng5-slider.animate {
            .ng5-slider-bubble {
                display: none;
            }
            .ng5-slider-bubble.ng5-slider-limit {
                display: none;
            }
        }
    }
}

.ng5-slider-inner-tooltip {
    display: none;
}

.form-control {
    &::-ms-clear {
        display: none;
    }
}

ngb-datepicker {
    select.custom-select {
        background: $whiteTo2a2a2a $selectArrow;
        background-position: $custom-selectPosition;
        color: $blackTowhite !important;
        margin: 0 2px;
    }
    .ngb-dp-weekday {
        color: $blackTowhite !important;
    }
    .bg-primary {
        background-color: $primary-color !important;
    }
    .btn-light {
        color: $blackTowhite !important;
        &:hover {
            background-color: $primary-color !important;
            color: $colorfff !important;
        }
        &:not(:disabled) {
            &:not(.disabled) {
                &:active {
                    background-color: $primary-color !important;
                    color: $colorfff !important;
                }
            }
            &:not(.disabled).active {
                background-color: $primary-color !important;
            }
        }
    }
    .btn-link {
        color: $blackTowhite !important;
    }
}

.dobicon {
    &:after {
        background: transparent url(/assets/base/images/PPP-icons.svg) no-repeat -201px -51px;
        height: 20px;
        width: 20px;
        border: none;
        cursor: pointer;
        content: "";
        position: absolute;
        top: 4px;
        right: 0;
    }
}

.timeIcon {
    &:after {
        background: url(/assets/base/images/PPP-icons.svg) no-repeat -199px -93px;
        height: 21px;
        width: 21px;
        border: none;
        cursor: pointer;
        content: "";
        position: absolute;
        top: 26px;
        right: 51px;
    }
}

.loclgreay {
    &:after {
        background: url(/assets/base/images/PPP-icons.svg) no-repeat -110px -54px;
        height: 13px;
        width: 12px;
        border: none;
        cursor: pointer;
        content: "";
        position: absolute;
        top: 8px;
        right: 0;
    }
}

input:-internal-autofill-selected {
    background-color: transparent!important
}

.loclgreen {
    &:after {
        background: url(/assets/base/images/PPP-icons.svg) no-repeat -110px -11px;
        height: 13px;
        width: 12px;
        border: none;
        cursor: pointer;
        content: "";
        position: absolute;
        top: 8px;
        right: 0;
    }
}

.backArrowb {
    background: transparent url(/assets/base/images/PPP-icons.svg) no-repeat -260px -77px;
    height: 25px;
    width: 27px;
    border: none;
    cursor: pointer;
    &:hover {
        background-position: -260px -203px;
    }
}

.nextArrowb {
    background: transparent url(/assets/base/images/PPP-icons.svg) no-repeat -375px -77px;
    height: 25px;
    width: 27px;
    border: none;
    cursor: pointer;
    &:hover {
        background-position: -375px -140px;
    }
}


/****  floating-Lable style start ****/

.floating-label.twoline {
    margin-top: 30px;
    .floating-input {
        &:focus {
            outline: none;
            border-bottom: 2px solid $primary-color;
            ~ {
                label.labelText {
                    top: -38px;
                }
                .bar {
                    &:before {
                        width: 50%;
                    }
                    &:after {
                        width: 50%;
                    }
                }
                .highlight {
                    -webkit-animation: inputHighlighter 0.3s ease;
                    -moz-animation: inputHighlighter 0.3s ease;
                    animation: inputHighlighter 0.3s ease;
                }
            }
        }
        &:not(:placeholder-shown) {
            ~ {
                label.labelText {
                    top: -38px;
                }
            }
        }
    }
    label.labelText {
        top: -15px
    }
}
payroll-details {
    .form-group { margin-bottom: 0;}
}
.floating-label.focusActive.customLabel label.labelText
,.floating-label.customLabel label.labelText,
.floating-label.customLabel .floating-textarea, 
.floating-label.customLabel label.labelTextarea,
.floating-label.customLabel .floating-select:not([value=""]):valid ~ label.labelText,
.floating-label.customLabel .floating-input:not(:placeholder-shown) ~ label.labelText{
    font-size: 14px!important;
    font-family: 'Poppins';
    font-weight: 400;
    color: #333;
  
}
.floating-label {
    label.LabelTextForSelect{
        top: -18px !important;
    }
}
.floating-label {
    position: relative;
    margin-bottom: 40px;
    &.focusActive {
        label.labelText {
            top: -18px;
            font-size: 12px;
            // color: $primary-color;
        } 
    }
    .floating-input {
        font-family: $font-familym;
        font-weight: $font-sami-bold;
        color: $colornav;
        border: none;
        border-bottom: 1px solid rgba(154, 154, 154, 0.5);
        font-size: 14px;
        padding: 4px 0;
        display: block;
        width: 100%;
        height: 30px;
        background-color: transparent;
        &:focus {
            outline: none;
            border-bottom: 2px solid $primary-color;
            ~ {
                label.labelText {
                    top: -18px;
                    font-size: 12px;
                    // color: $primary-color;
                }
                .bar {
                    &:before {
                        width: 50%;
                    }
                    &:after {
                        width: 50%;
                    }
                }
                .highlight {
                    -webkit-animation: inputHighlighter 0.3s ease;
                    -moz-animation: inputHighlighter 0.3s ease;
                    animation: inputHighlighter 0.3s ease;
                }
            }
        }
        &:not(:placeholder-shown) {
            ~ {
                label.labelText {
                    top: -18px;
                    font-size: 12px;
                    // color: $primary-color;
                }
            }
        }
        &:-webkit-autofill {
            ~ {
                label.labelText {
                    top: -18px;
                    font-size: 12px;
                }
            }
        }
    }
    .floating-select {
        font-size: 14px;
        font-family: $font-familym;
        font-weight: $font-sami-bold;
        color: $colornav;
        border: none;
        border-bottom: 1px solid rgba(154, 154, 154, 0.5);
        padding:4px 25px 4px 2px;
        display: block;
        width: 100%;
        height: 30px;
        background: url(/assets/base/images/drop-down-arrow.svg) no-repeat 98% 1px;
        background-size: 4% auto;
        &:focus {
            outline: none;
            border-bottom: 2px solid $primary-color;
            ~ {
                label.labelText {
                    top: -18px;
                    font-size: 12px;
                    //color: $primary-color;
                }
                .bar {
                    &:before {
                        width: 50%;
                    }
                    &:after {
                        width: 50%;
                    }
                }
                .highlight {
                    -webkit-animation: inputHighlighter 0.3s ease;
                    -moz-animation: inputHighlighter 0.3s ease;
                    animation: inputHighlighter 0.3s ease;
                }
            }
        }
        &:not([value=""]) {
            &:valid {
                ~ {
                    label.labelText {
                        top: -18px;
                        font-size: 12px;
                        // color: $primary-color;
                    }
                }
            }
        }
        &:-webkit-autofill {
            ~ {
                label.labelText {
                    top: -18px;
                    font-size: 12px;
                }
            }
        }
    }
    label.labelText {
        color: $color9a9a;
        font-size: 12px;
        // font-family: $font-familyp;
        font-weight: 500;
        position: absolute;
        pointer-events: none;
        left: 0;
        top: 5px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }
    label.labelTextarea {
        color: $color9a9a;
        font-size: 12px;
        // font-family: $font-familyp;
        font-weight: 500;
        position: absolute;
        pointer-events: none;
        left: 0;
        top: -18px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }
    .floating-textarea {
        min-height: 30px;
        max-height: 260px;
        overflow: hidden;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .highlight {
        position: absolute;
        height: 50%;
        width: 100%;
        top: 15%;
        left: 0;
        pointer-events: none;
        opacity: 0.5;
    }
    // @-webkit-keyframes "inputHighlighter" {
    //     from {
    //         background: $primary-color;
    //     }
    //     to {
    //         width: 0;
    //         background: transparent;
    //     }
    // }
    // @-moz-keyframes "inputHighlighter" {
    //     from {
    //         background: $primary-color;
    //     }
    //     to {
    //         width: 0;
    //         background: transparent;
    //     }
    // }
    // @keyframes "inputHighlighter" {
    //     from {
    //         background: $primary-color;
    //     }
    //     to {
    //         width: 0;
    //         background: transparent;
    //     }
    // }
}
.businessphone {
    input.ng-invalid ~ label.labelText { top: 3px; } 
}

/****  floating-Lable style end ****/

.radioWrap {
    [type="radio"] {
        &:checked {
            position: absolute;
            left: -9999px;
            + {
                label.rlt {
                    position: relative;
                    padding-left: 28px;
                    cursor: pointer;
                    line-height: 20px;
                    display: inline-block;
                    //color: $color20e;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 18px;
                        height: 18px;
                        border: 4px solid $color6f9;
                        border-radius: 100%;
                        background: $color6f9;
                        -webkit-transition: all 0.2s ease;
                        transition: all 0.2s ease;
                        opacity: 1;
                    }
                    &:after {
                        content: '';
                        width: 8px;
                        height: 8px;
                        background: #fff;
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        border-radius: 100%;
                        -webkit-transform: scale(1);
                        transform: scale(1);
                    }
                }
            }
        }
        &:not(:checked) {
            position: absolute;
            left: -9999px;
            + {
                label.rlt {
                    position: relative;
                    padding-left: 28px;
                    cursor: pointer;
                    line-height: 20px;
                    display: inline-block;
                    //color: $color20e;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 18px;
                        height: 18px;
                        border: 1px solid $colorc0c;
                        border-radius: 100%;
                        background: #fff;
                    }
                    &:after {
                        content: '';
                        width: 12px;
                        height: 12px;
                        background: $color507;
                        position: absolute;
                        top: 4px;
                        left: 4px;
                        border-radius: 100%;
                        -webkit-transition: all 0.2s ease;
                        transition: all 0.2s ease;
                        opacity: 0;
                        -webkit-transform: scale(0);
                        transform: scale(0);
                    }
                }
            }
        }
        &.sba_disabled {
            &:not(:checked) {
                ~ {
                    .rlt { color: #ddd !important;
                        &:before { box-shadow: none; border: none; background: transparent url(/assets/base/images/graydisableIcon.svg) no-repeat !important; 
                             cursor: not-allowed; }
                    }
                }
            }
            &:checked {
                + {
                    .rlt { color: #ddd !important;
                        &:before { background: transparent url(/assets/base/images/graydisableIcon.svg) no-repeat !important;
                            box-shadow: none;
                            border: none;
                            background-color: $dropdown-list-bg-color;
                            opacity: 0.5;
                            cursor: not-allowed;
                        }
                        &:after {
                            color: $reddiscolor;
                            width: 0px;
                            height: 0px;
                        }
                    }
                }
            }
            + {
                .rlt {
                    color: $global-border-color;
                }
            }
        }
    }
}





/* Useless styles, just for demo design */

.checWrap.sm-check {
    [type="checkbox"] {
        &:not(:checked) {
            + {
                label.clt {
                    &:before {
                        width: 1.4em;
                        height: 1.4em;
                    }
                }
            }
        }
        &:checked {
            + {
                label.clt {
                    &:before {
                        width: 1.4em;
                        height: 1.4em;
                    }
                }
            }
        }
        &:checked {
            position: absolute;
            left: -9999px;
            + {
                label.clt {
                    &:after {
                        font-size: 1em;
                        top: 0.55em;
                    }
                }
            }
        }
    }
}

.checWrap {
    [type="checkbox"] {
        &:not(:checked) {
            position: absolute;
            left: -9999px;
            + {
                label.clt {
                    position: relative;
                    padding-left: 2.95em;
                    cursor: pointer;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 3px;
                        width: 1.8em;
                        height: 1.8em;
                        border: 2px solid #ccc;
                        background: #fff;
                        border-radius: 4px;
                        box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
                    }
                    &:after {
                        content: '\2713\0020';
                        position: absolute;
                        top: .15em;
                        left: .22em;
                        font-size: 1.3em;
                        line-height: 0.8;
                        color: #09ad7e;
                        transition: all .2s;
                        font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
                        opacity: 0;
                        transform: scale(0);
                    }
                }
            }
            &:focus {
                + {
                    label.clt {
                        &:before {
                            // border: 2px dotted blue;
                        }
                    }
                }
            }
        }
        &:checked {
            position: absolute;
            left: -9999px;
            + {
                label.clt {
                    position: relative;
                    padding-left: 2.95em;
                    cursor: pointer;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 3px;
                        width: 1.8em;
                        height: 1.8em;
                        border: 2px solid $color6f9;
                        background: #fff;
                        border-radius: 4px;
                        box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
                    }
                    &:after {
                        content: '\2713\0020';
                        position: absolute;
                        top: .40em;
                        left: .22em;
                        font-size: 1.5em;
                        line-height: 0.8;
                        color: $color6f9;
                        transition: all .2s;
                        font-family: $font-familyp;
                        opacity: 1;
                        font-weight: $font-sami-bold;
                        transform: scale(1);
                    }
                }
            }
            &:focus {
                + {
                    label.clt {
                        &:before {
                            // border: 2px dotted blue;
                        }
                    }
                }
            }
        }
        &:disabled {
            &:not(:checked) {
                + {
                    label.clt {
                        &:before {
                            box-shadow: none;
                            border-color: #bbb;
                            background-color: #ddd;
                        }
                    }
                }
            }
            &:checked {
                + {
                    label.clt {
                        &:before {
                            box-shadow: none;
                            border-color: #bbb;
                            background-color: #ddd;
                        }
                        &:after {
                            color: #999;
                        }
                    }
                }
            }
            + {
                label.clt {
                    color: #aaa;
                }
            }
        }
    }
    label {
        &:hover {
            &:before {
                border: 2px solid $color6f9 !important;
            }
        }
    }
    &.check-sm {
        [type="checkbox"] {
            &:not(:checked) {
                + {
                    label.clt {
                        &:before { width: 1.5em; height: 1.5em; }
                        &:after { font-size: 1.3em; }
                    }
                }
            }
            &:checked {
                + {
                    label.clt {
                        &:before {
                            width: 1.5em;
                            height: 1.5em;
                        }
                        &:after { font-size: 1.3em; }
                    }
                }
            }
        }
    }
}
.timeWrap{
    .label-text{
        margin-left: -15px;
    }
    [type="checkbox"] {
        &:not(:checked) {
            position: absolute;
            left: -9999px;
            + {
                label.clt {
                    position: relative;
                    padding-left: 2.95em;
                    cursor: pointer;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 3px;
                        width: 1.2em;
                        height: 1.2em;
                        border: 2px solid #ccc;
                        background: #fff;
                        border-radius: 4px;
                        box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
                    }
                    &:after {
                        content: '\2713\0020';
                        position: absolute;
                        top: .15em;
                        left: .22em;
                        font-size: 1.3em;
                        line-height: 0.8;
                        color: #09ad7e;
                        transition: all .2s;
                        font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
                        opacity: 0;
                        transform: scale(0);
                    }
                }
            }
            &:focus {
                + {
                    label.clt {
                        &:before {
                            // border: 2px dotted blue;
                        }
                    }
                }
            }
        }
        &:checked {
            position: absolute;
            left: -9999px;
            + {
                label.clt {
                    position: relative;
                    padding-left: 2.95em;
                    cursor: pointer;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 3px;
                        width: 1.2em;
                        height: 1.2em;
                        border: 2px solid $color6f9;
                        background: #fff;
                        border-radius: 4px;
                        box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
                    }
                    &:after {
                        content: '\2713\0020';
                        position: absolute;
                        top: .18em;
                        left: .06em;
                        font-size: 1.4em;
                        line-height: 0.8;
                        color: #fff;
                        transition: all .2s;
                        font-family: $font-familyp;
                        opacity: 1;
                        font-weight: 100;
                        transform: scale(1);
                        background-color: #228848;
                    }
                }
            }
            &:focus {
                + {
                    label.clt {
                        &:before {
                            // border: 2px dotted blue;
                        }
                    }
                }
            }
        }
        &:disabled {
            &:not(:checked) {
                + {
                    label.clt {
                        &:before {
                            box-shadow: none;
                            border-color: #bbb;
                            background-color: #ddd;
                        }
                    }
                }
            }
            &:checked {
                + {
                    label.clt {
                        &:before {
                            box-shadow: none;
                            border-color: #bbb;
                            background-color: #ddd;
                        }
                        &:after {
                            color: #999;
                        }
                    }
                }
            }
            + {
                label.clt {
                    color: #aaa;
                }
            }
        }
    }
}
.my-text-wrap{
    [type="checkbox"]{
        position: absolute;
        left: -9999px;
    }
}
input:checked ~ .checkmark {
    background-color:  #228848;
}
lib-create-client rubicon-dynamic .form-group{
    margin-bottom: 0px !important;
}
ppp2-edit-user rubicon-dynamic .form-group{
    margin-bottom: 0px !important;
}
lib-create-client rubicon-dynamic .h-line{
   border-bottom: 2px solid #228848;
   height: 70px;
   margin-bottom: 20px !important;
}
lib-create-client rubicon-dynamic .my-amount{
    margin-top: 10px !important;
 }
.switchBtn {
    font-size: 1.5em;
    height: $height;
    margin-bottom: 10 / 16 * 1em;
    position: absolute;
    right: 0;
    top: -19px;
    width: $width;
    .lbl-off,
    .lbl-on {
        cursor: pointer;
        display: block;
        font-size: 0.6em;
        font-weight: bold;
        line-height: 1em;
        position: absolute;
        top: 5 / 16 * 1em;
        transition: opacity $transition-default;
        text-transform: uppercase;
    }
    .lbl-off {
        right: 7 / 7 * 1em;
        color: $color-white;
    }
    .lbl-on {
        color: $color-white;
        opacity: 0;
        left: 7 / 16 * 1em;
    }
    .switch {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        // --
        height: 0;
        font-size: 1em;
        left: 0;
        line-height: 0;
        outline: none;
        position: absolute;
        top: 0;
        width: 0;
        &:before,
        &:after {
            content: '';
            font-size: 1em;
            position: absolute;
        }
        &:before {
            border-radius: 20 / 16 * 1em;
            background: $color-silver;
            height: $height;
            left: 3 / 16 * -1em;
            top: 1 / 16 * -1em;
            transition: background-color $transition-default;
            width: $width;
        }
        &:after {
            box-shadow: 0 .0625em .375em 0 #666;
            border-radius: 50%;
            background: $color-white;
            height: 14 / 16 * 1em;
            transform: translate(0, 0);
            transition: transform $transition-default;
            width: 14 / 16 * 1em;
        }
        &:checked {
            &:after {
                transform: translate(29 / 16 * 1em, 0);
            }
            &~.lbl-off {
                opacity: 0;
            }
            &~.lbl-on {
                opacity: 1;
            }
        }
        &.switch-blue {
            &:checked:before {
                background: $color-peter-river;
            }
        }
    }
}

.bs-datepicker {
    @include border-radius(4px);
    .bs-datepicker-container {
        padding: 5px;
    }
}

.pageContent {
    min-height: calc(100vh - 136px);
}

.dashboardContent {
    min-height: calc(100vh - 229px); padding-top: 20px; padding-bottom: 20px; 
}

.modal-header {
    background-color: $color6f9;
    color: $color-white;
    h4 {
        font-size: 18px; background: none;
    }
    .close {
        opacity: 1;
        font-weight: 400;
        font-size: 30px;
        color: #fff;
        line-height: 20px;
    }
    &.whiteHead {background-color: #fff; color: #333;
        .close { color:#000;}
    }
}

.rightArrow {
    background: url(/assets/base/images/right-arrow.svg) no-repeat;
    width: 15px;
    height: 12px;
    display: inline-block;
    margin-left: 30px;
}

.card.login {
    label.labelText {
        top: -18px;
        font-size: 12px;
        // color: $primary-color;
    }
    .form-group {
        margin-bottom: 0;
        margin-top: 20px;
    }
}

.floating-label .floating-select[multiple] {
    height: 80px;
}

.mainWraper {
    padding-bottom: 80px;
    position: relative;
    min-height: 100%;
}

html,
body {
    height: 100%;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
}

.popover-lg .popover {
    max-width: 500px;
}

.search-result {
    position: absolute;
    top: 35px;
    background-color: #fff;
    width: 93%;
    z-index: 99;
    max-height: 300px; border: 1px solid #d6d4d4;
    overflow-y:auto; @include white-box-shadow();
}

.search-result li {
    border-bottom: 1px solid #e0e0e0;
    padding: 5px;
}

.search-result li:hover {
    cursor: pointer;
}

.signup {
    padding: 0;
    border: 0;
    .form-group {
        margin-bottom: 0;
    }
}

.form-group {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        // border: 1px solid green;
        //-webkit-text-fill-color: green;
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}

.dropdown-item.active,
.dropdown-item:active {
    text-decoration: none;
    background-color: #f8f9fa;
}



.leftColtopSpace {
    .col-lg-8.pt-5 {
        padding-top: 0!important
    }
}

@media (min-width:1024px) {
    .fullTableui {width: 1230px!important;z-index: 1;max-width: none!important; position: relative;
        table {width: 1230px!important; z-index: 1;max-width: none!important;
            .downArrow {  margin-left: 20px; }
            tr.cocp {
                th { padding: 10px 3px!important; vertical-align: middle;
                    br {  display: none }
                }
                td {padding: 10px 3px!important; vertical-align: top;
                    .textSelect {  background-position: 98% 12px; background-size: 11% auto;  }
                }
                td.thcol1,
                th.thcol1 { width: 30px!important; }
                td.thcol2,
                th.thcol2 {      width: 170px!important; }
                td.thcol3,
                th.thcol3 {      width: 80px!important; }
                td.thcol4,
                th.thcol4 {
                    width: 100px!important;
                }
                td.thcol5,
                th.thcol5 {
                    width: 150px!important;
                }
                td.thcol6,
                th.thcol6 {
                    width: 120px!important;
                }
                td.thcol7,
                th.thcol7 {
                    width: 150px!important;
                }
                td.thcol8,
                th.thcol8 {
                    width: 60px!important;
                }
                td.thcol9,
                th.thcol9 {
                    width: 60px!important;
                }
                td.thcol10,
                th.thcol10 {
                    width: 100px!important;
                }
                td.thcol11,
                th.thcol11 {
                    width: 150px!important;
                }
            }
        }
    }

// .ListDocument {
//     li:first-child {
//         .text-right.col-12.col-lg-3 { max-width: 34%;  flex: 0 0 34%; }
//     }
// }

.modal {         
    .userOnBoarding {   
        .modal-content { padding: 25px 35px; border-radius: 16px; }
        }
    }
}

.borderMessage {
    border: 1px solid $color9a9a;
}

.daskDashTable {
    .emailidText {
        word-break: break-word;
        word-wrap: break-word;
        width: 100px;
    }
}


/*For IE10+*/

select::-ms-expand {
    display: none;
}

.onoffswitch {
    position: relative;
    width: 52px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.onoffswitch-checkbox {
    display: none;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
}

.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 22px;
    padding: 0;
    line-height: 22px;
    font-size: 12px;
    color: $colornav;
    font-weight: bold;
    box-sizing: border-box;
}

.onoffswitch-inner:before {
    content: "Yes";
    padding-left: 9px;
    background-color: $primary-color;
    color: $colorfff;
}

.onoffswitch-inner:after {
    content: "No";
    padding-right: 9px;
    background-color: $colorc0c;
    color: $colorfff;
    text-align: right;
}

.onoffswitch-switch {
    display: block;
    width: 17px;
    height: 17px;
    margin: 2px 2px 0 0px;
    background: $colorfff;
    position: absolute;
    top: 0px;
    bottom: 0;
    right: 30px;
    border: 2px solid $colorfff;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
    right: 0px;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    // .v-play {
    //     width: 190px!important;
    // }
    /* IE10+ specific styles go here */
    .ieLabel {
        label.labelText {
            top: -18px;
            font-size: 12px;
        }
    }
    .tableui {
        th {
            border: 1px solid $color507!important;
            border-bottom: 1px solid $color507!important;
        }
    }
    .logoRow {
        .dropdown-menu {
            animation-name: none!important;
        }
    }
    .daskDashTable {
        .btn-apply {
            width: 150px;
            white-space: normal;
            span {
                float: left;
                width: 80px;
                display: block !important;
                &.rightArrow {
                    width: 20px;
                    margin-top: 20px!important;
                    margin-left: 5px!important;
                }
            }
        }
    }
    
    .card {         
        &.purple { 
            .card-header { 
                h2 { background: none !important; filter:0 !important;  color: #c6a526 !important; }
            }
            .card-body {
                .price { 
                    span { background: none !important; filter:0 !important;  color: #c6a526 !important;}
                }
            }
        }       
        &.blue { 
            .card-header { 
                h2 { background: none !important; filter:0 !important; color: #7d7d7d !important; }
            }
            .card-body {
                .price {  
                    span { background: none !important; filter:0 !important;  color: #7d7d7d !important;}
                }
            }
        }
    }
    .purpleBlock .cpa-purpalBox { width: 700px; }
    .subscribe {
        table {
            thead {                            
                tr {
                    th.brown{ 
                        h2 { background: none !important; filter:0 !important;  color: #c6a526 !important; }
                    } 
                    th.gray{ 
                        h2 { background: none !important; filter:0 !important; color: #7d7d7d !important; }
                    } 
                 }
            }
        }
    }

}

#wgt_addit_pyroll_benef_modal {
    .modal-title {
        color: #fff;
    }
    .close {
        color: #fff;
        font-size: 40px!important
    }
    .col-form-label {
        font-size: 14px;
        color: #111111;
        font-weight: 600;
        display: block;
    }
    .input-group-sm>.form-control {
        padding: .75rem .5rem;
        border-radius: 0!important
    }
    .modal-footer {
        label {
            font-size: 14px;
            color: #111111;
            font-weight: 600;
            display: block;
        }
    }
}


/*Microsoft Edge Browser 12+ (All)*/

@supports (-ms-ime-align:auto) {
    .ieLabel {
        label.labelText {
            top: -18px;
            font-size: 12px;
        }
    }
    .tableui {
        th {
            border: 1px solid $color507!important;
            border-bottom: 1px solid $color507!important;
        }
    }
    .logoRow {
        .dropdown-menu {
            animation-name: none!important;
        }
    }
    // .v-play {
    //     width: 192px!important;
    // }
    
}

.blueLightGradi {
    @include blueLightGradient();
}
.greenGradi {
    @include greenGradient();
}
.blueDarkGradi {
    @include blueDarkGradient();
}
.acl {
    .user-step { background: $color6f9 !important;
        .nav {
            li { 
                span.icon { background-color: rgba(255,255,255,.2); }
                &::after { border-color: $colorfff !important; }
                &.active::before { left: calc(100% + 17px) !important; border-left: 7px solid $color6f9 !important;  }
                h4 { color: $colorfff; }
                &.done {
                    span.icon { background-color: #249a40; box-shadow: 0 0 0 5px  rgba(36,154,64,.5); }
                }
                &.active { 
                    span.icon {background-color: $color2d6;}
                }
            }
        }
    }
}
lib-role-list {
    .table-list { 
        table { 
            td, th { min-width: 130px;
            .btn { min-width: 0px; padding: 0 5px!important; }
            } 
        }
    }
}

.tab-content-inner {
    .col-4.borderRight { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%;}
    .card-body { padding: 5px; }
    .card-header { padding: 5px; }
}
@media (max-width:1200px) {
    .ListDocument {
        label.btn.fileUp { padding: 5px 12px !important; font-size: 12px !important; }
        .uploadIcon { margin-right: 0; }
        }
    .user-mgmt-section {
        .row.justify-content-md-center {
            .col-10  {  -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%;}
            .col-4.pt-2 {  -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%;}
        }
        .listDetlSection { 
            .dataTblArea { overflow: auto; max-width: calc(100% - 30px); padding: 0; margin: 0 15px; 
                table {
                    tr {
                        td, th { padding: 22px 10px;                         
                            &:nth-child(5) { width: 100px; min-width: 100px; }
                        }
                        
                    }
                }
            }
        }
    }
    .user-step-wrap {
        .user-step {
            .nav {
                li {
                    &.active::before { border-left: 7px solid $primary-color !important; left: calc(100% + 17px) !important; }
                }
            }
        }
        .card.p-5 { padding: 5px 0!important; box-shadow: none; }
            lib-dynamic-form-builder {
                lib-field-builder { width: 100% !important; }
            }
        }
        lib-role-list {
            .table-list { overflow: auto; margin: 0;
                table { 
                    td, th { min-width: 130px;
                        &:nth-child(1) { min-width: 0;}
                        .btn { min-width: 0px; }
                    }
                }
                .col-12 { padding: 0px; } 
            }
        }
        lib-action-title-header {
            .col-6.text-right {
                .btn {margin-top: 5px; padding: 10px 15px; }
            }
        }
        .table{
            &.botarrowMobile { width: 100% !important;  }
        } 
        .fullTableui { overflow: auto; 
            .tableui {
                td { vertical-align: top; 
                    label.error { line-height: normal; font-size: 10px; }
                }
            }
        }
        
}
@media (max-width:767px) {
    .fullTableui { width: 100%; }
    .tableui.table-sm tr td { padding: 12px 10px; }
    .dashboardContent { padding-top: 0px; }
    .user-mgmt-section {
        h2 { font-size: 21px; }
        .btn.btn-primary { font-size: 12px !important; margin-bottom: 3px; padding: 5px 10px !important; }
        .row.justify-content-md-center {
            .col-4.pt-2 {  -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%;}
            .col-10  {  -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%;}
        }
    }
    .sendForEsign {
        .btn { font-size: 14px !important; }
        .fs18 { font-size: 13px; }
    }
    .signup, .login {        
        .card-body { padding: 20px !important; 
            .floating-label { min-height: 50px; margin-bottom: 10px !important; }
        }
    }
    
    .blueulList{
        li { font-size: 12px !important;; }
    }
}

@media (max-width:480px) {
    .mainmenu {
        .container { padding: 0;
            .btn { padding: 8px 17px !important; font-size: 11px !important; }
        }
    }
    .ListDocument {
        li.wbg { padding: 10px 20px;
            &:before { left: 11px; }
        }
        .btn.fileUp { padding: 5px 7px !important; font-size: 12px !important; }
    }
    lib-main-component {
        lib-document-management {
            .row.py-5.my-5 { padding: 20px 0 !important; margin: 0 !important; }
        }
    }
    .user-mgmt-section {
        h2 { font-size: 21px; }
        .btn.btn-primary { font-size: 12px !important; margin-bottom: 3px; padding: 5px 10px !important; }
        .row.justify-content-md-center {
            .col-4.pt-2 {  -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%;}
            .col-6.text-right {  -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; text-align: left !important; }
        }
    }
    .col-4.pt-2 {  -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%;}
    .user-step-wrap {
        .col-3.pl-0 { padding: 0; }
        .user-step {
            .card-body { padding: 0; }
            .nav {
                li {
                    // &::before, &::after  { content: none !important; }
                   h4 { font-size: 12px !important; padding: 0 5px; }
                }
            }
        }
        lib-dynamic-form-builder {
            .card-body { padding: 10px 0; }
        }
    }
    
    .tab-content-inner {
        .col-4.borderRight { padding: 0; -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; border-right: none !important;}
    }
    .acl .container { padding: 0;}
    .fs-xs-10 { font-size: 10px !important; }
    .fs-xs-9 { font-size: 9px !important; }
    .w-50.inputbotline {
        width: 100%!important;
    }
}

@media (min-width:768px) {
    .dashboardContent  { 
        &.acl {
            .container { max-width: 720px;             
            .container-fluid { padding: 0; }
         }
        }
    }
    
}

@media (min-width:1199px) {
    .dashboardContent  {
        &.acl {
            .container { max-width: 1260px;             
            .container-fluid { padding: 0; }
         }
        }
    }
}

.pageContent { padding-bottom: 20px; }
.que_info { cursor: pointer; display: inline-block; vertical-align: top; width: 18px;min-width: 18px; height: 18px; background: url(/assets/base/images/info-icon.svg) 1px 0px no-repeat;background-size: 16px auto;position: relative;
    top: 0px; right: -3px; }

.que_info-text { cursor: pointer; display: inline-block; vertical-align: middle; width: 18px; height: 18px; background: url(/assets/base/images/info-icon.svg) 1px 1px no-repeat;background-size: 16px auto }
.floating-label { position: relative;
    .que_info { position: absolute; top: 6px; right: 0; }
}
.labeltext { float: left; border-radius: 5px !important; position: relative; background: #fff; padding: 8px 0 8px 0; width: 80px; margin: 0 15px 0 0; color: #333;font-size: 13px; font-weight: 500; text-align: center; cursor: pointer; border: solid 1px $primary-color; }
.labeltext input+span { display: none; }
.labeltext input:checked+span { width: 102%; height: 104%; position: absolute; left: -1px; top: -1px; border-radius: 4px; content: ''; background: $primary-color; display: block }
.labeltext input:checked+span+em { color: #fff; }
.labeltext input+label+span { display: none; }
.labeltext input:checked+label+span { width: 102%; height: 104%; position: absolute; border-radius: 4px; left: -1px; top: -1px; content: ''; background: $primary-color; display: block; }
.labeltext input:checked+label+span+em { color: #fff; }
.labeltext em { font-style: normal!important; position: relative; z-index: 999; color: #333; }
.labeltext input { display: none; }
.divline { background: $primary-color; margin: 4px 0; height: 4px; width: 100%; }
.pppEsignPopup { background-image: url(/assets/base/images/esign_lock.svg)!important; background-repeat: no-repeat!important; background-position:12px 10px !important; background-size:17px!important; padding-left:35px!important;
    &:hover,&:active,&:focus { background-image: url(/assets/base/images/esign_lock.svg)!important; background-repeat: no-repeat!important; background-position:12px 10px !important; background-size:17px!important; }
}


.borBox {
    border: solid 1px $color2d6;
    color:$color2d6;
    padding: 10px;
    max-width: 500px;
    margin: auto;
    ul {
        list-style: disc;
        padding: 0 0 10px 0;
        margin: 0 0 0 0;
        list-style-position: inside;
        li {
            padding: 0 0 5px 0;
            margin: 0 0 0 0;
            font-size: 13px;
            font-weight: 500;
        }
    }
}
.posrighT {
    float: right;
    position: relative;
    top: -31px;
    margin-bottom: -33px;
}
.btn-defaultLink {
    background: $colorfff;
    border-color: $primary-color;
    color: $primary-color!important;
    &:hover {
        background: $primary-color;
        color: $colorfff!important;
    }
    &:focus {
        background: $primary-color;
        color: $colorfff!important;
    }
    &:active {
        background: $primary-color;
        color: $colorfff!important;
    }
}
.cardModal{ max-width: 970px;}
.amountSlider {
    .ng5-slider {
        .ng5-slider-pointer { width: 20px; height: 20px; top: -8px; background: $primary-color; outline: none;
            &::after { top: 6px; left: 6px; }            
         &.ng5-slider-active:after { background: #fff !important; }
        }
        .ng5-slider-bar {border-radius: 6px; height: 6px; background: rgba(154, 154, 154, 0.2); }
       
        .ng5-slider-selection-bar { visibility: visible !important;
            .ng5-slider-selection { background: $primary-color; border-radius: 6px; height: 6px; }
        }
        .ng5-slider-model-value { display: block; background: #fff; padding: 3px 11px; bottom: 20px; font-size: 11px; border-radius: 4px; font-weight: 600;
            &::after { position: absolute; top: 100%; left: 50%; margin-left: -5px; content: ""; border-top: 5px solid #fff; border-left: 5px solid transparent; border-right: 5px solid transparent;  }
        }
        .ng5-slider-tick { height: 0; }
        .ng5-slider-tick-legend { top: 5px; }
    }
}
affiliate-create-user{
    .floating-label {
        margin-bottom: 25px;
    }
    .StartwithLabel{
        span{
        color: $color9a9a;
        font-size: 12px;
        top: 2px;
        font-weight: 500;
        position: absolute;
        pointer-events: none;
        }
    }
    .radioWrap {
        label.rlt {
            font-size: 12px;
            font-weight: 600;
            color: $colornav!important;}
        }
}

.viewEditWrap{
.btn.btn-sm {
    padding: 0px 12px !important;
    font-size: 13px !important; height: auto;
}
}
.ngb-dp-header {
    .btn { padding: 5px !important; }
}
.text-Break { word-break: break-word; }
.minHeight400 { min-height: 400px; }
.oneLine-text { min-height: 62px; }
.twoLine-text { min-height: 73px; }
.lh16 { line-height: 16px !important; }
.lh20 { line-height: 20px !important; }
.modal-dialog.worldPay.thankyou{
    max-width: 420px!important;    
}

lib-user-create {
    .text-danger { font-size: 12px; margin-top: 5px; }
    .fs12.fwm.pb-2 { margin-top: 10px; }
}
.downloadicon  {
    background: url(/assets/base/images/downloadIcon.svg) center center no-repeat; background-size: 14px;
    height: 24px;
    width: 24px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    background-color: #fff;
    padding: 4px;
    border-radius: 14px;
    text-align: center;
    margin: auto; box-shadow:  0 0 5px 0px #ccc;
}
.close-btn { box-shadow:  0 0 5px 0px #ccc; 
    background-color: #fff;  height: 24px;
    width: 24px;
    display: inline-block; 
    padding: 1px; font-size: 14px !important;
    border-radius: 14px;
    text-align: center; vertical-align: top;
    line-height: 18px; }
    .bankLoginModal{ max-width: 940px;}
.payrollLogo{
    text-align: center; margin: 0 5px;
    display: flex;
    div.wbg{
        margin:10px 5px;
    padding: 5px 0;
    display: inline-block;
    width: 90px;
    text-align: center;
        img{
             width: 100%;
        }
    &.paycimgh{
        img{padding: 7px;}
        
    }
    
    }
    .success{
        position: relative;
        display: inline-block;
        width: 100%;
        height: 23px;
    
    &:after{
        content: '\2713\0020';
    position: absolute;
    top: 0;
    left: 36%;
    font-size:2.5em;
    line-height: 0.8;
    color: #78b13f;
    transition: all .2s;
    font-family: "Roboto", sans-serif;
    opacity: 1;
    font-weight: 600;
    transform: scale(1);
        
    }
      
}
.gustoimgh{
    img{ width: auto!important;max-height: 33px;}
    
}
}
.yodleeWidget{
    .yodleeWrapper{
        display: -ms-flexbox!important;
        display: flex!important;
        > div{
            margin: 23px 10px 0;
        }
    }
    .actionContainer {
        margin: 20px 10px 25px;
    }
    .autoDocumentSection h3.autoActiveSection, .manualDocumentSection h3.manualActiveSection{
        background: $primary-color;
    }
    .autoDocumentSection h3, .manualDocumentSection h3{
        font-size: 20px;
    }
    .documentUpload{
        padding: 20px 15px 70px;
        >.border-bottom-1-5{margin-bottom: 20px;}
        .list-style-disc{
            list-style: disc;
        }
        .dragBox { outline: $color6f9 dashed 2px; }
        .lightBoldText  { margin-top: 10px !important; }
        #auth{background: $color6f9;
            border-color: $color6f9;
            box-shadow: none !important; color: #fff;
            width: 48.4%;
            padding: 10px;
            border: none;
            font-size: 20px;
            text-transform: uppercase;}
             
    }
    .uploadBox { margin-bottom: 20px;
        .progress-bar {
            background-color: $color6f9 !important;
        }
        .fileName {
            font-size: 12px;
            font-weight: 500;
            padding-left: 0;}
            .silverChalice{
                div{
                    font-size: 12px; color:$color6f9 !important;     
                }
            }
    }
    .uploadedFiles{max-height: 255px;
        overflow-x: hidden;
        overflow-y: auto;}
    
}
.uploadContainer{
    .container+.container{
        .col-12{
            >div{ float:none!important;
            #doneMessageID{
                 padding: 10px;color:$color6f9 !important;
            }
            .btn{ float:right;}
            }
        }
    }
}
.bankLoginModal{
    .actionContainer{ float: none!important;
        #doneMessageID{
            color: $primary-color;
        }
        #Failure { color: $red-color; }
        #yodleeAutoBtnLinkAccount{
            float: right;
        }
        
    }
    #yodleeManualBtnContinue{
        float: right;
    }
}
.appListView{
    .actionText {
            margin-left: 18px;
        }
    
        .tooltip_info {
            width: 10px;
                line-height: 3px;
                font-size: 6px;
                padding: 3px;
                text-align: center;
                border: 1px solid;
                border-radius: 100%;
                color: #585c5d !important;
        }
    .ag-header-row{
        background-color: #E3EDF0;
        color: #3C474B
    }
    .tooltip{
        .tooltip-inner {     
                background: #3E494D !important; border-radius: 6px !important; color: #fff; font-size: 12px;
                * {
                    background: #3E494D !important;
                    color: #fff !important;
                    font-size: 12px;
                }
        }
        &.bs-tooltip-left {
            .arrow {
                &::before { border-left-color: #3E494D !important; }
            }
        }
        &.bs-tooltip-bottom {
            .arrow {
                &::before { border-bottom-color: #3E494D !important; }
            }        
            .arrow {
                &::before { border-top-color: #3E494D !important; }
            }
        }
        &.bs-tooltip-right {
            .arrow {
                &::before { border-right-color: #3E494D !important; }            
            }
        }
        &.bs-tooltip-top {
            .arrow {
                &::before { border-top-color: #3E494D !important; }
            }        
            .arrow {
                &::before { border-bottom-color: #3E494D !important; }
            }
        }
    }
}
.modal-body{
    .tooltip{
        .tooltip-inner {     
            background: #fff !important; border-radius: 6px !important; color: #fff; font-size: 12px;
            max-width: 330px !important;
            * {
                background: #fff !important;
                color: #3E494D !important;
                font-size: 12px;
            }
        }
        &.bs-tooltip-right {
            .arrow {
                &::before { display: none; }            
            }
        }
    }
}
.clientListView,.usageTooltip, .affiliateTooltip, .featureCloseBtn {
    .tooltip{
    .tooltip-inner {     
            background: #3E494D !important; border-radius: 6px !important; color: #fff; font-size: 12px;
            * {
                background: #3E494D !important;
                color: #fff !important;
                font-size: 12px;
            }
    }
    &.bs-tooltip-left {
        .arrow {
            &::before { border-left-color: #3E494D !important; }
        }
    }
    &.bs-tooltip-bottom {
        .arrow {
            &::before { border-bottom-color: #3E494D !important; }
        }        
        .arrow {
            &::before { border-top-color: #3E494D !important; }
        }
    }
    &.bs-tooltip-right {
        .arrow {
            &::before { border-right-color: #3E494D !important; }            
        }
    }
    &.bs-tooltip-top {
        .arrow {
            &::before { border-top-color: #3E494D !important; }
        }        
        .arrow {
            &::before { border-bottom-color: #3E494D !important; }
        }
    }
}}

.tooltip { opacity: 1 !important;
    .tooltip-arrow {
        &::before { border-top-color: #fff; }
    }
    .tooltip-inner { 
        max-width: 350px; min-width: 100px; background: #fff ; opacity: 1 !important; box-shadow:  0px 2px 9px 0 rgba(0, 0, 0, .25); color: #111111; padding: 10px !important;
    }
    
    &.bs-tooltip-left {
        .arrow {
            &::before { border-left-color: #fff !important; }
        }        
        .arrow {
            &::before { border-top-color: transparent !important; }
        }
    }
    &.bs-tooltip-bottom {
        .arrow {
            &::before { border-bottom-color: #fff !important; }
        }        
        .arrow {
            &::before { border-top-color: #fff !important; }
        }
    }
    &.bs-tooltip-right {
        .arrow {
            &::before { border-right-color: #fff !important; }            
        }        
        .arrow {
            &::before { border-top-color: transparent !important; }
        }
    }
    &.bs-tooltip-top {
        .arrow {
            &::before { border-top-color: #fff !important; }
        }        
        .arrow {
            &::before { border-bottom-color: #fff !important; }
        }
    }
    &.show { opacity: 1 !important;
        .tooltip-inner {  opacity: 1 !important; }
    }
}

.ppp2-eligb-ques-form {
    .tooltip-inner {  text-align: left !important; }
}
.large-btn:hover { opacity: 1 !important; }
.sidebar .tooltip-inner { min-width: 220px !important}
.sidebar { padding-bottom: 100px; }

.businessFunForm{margin-left: 40px;
    label.error, div.error{
        margin-bottom: 0; color: #E31836;
    }
    .form-control{
         border: 1px solid #525252; padding: 5px 8px !important;  border-radius: 0!important; color: #2a363b; font-size: 15px; font-family: $font-familyp; 
    }
    .form-group {
        margin-bottom: 0.5rem;
    }
    .btn-primary{
        background: $colorfff!important; color: $color3a3a!important; font-size: 18px!important; font-weight: 600; padding: 15px 60px; margin-top: 10px;
    }
    .cpabPasspolicy{ padding-right: 43px!important}
    .infoTip{position: absolute; top: 6px;
        right: 22px;
        border-radius: 40px;
        width: 23px;}
    
        
    &.ertcLoans {
        .heading { margin-left: -10px; }
        .form-group { margin-bottom: 0.5rem; 
            &.col-lg-6, &.col-12 { padding: 0 5px; }
        }
        .form-control{ font-size: 14px; padding: 5px 12px 5px 17px !important; } 
        .input-group-text { left: 6px !important; } 
    }
}

#yodlee_frame { border: 1px solid transparent; }
#yodleeAutoModal .modal-footer .btn { padding: 7px 20px; }
#yodleeAutoModal.modal .modal-content { border: none; }
.bankLoginModal .modal-header { border-top-left-radius: .3rem; border-top-right-radius: .3rem; }
.documentUpload { padding: 20px 15px 30px !important; 
    .border-bottom-1-5 { padding-bottom: 10px; padding-top: 0px; border-bottom: 1px solid rgba(208,208,208, .2); }
    .yodleeManualHeader { font-size: 16px; }
    .btn { padding: 7px 20px; font-size: 14px; }
    .row.mt-40 { margin-top: 20px !important; }
    .dragBox { min-height: 180px !important;    
        .fileTypeLabel { min-height: 180px !important; padding-top: 40px; }
    }
    .uploadBox {
        &:first-child { 
            .font-weight-bold { display: block; }
        }
        .font-weight-bold { display: none; }
    }
    .uploadedFiles { max-height: 187px; }
}
.dashboardContent {
lib-loader {
    div { position: fixed; width: 100%; height: 100%; left: 0px; top: 0px; background: rgba(0, 0, 0, 0.3);
        .loading  { display: block;  z-index: 9; margin-top: -40px; top: 50%; left: 50%; margin-left: -40px; width: 100px !important; height: 100px !important; background: none;
            &::after { position: absolute; margin: 0; box-shadow: none !important;  width: 100%; height: 100%; border: 4px solid #6fb253; opacity: 1; border-radius: 50% !important;  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;   }
            &::before { background: none; margin: 0; content: ""; box-shadow: none !important; width: 100%; height: 100%;  position: absolute; border: 4px solid #6fb253; opacity: 1; border-radius: 50%; animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;     -webkit-animation-delay: -0.5s; animation-delay: -0.5s; }
        }
    }
}
}

    .infoTip:focus {  outline: 1px dashed rgba(0, 0, 0, 0.3); }
    button {
        &.btn {        
            &:focus {
                outline: 1px dashed rgba(0, 0, 0, 0.3)
            }
        }
    }
.using-mouse {
    .form-control {
        &:focus {
            outline: 0 !important;
        }
    }
    button {
        &.btn {        
            &:focus {
                outline: 0 !important;
            }
        }
    }
    &:focus {
        outline: 0 !important;
    }
    a {
        &:focus {
            outline: 0 !important;
        }
    }
}

.aicpaLogoOne { position: relative; top: -5px; 
    &:hover { opacity: 1 !important; }
}
.tooltip.show { opacity: 1 !important; }
.tooltipStyle {
    .tooltip { 
        .tooltip-inner { padding: 10px 3px 10px 10px; max-width: 200px; border-radius: 10px; box-shadow: 0 0 10px rgba(0, 0, 0, .1); font-size: 10px; text-align: left; }
    
        &.bs-tooltip-bottom {
            .tooltip-arrow {
                &::before { border-width: 0 .7rem .4rem; }
            } 
        }
        &.bs-tooltip-top {
            .tooltip-arrow {
                &::before { border-width: .4rem .7rem 0; }
            } 
        }

     }
}
/* Manage User */
.user-mgmt-section {
    .col-7.mt-3.text-right, .col-5.text-right { margin-bottom: 8px; }
}
.videoModel { max-width: 729px !important;
    .modal-content { border: 0px !important;}
    iframe { vertical-align: top; border: 0px !important; }
    .close  { font-size: 30px; font-weight: 400; position: absolute; top: 0px; right: 0px; background: #fff; width: 30px; height: 30px; display: block; }
}
.floating-label .floating-input:focus {
    outline: none;
    border-bottom: 1px solid #6fb253 !important;
}

.large-btn { white-space: normal; line-height: normal; display: block; border-radius: 22px !important; text-align: left; max-width: 260px;
    img { vertical-align: top; }
}
.alert.special {
    &.alert-warning { padding: 7px 13px; border-radius: 4px; border: solid 0.12rem #fa6400; background-color: #faebe1; 
        p { color: #fa6400; }
    }
}
h4 { background-image: none !important; }
@media (max-width: 820px) {
    .mainWraper { padding-bottom: 0px; }
}
@media (max-width: 480px) {
    .wbg  .btn.btn-sm {  padding: 4px 12px!important; font-size: 11px!important; }
}
government-declaration{
    form{ padding: 0 15px;}
}

.worldPay { font-family: $font-familyp;
    .close { background: url(/assets/base/images/close-green.svg) no-repeat; z-index: 99; font-size: 24px; width: 20px; height: 20px; font-weight: 400; position: absolute; right: 15px; top: 25px; 
        span { font-size: 0px !important; }
        &.upgradeClose { top: 0; }
    }
    .modal-content { @include border-radius(15px); padding: 30px 0px; @include whitebox-shadow;
        checkout {
            h2 { line-height: 30px; }    
        }
        h2 { font-size: 30px; line-height: 45px; }
        .plantext { text-transform: uppercase;
            &.basic { color: $primary-color; }
            &.enterprise { color: #7d7d7d; }
            &.premium { color: #f2d35f; }
        }
        p { font-size: 18px; line-height: 30px; margin-bottom: 10px; padding: 0 12px; 
            span.price { color: $color6f9; }
        }
    }
    &.thankyou {
        .modal-content { max-width: 435px;  
            h1 { color: $color20e; }
            p { font-size: 18px; line-height: 30px; margin-bottom: 15px; padding: 0 6px;
                a { color: $color6f9; } 
            }
            .btn { padding: 11px 32px; }
        }
    }
    .upgrademodal {
        // p { line-height: 26px !important; margin-bottom: 15px; }
    }
}
.termsModel { max-width: 1000px;
    .close { background: url(/assets/base/images/closeIcon.svg) no-repeat; z-index: 99; font-size: 24px; width: 20px; height: 20px; font-weight: 400; position: absolute; right: 15px; top: 25px; 
        span { font-size: 0px !important; }
    }
    .modal-content {
        .modal-body { padding: 10px 0 0 0 !important; text-align: left !important;
            p { font-size: 13px; }
            .list { list-style: lower-roman; padding-left: 20px;
                li { font-size: 13px; margin-bottom: 10px; }
            }
            .model-inner-body { padding: 15px 0; 
                .modelContent { padding: 10px 25px;  height: calc(100vh - 150px); overflow-y: scroll; }
            }
            .model-footer { padding: 10px 20px; box-shadow: 0 0 2px rgba(0, 0, 0, .1) }
        }
    }
}


@media (min-width: 991px) {
    #eProtectiframe {
        #eProtect-iframe { height: 120px !important; margin-left: -6px; width: 530px !important}
    }
}

@media (max-width: 990px) {
    #eProtectiframe {
        #eProtect-iframe { height: 380px !important; margin-left: -6px }
    }
}

.linkexpiry{padding: 50px;
    text-align: center;
    font-size: 16px;
    color:$colororange;}
    .disabled{cursor: not-allowed;}

    .ListDocument {
            .card-body{
                > .pt-sm-3 {
                    padding-top: 0!important;padding-bottom: 20px;
                }
            }
    }
.dashboardContent {
    &.acl {
        > .container {
            >.row { margin: 0;
                &.justify-content-md-center {
                     .col-10 { 
                        > p {
                            > span {
                                &.fw500 { padding-top: 10px; display: inline-block; }
                                &:first-child { padding-top: 0; }
                            }
                        }
                        
                     }
                }
            }
        }
    }
}
.loaninfo .md-radio [type=radio] {
    position: absolute;
    left: -5px;
}
modal-container {
    &.modal {
        .noheader {
            .modal-header { background: none; border: none; padding: 0; 
                .close { position: absolute; right: 13px; background: $primary-color; z-index: 99; top: 14px !important; width: 40px; height: 40px; padding: 0; }
            }
            .modal-body { background: none; padding: 0px; 
                iframe { border: none !important; border-radius: 12px; }
            }
        }
    }
}
.owl-nav { 
    .owl-prev { content: "&#8249;"; width: 40px; height: 40px; line-height: 20px; color: $primary-color; z-index: 999;
        background: $color6f9 !important; position: absolute; left: -10px; top: 50%; margin-top: -22px; border-radius: 40px; box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3) !important;
        span { font-size: 0; background: url(/assets/base/images/backarrow.svg) no-repeat; display: block; margin: 14px auto; width: 10px; height: 15px; }
    }
    .owl-next { content: "&#8250;"; width: 40px; height: 40px; line-height: 20px; color: $primary-color; z-index: 999;
        background: $color6f9 !important; position: absolute; right: -10px; top: 50%; margin-top: -22px; border-radius: 40px; box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3) !important;
        span { font-size: 0; background: url(/assets/base/images/backarrow.svg) no-repeat; display: block; margin: 11px auto; width: 10px; height: 15px; transform: rotate(180deg); }
    }
}
.owl-carousel {
    .owl-dots { text-align: center;
        button.owl-dot { border-radius: 12px; margin: 0 2px; width: 12px; height: 12px; display: inline-block; background: $color6f9; opacity: 0.34;
            &.active { opacity: 1; }
        }
    }
    &#testmonial {
        .owl-dots { margin-top: -40px; 
            button.owl-dot { margin: 0 5px; }
        }
    }
}

.opportunity, .testmonial {
    .owl-carousel {
        .owl-nav {
            .owl-next { width: auto; height: auto; font-size: 0px; font-weight: 300; color: $color507; box-shadow: none !important; background: none !important; display: block; margin-right: -47px;  
                &::after { content: ""; display: inline-block; width: 41px; height: 52px; background: transparent url(/assets/base/images/backarrowGray.svg) right top -20px no-repeat !important; vertical-align: middle; }
                &.disabled { font-size: 0px;
                    &::after { background: transparent url(/assets/base/images/backarrowGray.svg) right top -90px no-repeat !important; }
                }
            }
            .owl-prev { width: auto; height: auto; font-size: 0px; font-weight: 300; color: $color507; box-shadow: none !important; background: none !important; display: block; margin-left: -47px; 
                &::before { content: ""; display: inline-block; width: 41px; height: 52px; background: transparent url(/assets/base/images/backarrowGray.svg) left top -90px no-repeat !important; vertical-align: middle; }
                &.disabled { font-size: 0px;
                    &::before { background: transparent url(/assets/base/images/backarrowGray.svg) left top -20px no-repeat !important; }
                }
            }
        }
        .owl-item img { display: inline-block; width: 80px; }
    }
    
}

.panel {
    .signupshowHdie { background: none; box-shadow: none; border: none;
        .panel-heading { background: none; box-shadow: none; border: none; padding: 0;
            .panel-title { border: none; background: none; color: $colorfff; 
                button { background: none; border: none; color: $colorfff; cursor: pointer;
                    &::after { content: ""; background: url(/assets/base/images/backarrow.svg) no-repeat; width: 13px; height: 13px; position: absolute; right: 0px; top: 13px; transform: rotate(270deg); transition: all 0.3s ease; }
                }
            }
        }
        .panel-body { padding: 10px; }
    }
    &.panel-open {
        .signupshowHdie { 
            .panel-title {
                button { 
                    &::after { content: ""; transform: rotate(90deg); }
                }
            }
        }
    }
}
.planUpdate { max-width: 833px; 
    .modal-content { border-radius: 16px;
        h3 { border-bottom: solid 1px rgba(151, 151, 151, 0.15); margin: 0 40px; padding: 25px 0; }
        .close { position: absolute; top: 33px; right: 35px; background: url(/assets/base/images/closeL.svg) no-repeat; width: 20px; height: 20px; 
            span { font-size: 0px;  }
        }
        .AddonPlan { padding: 40px 35px;
            .innerContent { border: solid 2px $color6f9; border-radius: 17px; padding: 30px 10px 40px 10px; text-align: center;
                h2 { color: $color6f9; line-height: 38px;
                    span { display: block; font-size: 36px; }
                }
                p { font-size: 16px; font-weight: 500; }
                .btn { padding: 8px 33px; font-weight: 400; }
                &:hover { background: $color6f9; box-shadow: 0 36px 31px 0 rgba(0, 0, 0, 0.28); transition: all .5s ease-out;
                    h2 { color: $colorfff; }
                    p { color: $colorfff; }
                    .btn { background: $colorfff !important; color: $color6f9 !important; }
                }
            }
            .pagenation {
                button { background: none; border: none; }
                .backArrow { position: relative; display: block; color: $color6f9;
                    &::before { content: ""; display: inline-block; position: relative; top: 6px; background: transparent url(/assets/base/images/PPP-icons.svg) no-repeat -260px -77px; height: 25px;
                        width: 27px; border: none; cursor: pointer; }
                }
                .nextArrow { position: relative; display: block; color: $color6f9;
                    &::after { content: ""; display: inline-block; position: relative; top: 6px; background: transparent url(/assets/base/images/PPP-icons.svg) no-repeat -375px -77px; height: 25px;
                        width: 27px; border: none; cursor: pointer; }
                }
            }
        }
    }
    &.chosseplan {  max-width: 433px; text-align:center; margin: 0 auto;
        .modal-content { 
            h3 { border-bottom: none; margin: 0 0px; }
            .close { top: 20px; right: 20px; }
            .AddonPlan { padding: 30px 5px; max-width: 335px; width: 335px; margin: 0 auto !important; 
                .innerContent { box-shadow: 0 15px 14px 0 rgba(0, 0, 0, 0.12); border: solid 1px rgba(34, 136, 72, 0.21); 
                    &:hover { background: none;
                        h2 { color: $color6f9; }
                        p { color: $colortext; }
                    }
                }
             }
             .modal-footer { border-radius: 0px 0px 16px 16px; background-color: #2a363b; color: $colorfff; justify-content: space-around;
                a { color: $colorfff; }
                
                .nextArrow { 
                    position: absolute; top: 30%; right: 0; display: block; color: $colorfff !important;
                    width: auto;
                    border: none;
                    vertical-align: middle;

                    &::after { 
                        content: ""; 
                        background: transparent url(/assets/base/images/whitearrow.svg) no-repeat;
                        height: 16px; width: 27px; 
                        border: none; 
                        cursor: pointer; 
                        display: inline-block; 
                        vertical-align: middle; 
                    }
            }
             }
        }
    }
    &.commissionReport { max-width: 500px; }
}

.cardDetail {
    text-align: center; 
    .close { top: 20px; right: 20px; }
    .modal-content { border-radius: 16px;
        h3 { margin: 0 40px; padding: 25px 0; }
        .close { position: absolute; top: 23px; right: 15px; background: url(/assets/base/images/closeL.svg) no-repeat; width: 20px; height: 20px; z-index: 9; 
            span { font-size: 0px;  }
        }
        .modal-body {
            .btn { min-width: 150px; }
            .gray { color: #767676; } 
            .radioWrap { padding: 12px 20px; margin-bottom: 20px; text-align: left; border-radius: 8px; box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25); 
                label { margin: 0; font-size: 16px; color: #767676; }
                &.active { box-shadow: 0 0 4px 0 #228848; }
            }
        }

        .modal-footer { border-radius: 0px 0px 16px 16px; background-color: #2a363b; color: $colorfff; justify-content: space-around;
            a { color: $colorfff; }
         }
    }
}
.npo{
    .firstname{
        margin-right: 2em
    }
}
body .editfield { position: relative;
    .floating-input { padding-right: 30px; }
  &::after { content: ""; position: absolute; top: 10px; left: auto; right: 10px; width: 20px; height: 20px; background: url(/assets/base/images/edit_draw.svg) no-repeat; background-size: 13px; opacity: .5; }
}
.accountsetting {
    input:disabled { opacity: .5 !important; }
    input:disabled ~ .labelText { opacity: .5 !important; }
}
.change_password.login{
    .infoTip {
        position: absolute;
        top: 0px;
        right: 0px;
        border-radius: 40px;
        width: 23px;
    }
    .card-body {
        padding: 30px 30px 20px 30px!important;
    }
    .modal-body {
        padding: 20px 30px 20px 30px!important;
        .floating-label {
            margin-bottom: 20px;
        }
    }
}
.popup_changePassword {
    max-width: 500px;
}
.w-92{
    width : 92%;
}

.word-break{
    word-break: break-all;
}

.fte-modal{
    max-width: 600px;
}
.accountModal { max-width: 1000px;
    .order-lg-1 > .fs20, .order-lg-1 > .fs12{ padding-left: 24px;}

    .close { display: none; background: url(/assets/base/images/closeL.svg) no-repeat; z-index: 99; font-size: 24px; width: 20px; height: 20px; font-weight: 400; position: absolute; right: 20px; top: 20px; 
        span { font-size: 0px !important; }
    }
    .accountsetting {
        box-shadow: none !important; margin-bottom: 0 !important; padding: 25px !important;
    }
    .col-12.col-lg-6.order-lg-1.order-2 { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
    .modal-body { padding: 0 !important;
        .fs12.pb-5 { padding-bottom: 0 !important; } }
    }
.modal {         
    .userOnBoarding {   
        .modal-content { padding: 25px 15px; border-radius: 16px;
            .modal-header { background: none; border: none; padding: 0;  z-index: 999;
                .modal-title { border: none; background: none; color: $colore2a3; }
                .close { position: absolute; top: 43px !important; right: 37px; background:none; background: url(/assets/base/images/closeL.svg) no-repeat; width: 20px; height: 20px; 
                    span { font-size: 0px;  }
                }
            }
        }
    }
}
        .customNextBtn { cursor: pointer; position: relative; top: -15px; font-weight: 400 !important; z-index: 999; line-height: 24px !important; padding: 4px 28px !important; }
.employee_number {
    margin-bottom: -30px;
    rubicon-text {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        .col-4{
            margin-left: 0px;
            float: right;
            max-width: 30%;
        }
        .error-msg{
            padding-left: 15px;
        }
        .primary-label {
            display: inline-block;width: 56%;
        }
    }
}

.hideinfotip {
    .infoTip {
        display: none;
    }
}

.previous-loan-info{
    .revnue-red-remove{
        margin-top:-40px !important;
    }
}

.is_no_ein {
    .ppp2SsnParent, .ppp2TinParent{
        display: none;
    }
}

.cnfrmSkpPyrlSchdl4{
    max-width: 600px;
}

.prev_loan_group_parent .prev_loan_group:first-of-type .revnue-red-remove{
    display: none !important;
}
.number_of_monField{ left: 23px;
    //max-width: 152px;
    //select{background-size: 10% 28px!important; padding-left:2px!important; }

}
.pac-container {
    z-index: 1050 !important;
}

.primary-label .que_info-text { margin: 1px 0 0 2px; }
.success-msg .success {
    color: #228848;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
}

.success-msg a.error {
    color: #ef5555;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    text-decoration: underline;
}
.Purposetheloan{
    .col-lg-auto{ width: 50%;}
}
.full_time_period{
    .floating-select{
        background-size: 7% auto;
    }
}
.tooltip-inner{
    text-align: left!important;
}
.addenduma-wrap .helpTextETran{
    display: none;
}
// .addenduma-wrap:first-child .helpTextETran{
//     display: block;
// }
.modalHp { max-width: 700px; }

.dashboardtooltip { position: relative;display: inline-block;
    .tooltip { pointer-events: visible !important; }
    .tooltip-inner { width: 250px; z-index: 999; padding-bottom: 1px !important; }
}
.red-text { color: $red-color !important; }
.exclaimation{border: 2px solid red;
    border-radius: 20px;
    display: inline-block;
    width: 20px;
    text-align: center;
    height: 20px;
    margin-right: 10px;}
    .doclupload{
    .eyeIcon{
        position: relative;
        .eye{
            position: absolute; right: -30px; top: -3px; cursor: pointer;
            background: url(/assets/base/images/PPP-icons.svg) no-repeat -162px -176px; width: 21px; height: 20px; display: inline-block;
        }
        .eye{
            &.eyeclose{ background-position:-162px -205px }
        }
    }
}
.speciTooltip{
    position: relative;right: -3px;top: -2px;
    }
 //new 3508 changes
 .labeltext { float: left; border-radius: 5px !important; position: relative; background: #fff; padding: 8px 0 8px 0; width: 80px; margin: 0 15px 0 0; color: #333;font-size: 13px; font-weight: 500; text-align: center; cursor: pointer; border: solid 1px $primary-color; }
 .labeltext input+span { display: none; }
 .labeltext input:checked+span { width: 101%; height: 104%; position: absolute; left: 0; top: -1px; border-radius: 4px; content: ''; background: $primary-color; display: block }
 .labeltext input:checked+span+em { color: #fff; }
 .labeltext input+label+span { display: none; }
 .labeltext input:checked+label+span { width: 101%; height: 104%; position: absolute; border-radius: 4px; left: 0; top: -1px; content: ''; background: $primary-color; display: block; }
 .labeltext input:checked+label+span+em { color: #fff; }
 .labeltext em { font-style: normal!important; position: relative; z-index: 999; color: #333; }
 .labeltext input { display: none; }
 .search-result {
 position: absolute;
 top: 35px;
 background-color: #fff;
 width: 93%;
 z-index: 99;
 max-height: 300px;
 overflow-y:auto;
 }
 .search-result li {
 border-bottom: 1px solid #e0e0e0;
 padding: 5px;
 }
 .search-result li:hover {
 cursor: pointer;
 }
 .que_info { cursor: pointer; display: inline-block; vertical-align: middle; width: 18px; height: 18px; background: url(/assets/base/images/info-icon.svg) 1px 0px no-repeat; background-size: 17px auto; }
 .que_info-text { cursor: pointer; display: inline-block; vertical-align: middle; width: 18px; height: 18px; background: url(/assets/base/images/info-icon.svg) 1px 1px no-repeat;background-size: 16px auto }
 .floating-label .que_info {
 position: absolute;
 top: 6px;
 right: 0;
 } 
 .info-icon-tooltip {
     cursor: pointer; display: inline-block; 
     width: 18px;min-width: 18px; height: 18px; 
     background: url(/assets/base/images/tooltip-icon.svg) 1px 0px no-repeat !important; 
     background-size: 16px auto;position: relative;
     top: 2px; right: -3px;
 }

 .floating-label.multiline .floating-select:not([value=""]):valid ~ label.labelText{
     top: -32px !important 
 }

 .docimenti{
     font-size: 12px; color: rgb(207, 13, 7); font-weight: 400; padding-left:30px;
     img{ padding-right: 5px;margin-top: -3px;}
 }
 .cblock{
    width: 31%!important;
    position: absolute;
    right: 148px;border: 1px solid #666;
 }
 .modal-irff{ max-width: 700px;}

 .calendarTooltipClass{
    position: absolute;
    top: 4px;
    right: 10%;
    z-index: 99;
 }

 .payrollModalBox{
    max-width: 1200px;
}


#impotantInfocarousel {
    &.owl-carousel {
     .owl-dots { text-align: center; top: calc(100% + 25px); position: absolute; left: 50%; right: auto; }
    }
}
@media (max-width: 360px) {
    .planUpdate.chosseplan .modal-content .AddonPlan { width: 100%; }
    
}

.contactForm { background: #228848; position: relative; padding: 45px 0 25px 0;
    // &::before { content: ""; display: block; width: 550px; height: 450px; position: absolute; left: 0; top: 0%;  background: url(/assets/base/images/graph-lb.svg) no-repeat; }
    // &::after { content: ""; display: block; width: 240px; height: 430px; position: absolute; right: 0; top: auto; bottom: -11px; z-index: 0;  background: url(/assets/base/images/graph-rt.png) no-repeat; }
    .container { z-index: 1; position: relative; }
    .floating-label {  margin-bottom: 20px; 
        label {
            &.labelText { color: #2a363b; font-weight: 500; left: 20px; margin-top: 7px; font-size: 16px;
                span.error { color: $red-color; }
            }
        }
        .floating-input, .floating-select { background-color: $colorfff; border-radius: 4px; padding: 15px 20px 10px 20px; height: 50px; background-position: right 15px center; 
           &:focus { outline: none; border-bottom: 2px solid $primary-color;
                ~ {
                    label.labelText { top: -5px; left: 21px; font-size: 11px; }
                    .bar {
                        &:before { width: 50%; }
                        &:after { width: 50%; }
                    }
                    .highlight { -webkit-animation: inputHighlighter 0.3s ease; -moz-animation: inputHighlighter 0.3s ease; animation: inputHighlighter 0.3s ease; }
                }
            }
            &:not(:placeholder-shown) {
                ~ {
                    label.labelText { top: -5px; left: 21px; font-size: 11px; }
                }
            }
            &:-webkit-autofill {
                ~ {
                    label.labelText { top: -5px; left: 21px; font-size: 11px; }
                }
            }
        }
    }
    .btn-white { background: #fff; padding: 12px 35px; }
    .checWrap {
        [type="checkbox"] {
            &:not(:checked) {
                + {
                    label.clt {
                        &:before { border: 0px solid #fff;}
                    }
                }
            }
        }
    }
    .error-msg { position: absolute; }
    .mulityselect .floating-label { background: #fff !important; border-radius: 4px; padding-top: 20px; }
    .floating-label .floating-select[multiple] { height: 80px; background: #fff !important; }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1300px) { 
    .aicpaProTipWrap { max-width: 100%; }
}

.form-group {
    .form-control { padding-left:15px; color: #4D4D4D; font-size: 12px; font-weight: 400; height: 40px; /**box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);**/ border: solid 1px #d3d3d3; border-radius: 5px !important; 
        &.locationIcon { background-image: url(/assets/base/images/location.svg); background-repeat: no-repeat; background-position: right 15px center; }
        &.selectArrow { background: #fff url(/assets/base/images/select-arrow.svg) right 20px center no-repeat; }
        &.textaria { height: 135px; padding: 15px 10px !important;}
    }
    .labelText, .labelTextarea {color: #40464D;}
    .input-group-append {
        .calicon::after { background: #fff url(/assets/base/images/calendar-icon.svg) no-repeat; right: 0; top: 3px; z-index: 1;  }
    }
    .pr.d-block {
        .input-group-append {
            .calicon::after { background: #fff url(/assets/base/images/calendar-icon.svg) no-repeat; top: 14px; right: 10px; z-index: 1;  }
        }
    }
    .input-group {
        .input-group-prepend { position: relative; 
            .input-group-text { font-weight: 500; position: absolute; top: 9px; left: 20px; padding: 0; border: none; z-index: 4; }
        }
        .form-control { padding-left: 32px; }
    }
}
.form-group {
    select {
        &.form-control { background: #fff url(/assets/base/images/select-arrow.svg) right 15px center no-repeat; height: 40px !important; /**box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);**/ border: solid 1px #d3d3d3; border-radius: 5px !important; }
    }
}

.termsModal {
    .modal-content { padding: 50px 10px 20px 20px; border-radius: 10px; box-shadow: 0 22px 34px 0 rgba(0, 0, 0, 0.1); 
        .modal-body { padding: 0 15px; max-height: calc(100vh - 130px); overflow-y: auto; }
    }
}

.termsslider {
    &.amountSlider {
        .ng5-slider {
            .ng5-slider-selection-bar {
                .ng5-slider-selection { background: $colora32; }
            }
            .ng5-slider-pointer { background: $colora32;
                &::after, &.ng5-slider-active::after { background: $colora32 !important; }
            }
            .ng5-slider-model-value { font-size: 16px; font-weight: 600; background: #fbfbfb; bottom: auto; opacity: 1; z-index: 9; margin-top: 20px; }
            .ng5-slider-tick-legend { font-size: 14px; font-weight: 600; color: $color000; opacity: .5; }
        }
    }
}
.radio-custom { position: relative; padding: 20px; border-radius: 20px; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); background: $colorfff; 
}

.ownershipWrap, term-loan-business-info {       
    .radioWrap {
        [type="radio"] {
            &:checked {
                + {
                    label.rlt {
                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 20px;
                            height: 20px;
                            background: $colorfff;
                            border: 1px solid $color6f9;
                            border-radius: 100%;
                            -webkit-transition: all 0.2s ease;
                            transition: all 0.2s ease;
                            opacity: 1;
                        }
                        &:after {
                            content: '';
                            width: 8px;
                            height: 8px;
                            background: $color6f9;
                            position: absolute;
                            top: 6px;
                            left: 6px;
                            border-radius: 100%;
                            -webkit-transform: scale(1);
                            transform: scale(1);
                        }
                    }
                }
            }
            &:not(:checked) {
                position: absolute;
                left: -9999px;
                + {
                    label.rlt {
                        position: relative;
                        padding-left: 28px;
                        cursor: pointer;
                        line-height: 20px;
                        display: inline-block;
                        //color: $color20e;
                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 20px;
                            height: 20px;
                            border: 1px solid $colorc0c;
                            border-radius: 100%;
                            background: #fff;
                        }
                        &:after {
                            content: '';
                            width: 12px;
                            height: 12px;
                            background: $color507;
                            position: absolute;
                            top: 4px;
                            left: 4px;
                            border-radius: 100%;
                            -webkit-transition: all 0.2s ease;
                            transition: all 0.2s ease;
                            opacity: 0;
                            -webkit-transform: scale(0);
                            transform: scale(0);
                        }
                    }
                }
            }
        }
    }
    .owner_type .form-check-inline { padding: 15px 101px 15px 18px; min-width: 29%; border-radius: 10px; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05); border: solid 1px #d3d3d3; }
}
.addenduma-wrap-terms { padding: 0 15px; margin-bottom: 30px; background: $coloreae; padding: 20px 30px; border-radius: 10px;
    .btn-link { color: $color6f9 !important; font-weight: 500; }
}
.naics-group {
    .naics-field { position: absolute; top: -12px; }
    .search-result { top: 84px; }
    .form-control { font-size: 13px; }
}
.arrowd { margin-left: 0px; cursor: pointer; width: 26px; font-size: 0; height: 15px; padding: 10px; display: inline-block; background: url(/assets/base/images/arrow-g.svg) center center no-repeat; 
    &.accordionuploadIcon { width: auto; font-size: 14px; background-position: 0px 7px; padding: 0 0 0 20px; display: inline-block; }
}

.arrowdblk{ margin-left: 0px; cursor: pointer; width: 18px; font-size: 0; height: 10px; display: inline-block;background: url(/assets/base/images/arrow-blk.svg) center center no-repeat; }



.wbg {
    &.businessFunForm { border-radius: 50px !important; box-shadow: none; margin: 0 !important;
        .infoTip { top: 41px; }
    }
}
.star { background: #682d8b; width: 60px; height: 60px; position: relative;
    &:before { content: ""; position: absolute; top: 0; left: 0; height: 60px; width: 60px; background: #682d8b;
        -webkit-transform: rotate(30deg); -moz-transform: rotate(30deg); -ms-transform: rotate(30deg); -o-transform: rotate(30deg); }
       &::after { content: ""; position: absolute; top: 0; left: 0; height: 60px; width: 60px; 
        background: #682d8b; -webkit-transform: rotate(-30deg); -moz-transform: rotate(-30deg); -ms-transform: rotate(-30deg); -o-transform: rotate(-30deg); }
       span { position: absolute; top: 18px; left: 2px; font-size: 14px; text-align: center; z-index: 1; font-weight: 600;}
   }
   .piyanoNav {
        &.horizontal { position: relative; display: flex; padding: 0;
            li { position: relative; width: 100%; font-size: 12px; font-weight: $font-regular; padding: 20px 0; text-align: center;
                .circleap {
                    &:after { content: ''; display: block; position: relative; left: 0px; font-size: 1.2em; height: 16px; width: 16px; @include border-radius(50px); 
                        background: $colorfff; border: 1px solid $colorc0c; z-index: 2; margin: 0 auto; }
                }
                &.done { 
                    .circleap { background: transparent url(/assets/base/images/PPP-icons.svg) no-repeat -253px -6px;
                        height: 17px; width: 18px; display: block; position: relative; left: 2px; z-index: 2; margin: 0 auto;
                        &:after { display: none; }
                    }
                }
                &.active { 
                    .circleap { background: transparent url(/assets/base/images/PPP-icons.svg) no-repeat -296px -6px; transform: rotate(-90deg); height: 17px;
                        width: 18px; display: block; position: relative; left: 2px; z-index: 2; margin: 0 auto;
                        &:after { display: none; }
                    }
                }
                &:first-child {
                    &::after { content: ""; width: 50%; position: absolute; left: 0; top: 27px; height: 3px; background: $colorfff; z-index: 1; }
                }
                &:last-child {
                    &::after { content: ""; width: 50%; position: absolute; left: auto; right: 0px; top: 27px; height: 3px; background: $colorfff; z-index: 1; }
                }
            }
            &:after { content: ""; position: absolute; top: 28px; left: 13px; height: 1px; width: calc(100% - 13px); background-color: $color9a9a; opacity: 0.3; }
        }
    }
    .darkgray { background: $footerbg; }
    td {
        .workingCapital {
            + .tooltip { 
                .tooltip-inner { min-width: 300px; }       
            }
        }
    }

    .form-group .form-control.btc2_select {
        color: $rgbcolor !important;
        padding-right: 20px !important;
        font-size: 16px;
        background-position: right 5px top 19px;
        background-size: 12px;
    }
    .prefill-cls{
        padding: 7px 17px; 
        text-decoration: underline !important;
        cursor: pointer !important;
    }

resource-hub { background: $colorfff;
    .headerinner {
        .container { max-width: 1120px; }
        + .container { max-width: 1120px; }
    }
   
}
 
#seelessbtn{
    span.arrowdblk{
        background: url(/assets/base/images/arrow-blk.svg) center center no-repeat;
        transform: rotate(180deg);
    }
}
.tremDocument {
 .pptc {
    &:hover { color: $color762; }
 }
}

.quarter_fields .form-control{
    font-size: 14px !important;
    font-weight: 400 !important;
    -webkit-appearance: auto;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
}
.modal { top: 20px; }
.searchwrap { position: relative; min-width: 350px;
    &:before { background: url(/assets/base/images//search.svg) no-repeat; content: ''; width: 18px; height: 18px; display: block; z-index: 99; position: absolute; top: 7px; left: 6px; background-size: 18px; }
    .has-search { padding-left: 30px !important }
}
funnel-table {
    .quesIcon { width: 11px !important; height: 11px !important;  line-height: 11px;
        img { max-width: 11px; }
    }
}
commision-report{
    .quesIcon { width: 11px !important; height: 11px !important;  line-height: 11px;
        img { max-width: 11px; }
    }
}
#eProtectiframe {
    body { display: flex; }
    .positionNumberDiv { background: #ccc;  }

}

.paymentOption { border: 2px solid #ccc; border-radius: 10px; padding: 10px; cursor: pointer;
    &.active { border-color: $primary-color; }
}
.progress {border-radius: 10px; background-color: transparent; border: 1px solid #ccc; height: 17px; overflow: inherit;position: relative;
    .redOrg {
        background-color: red; /* For browsers that do not support gradients */        
        border-radius: 8px;
        background-image: linear-gradient(to right, red , orange);
    }
    .orgYell {
        background-color: orange; /* For browsers that do not support gradients */        
        border-radius: 8px;
        background-image: linear-gradient(to right, orange , yellow);
    }
    .yellLigGree {
        background-color: yellow; /* For browsers that do not support gradients */        
        border-radius: 8px;
        background-image: linear-gradient(to right, orange , #91de78);
    }
    .ligGreeGreen {
        background-color: #91de78; /* For browsers that do not support gradients */
        background-image: linear-gradient(to right, #91de78 , #08a242);
        border-radius: 8px;
    }
    .red{
        background-color: red; /* For browsers that do not support gradients */        
        border-radius: 8px;
    }
    .orange{
        background-color: orange; /* For browsers that do not support gradients */        
        border-radius: 8px;
    }
    .yellow{
        background-color: yellow; /* For browsers that do not support gradients */        
        border-radius: 8px;
    }
    .lightGreen{
        background-color: #91de78; /* For browsers that do not support gradients */
        border-radius: 8px;
    }
    .value { padding-top: 20px;margin-left: -20px;text-indent: -18px;}
    .leftValue { left: -1px; position: absolute; top:20px;}
    .rightValue { right:0px; position: absolute; top:20px;}
    .successIcon { background: url(/assets/base/images//success_tick_icon-svg.svg) no-repeat 3px bottom;background-size: 16px; width:20px; height: 36px;display: inline-block;} 

}
.qusBox { display: inline-block; width: 30px; height: 20px;padding-left: 5px;vertical-align: text-bottom;    padding-top: 2px;
    .qusIconBtn { width: 15px; height: 15px; cursor: pointer; text-indent: -90000px; background: url(/assets/base/images//question_icon.svg) no-repeat; border: 0; background-color: transparent; opacity: .6; position: relative; top: -6px;}
    @media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
        .qusIconBtn  {
            top:0;
        }
    }
}

.showBox { position: absolute; top: 18px; left: 32%;
    .successIcon { background: url(/assets/base/images//success_tick_icon-svg.svg) no-repeat 3px 3px;background-size: 16px; width:20px; height: 20px;display: inline-block;position: absolute; bottom: 2px;} 
}
.progress-text{ font-size: 12px; color: #333;}

.worldPay{ max-width: 530px; }

.checkoutPlan{ max-width: 470px !important; width: 470px !important; }

.PlanFullWidth{ 
    max-width: 100% !important; 
    width: 100% !important; 
    #eProtect-iframe{
        display: block;
        margin: auto !important;
    }
}


.planUpdate.chosseplan{ max-width: 500px !important; }

.planUpdate.chosseplan .modal-content .close{ z-index: 10; }

.cardDetail{ max-width: 625px !important; }

.cardDetail .checkoutPlan { padding-left: 16px; }


.loanPurDetails{
    border: 1px solid $global-border-color; padding: 20px;
    @include border-radius(10px); background: $colorfff;
    rubicon-group{
    .loanBlockRow{
        position: relative;
        &:first-child{
            .deleteIcon{
                position: absolute;
                    right: 83px;
                    top: 24px;

            }
        }
        .deleteIcon{ position: absolute;
            right: 83px;
            top: 24px;}

        }
    }
    .addMoreBtn{
        position: absolute;
        bottom: 16px;
        right: 15px;width:77px!important;
        .btn{ font-size: 14px;}
    }
    .onError{
        .addMoreBtn{
            bottom: 37px;
        }
    }
}


.groupTitle {
    font-size: 16px;
    font-weight: $font-sami-bold;
    line-height: 24px;
    padding: 0 0 5px 0;
    color: $text-color;
    border-bottom: 2px solid $colorC4C;
    margin: 0px 0px 5px 0px;
    width: 100%;
  }

  .loanRequestTitle { position: relative; padding: 0 0 0 190px;
    &:after{ content: ""; position: absolute; top: 0; left: 0; width: 180px; height: 126px; background:url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat  -16px -13px; }
}
.notificationbox { border: 0.1em solid $color2d6; border-radius: 8px; }
.notificationsuccessbox { background-color: #e1f0dc; border: 0.1em solid #8fc49e; border-radius: 8px; 
    span { color: #339155; } 
}
.directorybox { border: 0.1em solid $color9AB; border-radius: 8px; background: rgba(121,57,171,.2); color: #3E494D; 
    span, a { color: $color9AB !important; }
    a { text-decoration: underline; font-weight: 600; }
}

.successbox { border: 0.1em solid $primary-color; border-radius: 8px; color: #3E494D; }


.betaTag { background: $color0c5; text-transform: uppercase; border-radius: 10px; font-size:11px; height: 17px; line-height: 12px; font-weight: 500; display: inline-block;     vertical-align: top; margin-top: 4px; color:#fff !important;}
.beta-qicon {
    .qusIconBtn { border: 1px solid #3F865D; display: inline-block; border-radius: 10px; background: url(/assets/base/images/qIcon.svg) 4px 3px no-repeat; }
}


.app_type .radioWrap:last-child {
    // &::after { content: "Beta"; transform: none; position: static; background: $color0c5; width: 44px; height: 20px; text-transform: uppercase;
    //     border-radius: 10px; font-size: 11px; line-height: 12px; font-weight: 500; display: inline-block;
    //     text-indent: 7px; line-height: 19px; font-weight: 600; opacity: 1; color: #fff; margin-left: 3px; 
    // }
}
.col-2-cust { padding-right: 0px !important;
    .qusBox { vertical-align: top;}
 .tooltip-inner { min-width: 300px; } 
}

.tooltip-wrap {
    .tooltip-inner { min-width: 300px; } 
}

.creditCheckModal{
    max-width : 950px;
}

.hideOwnershipField {
    .hideOwnershipClass {
      display: none;
    }
  }
  ppp2-document-management { .ListDocument li { padding-left: 20px; &:before { content: none; } }}
  
  .uploadFirm {
    .dropzone  { padding: 10px 10px 10px 10px; text-align: center; background: #fff !important; border: 1px dashed #8A8A8A !important; }
    h4 { font-weight: 400; color: #4D4D4D; font-size: 14px; 
        span { text-decoration: underline; cursor: pointer; color: #25A0C5; }
    }
  }
    .updateProfile  { max-width: 880px; 
        .modal-content { padding: 30px 10px; border-radius: 5px !important; }
    }
  .company-profile { border: 0.5px solid #CFD1D2;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    li { padding: 5px 0; font-size: 10px; font-weight: 500; border-bottom: 1px solid #D2D2D2; }
    .btn { letter-spacing: 0.05em; }
  }
  .blueStar:after {
    color: #25A0C5;
    content: '*';
}

platform-core-collateral-table {
    .tooltip-inner { min-width: 50px !important; }
}

ng-multiselect-dropdown {
    .multiselect-dropdown {
        .dropdown-btn {font-size: 14px;
            font-family: $font-familym;
            font-weight: $font-sami-bold !important;
            color: $colornav;
            border: none !important;
            border-bottom: 1px solid rgba(154, 154, 154, 0.5) !important;
            padding:4px 25px 4px 2px !important;
            display: block !important;
            width: 100% !important;
            height: 30px;
            border-radius: 0 !important;
            .dropdown-multiselect__caret {
                transform:inherit !important;
                &::before { border-color: #000 transparent !important; top: 52% !important; right: -7px !important; border-width: 6px 6px 0 !important;}
            }
            span {
                span.ng-star-inserted {  position: absolute;top: 10px;left: 13px; }
            }
        }
        .dropdown-multiselect--active {

            .dropdown-multiselect__caret {
                &::before { border-color: #000 transparent !important; top:52% !important; right: -7px !important; border-width: 6px 6px 0 !important;}
            }
        }
        .selected-item {width: 157px; position: relative;min-height: 25px;margin-bottom: 0 !important;background-color: #228848 !important;border: 0 !important; color: $colorfff !important;
            a { color: $colorfff !important;
                position: absolute;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                font-weight: 500;
                line-height: 15px;
                text-align: center;
                
              }
            span {width:135px;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;line-height: 15px;font-weight: $font-medium !important;font-size: 12px !important;
                padding-top: 3px;
            &:hover {white-space: unset;
                text-overflow: unset;}
            }
        }
    }
}
.form-group {
    &.popNew {
        ng-multiselect-dropdown {
            .multiselect-dropdown {
                .dropdown-list {
                    li.multiselect-item-checkbox {
                        div { color: #4D4D4D !important;}
                    }
                }
                .selected-item { background-color: transparent !important; color: #4D4D4D !important;font-family: "Poppins", sans-serif !important;
                    span {font-size: 12px !important; font-weight: 400 !important;}
                    &:hover { box-shadow: none !important;}
                    a {color: #4d4d4d !important;}
                }
                .multiselect-item-checkbox {
                    input[disabled]{
                        +{
                           div{
                                &::before{
                                    background: #ccc !important;
                                    border:1px solid #666 !important;
                                }
                           } 
                        }
                    }
                    div {
                        &::before {border-radius: 3px; border-width: 1px !important; width: 16px !important; height: 16px !important;background-color: $colorfff !important; border-color:#4D4D4D !important ;}
                        &::after {margin-top: -5px !important;border-width:0 0 2px 2px !important;left: 3px !important;width: 11px !important;height: 4px !important;border-color: #4D4D4D !important;}
                    }
                }
                .dropdown-btn {
                    border:1px solid #d3d3d3 !important;
                    height: 40px;
                    border-radius: 5px !important;
                    padding: 7px 25px 4px 15px !important; font-weight: 400 !important;
                    .selected-item-container { display: none !important;}
                    span {font-size: 14px !important; color: #4D4D4D;  float: none !important;
                        .dropdown-multiselect__caret {
                            &::before { border: 0 !important; width: 26px;
                                height: 20px;
                                position: absolute !important;
                                right: 10px !important;
                                left: inherit !important;
                                top: 5px !important; background: transparent url(/assets/base/images/arrow-blk.svg) no-repeat right 2px center;}
                        }
                        span {position: relative; left: inherit; top:inherit;padding-right: 126px !important;
                            &::before {position: absolute;
                                left: -65px;
                                top: -1px;
                                content: "Selected \2013";
                                background-color: #fff;
                                width: 73px;
                                height: 21px;
                                color: #4D4D4D;
                                font-size: 14px;
                              }
                        }
                    }
                    
                }
            }
        }
    }
}
.modal-body account-directory .form-group.popNew ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn span span {padding-right:70px!important;}
.multiLabel {
    .labelText {top: -18px !important;left: 15px !important;}
}
.modal-body account-directory .popTwoLine {
    padding-top: 1rem!important;
    margin-top: 0.5rem !important;
}
.multiselect-item-checkbox{
    input[disabled]{
        +{
           div{
                &::before{
                    background: #f1f1f1 !important;
                    border:2px solid #f1f1f1 !important;
                }
           } 
        }
    }
    div{
        &::before{
            background: #228848 !important;
            border:2px solid #228848 !important;
        }
    }
}
.reasonsDiv{
    margin-bottom: 12px;
    background-color: #ffffff;
    padding: 10px 15px;
    min-height: 50px;
    height: auto;
    border-radius: 5px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

span.question {
    padding-right: 20px;
}
.charLimit{
    position: relative;
    .limits{
    position:absolute;
     color: #9D9D9D;
     font-size: 10px;
     font-weight: 400;
     right: 20px;
     bottom: 0px;
     width: auto;
     height: 16px;
    }
    &.limiterror{
        .limits{bottom:27px;}
    }
}

.reasonsDiv{
    margin-bottom: 12px;
    background-color: #ffffff;
    padding: 10px 15px;
    min-height: 50px;
    height: auto;
    border-radius: 5px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

span.question {
    padding-right: 20px;
}

.charLimit{
    position: relative;
    .limits{
    position:absolute;
     color: #9D9D9D;
     font-size: 10px;
     font-weight: 400;
     right: 20px;
     bottom: 0px;
     width: auto;
     height: 16px;
    }
    &.limiterror{
        .limits{bottom:27px;}
    }
}
.noSROnly {
    clip-path: inherit !important;position: static; width: auto; height: auto; padding: 0; overflow: visible !important; clip: auto !important; white-space: normal;
}
.placeHolderColor {
    &::placeholder{
        color: #08a242 !important;
        font-style: normal !important;
    }
    &:-ms-input-placeholder{
        color: #08a242 !important;
        font-style: normal !important;
    }
    &::-ms-input-placeholder{
        color: #08a242 !important;
        font-style: normal !important;
    }
}
    
        
.financingProductLabel{
    background-color: #fff;
    width: 86%;
}
.apptype{
    .tooltip-inner {
        color: #fff !important;
    }
}

.ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell, .ag-theme-alpine .ag-cell { padding-left: 12px;  padding-right: 12px; }

.featureClass .modal-content {
    background-color: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);
    border: 1px solid #f3f3f3;
    width: 1250px !important;
    right: 375px !important;
    margin-top: -20px;
}
.selfServiceHead{
    #frame1,
    #frame2,
    #frame3{
        .owl-dots{
            position: absolute;
            bottom: -40px;
            width: 100%;
            display: none;
        }
    }
}

.email-client a{color: white !important}

#multi_applicant_type{ margin-left: 30px; font-size: 12px; }
#cooperative_rubiconField{ margin-left: 25px; margin-right: 15px; }
#other_rubiconField{ margin-left: 14px; }
#unique_entity_id_in_sam_rubiconField{ margin-left: 2%; }


.tlCls{
    font-size: 10px;
    padding-top: 7px; 
}
.imgCls{
    height: 15px;
    width: 15px !important;
    cursor: pointer;
}
.hoverCls{
    font-size: 10px;    
    word-break: break-all;
    white-space: normal;
}
.appClint {
    // .nav-tabs { border: 0;}
    bottom: -11px;
    position: relative;
    .nav-item { margin-right: 10px; margin-bottom: 0 !important; padding: 0 !important;
        &.active {z-index: 999;
            .nav-link {color: #228848;}
        }
        &::after {display: none;}
    }
    .nav-link {box-shadow:0px -1.5px 2px #999; border-radius: 10px 10px 0 0; background-color: $colorfff;padding: 10px 15px !important; font-size: 14px; color:#333;
    }
}
.no-bg{
    background: none !important;
}
.videoBanner {
  margin-top: 0px;
  margin-bottom: 15px;
  position: relative;
  background: #25a0c5;
}
.businessFunBlock{ color: #2a363b; border-top: 0px solid $primary-color; background: url(/assets/base/images/signupbg.jpg) no-repeat center -50px; background-attachment: fixed;background-size: cover;padding-top: 70px;}
.pntc { color: $color6f9;}
.solutionImage { position: relative;
    .solutionTextBox {
        width: 220px; position: absolute; right: 30px; box-shadow:0 3px 4px $color9a9a; border-radius: 10px; background-color: $colorfff; top: 120px; padding:40px 20px 10px;
    }
    .solutionHeadimg { width: 450px;}
}
.solutionBody { background-color: $colorf1f; padding: 80px 0;
    .clientServBox { background-color: $colorfff; border-radius:10px; padding: 40px; color: $colortext;min-height: 292px;
        span.icon { display: inline-block; width: 75px; height: 65px; background: url(/assets/base/images/clientServIcon.svg) no-repeat 0 0;
            &.admin { background-position: -104px 0;}
            &.dasktop { background-position: -220px 0;}
            &.idea { background-position: -337px 0;}
        }
    }
}
.headclr { color: $color288;}
.solutionHowWork { background-color: $colorf1f; padding: 50px 0;}
.solutionFaq { background-color: $colorf1f; padding: 50px 0;
    .card-header { padding: 0;}
    .card, .card-header  { background-color: transparent; padding: 0; border: 0;}
    .panel {
        &.panel-open {
            .accordion-toggle {
                .btn-link {background: url(/assets/base/images/faq-min.svg) no-repeat 0 23px;}
            }
        }
        .accordion-toggle {
            .btn-link {font-size: 18px; font-weight: 500; padding-left: 40px; background: url(/assets/base/images/faq-plus.svg) no-repeat 0 14px;
                &:hover, &:focus { text-decoration: none; opacity: .8;}
            }
        }
    }
}
.videoContain { background: #25a0c5; color: $colorfff;
    p { font-size: 18px; }
    .playicon { background: #fff url(/assets/base/images/v-play-blue.svg) center center no-repeat; font-size: 0;
        border-radius: 10px; display: block; width: 90px; height: 55px; }

    .videoBox { height: calc(100% + 40px); border: 1px solid #fff; position: relative; margin: -20px 0; font-weight: 600;
        font-size: 18px; @include purple3Gradient(); padding-top: 60px; }
        .base-color { color: $base-color;}
}
.leads_list{ max-height: 150px; overflow: auto; margin-left: 0px; padding-left: 30px;
    li { padding-bottom: 7px; font-size: 14px;}
}
.color616 { color: $color616;}
.failedBox { background: $colorfce; padding:15px; font-weight: 500;
    .colorred {color:$colore96; font-weight: 600;}
    .status {background: $colore96; display: inline-block; padding: 4px 12px; border-radius: 25px; color: $colorfff; font-size: 14px;}
}
.duplicateBox{ background: $colorfcd; padding:15px; font-weight: 500;
    .colorred {color:$colorec8; font-weight: 600;}
    .status {background: $colorec8; display: inline-block; padding: 4px 12px; border-radius: 25px; color: $colorfff; font-size: 14px;}
}
.successBox{ background: $colorc7e; padding:15px; font-weight: 500;
    .colorred {color:$primary-color; font-weight: 600;}
    .status {background: $primary-color; display: inline-block; padding: 4px 12px; border-radius: 25px; color: $colorfff; font-size: 14px;}
}
.welcomepopupClass .headingTitle, .represetativePopUp .headingTitle{font-size:22px;line-height: 30px;color:#616161;text-align: center;margin-bottom: 16px;font-weight: 600;}
.welcomepopupClass .para-small, .represetativePopUp .para-small{font-size:12px;line-height: 18px;color:#4D4D4D;text-align: center;margin-bottom: 16px;}
.welcomepopupClass .para-small, .represetativePopUp .para{font-size:14px;line-height: 20px;color:#4D4D4D;text-align: center;margin-bottom: 16px;}
.represetativePopUp .icon{text-align: center;margin-bottom: 16px;}
.welcomepopupClass .width85, .represetativePopUp .width85{width:80%;margin:auto;}
.welcomepopupClass .tickul{list-style: none;}
.welcomepopupClass .tickul li{ position: relative;  padding-left: 30px; color: #000; font-size: 14px; line-height: 20px;     margin-bottom: 5px;}
.welcomepopupClass .tickul li:before{ content: ""; display: inline-block; position: absolute; top: 2px; left: 0; width: 21px; height: 15px;
margin-right: 16px; background: url(~projects/affiliate-journey/src/assets/acl/images/tickIcon.svg) no-repeat; }
.welPopUp .closewrap  { padding:15px 15px 0px}
.welPopUp .closewrap  .close{color:#000;font-size:36px!important;    line-height: 18px;  height: 20px;
    display: block;}
.welPopUp .closewrap .close span{color:#000!important}
.welPopUp .modal-footer{border:0px; padding-bottom: 24px;}
@media (max-width:767px){
    .welcomepopupClass .para-small br, .represetativePopUp .para br{display: none;} 
    .welcomepopupClass .width85, .represetativePopUp .width85{width:100%;margin:auto;}
}
#toast-container {
    .toast-warning {background-color: $colora57 !important; opacity: 1 !important;box-shadow: 0 0 0 #fff;width: 415px; border-radius: 8px; padding:13px 10px 13px 60px;
        &:hover { box-shadow: 0 0 0 #fff;}
    }
    .icon-warning {
        background-position: 15px 15px; background-image: url(/assets/acl/images/warning-new-icon.svg) !important; background-repeat: no-repeat;width: 50px;
    }
    .toast-content { font-size: 16px;width: 100%; line-height: 24px; font-weight: 500;}
    .toast-message { width: 100% !important;} 
}
.affiliatePage {
    .affiliateOwnersWrap { 
        width: 100%;position: relative;
        @include border-radius(5px);margin: 0 15px 30px;
        border: 1px solid $global-border-color2;
        .groupTitle {
            font-size: 16px;
            font-weight: $font-sami-bold;
            line-height: 24px;
            padding: 0 0 0 0;
            color: $text-color;
            border-bottom: none;
            margin: 13px 0px 0 0px !important;
            width: 50%;
        }
        .aoHelpText{
            font-size: 13px;
            font-weight: $font-regular; margin-bottom: 10px;
        }
        #affiliate_owners_rubiconField{
            position: unset;
        }
        .addMoreBtn{
            position: absolute; top: 12px; right: 15px;    max-width: 190px;
        }
        .deleteWrapAi{position: relative;
            .deleteWraptop{ position: absolute;
                top: 18px !important;
                right: 25px;
                .deleteIcon{
                    background: none;height: 20px;
                    width: 15px;
                     &::before{ position: absolute; background: url(../images/sprite-icons.svg) no-repeat -433px -96px; height: 20px; width:20px; left: -3px; bottom: 9px; content: "";}
                }
            }
            margin: 0; border-width:1px;  padding:10px 15px 0 !important; border-color: $rgb;
            .form-group{ 
                margin-bottom: 10px;
            }
        }
        .affiliateOwnersHeaders{
            background-color: $aggrid-header-row-color; padding: 0 15px;
            .aoHeaderLabel{
                color: $color000;
                font-weight: $font-sami-bold;padding-top: 10px;
                padding-bottom: 5px;
                font-size: 12px;
                font-family: $font-familyr !important;
                text-transform: uppercase;
            }
        }
    }
    .footerFixedBottom {
        margin-bottom: 30px; width: 100%;
    }
    #relationship_text_rubiconField { 
        padding-left: 0;
        .ng-select-container{
            &:hover{ box-shadow: none !important;}
        }
    }
    
    #affiliates_of_rubiconField ng-select.ng-select-multiple .ng-select-container .ng-value-container {
        flex-wrap: wrap!important;
        min-height: 33px;
    }
}
.inputLikeLabel{
    background: transparent !important; 
    padding:0 !important;
    min-height: unset !important; 
    width: 100%; 
    border-radius: 0 !important;
    text-align: left;
    line-height: 36px;resize: none;
    font-size: 14px !important; border: 0 !important;
    &:focus-visible, &:focus{
        box-shadow: none !important;
        border: none;
        outline: none;
    }
    &:disabled {
        background: transparent !important;
    }
}
.inputLikeLabel.form-control[readonly] {background-color: #fff !important;}
.revmoveBorder {
    border: none !important;
    height: 38px !important;
}
// .ng-select {
//     &.ng-select-focused {
//         .ng-select-container { border-color: $colord3d !important; box-shadow: none !important;}
//     }
//     .ng-select-container { min-height: inherit; height: 40px;border-radius: 5px;}
//     .ng-value-container {
//         .ng-value {
//             background-color: #6fb253 !important;color: #fff;
//             .ng-value-icon { border-color: #228848;
//                 &:hover {background-color: #6fb253 !important;}
//             }
//         }
//     }
// }
        .toast-content { font-size: 16px;width: 100%; line-height: 24px; font-weight: 500;}
        .toast-message { width: 100% !important;
        } 

    input#business_search_2::placeholder{font-size: 12px !important;}
    .resourceBtn {background:$colord1f url(/assets/base/images/resource-icon.svg) no-repeat right 10px top 6px;  height: 45px !important;
        padding: 7px 50px 0 15px; opacity: 1; color: $color3e6 !important; font-size: 16px; font-weight: 500; border-radius: 8px !important; border:2px solid $color3e6;} 



.plusCount {
    background: $rgb;
    padding: 1px 10px;
    border-radius: 5px;
    color: $color000;
    margin-left: 5px;
    display: inline-block;
    cursor: pointer;
  }

.ownerWidth {
    rubicon-select { width: 80%; display: block;}
}

.dropdownIcon{
    rubicon-select {
        .ng-select-container {background: url(/assets/base/images//arrow-blk.svg) no-repeat right 15px top 15px; background-size: 0;     background-color: transparent !important;}
    }
}


.two-col-sec {
    .pdf-sec, .field-sec {
        height: calc(100vh - 50px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .pdf-sec {
        overflow-y: hidden;
    }
}
app-term-loan{
    pdf-info{
        .field-sec{
            .fw-bold{
                font-weight: bold;                  
            }
        }
        details-form{
            rubicon-label{
                .ownership_txt{
                    color: #228848;
                }
            }
            rubicon-radio{
                .business_has_outstanding_debt{
                    input[type="radio"] {   
                        accent-color: #228848;
                    }
                }
            }
            rubicon-switch{
                .switchWrap{
                    input[type="checkbox"] {
                        accent-color: #000000;
                    }
                }
            }
            rubicon-slider{
                    .termsslider.amountSlider .ng5-slider{
                        .ng5-slider-selection-bar .ng5-slider-selection,
                        .ng5-slider-pointer,
                        .ng5-slider-pointer::after,
                        .ng5-slider-pointer.ng5-slider-active::after {
                            background: #228848 !important;
                        }
                    }
            }
            .radio-border{
                .business_has_outstanding_debt{
                    .md-radio{
                        border: 1px solid #D3D3D3;
                        padding: 8px 50px 8px 8px;
                        border-radius: 8px;
                        box-shadow: 0px 0px 4px 0px #00000040;
                    }
                }
            }
            .icon-top{
                .pr.d-block .input-group-append .calicon::after{
                    top: 10px;
                }
            }
            .pdf-flex{
                .switchWrap{
                    label.el-switch{
                        display: flex;
                        label.clt{
                            margin-left: 8px;
                        }
                    }
                }
            }
            .radio-flex{
                .business_has_outstanding_debt{
                    div.fs14{
                        margin-bottom: 8px !important;
                    }
                    .option-flex{
                        display: flex;
                        .md-radio{
                            margin-right: 16px;
                            .form-check-input{
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }
            .auth-spacing{
                .switchWrap{
                    label.el-switch{
                        label.clt{
                            margin-left: 8px;
                        }
                    }
                }
            }
            .font-styling{
                .secondary-label{
                    font-weight: 600;
                }
            }
            .naics-group{
                .naics-field.labelText{
                    font-size: 14px !important;
                    color: #111111;
                }
            }
            .custom-green{
                .business_has_outstanding_debt{
                    span{
                        color: #228848;
                        font-weight: 600;
                    }
                }
            }
            .addenduma-wrap-terms{
                padding: 20px 40px;
            }
        }
    }
}
select.form-control {
    &.h-48 { height: 48px !important;}
}
