/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&family=Open+Sans:wght@600&family=Poppins:wght@400;500;600;700&display=swap");
@import url(~@ng-select/ng-select/themes/default.theme.css);
/*images */
/* Ripple Out */
@import url(~ag-grid-community/dist/styles/ag-grid.css);
@import url(~ag-grid-community/dist/styles/ag-theme-alpine.css);
@-webkit-keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

@keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

a {
  color: #6fb253;
}

a:hover {
  text-decoration: none;
  opacity: 0.9;
}

.form-control:disabled {
  background-color: #e9ecef !important;
  color: #484848;
}

.form-control[readonly] {
  background-color: #e9ecef !important;
}

::-webkit-input-placeholder {
  /* Edge */
  color: rgba(0, 0, 0, 0.5) !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.5) !important;
}

::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}

input[readonly]::-webkit-input-placeholder {
  /* Edge */
  color: #989ba6 !important;
}

input[readonly]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #989ba6 !important;
}

input[readonly]::placeholder {
  color: #989ba6 !important;
}

button:focus {
  outline: none;
}

.mainCont {
  position: relative;
}

.uline {
  text-decoration: underline !important;
}

.uline:hover {
  text-decoration: none !important;
}

.fwl {
  font-weight: 300 !important;
}

.fwr {
  font-weight: 400 !important;
}

.fwm {
  font-weight: 500 !important;
}

.fwsb {
  font-weight: 600 !important;
}

.fwb {
  font-weight: 700 !important;
}

.fwbder {
  font-weight: 800 !important;
}

.lh {
  line-height: 25px !important;
}

.fs0 {
  font-size: 0px !important;
}

.fs10 {
  font-size: 10px !important;
}

.fs11 {
  font-size: 11px !important;
}

.fs12 {
  font-size: 12px !important;
}

.fs13 {
  font-size: 13px !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs15 {
  font-size: 15px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs17 {
  font-size: 17px !important;
}

.fs18 {
  font-size: 18px !important;
}

.fs19 {
  font-size: 19px !important;
}

.fs20 {
  font-size: 20px !important;
}

.fs21 {
  font-size: 21px !important;
}

.fs22 {
  font-size: 22px !important;
}

.fs23 {
  font-size: 23px !important;
}

.fs24 {
  font-size: 24px !important;
}

.fs25 {
  font-size: 25px;
}

.fs26 {
  font-size: 26px !important;
}

.fs28 {
  font-size: 28px !important;
}

.fs30 {
  font-size: 30px !important;
}

.fs32 {
  font-size: 32px !important;
}

.fs36 {
  font-size: 36px !important;
}

.fs40 {
  font-size: 40px !important;
}

.fs45 {
  font-size: 45px !important;
}

.fs60 {
  font-size: 60px !important;
}

.fs64 {
  font-size: 64px !important;
}

.fs50 {
  font-size: 50px !important;
  line-height: 60px;
}

.ffp {
  font-family: "Poppins", sans-serif;
}

.ffm {
  font-family: "Montserrat", sans-serif;
}

.ptc {
  color: #6fb253 !important;
}

.pdtc {
  color: #228848 !important;
}

.gtc {
  color: #484848 !important;
}

.graytc {
  color: #333333 !important;
}

.bltc {
  color: #25a0c5 !important;
}

.gbc {
  background: #f1f1f1;
}

.gtColor {
  color: #747474;
}

.grtc {
  color: #228848 !important;
}

.stc {
  color: #3e494d !important;
}

.batc {
  color: #2a363b;
}

.bdtc {
  color: #111111 !important;
}

.btc {
  color: #0083d8 !important;
}

.btc2 {
  color: #005072 !important;
}

.wtc {
  color: #fff !important;
}

.gltc {
  color: #888888;
}

.baltc {
  color: #565656;
}

.cp {
  cursor: pointer;
}

.pptc {
  color: #762b71;
}

.lgbc {
  background-color: #fbfbfb;
}

.lgtc {
  color: #c0c0c0 !important;
}

.colOrg {
  color: #ff982b !important;
}

.wbc {
  background: #fff;
}

.modal-xl {
  max-width: 1000px !important;
}

.modal-md {
  max-width: 600px !important;
}

.modal-md-sm {
  max-width: 480px !important;
}

.modal-backdrop {
  background-color: #fff;
  opacity: 0.8 !important;
}

.modal .modal-backdrop.fade {
  background-color: #fff;
}

.modal .modal-content {
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);
  border: 1px solid #f3f3f3;
}

.modal .modal-content .app_type_two {
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);
  border: 1px solid #f3f3f3;
  width: 110%;
}

.modal .OTPVeriModal {
  max-width: 407px;
}

.modal .OTPVeriModal ng-otp-input input {
  border: none;
  border-bottom: 1px solid #001e5d;
  border-radius: 0 !important;
  font-size: 22px;
  color: #6fb253;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin: 0 10px;
}

.modal .OTPVeriModal ng-otp-input input:focus {
  outline: none;
}

.modal .OTPVeriModal .btn {
  padding: 10px 50px;
}

.modal .signupFAQ {
  max-width: 783px;
}

.modal .signupFAQ .card {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0 !important;
}

.modal .signupFAQ .card .card-header {
  padding: 20px 0 20px;
}

.modal .signupFAQ .card .sbaFAQcontents .card-body {
  padding: 0 0 0 30px;
}

.modal .signupFAQ .card .sbaFAQcontents .card-body .answ {
  position: absolute;
  left: 0;
}

.modal .signupFAQ .card.last-card {
  border-bottom: none;
}

.modal .signupFAQ .modal-body {
  padding: 10px 30px;
}

.modal .signupFAQ .questionText {
  position: relative;
  display: block;
  padding: 0 30px 0 30px;
}

.modal .signupFAQ .questionText:after {
  color: #3a3a3a !important;
  content: "--";
  position: absolute;
  right: 2px;
  top: -9px;
  letter-spacing: -4px;
  font-size: 26px;
  font-weight: 500;
}

.modal .signupFAQ .questionText.collapsed:after {
  content: "+";
  position: absolute;
  top: -7px;
}

.modal .signupFAQ .questionText span.pr-3 {
  position: absolute;
  left: 0;
}

.modal .bookAppoint {
  max-width: 833px;
}

.modal .bookAppoint .dobicon {
  position: relative;
}

.modal .bookAppoint .dobicon input {
  z-index: 1;
  padding: 10px 40px;
}

.modal .bookAppoint .dobicon input ::placeholder {
  color: #005072 !important;
}

.modal .bookAppoint .dobicon::after {
  top: 10px;
  right: 61px;
  z-index: 0;
}

.modal .bookAppoint .dateTime {
  position: relative;
}

.modal .bookAppoint .dateTime select option {
  padding: 10px;
}

.modal .bookAppoint .dateTime .btn {
  padding-left: 25px !important;
  padding-right: 46px !important;
  z-index: 1;
}

.modal .bookAppoint .dateTime .btn .dobicon::after {
  top: 10px;
  right: 27px;
}

.modal .paymentCard {
  max-width: 407px;
}

.modal .paymentCard .border-bottom2 {
  border-bottom: 2px solid #6fb253;
}

.lbluebg {
  background-color: rgba(5, 119, 205, 0.07);
}

.dbluebg {
  background-color: #0083d8 !important;
}

.dgreenbg {
  background-color: #228848 !important;
}

.fileUp {
  overflow: hidden;
  position: relative;
}

.fileUp input {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.fileName {
  padding-left: 30px;
}

.signpage.radius0,
.keyppp.radius0 {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
}

.freeOnlineAss {
  background: url(/assets/base/images/PPP-icons.svg) no-repeat 0 -183px;
  height: 117px;
  width: 117px;
  display: inline-block;
}

.bookanAppoi {
  background: url(/assets/base/images/PPP-icons.svg) no-repeat 0 -309px;
  height: 117px;
  width: 117px;
  display: inline-block;
}

.funded {
  background: url(/assets/base/images/PPP-icons.svg) no-repeat -130px -309px;
  height: 117px;
  width: 117px;
  display: inline-block;
}

.fileIcon {
  background: url(/assets/base/images/PPP-icons.svg) no-repeat -156px -95px;
  height: 21px;
  width: 16px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: bottom;
  margin-left: -27px;
}

.uploadIcon {
  background: url(/assets/base/images/PPP-icons.svg) no-repeat -106px -95px;
  height: 21px;
  width: 22px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: bottom;
}

.appliIcon {
  background: url(/assets/base/images/PPP-icons.svg) no-repeat 0 0;
  height: 35px;
  width: 35px;
}

.mobileIcon {
  background: url(/assets/base/images/PPP-icons.svg) no-repeat 2px -74px;
  height: 65px;
  width: 65px;
  margin: 20px auto;
}

.downArrow {
  background: url(/assets/base/images/PPP-icons.svg) no-repeat -155px -7px;
  height: 12px;
  width: 12px;
  display: inline-block;
  cursor: pointer;
}

.downArrow.open {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.deleteIcon {
  background: url(/assets/base/images/PPP-icons.svg) no-repeat -156px -53px;
  height: 15px;
  width: 12px;
  display: inline-block;
  cursor: pointer;
}

.deleteIcon:hover {
  opacity: 0.9;
}

.deleteIconAffiliate {
  background: url(/assets/base/images/PPP-icons.svg) no-repeat -156px -53px;
  height: 15px;
  width: 12px;
  display: inline-block;
  cursor: pointer;
  margin-top: 3px;
}

.deleteIconAffiliate:hover {
  opacity: 0.9;
}

.eyeIconAffiliate {
  background: url(/assets/base/images//PPP-icons.svg) no-repeat -162px -176px;
  height: 21px;
  width: 22px;
  display: inline-block;
  cursor: pointer;
  margin-top: -2px;
}

.eyeIconAffiliate:hover {
  opacity: 0.9;
}

.btn-link {
  color: #3e494d !important;
  display: inline-block;
  font-size: 12px;
}

.btn-link:hover {
  text-decoration: none;
  opacity: 0.9;
}

.text-upper {
  text-transform: uppercase !important;
}

.radius20 {
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
}

.radius27 {
  border-radius: 27px !important;
  -webkit-border-radius: 27px !important;
  -moz-border-radius: 27px !important;
}

.radius20 {
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
}

.radius6 {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
}

.radius4 {
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
}

.brrbn {
  border-bottom-right-radius: 0px !important;
}

.brlbn {
  border-bottom-left-radius: 0px !important;
}

.radiusrb27 {
  border-bottom-right-radius: 27px !important;
}

body .radius4 {
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
}

.radius0 {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
}

.ortag span {
  background-color: rgba(5, 119, 205, 0.07);
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  padding: 10px;
  font-size: 12px;
  color: #005072;
  font-weight: 500;
  display: inline-block;
  border: 1px solid rgba(5, 119, 205, 0.1);
  -moz-box-shadow: 0px 2px 12px #d9dde9;
  -webkit-box-shadow: 0px 2px 12px #d9dde9;
  box-shadow: 0px 2px 12px #d9dde9;
}

/* Fade */
.btn {
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  font-size: 16px;
  padding: 10px 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.btn:hover {
  opacity: 0.9;
}

.btn.btn-sm {
  padding: 4px 20px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
}

.btn.btn-xs {
  padding: 2px 10px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
}

.btn.isBtnDisabledCls:hover {
  opacity: 0.5 !important;
  cursor: not-allowed;
  color: lightgrey !important;
}

.btn-lg {
  padding: 10px 30px;
}

.btn14 {
  font-size: 14px;
  text-transform: unset !important;
}

.btn-primary {
  background: #6fb253 !important;
  border-color: #6fb253 !important;
  color: #fff !important;
}

.btn-primary:hover {
  background: #6fb253 !important;
  border-color: #6fb253 !important;
}

.btn-primary:focus {
  background: #6fb253 !important;
  border-color: #6fb253 !important;
  box-shadow: none;
}

.btn-primary:active {
  background: #6fb253 !important;
  border-color: #6fb253 !important;
  box-shadow: none !important;
}

.btn-primaryGrey {
  background: #2a363b !important;
  border-color: #2a363b !important;
  color: #fff !important;
}

.btn-primaryGrey:hover {
  background: #2a363b !important;
  border-color: #2a363b !important;
}

.btn-primaryGrey:focus {
  background: #2a363b !important;
  border-color: #2a363b !important;
  box-shadow: none;
}

.btn-primaryGrey:active {
  background: #2a363b !important;
  border-color: #2a363b !important;
  box-shadow: none !important;
}

.btn-primaryDark {
  background: #228848 !important;
  border-color: #228848 !important;
  color: #fff !important;
}

.btn-primaryDark:hover {
  background: #228848 !important;
  border-color: #228848 !important;
}

.btn-primaryDark:focus {
  background: #228848 !important;
  border-color: #228848 !important;
  box-shadow: none;
}

.btn-primaryDark:active {
  background: #228848 !important;
  border-color: #228848 !important;
  box-shadow: none !important;
}

.btn-primaryDark .radius4 {
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
}

.btn-primaryDark:disabled {
  background: #c0c0c0 !important;
  border-color: #c0c0c0 !important;
}

.btn-secondary {
  background-color: transparent !important;
  border-color: #228848 !important;
  color: #228848 !important;
}

.btn-secondary:hover {
  background-color: transparent !important;
  border-color: #6fb253;
}

.btn-secondary:focus {
  background-color: transparent !important;
  opacity: 1 !important;
  border-color: #6fb253;
  box-shadow: none;
}

.btn-secondary:active {
  background-color: transparent !important;
  opacity: 1 !important;
  border-color: #6fb253;
  box-shadow: none !important;
}

.btn-success {
  background-color: #228848 !important;
  border-color: #fff;
  color: #fff !important;
}

.btn-success:hover {
  background-color: #228848 !important;
  border-color: #fff;
}

.btn-success:focus {
  background-color: #228848 !important;
  opacity: 1 !important;
  border-color: #fff;
  box-shadow: none;
}

.btn-success:active {
  background-color: #228848 !important;
  opacity: 1 !important;
  border-color: #fff;
  box-shadow: none !important;
}

.btn-blue-line {
  background: #fff !important;
  border: 0.15rem solid #25a0c5 !important;
  color: #25a0c5 !important;
}

.btn-blue-line:hover {
  background: #25a0c5 !important;
  border-color: #25a0c5 !important;
  color: #fff !important;
}

.btn-blue-line:focus {
  background: #25a0c5 !important;
  border-color: #25a0c5 !important;
  box-shadow: none;
  color: #fff !important;
}

.btn-blue-line:active {
  background: #25a0c5 !important;
  border-color: #25a0c5 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-blue {
  background: #25a0c5 !important;
  border-color: #25a0c5 !important;
  color: #fff !important;
}

.btn-blue:hover {
  background: #25a0c5 !important;
  border-color: #25a0c5 !important;
  color: #fff !important;
}

.btn-blue:focus {
  background: #25a0c5 !important;
  border-color: #25a0c5 !important;
  box-shadow: none;
  color: #fff !important;
}

.btn-blue:active {
  background: #25a0c5 !important;
  border-color: #25a0c5 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-primary:not(:disabled):not(.disabled).active {
  box-shadow: none;
  border: 1px dotted #989ba6;
  background: #6fb253;
}

.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
  border: 1px dotted #989ba6;
  background: #6fb253;
}

.btn-primary:not(:disabled):not(.disabled):active {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
  border: 1px dotted #989ba6;
  background: #0083d8;
}

.btn-secondary:not(:disabled):not(.disabled).active {
  box-shadow: none;
  border: 1px dotted #989ba6;
  background: #fff;
  color: #6fb253;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
  border: 1px dotted #989ba6;
  background: #fff;
  color: #6fb253;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  box-shadow: none;
  border: 1px dotted #989ba6;
  background: #fff;
  color: #6fb253;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
  border: 1px dotted #989ba6;
  background: #fff;
  color: #6fb253;
}

.pr {
  position: relative;
}

.calicon {
  background: transparent;
  padding-right: 40px !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
}

.calicon::after {
  background: url(/assets/base/images/PPP-icons.svg) no-repeat -151px -33px;
  position: absolute;
  content: "";
  top: 13px;
  right: 8px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

select,
select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  padding: 11px 30px 11px 11px;
}

input[type="checkbox"],
input[type="radio"],
.form-check-input {
  margin-left: 0px;
  position: static;
}

.wbg {
  background: #fff;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  padding: 30px;
  -moz-box-shadow: 0px 2px 12px #d9dde9;
  -webkit-box-shadow: 0px 2px 12px #d9dde9;
  box-shadow: 0px 2px 12px #d9dde9;
}

.graybg {
  background: #ebeef6;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  padding: 10px;
}

/*  */
.newTag {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  background: #f8e71c;
  font-size: 10px;
  color: #000;
  padding: 3px 5px;
  display: inline-block;
  margin-left: 5px;
}

.greenTag {
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  background: #6fb253;
  font-size: 12px;
  color: #fff;
  padding: 5px 8px;
  display: inline-block;
  min-width: 28px;
  text-align: center;
}

.yellowTag {
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  background: #fcf0c9;
  font-size: 10px;
  color: #242731;
  padding: 3px 8px;
  display: inline-block;
  margin-left: 5px;
}

.tag-1 {
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  background: #6fb253;
  font-size: 10px;
  color: #fefefe;
  padding: 3px 8px;
  display: inline-block;
  margin-left: 5px;
}

.tag-2 {
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  background: #fcf0c9;
  font-size: 10px;
  color: #242731;
  padding: 3px 8px;
  display: inline-block;
  margin-left: 5px;
}

.primary-label {
  font-size: 14px;
  color: #111111;
  font-weight: 600;
  display: block;
}

.pdfHeight {
  height: 79px;
}

.blueulList {
  padding-left: 15px;
  list-style-type: disc;
}

.blueulList li {
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 5px;
}

.grayulList {
  padding-left: 15px;
  list-style-type: disc;
}

.grayulList li {
  font-size: 12px;
  color: #666;
  padding-bottom: 5px;
}

.alList {
  padding-left: 25px;
  list-style-type: lower-alpha;
}

.alList li {
  font-size: 12px;
  color: #666;
  padding-bottom: 5px;
  padding-left: 7px;
}

.squareIcon {
  width: 17px;
  height: 17px;
  border: 1px solid #005072;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
}

.squareIcon.darki {
  background: url(/assets/base/images/PPP-icons.svg) no-repeat -204px -10px;
}

.quesIcon {
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  background-color: #fff;
  width: 10px;
  height: 10px;
  color: #005072;
  font-size: 10px;
  display: inline-block;
  text-align: center;
  line-height: 12px;
  font-weight: 700;
  cursor: pointer;
}

.tableui {
  border-radius: 10px 10px 8px 8px;
  background: #fff;
}

.tableui tr th {
  background-color: #228848;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding: 22px 25px;
  border: 0 !important;
  border: none;
}

.tableui tr th:first-child {
  border-radius: 8px 0 0 0;
}

.tableui tr th:last-child {
  border-radius: 0 8px 0 0;
}

.tableui tr td {
  padding: 22px 25px;
  font-size: 14px;
  font-weight: 400;
  vertical-align: middle;
  color: #111111;
  border-bottom: 1px solid rgba(151, 151, 151, 0.13);
}

.tableui tr td a {
  color: #111111;
  font-weight: 500;
}

.tableui tr:last-child td {
  border: none;
}

.tableui tr:last-child td:first-child {
  border-radius: 0 0 0 8px;
}

.tableui tr:last-child td:last-child {
  border-radius: 0 0 8px 0;
}

.tableui tfoot tr {
  border-top: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
}

.tableui tfoot tr:nth-child(odd) {
  background: none;
}

.tableui tfoot tr:first-child {
  border: 0;
}

.tableui .textSelect {
  background: url(/assets/base/images/drop-down-arrow.svg) no-repeat 98% 6px;
  display: inline-block;
  cursor: pointer;
  padding-right: 15px !important;
  background-size: 18% auto;
}

.tableui .textSelect:focus {
  outline: none;
}

.tableui.table-sm {
  background: none;
}

.tableui.table-sm tr {
  background: none;
}

.tableui.table-sm tr th {
  padding: 12px 15px;
  font-size: 12px;
}

.tableui.table-sm tr td {
  padding: 12px 15px;
  font-size: 12px;
}

.tableui.table-sm tr:first-child {
  background-color: #e2e2e2;
}

.tableui.table-sm tfoot tr:first-child {
  background: none;
}

.form-control {
  color: #6fb253;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  background: #fff;
  border-color: #c0c0c0;
  height: auto;
}

.inputbotline {
  border: none;
  border-bottom: 1px solid #c0c0c0;
}

.inputbotline:focus {
  outline: none;
}

.form-control:focus {
  box-shadow: none;
  background-color: #fff;
  border-color: #cacddf;
  color: #242731;
}

.input-group-text {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  background: #fff;
  border-right: none;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  border-color: #cacddf;
  margin-right: -2px;
}

.input-group .form-control {
  border-left: none;
  padding-left: 0;
}

.form-text.text-muted {
  text-decoration: none;
  font-size: 11px;
  color: #5b5f6d !important;
}

.boxShadow {
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.09);
  border: none;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
}

.toggle.ios,
.toggle-on.ios,
.toggle-off.ios {
  border-radius: 20px;
}

.toggle.ios .toggle-handle {
  border-radius: 20px;
}

.disable {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.disable input {
  color: #666 !important;
}

.alert-warning {
  color: #242731;
  background-color: #fcf0c9;
  border-color: #f8e71c;
}

input[type="password"]:hover + div.passpolicy {
  display: block;
}

/* OWL carousel */
.owl-theme .owl-controls {
  margin-top: 10px;
  text-align: center;
}

.owl-theme .owl-controls .owl-page {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-theme .owl-controls .owl-page span {
  display: block;
  width: 8px;
  height: 8px;
  margin: 5px 7px;
  filter: Alpha(Opacity=50);
  opacity: 0.5;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  width: 8px;
  height: 8px;
  background: #989ba6;
  margin: 5px 10px;
}

.owl-theme .owl-controls .owl-page.active span {
  filter: Alpha(Opacity=100);
  opacity: 1;
  background-color: #6fb253;
  background: #6fb253;
}

.owl-theme .owl-controls.clickable .owl-page:hover span {
  filter: Alpha(Opacity=100);
  opacity: 1;
  background-color: #6fb253;
}

label.required {
  color: #ef5555;
  font-size: 11px;
  font-style: italic;
}

label.error,
div.error {
  color: #ef5555;
  font-size: 11px;
  font-style: italic;
  font-weight: 500;
}

.loaderWrap, .widgetLoaderWrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99999;
  text-align: center;
  top: 0;
  left: 0;
}

.loaderWrap:after, .widgetLoaderWrapper:after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: rgba(0, 0, 0, 0.5);
}

.loaderWrap .lds-ripple, .widgetLoaderWrapper .lds-ripple {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  z-index: 9;
  margin-top: -40px;
  top: 50%;
  left: 50%;
  margin-left: -40px;
}

.loaderWrap .lds-ripple div, .widgetLoaderWrapper .lds-ripple div {
  position: absolute;
  border: 4px solid #6fb253;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loaderWrap .lds-ripple div:nth-child(2), .widgetLoaderWrapper .lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* 404 css */
.page404 .container {
  max-width: 100%;
}

.page404 .bggradient {
  border-radius: 0;
}

.page404 .notfoundWrap {
  position: relative;
  height: 84vh;
}

.page404 .notfoundWrap .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-40%, -80%);
  -ms-transform: translate(-40%, -80%);
  transform: translate(-40%, -45%);
  max-width: 560px;
  width: 100%;
  padding-left: 160px;
  line-height: 1.1;
}

.page404 .notfoundWrap .notfound .notfound-404 {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 140px;
  height: 140px;
  background-image: url(/assets/base/images/404.svg);
  background-size: cover;
}

.page404 .notfoundWrap .notfound .notfound-404:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(2.4);
  -ms-transform: scale(2.4);
  transform: scale(2.4);
  border-radius: 50%;
  background-color: #f3f5fb;
  box-shadow: 0px 1px 0 #d9dde9;
  -moz-box-shadow: none;
  -webkit-box-shadow: 0px 1px 0 #d9dde9;
  z-index: -1;
}

.page404 .notfoundWrap .notfound h1 {
  font-size: 36px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 10px;
  color: #000;
  text-transform: uppercase;
}

.page404 .notfoundWrap .notfound h2 {
  font-size: 22px;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  color: #000;
}

.page404 .notfoundWrap .notfound p {
  font-size: 14px;
  color: #5b5f6d;
  font-weight: 400;
}

.page404 .notfoundWrap .notfound a {
  display: inline-block;
  font-weight: 700;
  text-decoration: none;
  color: #6fb253;
}

/* slider text hide */
.amountSlider .ng5-slider {
  margin: 20px 0 45px;
}

.amountSlider .ng5-slider .ng5-slider-model-value {
  display: none;
}

.amountSlider .ng5-slider .ng5-slider-tick {
  width: 1px;
  top: 20px;
  background: #6c757d !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  left: 1px;
}

.amountSlider .ng5-slider .ng5-slider-tick-legend {
  font-size: 10px;
  width: 100px;
  max-width: 100px;
  color: #242731;
}

.amountSlider .ng5-slider .ng5-slider.animate .ng5-slider-bubble {
  display: none;
}

.amountSlider .ng5-slider .ng5-slider.animate .ng5-slider-bubble.ng5-slider-limit {
  display: none;
}

.ng5-slider-inner-tooltip {
  display: none;
}

.form-control::-ms-clear {
  display: none;
}

ngb-datepicker select.custom-select {
  background: #fff url(/assets/base/images/down-arrow.svg) no-repeat right 18px;
  background-position: 93% 9px;
  color: #000 !important;
  margin: 0 2px;
}

ngb-datepicker .ngb-dp-weekday {
  color: #000 !important;
}

ngb-datepicker .bg-primary {
  background-color: #6fb253 !important;
}

ngb-datepicker .btn-light {
  color: #000 !important;
}

ngb-datepicker .btn-light:hover {
  background-color: #6fb253 !important;
  color: #fff !important;
}

ngb-datepicker .btn-light:not(:disabled):not(.disabled):active {
  background-color: #6fb253 !important;
  color: #fff !important;
}

ngb-datepicker .btn-light:not(:disabled):not(.disabled).active {
  background-color: #6fb253 !important;
}

ngb-datepicker .btn-link {
  color: #000 !important;
}

.dobicon:after {
  background: transparent url(/assets/base/images/PPP-icons.svg) no-repeat -201px -51px;
  height: 20px;
  width: 20px;
  border: none;
  cursor: pointer;
  content: "";
  position: absolute;
  top: 4px;
  right: 0;
}

.timeIcon:after {
  background: url(/assets/base/images/PPP-icons.svg) no-repeat -199px -93px;
  height: 21px;
  width: 21px;
  border: none;
  cursor: pointer;
  content: "";
  position: absolute;
  top: 26px;
  right: 51px;
}

.loclgreay:after {
  background: url(/assets/base/images/PPP-icons.svg) no-repeat -110px -54px;
  height: 13px;
  width: 12px;
  border: none;
  cursor: pointer;
  content: "";
  position: absolute;
  top: 8px;
  right: 0;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

.loclgreen:after {
  background: url(/assets/base/images/PPP-icons.svg) no-repeat -110px -11px;
  height: 13px;
  width: 12px;
  border: none;
  cursor: pointer;
  content: "";
  position: absolute;
  top: 8px;
  right: 0;
}

.backArrowb {
  background: transparent url(/assets/base/images/PPP-icons.svg) no-repeat -260px -77px;
  height: 25px;
  width: 27px;
  border: none;
  cursor: pointer;
}

.backArrowb:hover {
  background-position: -260px -203px;
}

.nextArrowb {
  background: transparent url(/assets/base/images/PPP-icons.svg) no-repeat -375px -77px;
  height: 25px;
  width: 27px;
  border: none;
  cursor: pointer;
}

.nextArrowb:hover {
  background-position: -375px -140px;
}

/****  floating-Lable style start ****/
.floating-label.twoline {
  margin-top: 30px;
}

.floating-label.twoline .floating-input:focus {
  outline: none;
  border-bottom: 2px solid #6fb253;
}

.floating-label.twoline .floating-input:focus ~ label.labelText {
  top: -38px;
}

.floating-label.twoline .floating-input:focus ~ .bar:before {
  width: 50%;
}

.floating-label.twoline .floating-input:focus ~ .bar:after {
  width: 50%;
}

.floating-label.twoline .floating-input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

.floating-label.twoline .floating-input:not(:placeholder-shown) ~ label.labelText {
  top: -38px;
}

.floating-label.twoline label.labelText {
  top: -15px;
}

payroll-details .form-group {
  margin-bottom: 0;
}

.floating-label.focusActive.customLabel label.labelText
, .floating-label.customLabel label.labelText,
.floating-label.customLabel .floating-textarea,
.floating-label.customLabel label.labelTextarea,
.floating-label.customLabel .floating-select:not([value=""]):valid ~ label.labelText,
.floating-label.customLabel .floating-input:not(:placeholder-shown) ~ label.labelText {
  font-size: 14px !important;
  font-family: 'Poppins';
  font-weight: 400;
  color: #333;
}

.floating-label label.LabelTextForSelect {
  top: -18px !important;
}

.floating-label {
  position: relative;
  margin-bottom: 40px;
}

.floating-label.focusActive label.labelText {
  top: -18px;
  font-size: 12px;
}

.floating-label .floating-input {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #111111;
  border: none;
  border-bottom: 1px solid rgba(154, 154, 154, 0.5);
  font-size: 14px;
  padding: 4px 0;
  display: block;
  width: 100%;
  height: 30px;
  background-color: transparent;
}

.floating-label .floating-input:focus {
  outline: none;
  border-bottom: 2px solid #6fb253;
}

.floating-label .floating-input:focus ~ label.labelText {
  top: -18px;
  font-size: 12px;
}

.floating-label .floating-input:focus ~ .bar:before {
  width: 50%;
}

.floating-label .floating-input:focus ~ .bar:after {
  width: 50%;
}

.floating-label .floating-input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

.floating-label .floating-input:not(:placeholder-shown) ~ label.labelText {
  top: -18px;
  font-size: 12px;
}

.floating-label .floating-input:-webkit-autofill ~ label.labelText {
  top: -18px;
  font-size: 12px;
}

.floating-label .floating-select {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #111111;
  border: none;
  border-bottom: 1px solid rgba(154, 154, 154, 0.5);
  padding: 4px 25px 4px 2px;
  display: block;
  width: 100%;
  height: 30px;
  background: url(/assets/base/images/drop-down-arrow.svg) no-repeat 98% 1px;
  background-size: 4% auto;
}

.floating-label .floating-select:focus {
  outline: none;
  border-bottom: 2px solid #6fb253;
}

.floating-label .floating-select:focus ~ label.labelText {
  top: -18px;
  font-size: 12px;
}

.floating-label .floating-select:focus ~ .bar:before {
  width: 50%;
}

.floating-label .floating-select:focus ~ .bar:after {
  width: 50%;
}

.floating-label .floating-select:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

.floating-label .floating-select:not([value=""]):valid ~ label.labelText {
  top: -18px;
  font-size: 12px;
}

.floating-label .floating-select:-webkit-autofill ~ label.labelText {
  top: -18px;
  font-size: 12px;
}

.floating-label label.labelText {
  color: #666;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 5px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.floating-label label.labelTextarea {
  color: #666;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: -18px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.floating-label .floating-textarea {
  min-height: 30px;
  max-height: 260px;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
}

.floating-label .highlight {
  position: absolute;
  height: 50%;
  width: 100%;
  top: 15%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

.businessphone input.ng-invalid ~ label.labelText {
  top: 3px;
}

/****  floating-Lable style end ****/
.radioWrap [type="radio"]:checked {
  position: absolute;
  left: -9999px;
}

.radioWrap [type="radio"]:checked + label.rlt {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}

.radioWrap [type="radio"]:checked + label.rlt:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 4px solid #228848;
  border-radius: 100%;
  background: #228848;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 1;
}

.radioWrap [type="radio"]:checked + label.rlt:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #fff;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.radioWrap [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.radioWrap [type="radio"]:not(:checked) + label.rlt {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}

.radioWrap [type="radio"]:not(:checked) + label.rlt:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #c0c0c0;
  border-radius: 100%;
  background: #fff;
}

.radioWrap [type="radio"]:not(:checked) + label.rlt:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #005072;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radioWrap [type="radio"].sba_disabled:not(:checked) ~ .rlt {
  color: #ddd !important;
}

.radioWrap [type="radio"].sba_disabled:not(:checked) ~ .rlt:before {
  box-shadow: none;
  border: none;
  background: transparent url(/assets/base/images/graydisableIcon.svg) no-repeat !important;
  cursor: not-allowed;
}

.radioWrap [type="radio"].sba_disabled:checked + .rlt {
  color: #ddd !important;
}

.radioWrap [type="radio"].sba_disabled:checked + .rlt:before {
  background: transparent url(/assets/base/images/graydisableIcon.svg) no-repeat !important;
  box-shadow: none;
  border: none;
  background-color: #FBFBFB;
  opacity: 0.5;
  cursor: not-allowed;
}

.radioWrap [type="radio"].sba_disabled:checked + .rlt:after {
  color: #989ba6;
  width: 0px;
  height: 0px;
}

.radioWrap [type="radio"].sba_disabled + .rlt {
  color: #9FA3A6;
}

/* Useless styles, just for demo design */
.checWrap.sm-check [type="checkbox"]:not(:checked) + label.clt:before {
  width: 1.4em;
  height: 1.4em;
}

.checWrap.sm-check [type="checkbox"]:checked + label.clt:before {
  width: 1.4em;
  height: 1.4em;
}

.checWrap.sm-check [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

.checWrap.sm-check [type="checkbox"]:checked + label.clt:after {
  font-size: 1em;
  top: 0.55em;
}

.checWrap [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.checWrap [type="checkbox"]:not(:checked) + label.clt {
  position: relative;
  padding-left: 2.95em;
  cursor: pointer;
}

.checWrap [type="checkbox"]:not(:checked) + label.clt:before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  width: 1.8em;
  height: 1.8em;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.checWrap [type="checkbox"]:not(:checked) + label.clt:after {
  content: '\2713\0020';
  position: absolute;
  top: .15em;
  left: .22em;
  font-size: 1.3em;
  line-height: 0.8;
  color: #09ad7e;
  transition: all .2s;
  font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
  opacity: 0;
  transform: scale(0);
}

.checWrap [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

.checWrap [type="checkbox"]:checked + label.clt {
  position: relative;
  padding-left: 2.95em;
  cursor: pointer;
}

.checWrap [type="checkbox"]:checked + label.clt:before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  width: 1.8em;
  height: 1.8em;
  border: 2px solid #228848;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.checWrap [type="checkbox"]:checked + label.clt:after {
  content: '\2713\0020';
  position: absolute;
  top: .40em;
  left: .22em;
  font-size: 1.5em;
  line-height: 0.8;
  color: #228848;
  transition: all .2s;
  font-family: "Poppins", sans-serif;
  opacity: 1;
  font-weight: 600;
  transform: scale(1);
}

.checWrap [type="checkbox"]:disabled:not(:checked) + label.clt:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}

.checWrap [type="checkbox"]:disabled:checked + label.clt:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}

.checWrap [type="checkbox"]:disabled:checked + label.clt:after {
  color: #999;
}

.checWrap [type="checkbox"]:disabled + label.clt {
  color: #aaa;
}

.checWrap label:hover:before {
  border: 2px solid #228848 !important;
}

.checWrap.check-sm [type="checkbox"]:not(:checked) + label.clt:before {
  width: 1.5em;
  height: 1.5em;
}

.checWrap.check-sm [type="checkbox"]:not(:checked) + label.clt:after {
  font-size: 1.3em;
}

.checWrap.check-sm [type="checkbox"]:checked + label.clt:before {
  width: 1.5em;
  height: 1.5em;
}

.checWrap.check-sm [type="checkbox"]:checked + label.clt:after {
  font-size: 1.3em;
}

.timeWrap .label-text {
  margin-left: -15px;
}

.timeWrap [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.timeWrap [type="checkbox"]:not(:checked) + label.clt {
  position: relative;
  padding-left: 2.95em;
  cursor: pointer;
}

.timeWrap [type="checkbox"]:not(:checked) + label.clt:before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  width: 1.2em;
  height: 1.2em;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.timeWrap [type="checkbox"]:not(:checked) + label.clt:after {
  content: '\2713\0020';
  position: absolute;
  top: .15em;
  left: .22em;
  font-size: 1.3em;
  line-height: 0.8;
  color: #09ad7e;
  transition: all .2s;
  font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
  opacity: 0;
  transform: scale(0);
}

.timeWrap [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

.timeWrap [type="checkbox"]:checked + label.clt {
  position: relative;
  padding-left: 2.95em;
  cursor: pointer;
}

.timeWrap [type="checkbox"]:checked + label.clt:before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  width: 1.2em;
  height: 1.2em;
  border: 2px solid #228848;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.timeWrap [type="checkbox"]:checked + label.clt:after {
  content: '\2713\0020';
  position: absolute;
  top: .18em;
  left: .06em;
  font-size: 1.4em;
  line-height: 0.8;
  color: #fff;
  transition: all .2s;
  font-family: "Poppins", sans-serif;
  opacity: 1;
  font-weight: 100;
  transform: scale(1);
  background-color: #228848;
}

.timeWrap [type="checkbox"]:disabled:not(:checked) + label.clt:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}

.timeWrap [type="checkbox"]:disabled:checked + label.clt:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}

.timeWrap [type="checkbox"]:disabled:checked + label.clt:after {
  color: #999;
}

.timeWrap [type="checkbox"]:disabled + label.clt {
  color: #aaa;
}

.my-text-wrap [type="checkbox"] {
  position: absolute;
  left: -9999px;
}

input:checked ~ .checkmark {
  background-color: #228848;
}

lib-create-client rubicon-dynamic .form-group {
  margin-bottom: 0px !important;
}

ppp2-edit-user rubicon-dynamic .form-group {
  margin-bottom: 0px !important;
}

lib-create-client rubicon-dynamic .h-line {
  border-bottom: 2px solid #228848;
  height: 70px;
  margin-bottom: 20px !important;
}

lib-create-client rubicon-dynamic .my-amount {
  margin-top: 10px !important;
}

.switchBtn {
  font-size: 1.5em;
  height: 1em;
  margin-bottom: 0.625em;
  position: absolute;
  right: 0;
  top: -19px;
  width: 3em;
}

.switchBtn .lbl-off,
.switchBtn .lbl-on {
  cursor: pointer;
  display: block;
  font-size: 0.6em;
  font-weight: bold;
  line-height: 1em;
  position: absolute;
  top: 0.3125em;
  transition: opacity 0.25s ease-out 0.1s;
  text-transform: uppercase;
}

.switchBtn .lbl-off {
  right: 1em;
  color: #fefefe;
}

.switchBtn .lbl-on {
  color: #fefefe;
  opacity: 0;
  left: 0.4375em;
}

.switchBtn .switch {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 0;
  font-size: 1em;
  left: 0;
  line-height: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 0;
}

.switchBtn .switch:before, .switchBtn .switch:after {
  content: '';
  font-size: 1em;
  position: absolute;
}

.switchBtn .switch:before {
  border-radius: 1.25em;
  background: #c0c0c0;
  height: 1em;
  left: -0.1875em;
  top: -0.0625em;
  transition: background-color 0.25s ease-out 0.1s;
  width: 3em;
}

.switchBtn .switch:after {
  box-shadow: 0 .0625em .375em 0 #666;
  border-radius: 50%;
  background: #fefefe;
  height: 0.875em;
  transform: translate(0, 0);
  transition: transform 0.25s ease-out 0.1s;
  width: 0.875em;
}

.switchBtn .switch:checked:after {
  transform: translate(1.8125em, 0);
}

.switchBtn .switch:checked ~ .lbl-off {
  opacity: 0;
}

.switchBtn .switch:checked ~ .lbl-on {
  opacity: 1;
}

.switchBtn .switch.switch-blue:checked:before {
  background: #72246c;
}

.bs-datepicker {
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
}

.bs-datepicker .bs-datepicker-container {
  padding: 5px;
}

.pageContent {
  min-height: calc(100vh - 136px);
}

.dashboardContent {
  min-height: calc(100vh - 229px);
  padding-top: 20px;
  padding-bottom: 20px;
}

.modal-header {
  background-color: #228848;
  color: #fefefe;
}

.modal-header h4 {
  font-size: 18px;
  background: none;
}

.modal-header .close {
  opacity: 1;
  font-weight: 400;
  font-size: 30px;
  color: #fff;
  line-height: 20px;
}

.modal-header.whiteHead {
  background-color: #fff;
  color: #333;
}

.modal-header.whiteHead .close {
  color: #000;
}

.rightArrow {
  background: url(/assets/base/images/right-arrow.svg) no-repeat;
  width: 15px;
  height: 12px;
  display: inline-block;
  margin-left: 30px;
}

.card.login label.labelText {
  top: -18px;
  font-size: 12px;
}

.card.login .form-group {
  margin-bottom: 0;
  margin-top: 20px;
}

.floating-label .floating-select[multiple] {
  height: 80px;
}

.mainWraper {
  padding-bottom: 80px;
  position: relative;
  min-height: 100%;
}

html,
body {
  height: 100%;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.popover-lg .popover {
  max-width: 500px;
}

.search-result {
  position: absolute;
  top: 35px;
  background-color: #fff;
  width: 93%;
  z-index: 99;
  max-height: 300px;
  border: 1px solid #d6d4d4;
  overflow-y: auto;
  -moz-box-shadow: 0px 2px 12px #d9dde9;
  -webkit-box-shadow: 0px 2px 12px #d9dde9;
  box-shadow: 0px 2px 12px #d9dde9;
}

.search-result li {
  border-bottom: 1px solid #e0e0e0;
  padding: 5px;
}

.search-result li:hover {
  cursor: pointer;
}

.signup {
  padding: 0;
  border: 0;
}

.signup .form-group {
  margin-bottom: 0;
}

.form-group input:-webkit-autofill,
.form-group input:-webkit-autofill:hover,
.form-group input:-webkit-autofill:focus,
.form-group textarea:-webkit-autofill,
.form-group textarea:-webkit-autofill:hover,
.form-group textarea:-webkit-autofill:focus,
.form-group select:-webkit-autofill,
.form-group select:-webkit-autofill:hover,
.form-group select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.dropdown-item.active,
.dropdown-item:active {
  text-decoration: none;
  background-color: #f8f9fa;
}

.leftColtopSpace .col-lg-8.pt-5 {
  padding-top: 0 !important;
}

@media (min-width: 1024px) {
  .fullTableui {
    width: 1230px !important;
    z-index: 1;
    max-width: none !important;
    position: relative;
  }
  .fullTableui table {
    width: 1230px !important;
    z-index: 1;
    max-width: none !important;
  }
  .fullTableui table .downArrow {
    margin-left: 20px;
  }
  .fullTableui table tr.cocp th {
    padding: 10px 3px !important;
    vertical-align: middle;
  }
  .fullTableui table tr.cocp th br {
    display: none;
  }
  .fullTableui table tr.cocp td {
    padding: 10px 3px !important;
    vertical-align: top;
  }
  .fullTableui table tr.cocp td .textSelect {
    background-position: 98% 12px;
    background-size: 11% auto;
  }
  .fullTableui table tr.cocp td.thcol1,
  .fullTableui table tr.cocp th.thcol1 {
    width: 30px !important;
  }
  .fullTableui table tr.cocp td.thcol2,
  .fullTableui table tr.cocp th.thcol2 {
    width: 170px !important;
  }
  .fullTableui table tr.cocp td.thcol3,
  .fullTableui table tr.cocp th.thcol3 {
    width: 80px !important;
  }
  .fullTableui table tr.cocp td.thcol4,
  .fullTableui table tr.cocp th.thcol4 {
    width: 100px !important;
  }
  .fullTableui table tr.cocp td.thcol5,
  .fullTableui table tr.cocp th.thcol5 {
    width: 150px !important;
  }
  .fullTableui table tr.cocp td.thcol6,
  .fullTableui table tr.cocp th.thcol6 {
    width: 120px !important;
  }
  .fullTableui table tr.cocp td.thcol7,
  .fullTableui table tr.cocp th.thcol7 {
    width: 150px !important;
  }
  .fullTableui table tr.cocp td.thcol8,
  .fullTableui table tr.cocp th.thcol8 {
    width: 60px !important;
  }
  .fullTableui table tr.cocp td.thcol9,
  .fullTableui table tr.cocp th.thcol9 {
    width: 60px !important;
  }
  .fullTableui table tr.cocp td.thcol10,
  .fullTableui table tr.cocp th.thcol10 {
    width: 100px !important;
  }
  .fullTableui table tr.cocp td.thcol11,
  .fullTableui table tr.cocp th.thcol11 {
    width: 150px !important;
  }
  .modal .userOnBoarding .modal-content {
    padding: 25px 35px;
    border-radius: 16px;
  }
}

.borderMessage {
  border: 1px solid #666;
}

.daskDashTable .emailidText {
  word-break: break-word;
  word-wrap: break-word;
  width: 100px;
}

/*For IE10+*/
select::-ms-expand {
  display: none;
}

.onoffswitch {
  position: relative;
  width: 52px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 22px;
  padding: 0;
  line-height: 22px;
  font-size: 12px;
  color: #111111;
  font-weight: bold;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "Yes";
  padding-left: 9px;
  background-color: #6fb253;
  color: #fff;
}

.onoffswitch-inner:after {
  content: "No";
  padding-right: 9px;
  background-color: #c0c0c0;
  color: #fff;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 17px;
  height: 17px;
  margin: 2px 2px 0 0px;
  background: #fff;
  position: absolute;
  top: 0px;
  bottom: 0;
  right: 30px;
  border: 2px solid #fff;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .ieLabel label.labelText {
    top: -18px;
    font-size: 12px;
  }
  .tableui th {
    border: 1px solid #005072 !important;
    border-bottom: 1px solid #005072 !important;
  }
  .logoRow .dropdown-menu {
    animation-name: none !important;
  }
  .daskDashTable .btn-apply {
    width: 150px;
    white-space: normal;
  }
  .daskDashTable .btn-apply span {
    float: left;
    width: 80px;
    display: block !important;
  }
  .daskDashTable .btn-apply span.rightArrow {
    width: 20px;
    margin-top: 20px !important;
    margin-left: 5px !important;
  }
  .card.purple .card-header h2 {
    background: none !important;
    filter: 0 !important;
    color: #c6a526 !important;
  }
  .card.purple .card-body .price span {
    background: none !important;
    filter: 0 !important;
    color: #c6a526 !important;
  }
  .card.blue .card-header h2 {
    background: none !important;
    filter: 0 !important;
    color: #7d7d7d !important;
  }
  .card.blue .card-body .price span {
    background: none !important;
    filter: 0 !important;
    color: #7d7d7d !important;
  }
  .purpleBlock .cpa-purpalBox {
    width: 700px;
  }
  .subscribe table thead tr th.brown h2 {
    background: none !important;
    filter: 0 !important;
    color: #c6a526 !important;
  }
  .subscribe table thead tr th.gray h2 {
    background: none !important;
    filter: 0 !important;
    color: #7d7d7d !important;
  }
}

#wgt_addit_pyroll_benef_modal .modal-title {
  color: #fff;
}

#wgt_addit_pyroll_benef_modal .close {
  color: #fff;
  font-size: 40px !important;
}

#wgt_addit_pyroll_benef_modal .col-form-label {
  font-size: 14px;
  color: #111111;
  font-weight: 600;
  display: block;
}

#wgt_addit_pyroll_benef_modal .input-group-sm > .form-control {
  padding: .75rem .5rem;
  border-radius: 0 !important;
}

#wgt_addit_pyroll_benef_modal .modal-footer label {
  font-size: 14px;
  color: #111111;
  font-weight: 600;
  display: block;
}

/*Microsoft Edge Browser 12+ (All)*/
@supports (-ms-ime-align: auto) {
  .ieLabel label.labelText {
    top: -18px;
    font-size: 12px;
  }
  .tableui th {
    border: 1px solid #005072 !important;
    border-bottom: 1px solid #005072 !important;
  }
  .logoRow .dropdown-menu {
    animation-name: none !important;
  }
}

.blueLightGradi {
  background: #3fb8e2;
  background: -webkit-linear-gradient(to left, #3fb8e2 0%, #0083d8 100%);
  background: -moz-linear-gradient(to left, #3fb8e2 0%, #0083d8 100%);
  background: linear-gradient(to left, #3fb8e2 0%, #0083d8 100%);
}

.greenGradi {
  background: #4c8564;
  background: -webkit-linear-gradient(to left, #4c8564 0%, #046736 100%);
  background: -moz-linear-gradient(to left, #4c8564 0%, #046736 100%);
  background: linear-gradient(to left, #4c8564 0%, #046736 100%);
}

.blueDarkGradi {
  background: #005072;
  background: -webkit-linear-gradient(to left, #005072 0%, #488af9 100%);
  background: -moz-linear-gradient(to left, #005072 0%, #488af9 100%);
  background: linear-gradient(to left, #005072 0%, #488af9 100%);
}

.acl .user-step {
  background: #228848 !important;
}

.acl .user-step .nav li span.icon {
  background-color: rgba(255, 255, 255, 0.2);
}

.acl .user-step .nav li::after {
  border-color: #fff !important;
}

.acl .user-step .nav li.active::before {
  left: calc(100% + 17px) !important;
  border-left: 7px solid #228848 !important;
}

.acl .user-step .nav li h4 {
  color: #fff;
}

.acl .user-step .nav li.done span.icon {
  background-color: #249a40;
  box-shadow: 0 0 0 5px rgba(36, 154, 64, 0.5);
}

.acl .user-step .nav li.active span.icon {
  background-color: #0083d8;
}

lib-role-list .table-list table td, lib-role-list .table-list table th {
  min-width: 130px;
}

lib-role-list .table-list table td .btn, lib-role-list .table-list table th .btn {
  min-width: 0px;
  padding: 0 5px !important;
}

.tab-content-inner .col-4.borderRight {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.tab-content-inner .card-body {
  padding: 5px;
}

.tab-content-inner .card-header {
  padding: 5px;
}

@media (max-width: 1200px) {
  .ListDocument label.btn.fileUp {
    padding: 5px 12px !important;
    font-size: 12px !important;
  }
  .ListDocument .uploadIcon {
    margin-right: 0;
  }
  .user-mgmt-section .row.justify-content-md-center .col-10 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .user-mgmt-section .row.justify-content-md-center .col-4.pt-2 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .user-mgmt-section .listDetlSection .dataTblArea {
    overflow: auto;
    max-width: calc(100% - 30px);
    padding: 0;
    margin: 0 15px;
  }
  .user-mgmt-section .listDetlSection .dataTblArea table tr td, .user-mgmt-section .listDetlSection .dataTblArea table tr th {
    padding: 22px 10px;
  }
  .user-mgmt-section .listDetlSection .dataTblArea table tr td:nth-child(5), .user-mgmt-section .listDetlSection .dataTblArea table tr th:nth-child(5) {
    width: 100px;
    min-width: 100px;
  }
  .user-step-wrap .user-step .nav li.active::before {
    border-left: 7px solid #6fb253 !important;
    left: calc(100% + 17px) !important;
  }
  .user-step-wrap .card.p-5 {
    padding: 5px 0 !important;
    box-shadow: none;
  }
  .user-step-wrap lib-dynamic-form-builder lib-field-builder {
    width: 100% !important;
  }
  lib-role-list .table-list {
    overflow: auto;
    margin: 0;
  }
  lib-role-list .table-list table td, lib-role-list .table-list table th {
    min-width: 130px;
  }
  lib-role-list .table-list table td:nth-child(1), lib-role-list .table-list table th:nth-child(1) {
    min-width: 0;
  }
  lib-role-list .table-list table td .btn, lib-role-list .table-list table th .btn {
    min-width: 0px;
  }
  lib-role-list .table-list .col-12 {
    padding: 0px;
  }
  lib-action-title-header .col-6.text-right .btn {
    margin-top: 5px;
    padding: 10px 15px;
  }
  .table.botarrowMobile {
    width: 100% !important;
  }
  .fullTableui {
    overflow: auto;
  }
  .fullTableui .tableui td {
    vertical-align: top;
  }
  .fullTableui .tableui td label.error {
    line-height: normal;
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .fullTableui {
    width: 100%;
  }
  .tableui.table-sm tr td {
    padding: 12px 10px;
  }
  .dashboardContent {
    padding-top: 0px;
  }
  .user-mgmt-section h2 {
    font-size: 21px;
  }
  .user-mgmt-section .btn.btn-primary {
    font-size: 12px !important;
    margin-bottom: 3px;
    padding: 5px 10px !important;
  }
  .user-mgmt-section .row.justify-content-md-center .col-4.pt-2 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .user-mgmt-section .row.justify-content-md-center .col-10 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .sendForEsign .btn {
    font-size: 14px !important;
  }
  .sendForEsign .fs18 {
    font-size: 13px;
  }
  .signup .card-body, .login .card-body {
    padding: 20px !important;
  }
  .signup .card-body .floating-label, .login .card-body .floating-label {
    min-height: 50px;
    margin-bottom: 10px !important;
  }
  .blueulList li {
    font-size: 12px !important;
  }
}

@media (max-width: 480px) {
  .mainmenu .container {
    padding: 0;
  }
  .mainmenu .container .btn {
    padding: 8px 17px !important;
    font-size: 11px !important;
  }
  .ListDocument li.wbg {
    padding: 10px 20px;
  }
  .ListDocument li.wbg:before {
    left: 11px;
  }
  .ListDocument .btn.fileUp {
    padding: 5px 7px !important;
    font-size: 12px !important;
  }
  lib-main-component lib-document-management .row.py-5.my-5 {
    padding: 20px 0 !important;
    margin: 0 !important;
  }
  .user-mgmt-section h2 {
    font-size: 21px;
  }
  .user-mgmt-section .btn.btn-primary {
    font-size: 12px !important;
    margin-bottom: 3px;
    padding: 5px 10px !important;
  }
  .user-mgmt-section .row.justify-content-md-center .col-4.pt-2 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .user-mgmt-section .row.justify-content-md-center .col-6.text-right {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    text-align: left !important;
  }
  .col-4.pt-2 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .user-step-wrap .col-3.pl-0 {
    padding: 0;
  }
  .user-step-wrap .user-step .card-body {
    padding: 0;
  }
  .user-step-wrap .user-step .nav li h4 {
    font-size: 12px !important;
    padding: 0 5px;
  }
  .user-step-wrap lib-dynamic-form-builder .card-body {
    padding: 10px 0;
  }
  .tab-content-inner .col-4.borderRight {
    padding: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    border-right: none !important;
  }
  .acl .container {
    padding: 0;
  }
  .fs-xs-10 {
    font-size: 10px !important;
  }
  .fs-xs-9 {
    font-size: 9px !important;
  }
  .w-50.inputbotline {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .dashboardContent.acl .container {
    max-width: 720px;
  }
  .dashboardContent.acl .container .container-fluid {
    padding: 0;
  }
}

@media (min-width: 1199px) {
  .dashboardContent.acl .container {
    max-width: 1260px;
  }
  .dashboardContent.acl .container .container-fluid {
    padding: 0;
  }
}

.pageContent {
  padding-bottom: 20px;
}

.que_info {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  width: 18px;
  min-width: 18px;
  height: 18px;
  background: url(/assets/base/images/info-icon.svg) 1px 0px no-repeat;
  background-size: 16px auto;
  position: relative;
  top: 0px;
  right: -3px;
}

.que_info-text {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  background: url(/assets/base/images/info-icon.svg) 1px 1px no-repeat;
  background-size: 16px auto;
}

.floating-label {
  position: relative;
}

.floating-label .que_info {
  position: absolute;
  top: 6px;
  right: 0;
}

.labeltext {
  float: left;
  border-radius: 5px !important;
  position: relative;
  background: #fff;
  padding: 8px 0 8px 0;
  width: 80px;
  margin: 0 15px 0 0;
  color: #333;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border: solid 1px #6fb253;
}

.labeltext input + span {
  display: none;
}

.labeltext input:checked + span {
  width: 102%;
  height: 104%;
  position: absolute;
  left: -1px;
  top: -1px;
  border-radius: 4px;
  content: '';
  background: #6fb253;
  display: block;
}

.labeltext input:checked + span + em {
  color: #fff;
}

.labeltext input + label + span {
  display: none;
}

.labeltext input:checked + label + span {
  width: 102%;
  height: 104%;
  position: absolute;
  border-radius: 4px;
  left: -1px;
  top: -1px;
  content: '';
  background: #6fb253;
  display: block;
}

.labeltext input:checked + label + span + em {
  color: #fff;
}

.labeltext em {
  font-style: normal !important;
  position: relative;
  z-index: 999;
  color: #333;
}

.labeltext input {
  display: none;
}

.divline {
  background: #6fb253;
  margin: 4px 0;
  height: 4px;
  width: 100%;
}

.pppEsignPopup {
  background-image: url(/assets/base/images/esign_lock.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 12px 10px !important;
  background-size: 17px !important;
  padding-left: 35px !important;
}

.pppEsignPopup:hover, .pppEsignPopup:active, .pppEsignPopup:focus {
  background-image: url(/assets/base/images/esign_lock.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 12px 10px !important;
  background-size: 17px !important;
}

.borBox {
  border: solid 1px #0083d8;
  color: #0083d8;
  padding: 10px;
  max-width: 500px;
  margin: auto;
}

.borBox ul {
  list-style: disc;
  padding: 0 0 10px 0;
  margin: 0 0 0 0;
  list-style-position: inside;
}

.borBox ul li {
  padding: 0 0 5px 0;
  margin: 0 0 0 0;
  font-size: 13px;
  font-weight: 500;
}

.posrighT {
  float: right;
  position: relative;
  top: -31px;
  margin-bottom: -33px;
}

.btn-defaultLink {
  background: #fff;
  border-color: #6fb253;
  color: #6fb253 !important;
}

.btn-defaultLink:hover {
  background: #6fb253;
  color: #fff !important;
}

.btn-defaultLink:focus {
  background: #6fb253;
  color: #fff !important;
}

.btn-defaultLink:active {
  background: #6fb253;
  color: #fff !important;
}

.cardModal {
  max-width: 970px;
}

.amountSlider .ng5-slider .ng5-slider-pointer {
  width: 20px;
  height: 20px;
  top: -8px;
  background: #6fb253;
  outline: none;
}

.amountSlider .ng5-slider .ng5-slider-pointer::after {
  top: 6px;
  left: 6px;
}

.amountSlider .ng5-slider .ng5-slider-pointer.ng5-slider-active:after {
  background: #fff !important;
}

.amountSlider .ng5-slider .ng5-slider-bar {
  border-radius: 6px;
  height: 6px;
  background: rgba(154, 154, 154, 0.2);
}

.amountSlider .ng5-slider .ng5-slider-selection-bar {
  visibility: visible !important;
}

.amountSlider .ng5-slider .ng5-slider-selection-bar .ng5-slider-selection {
  background: #6fb253;
  border-radius: 6px;
  height: 6px;
}

.amountSlider .ng5-slider .ng5-slider-model-value {
  display: block;
  background: #fff;
  padding: 3px 11px;
  bottom: 20px;
  font-size: 11px;
  border-radius: 4px;
  font-weight: 600;
}

.amountSlider .ng5-slider .ng5-slider-model-value::after {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  content: "";
  border-top: 5px solid #fff;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.amountSlider .ng5-slider .ng5-slider-tick {
  height: 0;
}

.amountSlider .ng5-slider .ng5-slider-tick-legend {
  top: 5px;
}

affiliate-create-user .floating-label {
  margin-bottom: 25px;
}

affiliate-create-user .StartwithLabel span {
  color: #666;
  font-size: 12px;
  top: 2px;
  font-weight: 500;
  position: absolute;
  pointer-events: none;
}

affiliate-create-user .radioWrap label.rlt {
  font-size: 12px;
  font-weight: 600;
  color: #111111 !important;
}

.viewEditWrap .btn.btn-sm {
  padding: 0px 12px !important;
  font-size: 13px !important;
  height: auto;
}

.ngb-dp-header .btn {
  padding: 5px !important;
}

.text-Break {
  word-break: break-word;
}

.minHeight400 {
  min-height: 400px;
}

.oneLine-text {
  min-height: 62px;
}

.twoLine-text {
  min-height: 73px;
}

.lh16 {
  line-height: 16px !important;
}

.lh20 {
  line-height: 20px !important;
}

.modal-dialog.worldPay.thankyou {
  max-width: 420px !important;
}

lib-user-create .text-danger {
  font-size: 12px;
  margin-top: 5px;
}

lib-user-create .fs12.fwm.pb-2 {
  margin-top: 10px;
}

.downloadicon {
  background: url(/assets/base/images/downloadIcon.svg) center center no-repeat;
  background-size: 14px;
  height: 24px;
  width: 24px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  background-color: #fff;
  padding: 4px;
  border-radius: 14px;
  text-align: center;
  margin: auto;
  box-shadow: 0 0 5px 0px #ccc;
}

.close-btn {
  box-shadow: 0 0 5px 0px #ccc;
  background-color: #fff;
  height: 24px;
  width: 24px;
  display: inline-block;
  padding: 1px;
  font-size: 14px !important;
  border-radius: 14px;
  text-align: center;
  vertical-align: top;
  line-height: 18px;
}

.bankLoginModal {
  max-width: 940px;
}

.payrollLogo {
  text-align: center;
  margin: 0 5px;
  display: flex;
}

.payrollLogo div.wbg {
  margin: 10px 5px;
  padding: 5px 0;
  display: inline-block;
  width: 90px;
  text-align: center;
}

.payrollLogo div.wbg img {
  width: 100%;
}

.payrollLogo div.wbg.paycimgh img {
  padding: 7px;
}

.payrollLogo .success {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 23px;
}

.payrollLogo .success:after {
  content: '\2713\0020';
  position: absolute;
  top: 0;
  left: 36%;
  font-size: 2.5em;
  line-height: 0.8;
  color: #78b13f;
  transition: all .2s;
  font-family: "Roboto", sans-serif;
  opacity: 1;
  font-weight: 600;
  transform: scale(1);
}

.payrollLogo .gustoimgh img {
  width: auto !important;
  max-height: 33px;
}

.yodleeWidget .yodleeWrapper {
  display: -ms-flexbox !important;
  display: flex !important;
}

.yodleeWidget .yodleeWrapper > div {
  margin: 23px 10px 0;
}

.yodleeWidget .actionContainer {
  margin: 20px 10px 25px;
}

.yodleeWidget .autoDocumentSection h3.autoActiveSection, .yodleeWidget .manualDocumentSection h3.manualActiveSection {
  background: #6fb253;
}

.yodleeWidget .autoDocumentSection h3, .yodleeWidget .manualDocumentSection h3 {
  font-size: 20px;
}

.yodleeWidget .documentUpload {
  padding: 20px 15px 70px;
}

.yodleeWidget .documentUpload > .border-bottom-1-5 {
  margin-bottom: 20px;
}

.yodleeWidget .documentUpload .list-style-disc {
  list-style: disc;
}

.yodleeWidget .documentUpload .dragBox {
  outline: #228848 dashed 2px;
}

.yodleeWidget .documentUpload .lightBoldText {
  margin-top: 10px !important;
}

.yodleeWidget .documentUpload #auth {
  background: #228848;
  border-color: #228848;
  box-shadow: none !important;
  color: #fff;
  width: 48.4%;
  padding: 10px;
  border: none;
  font-size: 20px;
  text-transform: uppercase;
}

.yodleeWidget .uploadBox {
  margin-bottom: 20px;
}

.yodleeWidget .uploadBox .progress-bar {
  background-color: #228848 !important;
}

.yodleeWidget .uploadBox .fileName {
  font-size: 12px;
  font-weight: 500;
  padding-left: 0;
}

.yodleeWidget .uploadBox .silverChalice div {
  font-size: 12px;
  color: #228848 !important;
}

.yodleeWidget .uploadedFiles {
  max-height: 255px;
  overflow-x: hidden;
  overflow-y: auto;
}

.uploadContainer .container + .container .col-12 > div {
  float: none !important;
}

.uploadContainer .container + .container .col-12 > div #doneMessageID {
  padding: 10px;
  color: #228848 !important;
}

.uploadContainer .container + .container .col-12 > div .btn {
  float: right;
}

.bankLoginModal .actionContainer {
  float: none !important;
}

.bankLoginModal .actionContainer #doneMessageID {
  color: #6fb253;
}

.bankLoginModal .actionContainer #Failure {
  color: #ef5555;
}

.bankLoginModal .actionContainer #yodleeAutoBtnLinkAccount {
  float: right;
}

.bankLoginModal #yodleeManualBtnContinue {
  float: right;
}

.appListView .actionText {
  margin-left: 18px;
}

.appListView .tooltip_info {
  width: 10px;
  line-height: 3px;
  font-size: 6px;
  padding: 3px;
  text-align: center;
  border: 1px solid;
  border-radius: 100%;
  color: #585c5d !important;
}

.appListView .ag-header-row {
  background-color: #E3EDF0;
  color: #3C474B;
}

.appListView .tooltip .tooltip-inner {
  background: #3E494D !important;
  border-radius: 6px !important;
  color: #fff;
  font-size: 12px;
}

.appListView .tooltip .tooltip-inner * {
  background: #3E494D !important;
  color: #fff !important;
  font-size: 12px;
}

.appListView .tooltip.bs-tooltip-left .arrow::before {
  border-left-color: #3E494D !important;
}

.appListView .tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #3E494D !important;
}

.appListView .tooltip.bs-tooltip-bottom .arrow::before {
  border-top-color: #3E494D !important;
}

.appListView .tooltip.bs-tooltip-right .arrow::before {
  border-right-color: #3E494D !important;
}

.appListView .tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #3E494D !important;
}

.appListView .tooltip.bs-tooltip-top .arrow::before {
  border-bottom-color: #3E494D !important;
}

.modal-body .tooltip .tooltip-inner {
  background: #fff !important;
  border-radius: 6px !important;
  color: #fff;
  font-size: 12px;
  max-width: 330px !important;
}

.modal-body .tooltip .tooltip-inner * {
  background: #fff !important;
  color: #3E494D !important;
  font-size: 12px;
}

.modal-body .tooltip.bs-tooltip-right .arrow::before {
  display: none;
}

.clientListView .tooltip .tooltip-inner, .usageTooltip .tooltip .tooltip-inner, .affiliateTooltip .tooltip .tooltip-inner, .featureCloseBtn .tooltip .tooltip-inner {
  background: #3E494D !important;
  border-radius: 6px !important;
  color: #fff;
  font-size: 12px;
}

.clientListView .tooltip .tooltip-inner *, .usageTooltip .tooltip .tooltip-inner *, .affiliateTooltip .tooltip .tooltip-inner *, .featureCloseBtn .tooltip .tooltip-inner * {
  background: #3E494D !important;
  color: #fff !important;
  font-size: 12px;
}

.clientListView .tooltip.bs-tooltip-left .arrow::before, .usageTooltip .tooltip.bs-tooltip-left .arrow::before, .affiliateTooltip .tooltip.bs-tooltip-left .arrow::before, .featureCloseBtn .tooltip.bs-tooltip-left .arrow::before {
  border-left-color: #3E494D !important;
}

.clientListView .tooltip.bs-tooltip-bottom .arrow::before, .usageTooltip .tooltip.bs-tooltip-bottom .arrow::before, .affiliateTooltip .tooltip.bs-tooltip-bottom .arrow::before, .featureCloseBtn .tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #3E494D !important;
}

.clientListView .tooltip.bs-tooltip-bottom .arrow::before, .usageTooltip .tooltip.bs-tooltip-bottom .arrow::before, .affiliateTooltip .tooltip.bs-tooltip-bottom .arrow::before, .featureCloseBtn .tooltip.bs-tooltip-bottom .arrow::before {
  border-top-color: #3E494D !important;
}

.clientListView .tooltip.bs-tooltip-right .arrow::before, .usageTooltip .tooltip.bs-tooltip-right .arrow::before, .affiliateTooltip .tooltip.bs-tooltip-right .arrow::before, .featureCloseBtn .tooltip.bs-tooltip-right .arrow::before {
  border-right-color: #3E494D !important;
}

.clientListView .tooltip.bs-tooltip-top .arrow::before, .usageTooltip .tooltip.bs-tooltip-top .arrow::before, .affiliateTooltip .tooltip.bs-tooltip-top .arrow::before, .featureCloseBtn .tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #3E494D !important;
}

.clientListView .tooltip.bs-tooltip-top .arrow::before, .usageTooltip .tooltip.bs-tooltip-top .arrow::before, .affiliateTooltip .tooltip.bs-tooltip-top .arrow::before, .featureCloseBtn .tooltip.bs-tooltip-top .arrow::before {
  border-bottom-color: #3E494D !important;
}

.tooltip {
  opacity: 1 !important;
}

.tooltip .tooltip-arrow::before {
  border-top-color: #fff;
}

.tooltip .tooltip-inner {
  max-width: 350px;
  min-width: 100px;
  background: #fff;
  opacity: 1 !important;
  box-shadow: 0px 2px 9px 0 rgba(0, 0, 0, 0.25);
  color: #111111;
  padding: 10px !important;
}

.tooltip.bs-tooltip-left .arrow::before {
  border-left-color: #fff !important;
}

.tooltip.bs-tooltip-left .arrow::before {
  border-top-color: transparent !important;
}

.tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #fff !important;
}

.tooltip.bs-tooltip-bottom .arrow::before {
  border-top-color: #fff !important;
}

.tooltip.bs-tooltip-right .arrow::before {
  border-right-color: #fff !important;
}

.tooltip.bs-tooltip-right .arrow::before {
  border-top-color: transparent !important;
}

.tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #fff !important;
}

.tooltip.bs-tooltip-top .arrow::before {
  border-bottom-color: #fff !important;
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltip.show .tooltip-inner {
  opacity: 1 !important;
}

.ppp2-eligb-ques-form .tooltip-inner {
  text-align: left !important;
}

.large-btn:hover {
  opacity: 1 !important;
}

.sidebar .tooltip-inner {
  min-width: 220px !important;
}

.sidebar {
  padding-bottom: 100px;
}

.businessFunForm {
  margin-left: 40px;
}

.businessFunForm label.error, .businessFunForm div.error {
  margin-bottom: 0;
  color: #E31836;
}

.businessFunForm .form-control {
  border: 1px solid #525252;
  padding: 5px 8px !important;
  border-radius: 0 !important;
  color: #2a363b;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}

.businessFunForm .form-group {
  margin-bottom: 0.5rem;
}

.businessFunForm .btn-primary {
  background: #fff !important;
  color: #3e494d !important;
  font-size: 18px !important;
  font-weight: 600;
  padding: 15px 60px;
  margin-top: 10px;
}

.businessFunForm .cpabPasspolicy {
  padding-right: 43px !important;
}

.businessFunForm .infoTip {
  position: absolute;
  top: 6px;
  right: 22px;
  border-radius: 40px;
  width: 23px;
}

.businessFunForm.ertcLoans .heading {
  margin-left: -10px;
}

.businessFunForm.ertcLoans .form-group {
  margin-bottom: 0.5rem;
}

.businessFunForm.ertcLoans .form-group.col-lg-6, .businessFunForm.ertcLoans .form-group.col-12 {
  padding: 0 5px;
}

.businessFunForm.ertcLoans .form-control {
  font-size: 14px;
  padding: 5px 12px 5px 17px !important;
}

.businessFunForm.ertcLoans .input-group-text {
  left: 6px !important;
}

#yodlee_frame {
  border: 1px solid transparent;
}

#yodleeAutoModal .modal-footer .btn {
  padding: 7px 20px;
}

#yodleeAutoModal.modal .modal-content {
  border: none;
}

.bankLoginModal .modal-header {
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.documentUpload {
  padding: 20px 15px 30px !important;
}

.documentUpload .border-bottom-1-5 {
  padding-bottom: 10px;
  padding-top: 0px;
  border-bottom: 1px solid rgba(208, 208, 208, 0.2);
}

.documentUpload .yodleeManualHeader {
  font-size: 16px;
}

.documentUpload .btn {
  padding: 7px 20px;
  font-size: 14px;
}

.documentUpload .row.mt-40 {
  margin-top: 20px !important;
}

.documentUpload .dragBox {
  min-height: 180px !important;
}

.documentUpload .dragBox .fileTypeLabel {
  min-height: 180px !important;
  padding-top: 40px;
}

.documentUpload .uploadBox:first-child .font-weight-bold {
  display: block;
}

.documentUpload .uploadBox .font-weight-bold {
  display: none;
}

.documentUpload .uploadedFiles {
  max-height: 187px;
}

.dashboardContent lib-loader div {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.3);
}

.dashboardContent lib-loader div .loading {
  display: block;
  z-index: 9;
  margin-top: -40px;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  width: 100px !important;
  height: 100px !important;
  background: none;
}

.dashboardContent lib-loader div .loading::after {
  position: absolute;
  margin: 0;
  box-shadow: none !important;
  width: 100%;
  height: 100%;
  border: 4px solid #6fb253;
  opacity: 1;
  border-radius: 50% !important;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.dashboardContent lib-loader div .loading::before {
  background: none;
  margin: 0;
  content: "";
  box-shadow: none !important;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 4px solid #6fb253;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.infoTip:focus {
  outline: 1px dashed rgba(0, 0, 0, 0.3);
}

button.btn:focus {
  outline: 1px dashed rgba(0, 0, 0, 0.3);
}

.using-mouse .form-control:focus {
  outline: 0 !important;
}

.using-mouse button.btn:focus {
  outline: 0 !important;
}

.using-mouse:focus {
  outline: 0 !important;
}

.using-mouse a:focus {
  outline: 0 !important;
}

.aicpaLogoOne {
  position: relative;
  top: -5px;
}

.aicpaLogoOne:hover {
  opacity: 1 !important;
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltipStyle .tooltip .tooltip-inner {
  padding: 10px 3px 10px 10px;
  max-width: 200px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 10px;
  text-align: left;
}

.tooltipStyle .tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-width: 0 .7rem .4rem;
}

.tooltipStyle .tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-width: .4rem .7rem 0;
}

/* Manage User */
.user-mgmt-section .col-7.mt-3.text-right, .user-mgmt-section .col-5.text-right {
  margin-bottom: 8px;
}

.videoModel {
  max-width: 729px !important;
}

.videoModel .modal-content {
  border: 0px !important;
}

.videoModel iframe {
  vertical-align: top;
  border: 0px !important;
}

.videoModel .close {
  font-size: 30px;
  font-weight: 400;
  position: absolute;
  top: 0px;
  right: 0px;
  background: #fff;
  width: 30px;
  height: 30px;
  display: block;
}

.floating-label .floating-input:focus {
  outline: none;
  border-bottom: 1px solid #6fb253 !important;
}

.large-btn {
  white-space: normal;
  line-height: normal;
  display: block;
  border-radius: 22px !important;
  text-align: left;
  max-width: 260px;
}

.large-btn img {
  vertical-align: top;
}

.alert.special.alert-warning {
  padding: 7px 13px;
  border-radius: 4px;
  border: solid 0.12rem #fa6400;
  background-color: #faebe1;
}

.alert.special.alert-warning p {
  color: #fa6400;
}

h4 {
  background-image: none !important;
}

@media (max-width: 820px) {
  .mainWraper {
    padding-bottom: 0px;
  }
}

@media (max-width: 480px) {
  .wbg .btn.btn-sm {
    padding: 4px 12px !important;
    font-size: 11px !important;
  }
}

government-declaration form {
  padding: 0 15px;
}

.worldPay {
  font-family: "Poppins", sans-serif;
}

.worldPay .close {
  background: url(/assets/base/images/close-green.svg) no-repeat;
  z-index: 99;
  font-size: 24px;
  width: 20px;
  height: 20px;
  font-weight: 400;
  position: absolute;
  right: 15px;
  top: 25px;
}

.worldPay .close span {
  font-size: 0px !important;
}

.worldPay .close.upgradeClose {
  top: 0;
}

.worldPay .modal-content {
  border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  padding: 30px 0px;
  -moz-box-shadow: 0 22px 34px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 22px 34px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 22px 34px 0 rgba(0, 0, 0, 0.15);
}

.worldPay .modal-content checkout h2 {
  line-height: 30px;
}

.worldPay .modal-content h2 {
  font-size: 30px;
  line-height: 45px;
}

.worldPay .modal-content .plantext {
  text-transform: uppercase;
}

.worldPay .modal-content .plantext.basic {
  color: #6fb253;
}

.worldPay .modal-content .plantext.enterprise {
  color: #7d7d7d;
}

.worldPay .modal-content .plantext.premium {
  color: #f2d35f;
}

.worldPay .modal-content p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 10px;
  padding: 0 12px;
}

.worldPay .modal-content p span.price {
  color: #228848;
}

.worldPay.thankyou .modal-content {
  max-width: 435px;
}

.worldPay.thankyou .modal-content h1 {
  color: #06620e;
}

.worldPay.thankyou .modal-content p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 15px;
  padding: 0 6px;
}

.worldPay.thankyou .modal-content p a {
  color: #228848;
}

.worldPay.thankyou .modal-content .btn {
  padding: 11px 32px;
}

.termsModel {
  max-width: 1000px;
}

.termsModel .close {
  background: url(/assets/base/images/closeIcon.svg) no-repeat;
  z-index: 99;
  font-size: 24px;
  width: 20px;
  height: 20px;
  font-weight: 400;
  position: absolute;
  right: 15px;
  top: 25px;
}

.termsModel .close span {
  font-size: 0px !important;
}

.termsModel .modal-content .modal-body {
  padding: 10px 0 0 0 !important;
  text-align: left !important;
}

.termsModel .modal-content .modal-body p {
  font-size: 13px;
}

.termsModel .modal-content .modal-body .list {
  list-style: lower-roman;
  padding-left: 20px;
}

.termsModel .modal-content .modal-body .list li {
  font-size: 13px;
  margin-bottom: 10px;
}

.termsModel .modal-content .modal-body .model-inner-body {
  padding: 15px 0;
}

.termsModel .modal-content .modal-body .model-inner-body .modelContent {
  padding: 10px 25px;
  height: calc(100vh - 150px);
  overflow-y: scroll;
}

.termsModel .modal-content .modal-body .model-footer {
  padding: 10px 20px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}

@media (min-width: 991px) {
  #eProtectiframe #eProtect-iframe {
    height: 120px !important;
    margin-left: -6px;
    width: 530px !important;
  }
}

@media (max-width: 990px) {
  #eProtectiframe #eProtect-iframe {
    height: 380px !important;
    margin-left: -6px;
  }
}

.linkexpiry {
  padding: 50px;
  text-align: center;
  font-size: 16px;
  color: orange;
}

.disabled {
  cursor: not-allowed;
}

.ListDocument .card-body > .pt-sm-3 {
  padding-top: 0 !important;
  padding-bottom: 20px;
}

.dashboardContent.acl > .container > .row {
  margin: 0;
}

.dashboardContent.acl > .container > .row.justify-content-md-center .col-10 > p > span.fw500 {
  padding-top: 10px;
  display: inline-block;
}

.dashboardContent.acl > .container > .row.justify-content-md-center .col-10 > p > span:first-child {
  padding-top: 0;
}

.loaninfo .md-radio [type=radio] {
  position: absolute;
  left: -5px;
}

modal-container.modal .noheader .modal-header {
  background: none;
  border: none;
  padding: 0;
}

modal-container.modal .noheader .modal-header .close {
  position: absolute;
  right: 13px;
  background: #6fb253;
  z-index: 99;
  top: 14px !important;
  width: 40px;
  height: 40px;
  padding: 0;
}

modal-container.modal .noheader .modal-body {
  background: none;
  padding: 0px;
}

modal-container.modal .noheader .modal-body iframe {
  border: none !important;
  border-radius: 12px;
}

.owl-nav .owl-prev {
  content: "&#8249;";
  width: 40px;
  height: 40px;
  line-height: 20px;
  color: #6fb253;
  z-index: 999;
  background: #228848 !important;
  position: absolute;
  left: -10px;
  top: 50%;
  margin-top: -22px;
  border-radius: 40px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3) !important;
}

.owl-nav .owl-prev span {
  font-size: 0;
  background: url(/assets/base/images/backarrow.svg) no-repeat;
  display: block;
  margin: 14px auto;
  width: 10px;
  height: 15px;
}

.owl-nav .owl-next {
  content: "&#8250;";
  width: 40px;
  height: 40px;
  line-height: 20px;
  color: #6fb253;
  z-index: 999;
  background: #228848 !important;
  position: absolute;
  right: -10px;
  top: 50%;
  margin-top: -22px;
  border-radius: 40px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3) !important;
}

.owl-nav .owl-next span {
  font-size: 0;
  background: url(/assets/base/images/backarrow.svg) no-repeat;
  display: block;
  margin: 11px auto;
  width: 10px;
  height: 15px;
  transform: rotate(180deg);
}

.owl-carousel .owl-dots {
  text-align: center;
}

.owl-carousel .owl-dots button.owl-dot {
  border-radius: 12px;
  margin: 0 2px;
  width: 12px;
  height: 12px;
  display: inline-block;
  background: #228848;
  opacity: 0.34;
}

.owl-carousel .owl-dots button.owl-dot.active {
  opacity: 1;
}

.owl-carousel#testmonial .owl-dots {
  margin-top: -40px;
}

.owl-carousel#testmonial .owl-dots button.owl-dot {
  margin: 0 5px;
}

.opportunity .owl-carousel .owl-nav .owl-next, .testmonial .owl-carousel .owl-nav .owl-next {
  width: auto;
  height: auto;
  font-size: 0px;
  font-weight: 300;
  color: #005072;
  box-shadow: none !important;
  background: none !important;
  display: block;
  margin-right: -47px;
}

.opportunity .owl-carousel .owl-nav .owl-next::after, .testmonial .owl-carousel .owl-nav .owl-next::after {
  content: "";
  display: inline-block;
  width: 41px;
  height: 52px;
  background: transparent url(/assets/base/images/backarrowGray.svg) right top -20px no-repeat !important;
  vertical-align: middle;
}

.opportunity .owl-carousel .owl-nav .owl-next.disabled, .testmonial .owl-carousel .owl-nav .owl-next.disabled {
  font-size: 0px;
}

.opportunity .owl-carousel .owl-nav .owl-next.disabled::after, .testmonial .owl-carousel .owl-nav .owl-next.disabled::after {
  background: transparent url(/assets/base/images/backarrowGray.svg) right top -90px no-repeat !important;
}

.opportunity .owl-carousel .owl-nav .owl-prev, .testmonial .owl-carousel .owl-nav .owl-prev {
  width: auto;
  height: auto;
  font-size: 0px;
  font-weight: 300;
  color: #005072;
  box-shadow: none !important;
  background: none !important;
  display: block;
  margin-left: -47px;
}

.opportunity .owl-carousel .owl-nav .owl-prev::before, .testmonial .owl-carousel .owl-nav .owl-prev::before {
  content: "";
  display: inline-block;
  width: 41px;
  height: 52px;
  background: transparent url(/assets/base/images/backarrowGray.svg) left top -90px no-repeat !important;
  vertical-align: middle;
}

.opportunity .owl-carousel .owl-nav .owl-prev.disabled, .testmonial .owl-carousel .owl-nav .owl-prev.disabled {
  font-size: 0px;
}

.opportunity .owl-carousel .owl-nav .owl-prev.disabled::before, .testmonial .owl-carousel .owl-nav .owl-prev.disabled::before {
  background: transparent url(/assets/base/images/backarrowGray.svg) left top -20px no-repeat !important;
}

.opportunity .owl-carousel .owl-item img, .testmonial .owl-carousel .owl-item img {
  display: inline-block;
  width: 80px;
}

.panel .signupshowHdie {
  background: none;
  box-shadow: none;
  border: none;
}

.panel .signupshowHdie .panel-heading {
  background: none;
  box-shadow: none;
  border: none;
  padding: 0;
}

.panel .signupshowHdie .panel-heading .panel-title {
  border: none;
  background: none;
  color: #fff;
}

.panel .signupshowHdie .panel-heading .panel-title button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

.panel .signupshowHdie .panel-heading .panel-title button::after {
  content: "";
  background: url(/assets/base/images/backarrow.svg) no-repeat;
  width: 13px;
  height: 13px;
  position: absolute;
  right: 0px;
  top: 13px;
  transform: rotate(270deg);
  transition: all 0.3s ease;
}

.panel .signupshowHdie .panel-body {
  padding: 10px;
}

.panel.panel-open .signupshowHdie .panel-title button::after {
  content: "";
  transform: rotate(90deg);
}

.planUpdate {
  max-width: 833px;
}

.planUpdate .modal-content {
  border-radius: 16px;
}

.planUpdate .modal-content h3 {
  border-bottom: solid 1px rgba(151, 151, 151, 0.15);
  margin: 0 40px;
  padding: 25px 0;
}

.planUpdate .modal-content .close {
  position: absolute;
  top: 33px;
  right: 35px;
  background: url(/assets/base/images/closeL.svg) no-repeat;
  width: 20px;
  height: 20px;
}

.planUpdate .modal-content .close span {
  font-size: 0px;
}

.planUpdate .modal-content .AddonPlan {
  padding: 40px 35px;
}

.planUpdate .modal-content .AddonPlan .innerContent {
  border: solid 2px #228848;
  border-radius: 17px;
  padding: 30px 10px 40px 10px;
  text-align: center;
}

.planUpdate .modal-content .AddonPlan .innerContent h2 {
  color: #228848;
  line-height: 38px;
}

.planUpdate .modal-content .AddonPlan .innerContent h2 span {
  display: block;
  font-size: 36px;
}

.planUpdate .modal-content .AddonPlan .innerContent p {
  font-size: 16px;
  font-weight: 500;
}

.planUpdate .modal-content .AddonPlan .innerContent .btn {
  padding: 8px 33px;
  font-weight: 400;
}

.planUpdate .modal-content .AddonPlan .innerContent:hover {
  background: #228848;
  box-shadow: 0 36px 31px 0 rgba(0, 0, 0, 0.28);
  transition: all .5s ease-out;
}

.planUpdate .modal-content .AddonPlan .innerContent:hover h2 {
  color: #fff;
}

.planUpdate .modal-content .AddonPlan .innerContent:hover p {
  color: #fff;
}

.planUpdate .modal-content .AddonPlan .innerContent:hover .btn {
  background: #fff !important;
  color: #228848 !important;
}

.planUpdate .modal-content .AddonPlan .pagenation button {
  background: none;
  border: none;
}

.planUpdate .modal-content .AddonPlan .pagenation .backArrow {
  position: relative;
  display: block;
  color: #228848;
}

.planUpdate .modal-content .AddonPlan .pagenation .backArrow::before {
  content: "";
  display: inline-block;
  position: relative;
  top: 6px;
  background: transparent url(/assets/base/images/PPP-icons.svg) no-repeat -260px -77px;
  height: 25px;
  width: 27px;
  border: none;
  cursor: pointer;
}

.planUpdate .modal-content .AddonPlan .pagenation .nextArrow {
  position: relative;
  display: block;
  color: #228848;
}

.planUpdate .modal-content .AddonPlan .pagenation .nextArrow::after {
  content: "";
  display: inline-block;
  position: relative;
  top: 6px;
  background: transparent url(/assets/base/images/PPP-icons.svg) no-repeat -375px -77px;
  height: 25px;
  width: 27px;
  border: none;
  cursor: pointer;
}

.planUpdate.chosseplan {
  max-width: 433px;
  text-align: center;
  margin: 0 auto;
}

.planUpdate.chosseplan .modal-content h3 {
  border-bottom: none;
  margin: 0 0px;
}

.planUpdate.chosseplan .modal-content .close {
  top: 20px;
  right: 20px;
}

.planUpdate.chosseplan .modal-content .AddonPlan {
  padding: 30px 5px;
  max-width: 335px;
  width: 335px;
  margin: 0 auto !important;
}

.planUpdate.chosseplan .modal-content .AddonPlan .innerContent {
  box-shadow: 0 15px 14px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px rgba(34, 136, 72, 0.21);
}

.planUpdate.chosseplan .modal-content .AddonPlan .innerContent:hover {
  background: none;
}

.planUpdate.chosseplan .modal-content .AddonPlan .innerContent:hover h2 {
  color: #228848;
}

.planUpdate.chosseplan .modal-content .AddonPlan .innerContent:hover p {
  color: #333333;
}

.planUpdate.chosseplan .modal-content .modal-footer {
  border-radius: 0px 0px 16px 16px;
  background-color: #2a363b;
  color: #fff;
  justify-content: space-around;
}

.planUpdate.chosseplan .modal-content .modal-footer a {
  color: #fff;
}

.planUpdate.chosseplan .modal-content .modal-footer .nextArrow {
  position: absolute;
  top: 30%;
  right: 0;
  display: block;
  color: #fff !important;
  width: auto;
  border: none;
  vertical-align: middle;
}

.planUpdate.chosseplan .modal-content .modal-footer .nextArrow::after {
  content: "";
  background: transparent url(/assets/base/images/whitearrow.svg) no-repeat;
  height: 16px;
  width: 27px;
  border: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

.planUpdate.commissionReport {
  max-width: 500px;
}

.cardDetail {
  text-align: center;
}

.cardDetail .close {
  top: 20px;
  right: 20px;
}

.cardDetail .modal-content {
  border-radius: 16px;
}

.cardDetail .modal-content h3 {
  margin: 0 40px;
  padding: 25px 0;
}

.cardDetail .modal-content .close {
  position: absolute;
  top: 23px;
  right: 15px;
  background: url(/assets/base/images/closeL.svg) no-repeat;
  width: 20px;
  height: 20px;
  z-index: 9;
}

.cardDetail .modal-content .close span {
  font-size: 0px;
}

.cardDetail .modal-content .modal-body .btn {
  min-width: 150px;
}

.cardDetail .modal-content .modal-body .gray {
  color: #767676;
}

.cardDetail .modal-content .modal-body .radioWrap {
  padding: 12px 20px;
  margin-bottom: 20px;
  text-align: left;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

.cardDetail .modal-content .modal-body .radioWrap label {
  margin: 0;
  font-size: 16px;
  color: #767676;
}

.cardDetail .modal-content .modal-body .radioWrap.active {
  box-shadow: 0 0 4px 0 #228848;
}

.cardDetail .modal-content .modal-footer {
  border-radius: 0px 0px 16px 16px;
  background-color: #2a363b;
  color: #fff;
  justify-content: space-around;
}

.cardDetail .modal-content .modal-footer a {
  color: #fff;
}

.npo .firstname {
  margin-right: 2em;
}

body .editfield {
  position: relative;
}

body .editfield .floating-input {
  padding-right: 30px;
}

body .editfield::after {
  content: "";
  position: absolute;
  top: 10px;
  left: auto;
  right: 10px;
  width: 20px;
  height: 20px;
  background: url(/assets/base/images/edit_draw.svg) no-repeat;
  background-size: 13px;
  opacity: .5;
}

.accountsetting input:disabled {
  opacity: .5 !important;
}

.accountsetting input:disabled ~ .labelText {
  opacity: .5 !important;
}

.change_password.login .infoTip {
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 40px;
  width: 23px;
}

.change_password.login .card-body {
  padding: 30px 30px 20px 30px !important;
}

.change_password.login .modal-body {
  padding: 20px 30px 20px 30px !important;
}

.change_password.login .modal-body .floating-label {
  margin-bottom: 20px;
}

.popup_changePassword {
  max-width: 500px;
}

.w-92 {
  width: 92%;
}

.word-break {
  word-break: break-all;
}

.fte-modal {
  max-width: 600px;
}

.accountModal {
  max-width: 1000px;
}

.accountModal .order-lg-1 > .fs20, .accountModal .order-lg-1 > .fs12 {
  padding-left: 24px;
}

.accountModal .close {
  display: none;
  background: url(/assets/base/images/closeL.svg) no-repeat;
  z-index: 99;
  font-size: 24px;
  width: 20px;
  height: 20px;
  font-weight: 400;
  position: absolute;
  right: 20px;
  top: 20px;
}

.accountModal .close span {
  font-size: 0px !important;
}

.accountModal .accountsetting {
  box-shadow: none !important;
  margin-bottom: 0 !important;
  padding: 25px !important;
}

.accountModal .col-12.col-lg-6.order-lg-1.order-2 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.accountModal .modal-body {
  padding: 0 !important;
}

.accountModal .modal-body .fs12.pb-5 {
  padding-bottom: 0 !important;
}

.modal .userOnBoarding .modal-content {
  padding: 25px 15px;
  border-radius: 16px;
}

.modal .userOnBoarding .modal-content .modal-header {
  background: none;
  border: none;
  padding: 0;
  z-index: 999;
}

.modal .userOnBoarding .modal-content .modal-header .modal-title {
  border: none;
  background: none;
  color: #2a363b;
}

.modal .userOnBoarding .modal-content .modal-header .close {
  position: absolute;
  top: 43px !important;
  right: 37px;
  background: none;
  background: url(/assets/base/images/closeL.svg) no-repeat;
  width: 20px;
  height: 20px;
}

.modal .userOnBoarding .modal-content .modal-header .close span {
  font-size: 0px;
}

.customNextBtn {
  cursor: pointer;
  position: relative;
  top: -15px;
  font-weight: 400 !important;
  z-index: 999;
  line-height: 24px !important;
  padding: 4px 28px !important;
}

.employee_number {
  margin-bottom: -30px;
}

.employee_number rubicon-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.employee_number rubicon-text .col-4 {
  margin-left: 0px;
  float: right;
  max-width: 30%;
}

.employee_number rubicon-text .error-msg {
  padding-left: 15px;
}

.employee_number rubicon-text .primary-label {
  display: inline-block;
  width: 56%;
}

.hideinfotip .infoTip {
  display: none;
}

.previous-loan-info .revnue-red-remove {
  margin-top: -40px !important;
}

.is_no_ein .ppp2SsnParent, .is_no_ein .ppp2TinParent {
  display: none;
}

.cnfrmSkpPyrlSchdl4 {
  max-width: 600px;
}

.prev_loan_group_parent .prev_loan_group:first-of-type .revnue-red-remove {
  display: none !important;
}

.number_of_monField {
  left: 23px;
}

.pac-container {
  z-index: 1050 !important;
}

.primary-label .que_info-text {
  margin: 1px 0 0 2px;
}

.success-msg .success {
  color: #228848;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
}

.success-msg a.error {
  color: #ef5555;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  text-decoration: underline;
}

.Purposetheloan .col-lg-auto {
  width: 50%;
}

.full_time_period .floating-select {
  background-size: 7% auto;
}

.tooltip-inner {
  text-align: left !important;
}

.addenduma-wrap .helpTextETran {
  display: none;
}

.modalHp {
  max-width: 700px;
}

.dashboardtooltip {
  position: relative;
  display: inline-block;
}

.dashboardtooltip .tooltip {
  pointer-events: visible !important;
}

.dashboardtooltip .tooltip-inner {
  width: 250px;
  z-index: 999;
  padding-bottom: 1px !important;
}

.red-text {
  color: #ef5555 !important;
}

.exclaimation {
  border: 2px solid red;
  border-radius: 20px;
  display: inline-block;
  width: 20px;
  text-align: center;
  height: 20px;
  margin-right: 10px;
}

.doclupload .eyeIcon {
  position: relative;
}

.doclupload .eyeIcon .eye {
  position: absolute;
  right: -30px;
  top: -3px;
  cursor: pointer;
  background: url(/assets/base/images/PPP-icons.svg) no-repeat -162px -176px;
  width: 21px;
  height: 20px;
  display: inline-block;
}

.doclupload .eyeIcon .eye.eyeclose {
  background-position: -162px -205px;
}

.speciTooltip {
  position: relative;
  right: -3px;
  top: -2px;
}

.labeltext {
  float: left;
  border-radius: 5px !important;
  position: relative;
  background: #fff;
  padding: 8px 0 8px 0;
  width: 80px;
  margin: 0 15px 0 0;
  color: #333;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border: solid 1px #6fb253;
}

.labeltext input + span {
  display: none;
}

.labeltext input:checked + span {
  width: 101%;
  height: 104%;
  position: absolute;
  left: 0;
  top: -1px;
  border-radius: 4px;
  content: '';
  background: #6fb253;
  display: block;
}

.labeltext input:checked + span + em {
  color: #fff;
}

.labeltext input + label + span {
  display: none;
}

.labeltext input:checked + label + span {
  width: 101%;
  height: 104%;
  position: absolute;
  border-radius: 4px;
  left: 0;
  top: -1px;
  content: '';
  background: #6fb253;
  display: block;
}

.labeltext input:checked + label + span + em {
  color: #fff;
}

.labeltext em {
  font-style: normal !important;
  position: relative;
  z-index: 999;
  color: #333;
}

.labeltext input {
  display: none;
}

.search-result {
  position: absolute;
  top: 35px;
  background-color: #fff;
  width: 93%;
  z-index: 99;
  max-height: 300px;
  overflow-y: auto;
}

.search-result li {
  border-bottom: 1px solid #e0e0e0;
  padding: 5px;
}

.search-result li:hover {
  cursor: pointer;
}

.que_info {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  background: url(/assets/base/images/info-icon.svg) 1px 0px no-repeat;
  background-size: 17px auto;
}

.que_info-text {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  background: url(/assets/base/images/info-icon.svg) 1px 1px no-repeat;
  background-size: 16px auto;
}

.floating-label .que_info {
  position: absolute;
  top: 6px;
  right: 0;
}

.info-icon-tooltip {
  cursor: pointer;
  display: inline-block;
  width: 18px;
  min-width: 18px;
  height: 18px;
  background: url(/assets/base/images/tooltip-icon.svg) 1px 0px no-repeat !important;
  background-size: 16px auto;
  position: relative;
  top: 2px;
  right: -3px;
}

.floating-label.multiline .floating-select:not([value=""]):valid ~ label.labelText {
  top: -32px !important;
}

.docimenti {
  font-size: 12px;
  color: #cf0d07;
  font-weight: 400;
  padding-left: 30px;
}

.docimenti img {
  padding-right: 5px;
  margin-top: -3px;
}

.cblock {
  width: 31% !important;
  position: absolute;
  right: 148px;
  border: 1px solid #666;
}

.modal-irff {
  max-width: 700px;
}

.calendarTooltipClass {
  position: absolute;
  top: 4px;
  right: 10%;
  z-index: 99;
}

.payrollModalBox {
  max-width: 1200px;
}

#impotantInfocarousel.owl-carousel .owl-dots {
  text-align: center;
  top: calc(100% + 25px);
  position: absolute;
  left: 50%;
  right: auto;
}

@media (max-width: 360px) {
  .planUpdate.chosseplan .modal-content .AddonPlan {
    width: 100%;
  }
}

.contactForm {
  background: #228848;
  position: relative;
  padding: 45px 0 25px 0;
}

.contactForm .container {
  z-index: 1;
  position: relative;
}

.contactForm .floating-label {
  margin-bottom: 20px;
}

.contactForm .floating-label label.labelText {
  color: #2a363b;
  font-weight: 500;
  left: 20px;
  margin-top: 7px;
  font-size: 16px;
}

.contactForm .floating-label label.labelText span.error {
  color: #ef5555;
}

.contactForm .floating-label .floating-input, .contactForm .floating-label .floating-select {
  background-color: #fff;
  border-radius: 4px;
  padding: 15px 20px 10px 20px;
  height: 50px;
  background-position: right 15px center;
}

.contactForm .floating-label .floating-input:focus, .contactForm .floating-label .floating-select:focus {
  outline: none;
  border-bottom: 2px solid #6fb253;
}

.contactForm .floating-label .floating-input:focus ~ label.labelText, .contactForm .floating-label .floating-select:focus ~ label.labelText {
  top: -5px;
  left: 21px;
  font-size: 11px;
}

.contactForm .floating-label .floating-input:focus ~ .bar:before, .contactForm .floating-label .floating-select:focus ~ .bar:before {
  width: 50%;
}

.contactForm .floating-label .floating-input:focus ~ .bar:after, .contactForm .floating-label .floating-select:focus ~ .bar:after {
  width: 50%;
}

.contactForm .floating-label .floating-input:focus ~ .highlight, .contactForm .floating-label .floating-select:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

.contactForm .floating-label .floating-input:not(:placeholder-shown) ~ label.labelText, .contactForm .floating-label .floating-select:not(:placeholder-shown) ~ label.labelText {
  top: -5px;
  left: 21px;
  font-size: 11px;
}

.contactForm .floating-label .floating-input:-webkit-autofill ~ label.labelText, .contactForm .floating-label .floating-select:-webkit-autofill ~ label.labelText {
  top: -5px;
  left: 21px;
  font-size: 11px;
}

.contactForm .btn-white {
  background: #fff;
  padding: 12px 35px;
}

.contactForm .checWrap [type="checkbox"]:not(:checked) + label.clt:before {
  border: 0px solid #fff;
}

.contactForm .error-msg {
  position: absolute;
}

.contactForm .mulityselect .floating-label {
  background: #fff !important;
  border-radius: 4px;
  padding-top: 20px;
}

.contactForm .floating-label .floating-select[multiple] {
  height: 80px;
  background: #fff !important;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1300px) {
  .aicpaProTipWrap {
    max-width: 100%;
  }
}

.form-group .form-control {
  padding-left: 15px;
  color: #4D4D4D;
  font-size: 12px;
  font-weight: 400;
  height: 40px;
  /**box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);**/
  border: solid 1px #d3d3d3;
  border-radius: 5px !important;
}

.form-group .form-control.locationIcon {
  background-image: url(/assets/base/images/location.svg);
  background-repeat: no-repeat;
  background-position: right 15px center;
}

.form-group .form-control.selectArrow {
  background: #fff url(/assets/base/images/select-arrow.svg) right 20px center no-repeat;
}

.form-group .form-control.textaria {
  height: 135px;
  padding: 15px 10px !important;
}

.form-group .labelText, .form-group .labelTextarea {
  color: #40464D;
}

.form-group .input-group-append .calicon::after {
  background: #fff url(/assets/base/images/calendar-icon.svg) no-repeat;
  right: 0;
  top: 3px;
  z-index: 1;
}

.form-group .pr.d-block .input-group-append .calicon::after {
  background: #fff url(/assets/base/images/calendar-icon.svg) no-repeat;
  top: 14px;
  right: 10px;
  z-index: 1;
}

.form-group .input-group .input-group-prepend {
  position: relative;
}

.form-group .input-group .input-group-prepend .input-group-text {
  font-weight: 500;
  position: absolute;
  top: 9px;
  left: 20px;
  padding: 0;
  border: none;
  z-index: 4;
}

.form-group .input-group .form-control {
  padding-left: 32px;
}

.form-group select.form-control {
  background: #fff url(/assets/base/images/select-arrow.svg) right 15px center no-repeat;
  height: 40px !important;
  /**box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);**/
  border: solid 1px #d3d3d3;
  border-radius: 5px !important;
}

.termsModal .modal-content {
  padding: 50px 10px 20px 20px;
  border-radius: 10px;
  box-shadow: 0 22px 34px 0 rgba(0, 0, 0, 0.1);
}

.termsModal .modal-content .modal-body {
  padding: 0 15px;
  max-height: calc(100vh - 130px);
  overflow-y: auto;
}

.termsslider.amountSlider .ng5-slider .ng5-slider-selection-bar .ng5-slider-selection {
  background: #006a32;
}

.termsslider.amountSlider .ng5-slider .ng5-slider-pointer {
  background: #006a32;
}

.termsslider.amountSlider .ng5-slider .ng5-slider-pointer::after, .termsslider.amountSlider .ng5-slider .ng5-slider-pointer.ng5-slider-active::after {
  background: #006a32 !important;
}

.termsslider.amountSlider .ng5-slider .ng5-slider-model-value {
  font-size: 16px;
  font-weight: 600;
  background: #fbfbfb;
  bottom: auto;
  opacity: 1;
  z-index: 9;
  margin-top: 20px;
}

.termsslider.amountSlider .ng5-slider .ng5-slider-tick-legend {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  opacity: .5;
}

.radio-custom {
  position: relative;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: #fff;
}

.ownershipWrap .radioWrap [type="radio"]:checked + label.rlt:before, term-loan-business-info .radioWrap [type="radio"]:checked + label.rlt:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #228848;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 1;
}

.ownershipWrap .radioWrap [type="radio"]:checked + label.rlt:after, term-loan-business-info .radioWrap [type="radio"]:checked + label.rlt:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #228848;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.ownershipWrap .radioWrap [type="radio"]:not(:checked), term-loan-business-info .radioWrap [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.ownershipWrap .radioWrap [type="radio"]:not(:checked) + label.rlt, term-loan-business-info .radioWrap [type="radio"]:not(:checked) + label.rlt {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}

.ownershipWrap .radioWrap [type="radio"]:not(:checked) + label.rlt:before, term-loan-business-info .radioWrap [type="radio"]:not(:checked) + label.rlt:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #c0c0c0;
  border-radius: 100%;
  background: #fff;
}

.ownershipWrap .radioWrap [type="radio"]:not(:checked) + label.rlt:after, term-loan-business-info .radioWrap [type="radio"]:not(:checked) + label.rlt:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #005072;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.ownershipWrap .owner_type .form-check-inline, term-loan-business-info .owner_type .form-check-inline {
  padding: 15px 101px 15px 18px;
  min-width: 29%;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #d3d3d3;
}

.addenduma-wrap-terms {
  padding: 0 15px;
  margin-bottom: 30px;
  background: #eaeaea;
  padding: 20px 30px;
  border-radius: 10px;
}

.addenduma-wrap-terms .btn-link {
  color: #228848 !important;
  font-weight: 500;
}

.naics-group .naics-field {
  position: absolute;
  top: -12px;
}

.naics-group .search-result {
  top: 84px;
}

.naics-group .form-control {
  font-size: 13px;
}

.arrowd {
  margin-left: 0px;
  cursor: pointer;
  width: 26px;
  font-size: 0;
  height: 15px;
  padding: 10px;
  display: inline-block;
  background: url(/assets/base/images/arrow-g.svg) center center no-repeat;
}

.arrowd.accordionuploadIcon {
  width: auto;
  font-size: 14px;
  background-position: 0px 7px;
  padding: 0 0 0 20px;
  display: inline-block;
}

.arrowdblk {
  margin-left: 0px;
  cursor: pointer;
  width: 18px;
  font-size: 0;
  height: 10px;
  display: inline-block;
  background: url(/assets/base/images/arrow-blk.svg) center center no-repeat;
}

.wbg.businessFunForm {
  border-radius: 50px !important;
  box-shadow: none;
  margin: 0 !important;
}

.wbg.businessFunForm .infoTip {
  top: 41px;
}

.star {
  background: #682d8b;
  width: 60px;
  height: 60px;
  position: relative;
}

.star:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 60px;
  background: #682d8b;
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
}

.star::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 60px;
  background: #682d8b;
  -webkit-transform: rotate(-30deg);
  -moz-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  -o-transform: rotate(-30deg);
}

.star span {
  position: absolute;
  top: 18px;
  left: 2px;
  font-size: 14px;
  text-align: center;
  z-index: 1;
  font-weight: 600;
}

.piyanoNav.horizontal {
  position: relative;
  display: flex;
  padding: 0;
}

.piyanoNav.horizontal li {
  position: relative;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  padding: 20px 0;
  text-align: center;
}

.piyanoNav.horizontal li .circleap:after {
  content: '';
  display: block;
  position: relative;
  left: 0px;
  font-size: 1.2em;
  height: 16px;
  width: 16px;
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  background: #fff;
  border: 1px solid #c0c0c0;
  z-index: 2;
  margin: 0 auto;
}

.piyanoNav.horizontal li.done .circleap {
  background: transparent url(/assets/base/images/PPP-icons.svg) no-repeat -253px -6px;
  height: 17px;
  width: 18px;
  display: block;
  position: relative;
  left: 2px;
  z-index: 2;
  margin: 0 auto;
}

.piyanoNav.horizontal li.done .circleap:after {
  display: none;
}

.piyanoNav.horizontal li.active .circleap {
  background: transparent url(/assets/base/images/PPP-icons.svg) no-repeat -296px -6px;
  transform: rotate(-90deg);
  height: 17px;
  width: 18px;
  display: block;
  position: relative;
  left: 2px;
  z-index: 2;
  margin: 0 auto;
}

.piyanoNav.horizontal li.active .circleap:after {
  display: none;
}

.piyanoNav.horizontal li:first-child::after {
  content: "";
  width: 50%;
  position: absolute;
  left: 0;
  top: 27px;
  height: 3px;
  background: #fff;
  z-index: 1;
}

.piyanoNav.horizontal li:last-child::after {
  content: "";
  width: 50%;
  position: absolute;
  left: auto;
  right: 0px;
  top: 27px;
  height: 3px;
  background: #fff;
  z-index: 1;
}

.piyanoNav.horizontal:after {
  content: "";
  position: absolute;
  top: 28px;
  left: 13px;
  height: 1px;
  width: calc(100% - 13px);
  background-color: #666;
  opacity: 0.3;
}

.darkgray {
  background: #181f23;
}

td .workingCapital + .tooltip .tooltip-inner {
  min-width: 300px;
}

.form-group .form-control.btc2_select {
  color: rgba(0, 0, 0, 0.5) !important;
  padding-right: 20px !important;
  font-size: 16px;
  background-position: right 5px top 19px;
  background-size: 12px;
}

.prefill-cls {
  padding: 7px 17px;
  text-decoration: underline !important;
  cursor: pointer !important;
}

resource-hub {
  background: #fff;
}

resource-hub .headerinner .container {
  max-width: 1120px;
}

resource-hub .headerinner + .container {
  max-width: 1120px;
}

#seelessbtn span.arrowdblk {
  background: url(/assets/base/images/arrow-blk.svg) center center no-repeat;
  transform: rotate(180deg);
}

.tremDocument .pptc:hover {
  color: #762b71;
}

.quarter_fields .form-control {
  font-size: 14px !important;
  font-weight: 400 !important;
  -webkit-appearance: auto;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
}

.modal {
  top: 20px;
}

.searchwrap {
  position: relative;
  min-width: 350px;
}

.searchwrap:before {
  background: url(/assets/base/images//search.svg) no-repeat;
  content: '';
  width: 18px;
  height: 18px;
  display: block;
  z-index: 99;
  position: absolute;
  top: 7px;
  left: 6px;
  background-size: 18px;
}

.searchwrap .has-search {
  padding-left: 30px !important;
}

funnel-table .quesIcon {
  width: 11px !important;
  height: 11px !important;
  line-height: 11px;
}

funnel-table .quesIcon img {
  max-width: 11px;
}

commision-report .quesIcon {
  width: 11px !important;
  height: 11px !important;
  line-height: 11px;
}

commision-report .quesIcon img {
  max-width: 11px;
}

#eProtectiframe body {
  display: flex;
}

#eProtectiframe .positionNumberDiv {
  background: #ccc;
}

.paymentOption {
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}

.paymentOption.active {
  border-color: #6fb253;
}

.progress {
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #ccc;
  height: 17px;
  overflow: inherit;
  position: relative;
}

.progress .redOrg {
  background-color: red;
  /* For browsers that do not support gradients */
  border-radius: 8px;
  background-image: linear-gradient(to right, red, orange);
}

.progress .orgYell {
  background-color: orange;
  /* For browsers that do not support gradients */
  border-radius: 8px;
  background-image: linear-gradient(to right, orange, yellow);
}

.progress .yellLigGree {
  background-color: yellow;
  /* For browsers that do not support gradients */
  border-radius: 8px;
  background-image: linear-gradient(to right, orange, #91de78);
}

.progress .ligGreeGreen {
  background-color: #91de78;
  /* For browsers that do not support gradients */
  background-image: linear-gradient(to right, #91de78, #08a242);
  border-radius: 8px;
}

.progress .red {
  background-color: red;
  /* For browsers that do not support gradients */
  border-radius: 8px;
}

.progress .orange {
  background-color: orange;
  /* For browsers that do not support gradients */
  border-radius: 8px;
}

.progress .yellow {
  background-color: yellow;
  /* For browsers that do not support gradients */
  border-radius: 8px;
}

.progress .lightGreen {
  background-color: #91de78;
  /* For browsers that do not support gradients */
  border-radius: 8px;
}

.progress .value {
  padding-top: 20px;
  margin-left: -20px;
  text-indent: -18px;
}

.progress .leftValue {
  left: -1px;
  position: absolute;
  top: 20px;
}

.progress .rightValue {
  right: 0px;
  position: absolute;
  top: 20px;
}

.progress .successIcon {
  background: url(/assets/base/images//success_tick_icon-svg.svg) no-repeat 3px bottom;
  background-size: 16px;
  width: 20px;
  height: 36px;
  display: inline-block;
}

.qusBox {
  display: inline-block;
  width: 30px;
  height: 20px;
  padding-left: 5px;
  vertical-align: text-bottom;
  padding-top: 2px;
}

.qusBox .qusIconBtn {
  width: 15px;
  height: 15px;
  cursor: pointer;
  text-indent: -90000px;
  background: url(/assets/base/images//question_icon.svg) no-repeat;
  border: 0;
  background-color: transparent;
  opacity: .6;
  position: relative;
  top: -6px;
}

@media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .qusBox .qusIconBtn {
    top: 0;
  }
}

.showBox {
  position: absolute;
  top: 18px;
  left: 32%;
}

.showBox .successIcon {
  background: url(/assets/base/images//success_tick_icon-svg.svg) no-repeat 3px 3px;
  background-size: 16px;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  bottom: 2px;
}

.progress-text {
  font-size: 12px;
  color: #333;
}

.worldPay {
  max-width: 530px;
}

.checkoutPlan {
  max-width: 470px !important;
  width: 470px !important;
}

.PlanFullWidth {
  max-width: 100% !important;
  width: 100% !important;
}

.PlanFullWidth #eProtect-iframe {
  display: block;
  margin: auto !important;
}

.planUpdate.chosseplan {
  max-width: 500px !important;
}

.planUpdate.chosseplan .modal-content .close {
  z-index: 10;
}

.cardDetail {
  max-width: 625px !important;
}

.cardDetail .checkoutPlan {
  padding-left: 16px;
}

.loanPurDetails {
  border: 1px solid #9FA3A6;
  padding: 20px;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  background: #fff;
}

.loanPurDetails rubicon-group .loanBlockRow {
  position: relative;
}

.loanPurDetails rubicon-group .loanBlockRow:first-child .deleteIcon {
  position: absolute;
  right: 83px;
  top: 24px;
}

.loanPurDetails rubicon-group .loanBlockRow .deleteIcon {
  position: absolute;
  right: 83px;
  top: 24px;
}

.loanPurDetails .addMoreBtn {
  position: absolute;
  bottom: 16px;
  right: 15px;
  width: 77px !important;
}

.loanPurDetails .addMoreBtn .btn {
  font-size: 14px;
}

.loanPurDetails .onError .addMoreBtn {
  bottom: 37px;
}

.groupTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 0 0 5px 0;
  color: #101820;
  border-bottom: 2px solid #C4C4C4;
  margin: 0px 0px 5px 0px;
  width: 100%;
}

.loanRequestTitle {
  position: relative;
  padding: 0 0 0 190px;
}

.loanRequestTitle:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
  height: 126px;
  background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -16px -13px;
}

.notificationbox {
  border: 0.1em solid #0083d8;
  border-radius: 8px;
}

.notificationsuccessbox {
  background-color: #e1f0dc;
  border: 0.1em solid #8fc49e;
  border-radius: 8px;
}

.notificationsuccessbox span {
  color: #339155;
}

.directorybox {
  border: 0.1em solid #7939AB;
  border-radius: 8px;
  background: rgba(121, 57, 171, 0.2);
  color: #3E494D;
}

.directorybox span, .directorybox a {
  color: #7939AB !important;
}

.directorybox a {
  text-decoration: underline;
  font-weight: 600;
}

.successbox {
  border: 0.1em solid #6fb253;
  border-radius: 8px;
  color: #3E494D;
}

.betaTag {
  background: #25a0c5;
  text-transform: uppercase;
  border-radius: 10px;
  font-size: 11px;
  height: 17px;
  line-height: 12px;
  font-weight: 500;
  display: inline-block;
  vertical-align: top;
  margin-top: 4px;
  color: #fff !important;
}

.beta-qicon .qusIconBtn {
  border: 1px solid #3F865D;
  display: inline-block;
  border-radius: 10px;
  background: url(/assets/base/images/qIcon.svg) 4px 3px no-repeat;
}

.col-2-cust {
  padding-right: 0px !important;
}

.col-2-cust .qusBox {
  vertical-align: top;
}

.col-2-cust .tooltip-inner {
  min-width: 300px;
}

.tooltip-wrap .tooltip-inner {
  min-width: 300px;
}

.creditCheckModal {
  max-width: 950px;
}

.hideOwnershipField .hideOwnershipClass {
  display: none;
}

ppp2-document-management .ListDocument li {
  padding-left: 20px;
}

ppp2-document-management .ListDocument li:before {
  content: none;
}

.uploadFirm .dropzone {
  padding: 10px 10px 10px 10px;
  text-align: center;
  background: #fff !important;
  border: 1px dashed #8A8A8A !important;
}

.uploadFirm h4 {
  font-weight: 400;
  color: #4D4D4D;
  font-size: 14px;
}

.uploadFirm h4 span {
  text-decoration: underline;
  cursor: pointer;
  color: #25A0C5;
}

.updateProfile {
  max-width: 880px;
}

.updateProfile .modal-content {
  padding: 30px 10px;
  border-radius: 5px !important;
}

.company-profile {
  border: 0.5px solid #CFD1D2;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.company-profile li {
  padding: 5px 0;
  font-size: 10px;
  font-weight: 500;
  border-bottom: 1px solid #D2D2D2;
}

.company-profile .btn {
  letter-spacing: 0.05em;
}

.blueStar:after {
  color: #25A0C5;
  content: '*';
}

platform-core-collateral-table .tooltip-inner {
  min-width: 50px !important;
}

ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600 !important;
  color: #111111;
  border: none !important;
  border-bottom: 1px solid rgba(154, 154, 154, 0.5) !important;
  padding: 4px 25px 4px 2px !important;
  display: block !important;
  width: 100% !important;
  height: 30px;
  border-radius: 0 !important;
}

ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  transform: inherit !important;
}

ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret::before {
  border-color: #000 transparent !important;
  top: 52% !important;
  right: -7px !important;
  border-width: 6px 6px 0 !important;
}

ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn span span.ng-star-inserted {
  position: absolute;
  top: 10px;
  left: 13px;
}

ng-multiselect-dropdown .multiselect-dropdown .dropdown-multiselect--active .dropdown-multiselect__caret::before {
  border-color: #000 transparent !important;
  top: 52% !important;
  right: -7px !important;
  border-width: 6px 6px 0 !important;
}

ng-multiselect-dropdown .multiselect-dropdown .selected-item {
  width: 157px;
  position: relative;
  min-height: 25px;
  margin-bottom: 0 !important;
  background-color: #228848 !important;
  border: 0 !important;
  color: #fff !important;
}

ng-multiselect-dropdown .multiselect-dropdown .selected-item a {
  color: #fff !important;
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
}

ng-multiselect-dropdown .multiselect-dropdown .selected-item span {
  width: 135px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  line-height: 15px;
  font-weight: 500 !important;
  font-size: 12px !important;
  padding-top: 3px;
}

ng-multiselect-dropdown .multiselect-dropdown .selected-item span:hover {
  white-space: unset;
  text-overflow: unset;
}

.form-group.popNew ng-multiselect-dropdown .multiselect-dropdown .dropdown-list li.multiselect-item-checkbox div {
  color: #4D4D4D !important;
}

.form-group.popNew ng-multiselect-dropdown .multiselect-dropdown .selected-item {
  background-color: transparent !important;
  color: #4D4D4D !important;
  font-family: "Poppins", sans-serif !important;
}

.form-group.popNew ng-multiselect-dropdown .multiselect-dropdown .selected-item span {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.form-group.popNew ng-multiselect-dropdown .multiselect-dropdown .selected-item:hover {
  box-shadow: none !important;
}

.form-group.popNew ng-multiselect-dropdown .multiselect-dropdown .selected-item a {
  color: #4d4d4d !important;
}

.form-group.popNew ng-multiselect-dropdown .multiselect-dropdown .multiselect-item-checkbox input[disabled] + div::before {
  background: #ccc !important;
  border: 1px solid #666 !important;
}

.form-group.popNew ng-multiselect-dropdown .multiselect-dropdown .multiselect-item-checkbox div::before {
  border-radius: 3px;
  border-width: 1px !important;
  width: 16px !important;
  height: 16px !important;
  background-color: #fff !important;
  border-color: #4D4D4D !important;
}

.form-group.popNew ng-multiselect-dropdown .multiselect-dropdown .multiselect-item-checkbox div::after {
  margin-top: -5px !important;
  border-width: 0 0 2px 2px !important;
  left: 3px !important;
  width: 11px !important;
  height: 4px !important;
  border-color: #4D4D4D !important;
}

.form-group.popNew ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn {
  border: 1px solid #d3d3d3 !important;
  height: 40px;
  border-radius: 5px !important;
  padding: 7px 25px 4px 15px !important;
  font-weight: 400 !important;
}

.form-group.popNew ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn .selected-item-container {
  display: none !important;
}

.form-group.popNew ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn span {
  font-size: 14px !important;
  color: #4D4D4D;
  float: none !important;
}

.form-group.popNew ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn span .dropdown-multiselect__caret::before {
  border: 0 !important;
  width: 26px;
  height: 20px;
  position: absolute !important;
  right: 10px !important;
  left: inherit !important;
  top: 5px !important;
  background: transparent url(/assets/base/images/arrow-blk.svg) no-repeat right 2px center;
}

.form-group.popNew ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn span span {
  position: relative;
  left: inherit;
  top: inherit;
  padding-right: 126px !important;
}

.form-group.popNew ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn span span::before {
  position: absolute;
  left: -65px;
  top: -1px;
  content: "Selected \2013";
  background-color: #fff;
  width: 73px;
  height: 21px;
  color: #4D4D4D;
  font-size: 14px;
}

.modal-body account-directory .form-group.popNew ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn span span {
  padding-right: 70px !important;
}

.multiLabel .labelText {
  top: -18px !important;
  left: 15px !important;
}

.modal-body account-directory .popTwoLine {
  padding-top: 1rem !important;
  margin-top: 0.5rem !important;
}

.multiselect-item-checkbox input[disabled] + div::before {
  background: #f1f1f1 !important;
  border: 2px solid #f1f1f1 !important;
}

.multiselect-item-checkbox div::before {
  background: #228848 !important;
  border: 2px solid #228848 !important;
}

.reasonsDiv {
  margin-bottom: 12px;
  background-color: #ffffff;
  padding: 10px 15px;
  min-height: 50px;
  height: auto;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

span.question {
  padding-right: 20px;
}

.charLimit {
  position: relative;
}

.charLimit .limits {
  position: absolute;
  color: #9D9D9D;
  font-size: 10px;
  font-weight: 400;
  right: 20px;
  bottom: 0px;
  width: auto;
  height: 16px;
}

.charLimit.limiterror .limits {
  bottom: 27px;
}

.reasonsDiv {
  margin-bottom: 12px;
  background-color: #ffffff;
  padding: 10px 15px;
  min-height: 50px;
  height: auto;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

span.question {
  padding-right: 20px;
}

.charLimit {
  position: relative;
}

.charLimit .limits {
  position: absolute;
  color: #9D9D9D;
  font-size: 10px;
  font-weight: 400;
  right: 20px;
  bottom: 0px;
  width: auto;
  height: 16px;
}

.charLimit.limiterror .limits {
  bottom: 27px;
}

.noSROnly {
  clip-path: inherit !important;
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal;
}

.placeHolderColor::placeholder {
  color: #08a242 !important;
  font-style: normal !important;
}

.placeHolderColor:-ms-input-placeholder {
  color: #08a242 !important;
  font-style: normal !important;
}

.placeHolderColor::-ms-input-placeholder {
  color: #08a242 !important;
  font-style: normal !important;
}

.financingProductLabel {
  background-color: #fff;
  width: 86%;
}

.apptype .tooltip-inner {
  color: #fff !important;
}

.ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell, .ag-theme-alpine .ag-cell {
  padding-left: 12px;
  padding-right: 12px;
}

.featureClass .modal-content {
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);
  border: 1px solid #f3f3f3;
  width: 1250px !important;
  right: 375px !important;
  margin-top: -20px;
}

.selfServiceHead #frame1 .owl-dots,
.selfServiceHead #frame2 .owl-dots,
.selfServiceHead #frame3 .owl-dots {
  position: absolute;
  bottom: -40px;
  width: 100%;
  display: none;
}

.email-client a {
  color: white !important;
}

#multi_applicant_type {
  margin-left: 30px;
  font-size: 12px;
}

#cooperative_rubiconField {
  margin-left: 25px;
  margin-right: 15px;
}

#other_rubiconField {
  margin-left: 14px;
}

#unique_entity_id_in_sam_rubiconField {
  margin-left: 2%;
}

.tlCls {
  font-size: 10px;
  padding-top: 7px;
}

.imgCls {
  height: 15px;
  width: 15px !important;
  cursor: pointer;
}

.hoverCls {
  font-size: 10px;
  word-break: break-all;
  white-space: normal;
}

.appClint {
  bottom: -11px;
  position: relative;
}

.appClint .nav-item {
  margin-right: 10px;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.appClint .nav-item.active {
  z-index: 999;
}

.appClint .nav-item.active .nav-link {
  color: #228848;
}

.appClint .nav-item::after {
  display: none;
}

.appClint .nav-link {
  box-shadow: 0px -1.5px 2px #999;
  border-radius: 10px 10px 0 0;
  background-color: #fff;
  padding: 10px 15px !important;
  font-size: 14px;
  color: #333;
}

.no-bg {
  background: none !important;
}

.videoBanner {
  margin-top: 0px;
  margin-bottom: 15px;
  position: relative;
  background: #25a0c5;
}

.businessFunBlock {
  color: #2a363b;
  border-top: 0px solid #6fb253;
  background: url(/assets/base/images/signupbg.jpg) no-repeat center -50px;
  background-attachment: fixed;
  background-size: cover;
  padding-top: 70px;
}

.pntc {
  color: #228848;
}

.solutionImage {
  position: relative;
}

.solutionImage .solutionTextBox {
  width: 220px;
  position: absolute;
  right: 30px;
  box-shadow: 0 3px 4px #666;
  border-radius: 10px;
  background-color: #fff;
  top: 120px;
  padding: 40px 20px 10px;
}

.solutionImage .solutionHeadimg {
  width: 450px;
}

.solutionBody {
  background-color: #f1f1f1;
  padding: 80px 0;
}

.solutionBody .clientServBox {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
  color: #333333;
  min-height: 292px;
}

.solutionBody .clientServBox span.icon {
  display: inline-block;
  width: 75px;
  height: 65px;
  background: url(/assets/base/images/clientServIcon.svg) no-repeat 0 0;
}

.solutionBody .clientServBox span.icon.admin {
  background-position: -104px 0;
}

.solutionBody .clientServBox span.icon.dasktop {
  background-position: -220px 0;
}

.solutionBody .clientServBox span.icon.idea {
  background-position: -337px 0;
}

.headclr {
  color: #288E4C;
}

.solutionHowWork {
  background-color: #f1f1f1;
  padding: 50px 0;
}

.solutionFaq {
  background-color: #f1f1f1;
  padding: 50px 0;
}

.solutionFaq .card-header {
  padding: 0;
}

.solutionFaq .card, .solutionFaq .card-header {
  background-color: transparent;
  padding: 0;
  border: 0;
}

.solutionFaq .panel.panel-open .accordion-toggle .btn-link {
  background: url(/assets/base/images/faq-min.svg) no-repeat 0 23px;
}

.solutionFaq .panel .accordion-toggle .btn-link {
  font-size: 18px;
  font-weight: 500;
  padding-left: 40px;
  background: url(/assets/base/images/faq-plus.svg) no-repeat 0 14px;
}

.solutionFaq .panel .accordion-toggle .btn-link:hover, .solutionFaq .panel .accordion-toggle .btn-link:focus {
  text-decoration: none;
  opacity: .8;
}

.videoContain {
  background: #25a0c5;
  color: #fff;
}

.videoContain p {
  font-size: 18px;
}

.videoContain .playicon {
  background: #fff url(/assets/base/images/v-play-blue.svg) center center no-repeat;
  font-size: 0;
  border-radius: 10px;
  display: block;
  width: 90px;
  height: 55px;
}

.videoContain .videoBox {
  height: calc(100% + 40px);
  border: 1px solid #fff;
  position: relative;
  margin: -20px 0;
  font-weight: 600;
  font-size: 18px;
  background: #682d8b;
  background: -moz-linear-gradient(45deg, #682d8b 46%, #983a72 100%);
  background: -webkit-linear-gradient(45deg, #682d8b 46%, #983a72 100%);
  background: linear-gradient(45deg, #682d8b 46%, #983a72 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#682d8b', endColorstr='#983a72',GradientType=1 );
  padding-top: 60px;
}

.videoContain .base-color {
  color: #242731;
}

.leads_list {
  max-height: 150px;
  overflow: auto;
  margin-left: 0px;
  padding-left: 30px;
}

.leads_list li {
  padding-bottom: 7px;
  font-size: 14px;
}

.color616 {
  color: #616161;
}

.failedBox {
  background: #FCE7E6;
  padding: 15px;
  font-weight: 500;
}

.failedBox .colorred {
  color: #E96D6D;
  font-weight: 600;
}

.failedBox .status {
  background: #E96D6D;
  display: inline-block;
  padding: 4px 12px;
  border-radius: 25px;
  color: #fff;
  font-size: 14px;
}

.duplicateBox {
  background: #fcd29b9c;
  padding: 15px;
  font-weight: 500;
}

.duplicateBox .colorred {
  color: #ec8e41;
  font-weight: 600;
}

.duplicateBox .status {
  background: #ec8e41;
  display: inline-block;
  padding: 4px 12px;
  border-radius: 25px;
  color: #fff;
  font-size: 14px;
}

.successBox {
  background: #C7EAD4;
  padding: 15px;
  font-weight: 500;
}

.successBox .colorred {
  color: #6fb253;
  font-weight: 600;
}

.successBox .status {
  background: #6fb253;
  display: inline-block;
  padding: 4px 12px;
  border-radius: 25px;
  color: #fff;
  font-size: 14px;
}

.welcomepopupClass .headingTitle, .represetativePopUp .headingTitle {
  font-size: 22px;
  line-height: 30px;
  color: #616161;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 600;
}

.welcomepopupClass .para-small, .represetativePopUp .para-small {
  font-size: 12px;
  line-height: 18px;
  color: #4D4D4D;
  text-align: center;
  margin-bottom: 16px;
}

.welcomepopupClass .para-small, .represetativePopUp .para {
  font-size: 14px;
  line-height: 20px;
  color: #4D4D4D;
  text-align: center;
  margin-bottom: 16px;
}

.represetativePopUp .icon {
  text-align: center;
  margin-bottom: 16px;
}

.welcomepopupClass .width85, .represetativePopUp .width85 {
  width: 80%;
  margin: auto;
}

.welcomepopupClass .tickul {
  list-style: none;
}

.welcomepopupClass .tickul li {
  position: relative;
  padding-left: 30px;
  color: #000;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
}

.welcomepopupClass .tickul li:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
  width: 21px;
  height: 15px;
  margin-right: 16px;
  background: url(~projects/affiliate-journey/src/assets/acl/images/tickIcon.svg) no-repeat;
}

.welPopUp .closewrap {
  padding: 15px 15px 0px;
}

.welPopUp .closewrap .close {
  color: #000;
  font-size: 36px !important;
  line-height: 18px;
  height: 20px;
  display: block;
}

.welPopUp .closewrap .close span {
  color: #000 !important;
}

.welPopUp .modal-footer {
  border: 0px;
  padding-bottom: 24px;
}

@media (max-width: 767px) {
  .welcomepopupClass .para-small br, .represetativePopUp .para br {
    display: none;
  }
  .welcomepopupClass .width85, .represetativePopUp .width85 {
    width: 100%;
    margin: auto;
  }
}

#toast-container .toast-warning {
  background-color: #A57B0D !important;
  opacity: 1 !important;
  box-shadow: 0 0 0 #fff;
  width: 415px;
  border-radius: 8px;
  padding: 13px 10px 13px 60px;
}

#toast-container .toast-warning:hover {
  box-shadow: 0 0 0 #fff;
}

#toast-container .icon-warning {
  background-position: 15px 15px;
  background-image: url(/assets/acl/images/warning-new-icon.svg) !important;
  background-repeat: no-repeat;
  width: 50px;
}

#toast-container .toast-content {
  font-size: 16px;
  width: 100%;
  line-height: 24px;
  font-weight: 500;
}

#toast-container .toast-message {
  width: 100% !important;
}

.affiliatePage .affiliateOwnersWrap {
  width: 100%;
  position: relative;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  margin: 0 15px 30px;
  border: 1px solid #CFD1D2;
}

.affiliatePage .affiliateOwnersWrap .groupTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 0 0 0 0;
  color: #101820;
  border-bottom: none;
  margin: 13px 0px 0 0px !important;
  width: 50%;
}

.affiliatePage .affiliateOwnersWrap .aoHelpText {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 10px;
}

.affiliatePage .affiliateOwnersWrap #affiliate_owners_rubiconField {
  position: unset;
}

.affiliatePage .affiliateOwnersWrap .addMoreBtn {
  position: absolute;
  top: 12px;
  right: 15px;
  max-width: 190px;
}

.affiliatePage .affiliateOwnersWrap .deleteWrapAi {
  position: relative;
  margin: 0;
  border-width: 1px;
  padding: 10px 15px 0 !important;
  border-color: #eeeeee;
}

.affiliatePage .affiliateOwnersWrap .deleteWrapAi .deleteWraptop {
  position: absolute;
  top: 18px !important;
  right: 25px;
}

.affiliatePage .affiliateOwnersWrap .deleteWrapAi .deleteWraptop .deleteIcon {
  background: none;
  height: 20px;
  width: 15px;
}

.affiliatePage .affiliateOwnersWrap .deleteWrapAi .deleteWraptop .deleteIcon::before {
  position: absolute;
  background: url(../base/images/sprite-icons.svg) no-repeat -433px -96px;
  height: 20px;
  width: 20px;
  left: -3px;
  bottom: 9px;
  content: "";
}

.affiliatePage .affiliateOwnersWrap .deleteWrapAi .form-group {
  margin-bottom: 10px;
}

.affiliatePage .affiliateOwnersWrap .affiliateOwnersHeaders {
  background-color: #F3F3F4;
  padding: 0 15px;
}

.affiliatePage .affiliateOwnersWrap .affiliateOwnersHeaders .aoHeaderLabel {
  color: #000000;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 12px;
  font-family: "Poppins", sans-serif !important;
  text-transform: uppercase;
}

.affiliatePage .footerFixedBottom {
  margin-bottom: 30px;
  width: 100%;
}

.affiliatePage #relationship_text_rubiconField {
  padding-left: 0;
}

.affiliatePage #relationship_text_rubiconField .ng-select-container:hover {
  box-shadow: none !important;
}

.affiliatePage #affiliates_of_rubiconField ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  flex-wrap: wrap !important;
  min-height: 33px;
}

.inputLikeLabel {
  background: transparent !important;
  padding: 0 !important;
  min-height: unset !important;
  width: 100%;
  border-radius: 0 !important;
  text-align: left;
  line-height: 36px;
  resize: none;
  font-size: 14px !important;
  border: 0 !important;
}

.inputLikeLabel:focus-visible, .inputLikeLabel:focus {
  box-shadow: none !important;
  border: none;
  outline: none;
}

.inputLikeLabel:disabled {
  background: transparent !important;
}

.inputLikeLabel.form-control[readonly] {
  background-color: #fff !important;
}

.revmoveBorder {
  border: none !important;
  height: 38px !important;
}

.toast-content {
  font-size: 16px;
  width: 100%;
  line-height: 24px;
  font-weight: 500;
}

.toast-message {
  width: 100% !important;
}

input#business_search_2::placeholder {
  font-size: 12px !important;
}

.resourceBtn {
  background: #D1FEBE url(/assets/base/images/resource-icon.svg) no-repeat right 10px top 6px;
  height: 45px !important;
  padding: 7px 50px 0 15px;
  opacity: 1;
  color: #3E682C !important;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px !important;
  border: 2px solid #3E682C;
}

.plusCount {
  background: #eeeeee;
  padding: 1px 10px;
  border-radius: 5px;
  color: #000000;
  margin-left: 5px;
  display: inline-block;
  cursor: pointer;
}

.ownerWidth rubicon-select {
  width: 80%;
  display: block;
}

.dropdownIcon rubicon-select .ng-select-container {
  background: url(/assets/base/images//arrow-blk.svg) no-repeat right 15px top 15px;
  background-size: 0;
  background-color: transparent !important;
}

.two-col-sec .pdf-sec, .two-col-sec .field-sec {
  height: calc(100vh - 50px);
  overflow-y: auto;
  overflow-x: hidden;
}

.two-col-sec .pdf-sec {
  overflow-y: hidden;
}

app-term-loan pdf-info .field-sec .fw-bold {
  font-weight: bold;
}

app-term-loan pdf-info details-form rubicon-label .ownership_txt {
  color: #228848;
}

app-term-loan pdf-info details-form rubicon-radio .business_has_outstanding_debt input[type="radio"] {
  accent-color: #228848;
}

app-term-loan pdf-info details-form rubicon-switch .switchWrap input[type="checkbox"] {
  accent-color: #000000;
}

app-term-loan pdf-info details-form rubicon-slider .termsslider.amountSlider .ng5-slider .ng5-slider-selection-bar .ng5-slider-selection,
app-term-loan pdf-info details-form rubicon-slider .termsslider.amountSlider .ng5-slider .ng5-slider-pointer,
app-term-loan pdf-info details-form rubicon-slider .termsslider.amountSlider .ng5-slider .ng5-slider-pointer::after,
app-term-loan pdf-info details-form rubicon-slider .termsslider.amountSlider .ng5-slider .ng5-slider-pointer.ng5-slider-active::after {
  background: #228848 !important;
}

app-term-loan pdf-info details-form .radio-border .business_has_outstanding_debt .md-radio {
  border: 1px solid #D3D3D3;
  padding: 8px 50px 8px 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px #00000040;
}

app-term-loan pdf-info details-form .icon-top .pr.d-block .input-group-append .calicon::after {
  top: 10px;
}

app-term-loan pdf-info details-form .pdf-flex .switchWrap label.el-switch {
  display: flex;
}

app-term-loan pdf-info details-form .pdf-flex .switchWrap label.el-switch label.clt {
  margin-left: 8px;
}

app-term-loan pdf-info details-form .radio-flex .business_has_outstanding_debt div.fs14 {
  margin-bottom: 8px !important;
}

app-term-loan pdf-info details-form .radio-flex .business_has_outstanding_debt .option-flex {
  display: flex;
}

app-term-loan pdf-info details-form .radio-flex .business_has_outstanding_debt .option-flex .md-radio {
  margin-right: 16px;
}

app-term-loan pdf-info details-form .radio-flex .business_has_outstanding_debt .option-flex .md-radio .form-check-input {
  margin-right: 8px;
}

app-term-loan pdf-info details-form .auth-spacing .switchWrap label.el-switch label.clt {
  margin-left: 8px;
}

app-term-loan pdf-info details-form .font-styling .secondary-label {
  font-weight: 600;
}

app-term-loan pdf-info details-form .naics-group .naics-field.labelText {
  font-size: 14px !important;
  color: #111111;
}

app-term-loan pdf-info details-form .custom-green .business_has_outstanding_debt span {
  color: #228848;
  font-weight: 600;
}

app-term-loan pdf-info details-form .addenduma-wrap-terms {
  padding: 20px 40px;
}

select.form-control.h-48 {
  height: 48px !important;
}

.sba_libs {
  background: #fff;
  height: auto;
  min-height: 100vh;
  /* for ducument */
}

.sba_libs input::-ms-reveal, .sba_libs input::-ms-clear {
  display: none;
}

.sba_libs .btn {
  border-width: .1em;
}

.sba_libs .loanRequestTitle {
  position: relative;
  padding: 0 0 0 220px;
}

.sba_libs .loanRequestTitle .page-title {
  font-size: 22px;
  font-weight: 600;
}

.sba_libs .loanRequestTitle:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 220px;
  height: 126px;
  background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -16px -13px;
}

.sba_libs .uploadDocTitle {
  position: relative;
  padding: 0 0 0 220px;
}

.sba_libs .uploadDocTitle:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 126px;
  background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -21px -166px;
}

.sba_libs .businessdetailTitle {
  position: relative;
  padding: 0 0 0 220px;
}

.sba_libs .businessdetailTitle .page-title {
  font-size: 22px;
  font-weight: 600;
}

.sba_libs .businessdetailTitle:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 126px;
  background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -721px -13px;
}

.sba_libs .ownersdetailTitle {
  position: relative;
  padding: 0 0 0 220px;
}

.sba_libs .ownersdetailTitle .page-title {
  font-size: 22px;
  font-weight: 600;
}

.sba_libs .ownersdetailTitle:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 126px;
  background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -268px -13px;
}

.sba_libs .eligibilityquestionsTitle {
  position: relative;
  padding: 0 0 0 220px;
}

.sba_libs .eligibilityquestionsTitle .page-title {
  font-size: 22px;
  font-weight: 600;
}

.sba_libs .eligibilityquestionsTitle:after {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  width: 200px;
  height: 126px;
  background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -495px -13px;
}

.sba_libs .ownersBusinessTitle {
  position: relative;
  padding: 0 0 0 220px;
}

.sba_libs .ownersBusinessTitle .page-title {
  font-size: 22px;
  font-weight: 600;
}

.sba_libs .ownersBusinessTitle:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 185px;
  height: 126px;
  background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -722px -13px;
}

.sba_libs .affiliateInformationTitle {
  position: relative;
  padding: 0 0 0 220px;
}

.sba_libs .affiliateInformationTitle .page-title {
  font-size: 22px;
  font-weight: 600;
}

.sba_libs .affiliateInformationTitle:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 185px;
  height: 126px;
  background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -722px -13px;
}

.sba_libs .additionalOwnerDetailsTitle {
  position: relative;
  padding: 0 0 0 220px;
}

.sba_libs .additionalOwnerDetailsTitle .page-title {
  font-size: 22px;
  font-weight: 600;
}

.sba_libs .additionalOwnerDetailsTitle:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 185px;
  height: 126px;
  background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -722px -13px;
}

.sba_libs .fundaccountinfoTitle {
  position: relative;
  padding: 0 0 0 220px;
}

.sba_libs .fundaccountinfoTitle .page-title {
  font-size: 22px;
  font-weight: 600;
}

.sba_libs .fundaccountinfoTitle:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 185px;
  height: 126px;
  background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -252px -166px;
}

.sba_libs .pfsTitle {
  position: relative;
  padding: 0 0 0 220px;
}

.sba_libs .pfsTitle:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  width: 180px;
  height: 126px;
  background: url(../base/images/sprite-icons-assisted-journey.svg) no-repeat -480px -155px;
}

.sba_libs .collateralDetailsTitle {
  position: relative;
  padding: 0 0 0 220px;
}

.sba_libs .collateralDetailsTitle:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  width: 180px;
  height: 126px;
  background: url(../base/images/sprite-icons-assisted-journey.svg) no-repeat -728px -157px;
}

.sba_libs .sbaform {
  position: relative;
  padding: 0 0 0 220px;
  min-height: 150px;
}

.sba_libs .sbaform .page-title {
  font-size: 22px;
  font-weight: 600;
}

.sba_libs .sbaform:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 185px;
  height: 126px;
  background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -947px -10px;
}

.sba_libs .treeIcon {
  cursor: pointer;
  display: inline-block;
  background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -236px -413px;
  width: 25px;
  height: 25px;
}

.sba_libs .refreshIcon {
  background: url(../base/images/refresh.svg) no-repeat 0 0;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background-size: 24px;
}

.sba_libs ng-select::-ms-expand {
  display: none;
}

.sba_libs ng-select, .sba_libs ng-select.form-control {
  padding: 0;
  background: #fff url(../base/images/down-arrow1.svg) no-repeat right 8px center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
}

.sba_libs ng-select .ng-value-label, .sba_libs ng-select .ng-placeholder, .sba_libs ng-select.form-control .ng-value-label, .sba_libs ng-select.form-control .ng-placeholder {
  padding-left: 10px;
}

.sba_libs .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: nowrap !important;
}

.sba_libs .normalClass .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
}

.sba_libs .ng-select > .ng-select-container .ng-value {
  padding-right: 33px !important;
}

.sba_libs .ng-select .ng-clear-wrapper {
  margin-right: 20px;
  margin-top: 3px;
}

.sba_libs .ng-select.ng-select-single .ng-select-container {
  height: auto;
  border: none;
  background: transparent;
  min-height: 100%;
}

.sba_libs .ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
  padding-right: 30px !important;
}

.sba_libs .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: auto;
}

.sba_libs .ng-select .ng-arrow-wrapper {
  display: none;
}

.sba_libs .form-control:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.sba_libs .ng-select-disabled {
  background-color: #eeeeee !important;
  opacity: 0.7;
  cursor: not-allowed;
}

.sba_libs .calendar {
  background: url(../base/images/sprite-icons-assisted-journey.svg) no-repeat -320px -330px;
  height: 20px;
  width: 16px;
  border: none;
  position: absolute;
  top: 39px;
  right: 28px;
}

.sba_libs .remove-link, .sba_libs .deleteIcon {
  border: none;
  background: url(../base/images/sprite-icons-assisted-journey.svg) no-repeat -424px -310px !important;
  top: 18px !important;
  width: 51px;
  height: 51px;
  cursor: pointer;
}

.sba_libs .remove-link.btn-primary:not(:disabled):not(.disabled):active:focus, .sba_libs .deleteIcon.btn-primary:not(:disabled):not(.disabled):active:focus {
  border: none;
}

.sba_libs sba-primary .addIcon {
  background: url(../base/images/sprite-icons-assisted-journey.svg) no-repeat -807px -333px;
  width: 13px;
  height: 13px;
  display: inline-block;
  position: relative;
  margin-right: 3px;
  top: 1px;
}

.sba_libs .totalAmount {
  font-size: 16px;
  font-weight: 600;
  color: #101820;
  border-bottom: 1px solid #9FA3A6;
}

.sba_libs .totalAmount.amount {
  color: #228848;
  padding-left: 20px;
}

.sba_libs .noteText {
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.sba_libs .noteText .note {
  font-weight: 600;
  background: none;
  padding: 0;
}

.sba_libs loan-type .bottomPartition {
  margin-top: 20px;
}

.sba_libs .checWrap [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.sba_libs .checWrap [type="checkbox"]:not(:checked) + label.clt {
  position: relative;
  padding-left: 2.25em;
  cursor: pointer;
}

.sba_libs .checWrap [type="checkbox"]:not(:checked) + label.clt:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0px;
  width: 1.4em;
  height: 1.4em;
  border: 0.1em solid #101820;
  background: #fff;
  border-radius: 2px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.sba_libs .checWrap [type="checkbox"]:not(:checked) + label.clt:after {
  content: '\2713\0020';
  position: absolute;
  top: .15em;
  left: .22em;
  font-size: 1.3em;
  line-height: 0.8;
  color: #101820;
  transition: all .2s;
  font-family: 'Arial Unicode MS', Arial;
  opacity: 0;
  transform: scale(0);
}

.sba_libs .checWrap [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

.sba_libs .checWrap [type="checkbox"]:checked + label.clt {
  position: relative;
  padding-left: 2.25em;
  cursor: pointer;
  font-weight: 600;
}

.sba_libs .checWrap [type="checkbox"]:checked + label.clt:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0px;
  width: 1.4em;
  height: 1.4em;
  border: 0.1em solid #101820;
  background: #fff;
  border-radius: 2px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.sba_libs .checWrap [type="checkbox"]:checked + label.clt:after {
  content: '\2713\0020';
  position: absolute;
  top: .35em;
  left: .22em;
  font-size: 1.0em;
  line-height: 0.8;
  color: #101820;
  transition: all .2s;
  font-family: "Poppins", sans-serif;
  opacity: 1;
  font-weight: 600;
  transform: scale(1);
}

.sba_libs .checWrap [type="checkbox"]:disabled:not(:checked) + label.clt:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}

.sba_libs .checWrap [type="checkbox"]:disabled:checked + label.clt:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}

.sba_libs .checWrap [type="checkbox"]:disabled:checked + label.clt:after {
  color: #999;
}

.sba_libs .checWrap [type="checkbox"]:disabled + label.clt {
  color: #aaa;
}

.sba_libs .checWrap label:hover:before {
  border: 2px solid #101820 !important;
}

.sba_libs .checWrap.check-sm [type="checkbox"]:not(:checked) + label.clt:before {
  width: 1.5em;
  height: 1.5em;
}

.sba_libs .checWrap.check-sm [type="checkbox"]:not(:checked) + label.clt:after {
  font-size: 1.3em;
}

.sba_libs .checWrap.check-sm [type="checkbox"]:checked + label.clt:before {
  width: 1.5em;
  height: 1.5em;
}

.sba_libs .checWrap.check-sm [type="checkbox"]:checked + label.clt:after {
  font-size: 1.3em;
}

.sba_libs .radioWrap > div:first-child .rlt {
  margin-left: 0;
}

.sba_libs .radioWrap .form-check-label {
  background: #eaeaea;
  padding: 4px 6px 3px 7px;
  margin-right: 10px;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  margin-bottom: 10px;
}

.sba_libs .radioWrap [type="radio"]:checked {
  position: absolute;
  left: -9999px;
}

.sba_libs .radioWrap [type="radio"]:checked ~ .rlt {
  position: relative;
  padding-left: 23px;
  padding-right: 0px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  font-weight: 600;
  color: #101820;
  font-size: 14px;
  margin-bottom: 0;
}

.sba_libs .radioWrap [type="radio"]:checked ~ .rlt:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  border: 4px solid #101820;
  border-radius: 100%;
  background: #101820;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 1;
}

.sba_libs .radioWrap [type="radio"]:checked ~ .rlt:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #fff;
  position: absolute;
  top: 6px;
  left: 4px;
  border-radius: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sba_libs .radioWrap [type="radio"]:checked:focus + div +
span.rlt:before {
  box-shadow: 0 0 0 0.1rem #6fb253 !important;
}

.sba_libs .radioWrap [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.sba_libs .radioWrap [type="radio"]:not(:checked) ~ .rlt {
  position: relative;
  padding-left: 23px;
  padding-right: 0px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #101820;
  font-size: 14px;
}

.sba_libs .radioWrap [type="radio"]:not(:checked) ~ .rlt:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  border: 1px solid #101820;
  border-radius: 100%;
  background: #fff;
}

.sba_libs .radioWrap [type="radio"]:not(:checked) ~ .rlt:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #101820;
  position: absolute;
  top: 6px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.sba_libs .radioWrap [type="radio"]:not(:checked):focus + div +
span.rlt:before {
  box-shadow: 0 0 0 0.1rem #6fb253 !important;
}

.sba_libs .radioWrap [type="radio"]:disabled:not(:checked) ~ .rlt:before {
  box-shadow: none;
  border-color: #9FA3A6;
  background-color: #FBFBFB;
  opacity: 0.5;
  cursor: not-allowed;
}

.sba_libs .radioWrap [type="radio"]:disabled:checked + .rlt:before {
  box-shadow: none;
  border-color: #9FA3A6;
  background-color: #FBFBFB;
  opacity: 0.5;
  cursor: not-allowed;
}

.sba_libs .radioWrap [type="radio"]:disabled:checked + .rlt:after {
  color: #989ba6;
}

.sba_libs .radioWrap [type="radio"]:disabled + .rlt {
  color: #9FA3A6;
}

.sba_libs .backIcon {
  padding: 3px 0;
  position: relative;
  padding-left: 12px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  color: #228848 !important;
  background: none;
}

.sba_libs .backIcon:focus {
  box-shadow: none;
}

.sba_libs .backIcon::after {
  background: url(../base/images/sprite-icons-assisted-journey.svg) no-repeat -382px -383px;
  content: "";
  position: absolute;
  top: 9px;
  left: 0;
  width: 8px;
  height: 12px;
}

.sba_libs .loanPurDetails rubicon-group .loanBlockRow {
  position: relative;
}

.sba_libs .loanPurDetails rubicon-group .loanBlockRow:first-child .remove-link {
  position: absolute;
  right: 83px;
  top: 24px;
}

.sba_libs .loanPurDetails rubicon-group .loanBlockRow .remove-link {
  position: absolute;
  right: 83px;
  top: 24px;
}

.sba_libs .loanPurDetails .addMoreBtn {
  position: absolute;
  bottom: 19px;
  right: 15px;
  width: 76px !important;
}

.sba_libs .loanPurDetails .addMoreBtn .btn {
  font-size: 14px;
}

.sba_libs .loanPurDetails .onError .addMoreBtn {
  bottom: 45px;
}

.sba_libs .form-group .input-group .input-group-prepend .input-group-text {
  top: 13px;
  left: 15px;
}

.sba_libs .form-group .input-group .form-control {
  padding-left: 25px;
}

.sba_libs .ag-theme-balham .ag-header-cell-text {
  color: #101820;
  font-weight: 600;
  font-size: 14px;
}

.sba_libs .ag-theme-balham .ag-row-hover {
  background: transparent;
}

.sba_libs .ag-row-odd {
  background-color: #FAFAFB !important;
}

.sba_libs .ag-theme-balham {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  background: #6fb253;
  font-size: 13px;
  position: relative;
  z-index: 1;
}

.sba_libs .ag-theme-balham .ag-root-wrapper {
  border-top: none !important;
}

.sba_libs .ag-theme-balham .ag-cell-value, .sba_libs .ag-theme-balham .ag-group-value {
  overflow: visible !important;
  text-overflow: unset;
  white-space: normal;
}

.sba_libs .ag-theme-balham .ag-header-cell::after, .sba_libs .ag-theme-balham .ag-header-group-cell::after {
  content: none !important;
}

.sba_libs .ag-theme-balham .ag-row {
  padding: 0;
  font-size: 13px;
  color: #101820;
  min-width: 100%;
  border-bottom: 1px solid #F3F3F4;
}

.sba_libs .ag-theme-balham .ag-row > div {
  padding: 10px 10px;
}

.sba_libs .ag-theme-balham .ag-row-selected {
  background-color: transparent !important;
}

.sba_libs .ag-center-cols-container, .sba_libs .ag-header-container {
  min-width: 100%;
}

.sba_libs .ag-center-cols-viewport {
  border-left: 1px solid #F3F3F4;
  border-right: 1px solid #F3F3F4;
  border-bottom: 1px solid #F3F3F4;
}

.sba_libs .ag-theme-balham .ag-header-cell, .sba_libs .ag-theme-balham .ag-header-group-cell {
  padding-left: 15px;
  padding-right: 0;
}

.sba_libs .ag-row-focus + .ag-row-no-focus bs-tooltip-container {
  z-index: 9;
}

.sba_libs .ag-row-hover {
  z-index: 10;
}

.sba_libs .ag-row-hover .dropdown-menu.show {
  display: block;
}

@media (min-width: 1200px) {
  .sba_libs .ag-root.ag-layout-auto-height, .sba_libs .ag-body-viewport.ag-layout-auto-height, .sba_libs .ag-body-viewport-wrapper.ag-layout-auto-height, .sba_libs .ag-header-viewport, .sba_libs .ag-floating-top-viewport, .sba_libs .ag-body-viewport, .sba_libs .ag-center-cols-viewport, .sba_libs .ag-floating-bottom-viewport, .sba_libs .ag-body-horizontal-scroll-viewport, .sba_libs .ag-virtual-list-viewport, .sba_libs .ag-center-cols-clipper, .sba_libs .ag-root-wrapper, .sba_libs .ag-theme-balham {
    overflow: visible;
  }
}

.sba_libs .ag-body-horizontal-scroll {
  z-index: -1;
}

.sba_libs .ng-select.ng-select-single .ng-select-container .ng-value-container, .sba_libs .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  line-height: 33px;
}

.sba_libs .ag-theme-balham .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
  border-color: transparent !important;
}

.sba_libs .datatext {
  max-width: 180px;
  display: inline-block;
}

.sba_libs .ng-select .ng-select-container {
  min-height: 32px;
}

.sba_libs .ellpstext {
  overflow: hidden;
  text-overflow: unset;
  max-width: none;
  display: inline-block;
}

.sba_libs .bNamefix {
  max-width: 200px !important;
  font-size: 16px;
  font-weight: 600;
  color: #40464D;
}

.sba_libs .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 14px;
}

.sba_libs .ag-theme-balham .ag-cell br, .sba_libs .ag-theme-balham .ag-cell {
  line-height: normal;
}

.sba_libs .ag-cell {
  overflow: visible;
}

.sba_libs .ag-root.ag-layout-auto-height,
.sba_libs .ag-body-viewport.ag-layout-auto-height,
.sba_libs .ag-body-viewport-wrapper.ag-layout-auto-height,
.sba_libs .ag-theme-balham .ag-row-odd {
  background: #fff;
}

.sba_libs .ag-root-wrapper {
  border-radius: 10px 10px 0 0;
}

.sba_libs .gridView .tableHeadWrap {
  border-radius: 5px 5px 0 0;
}

.sba_libs .gridView ag-grid-angular {
  border-radius: 0 0 5px 5px !important;
}

.sba_libs #myGrid.ag-theme-balham .ag-header {
  background-color: #F3F3F4;
  border-bottom: 1px solid #F3F3F4;
}

.sba_libs #myGrid.ag-theme-balham .ag-root-wrapper {
  overflow: hidden;
  border: none;
  border-radius: 0 0 5px 5px !important;
}

.sba_libs app-your_business_info rubicon-radio .tooltip .tooltip-inner {
  min-width: 250px;
}

.sba_libs app-your_business_info .tooltip .tooltip-inner {
  min-width: 0px;
}

.sba_libs .onerListDropdown1 .btn-primary {
  background: #fff !important;
  border-color: #228848 !important;
  color: #228848 !important;
  padding: 7px 20px;
}

.sba_libs .btnMultib .btn-default {
  background: #fff !important;
  border-color: #228848 !important;
  color: #228848 !important;
}

.sba_libs .btn-primary:not(:disabled):not(.disabled):active:focus {
  border-width: .1em;
}

.sba_libs .hv-wrapper {
  display: flex;
}

.sba_libs .hv-wrapper .hv-item {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 0.5px;
}

.sba_libs .hv-wrapper .hv-item .hv-item-parent {
  margin-bottom: 50px;
  position: relative;
  display: flex;
  justify-content: center;
}

.sba_libs .hv-wrapper .hv-item .hv-item-parent p {
  font-weight: bold;
  color: #101820;
}

.sba_libs .hv-wrapper .hv-item .hv-item-parent .ownerTreeprimaryBusinessHeight {
  color: #101820 !important;
}

.sba_libs .hv-wrapper .hv-item .hv-item-parent:after {
  position: absolute;
  content: '';
  width: 1px;
  height: 25px;
  bottom: 0;
  left: 50%;
  background-color: black;
  transform: translateY(100%);
}

.sba_libs .hv-wrapper .hv-item .hv-item-children {
  display: flex;
  justify-content: center;
}

.sba_libs .hv-wrapper .hv-item .hv-item-children .hv-item-child {
  padding: 0 5px;
  position: relative;
}

.sba_libs .hv-wrapper .hv-item .hv-item-children .hv-item-child:only-child:after {
  display: none;
}

.sba_libs .hv-wrapper .hv-item .hv-item-children .hv-item-child:before, .sba_libs .hv-wrapper .hv-item .hv-item-children .hv-item-child:after {
  content: '';
  position: absolute;
  background-color: black;
  left: 0;
}

.sba_libs .hv-wrapper .hv-item .hv-item-children .hv-item-child:before {
  left: 50%;
  top: 0;
  transform: translateY(-100%);
  width: 1px;
  height: 25px;
}

.sba_libs .hv-wrapper .hv-item .hv-item-children .hv-item-child:after {
  top: -25px;
  transform: translateY(-100%);
  height: 1px;
  width: 100%;
}

.sba_libs .hv-wrapper .hv-item .hv-item-children .hv-item-child:first-child:after {
  left: 50%;
  width: 50%;
}

.sba_libs .hv-wrapper .hv-item .hv-item-children .hv-item-child:last-child:after {
  width: calc(50% + 1px);
}

.sba_libs .hv-wrapper .hv-item .hv-item-children .coborrower::before {
  display: none;
}

.sba_libs .hv-wrapper .hv-item .hv-item-children .coborrower::after {
  background-color: white;
  height: 10px;
  top: -22px;
  left: 5px;
}

.sba_libs .hv-wrapper.reverse .hv-item {
  flex-direction: column-reverse;
}

.sba_libs .hv-wrapper.reverse .hv-item .hv-item-parent {
  margin-top: 50px;
  margin-bottom: 0;
}

.sba_libs .hv-wrapper.reverse .hv-item .hv-item-parent:after {
  top: 0;
  transform: translateY(-100%);
}

.sba_libs .hv-wrapper.reverse .hv-item .hv-item-child {
  display: flex;
  align-items: flex-end;
}

.sba_libs .hv-wrapper.reverse .hv-item .hv-item-child:before {
  top: auto;
  bottom: 0;
  transform: translateY(100%);
}

.sba_libs .hv-wrapper.reverse .hv-item .hv-item-child:after {
  top: auto;
  bottom: -25px;
  transform: translateY(100%);
}

.sba_libs .hv-item-parent .firstParent {
  margin-left: -1px;
  background: #228848;
  padding: 7px 15px 22px 15px;
  display: block;
  font-size: 14px;
  font-weight: 400;
  border-radius: 9px 9px 0 0;
  color: #fff;
  margin-bottom: -15px;
  position: relative;
  z-index: 0;
}

.sba_libs .hv-item-parent .parnetinner {
  background: #fff;
  padding: 10px;
  position: relative;
  z-index: 2;
  display: block;
  border-radius: 10px 10px 10px 10px;
}

.sba_libs .childBox {
  position: relative;
  padding: 0px;
  color: #101820 !important;
}

.sba_libs .childBox::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #228848;
  display: block;
  width: 100%;
  height: 20px;
  border-radius: 9px 9px 0 0;
}

.sba_libs .childBox .child-heading {
  background: #fff;
  color: #101820;
  position: relative;
  z-index: 1;
  margin-top: 13px;
  padding: 15px 10px 10px 10px;
  font-size: 16px;
  border-radius: 9px 9px 0 0;
  font-weight: 400 !important;
}

.sba_libs .childBox .chartinnerdetails {
  padding: 10px;
  display: block;
}

.sba_libs .piyanoNav::after {
  height: 88% !important;
}

.sba_libs .gridView {
  border-bottom: 1px solid #F3F3F4;
}

.sba_libs .gridView .headRow {
  border-radius: 5px 5px 0 0 !important;
  -webkit-border-radius: 5px 5px 0 0 !important;
  -moz-border-radius: 5px 5px 0 0 !important;
}

.sba_libs .gridView .headRow + .contentRow #myGrid.ag-theme-balham {
  border-radius: 0 0 10px 10px !important;
  -webkit-border-radius: 0 0 10px 10px !important;
  -moz-border-radius: 0 0 10px 10px !important;
}

.sba_libs .gridView .headRow + .contentRow #myGrid.ag-theme-balham .ag-header {
  color: #101820;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
}

.sba_libs .gridView .ag-cell-wrap-text,
.sba_libs .gridView .ag-cell-wrapper {
  white-space: normal;
  word-break: normal;
}

.sba_libs .treeStructure {
  max-width: 1240px;
}

.sba_libs .treeStructure .modal-header {
  color: #101820;
  border: none;
  background: none;
}

.sba_libs .treeStructure .modal-header .close {
  color: #101820;
}

.sba_libs .treeStructure .coborrowerTrue {
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sba_libs .treeStructure .icon.icon-company {
  background: url(../base/images/sprite-icons-assisted-journey.svg) -182px -377px no-repeat;
  margin-right: 5px;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  display: inline-block;
}

.sba_libs .treeStructure .icon.iconindividual {
  background: url(../base/images/sprite-icons-assisted-journey.svg) -236px -328px no-repeat;
  margin-right: 5px;
  margin-bottom: 3px;
  vertical-align: middle;
  width: 25px;
  height: 22px;
  display: inline-block;
}

.sba_libs .treeStructure .icon.iconbusiness {
  background: url(../base/images/sprite-icons-assisted-journey.svg) -621px -328px no-repeat;
  margin-right: 5px;
  margin-bottom: 3px;
  vertical-align: middle;
  width: 25px;
  height: 22px;
  display: inline-block;
}

.sba_libs .treeStructure .icon.confirmed {
  padding-left: 30px;
  position: relative;
  font-size: 12px;
}

.sba_libs .treeStructure .icon.confirmed::after {
  background: url(../base/images/sprite-icons-assisted-journey.svg) -297px -377px no-repeat;
  content: "";
  position: absolute;
  top: -4px;
  left: 0px;
  width: 22px;
  height: 22px;
}

.sba_libs .editIcon2, .sba_libs .deleteIcon2, .sba_libs .infoicon-blue, .sba_libs .infoicon-blue, .sba_libs .treeIcon {
  cursor: pointer;
  display: inline-block;
}

.sba_libs .editIcon2 {
  background: url(../base/images/sprite-icons-assisted-journey.svg) no-repeat -587px -332px;
  width: 16px;
  height: 15px;
  position: relative;
  top: 2px;
}

.sba_libs .deleteIcon2 {
  background: url(../base/images/sprite-icons-assisted-journey.svg) no-repeat -396px -332px;
  width: 16px;
  height: 15px;
}

.sba_libs .deleteIcon3 {
  background: url(../base/images/sprite-icons-assisted-journey.svg) no-repeat -396px -329px;
  width: 16px;
  height: 15px;
}

.sba_libs .lock2 {
  background: url(../base/images/sprite-icons-assisted-journey.svg) no-repeat -426px -380px;
  width: 21px;
  height: 20px;
}

.sba_libs .sendConsentIcon {
  background: url(../base/images/sprite-icons-assisted-journey.svg) no-repeat -1038px -328px;
  width: 17px;
  height: 21px;
  cursor: pointer;
  display: inline-block;
  padding-left: 20px;
}

.sba_libs .statusIcon, .sba_libs .statusIcon1 {
  padding-left: 30px;
  position: relative;
  font-size: 13px;
  white-space: nowrap;
  color: #25a0c5;
  text-decoration: none !important;
}

.sba_libs .statusIcon.confirmed:after, .sba_libs .statusIcon1.confirmed:after {
  background: url(../base/images/sprite-icons-assisted-journey.svg) -767px -329px no-repeat;
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
  width: 20px;
  height: 20px;
}

.sba_libs .statusIcon.sent:after, .sba_libs .statusIcon1.sent:after {
  background: url(../base/images/sprite-icons-assisted-journey.svg) -838px -327px no-repeat;
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
  width: 22px;
  height: 22px;
}

.sba_libs .nrIcon {
  position: relative;
  font-size: 13px;
  white-space: nowrap;
  color: #25a0c5;
  text-decoration: none !important;
}

.sba_libs .nrIcon.confirmed:after {
  background: url(../base/images/sprite-icons-assisted-journey.svg) -767px -329px no-repeat;
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
  width: 20px;
  height: 20px;
}

.sba_libs .nrIcon.sent:after {
  background: url(../base/images/sprite-icons-assisted-journey.svg) -838px -327px no-repeat;
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
  width: 22px;
  height: 22px;
}

.sba_libs .threeDote {
  padding: 0 5px;
  position: absolute;
  top: -5px;
  right: 20px;
  cursor: pointer;
}

.sba_libs .threeDote .dote {
  background-color: #228848;
  margin-bottom: 2px;
  width: 4px;
  height: 4px;
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  display: block;
}

.sba_libs app-your_business_info .dropleft .threeDote {
  top: 12px;
}

.sba_libs platform-core-action-cell-renderer .threeDote + .dropdown-menu {
  padding: 0;
  top: -2px !important;
}

.sba_libs platform-core-action-cell-renderer .threeDote + .dropdown-menu .dropdown-item {
  padding: 5px;
}

.sba_libs app-your_business_info platform-core-action-cell-renderer .deleteIcon2 {
  background-position: -395px -330px;
  margin: 0 5px 0 2px;
}

.sba_libs app-your_business_info platform-core-action-cell-renderer .sendConsentIcon {
  margin-right: 5px;
}

.sba_libs .thankyouWrap {
  padding-bottom: 30px;
}

.sba_libs .thankyouWrap .mainTitle {
  color: #228848 !important;
  font-size: 36px !important;
}

.sba_libs .thankyouWrap .subTitle {
  font-size: 22px !important;
}

.sba_libs .thankyouWrap .contentBlock {
  font-size: 22px !important;
  color: #101820;
}

.sba_libs .thankyouWrap .btn-primary {
  background: #6fb253 !important;
  border-color: #6fb253 !important;
}

.sba_libs .thankyouWrap .btn-primary:hover {
  background: #6fb253 !important;
  border-color: #6fb253 !important;
}

.sba_libs .thankyouWrap .btn-primary:focus {
  background: #6fb253 !important;
  border-color: #6fb253 !important;
  box-shadow: none;
}

.sba_libs .thankyouWrap .btn-primary:active {
  background: #6fb253 !important;
  border-color: #6fb253 !important;
  box-shadow: none !important;
}

.sba_libs .countryOne rubicon-text {
  display: flex;
}

.sba_libs .notewrap {
  max-width: 100%;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border: 1px solid #4093C3;
  background: rgba(61, 61, 61, 0.08);
  padding: 25px;
  width: 325px;
}

.sba_libs h1.page-title, .sba_libs .page-title {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #101820;
}

.sba_libs .estimatedRow rubicon-amount {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.sba_libs .p-label {
  color: #101820;
  font-size: 13px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  line-height: 21px;
  margin-bottom: 3px;
}

.sba_libs .countriesExport > rubicon-group {
  display: flex !important;
}

.sba_libs .countriesExport > rubicon-group > .sba-primarycountries_export_arr:first-child .form-group {
  width: 535px;
}

.sba_libs .countriesExport > rubicon-group > .sba-primarycountries_export_arr:first-child .form-group .col-11.ml-auto {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.sba_libs .countriesExport > rubicon-group > .sba-primarycountries_export_arr:first-child .p-label {
  display: block !important;
  padding-right: 0;
}

.sba_libs .positionset {
  position: relative;
}

.sba_libs .positionset .deleteIcon {
  position: absolute;
  right: 15px;
  top: -5px !important;
}

.sba_libs .positionset:not(:first-child) .forHide {
  visibility: hidden;
}

.sba_libs about-business .btnMultib .btn-default {
  background-color: transparent !important;
  border-color: #228848 !important;
  color: #228848 !important;
}

.sba_libs borrower-information .btnMultib .btn-default {
  background-color: transparent !important;
  border-color: #228848 !important;
  color: #228848 !important;
}

.sba_libs application-view h2 {
  font-size: 15px;
  font-weight: 600;
}

.sba_libs application-view h2 button {
  border: none;
  font-size: 15px;
  font-weight: 600;
  background: none;
}

.sba_libs application-view .accordion .card {
  margin-bottom: 10px;
  border: none;
}

.sba_libs application-view .accordion .card .card-header {
  background-color: #E9F7EF;
  border: 1px solid #CFCFCF;
  border-radius: 5px;
}

.sba_libs application-view .accordion .card .card-header button {
  padding: 0;
  color: #101820 !important;
  text-decoration: none;
}

.sba_libs application-view .accordion .card .card-header button::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 17px;
  width: 20px;
  height: 10px;
  display: inline-block;
  background: url(../base/images/sprite-icons-assisted-journey.svg) -58px -332px no-repeat;
  transform: rotate(180deg);
}

.sba_libs application-view .accordion .card .card-header button.collapsed::after {
  display: inline-block;
  background: url(../base/images/sprite-icons-assisted-journey.svg) -58px -332px no-repeat;
  transform: rotate(0deg);
}

.sba_libs .search-result {
  top: 75px;
  width: 96%;
  background: #fff;
  -moz-box-shadow: 0 2px 35px 0 rgba(145, 145, 145, 0.28);
  -webkit-box-shadow: 0 2px 35px 0 rgba(145, 145, 145, 0.28);
  box-shadow: 0 2px 35px 0 rgba(145, 145, 145, 0.28);
  border-radius: 0 0 10px 10px !important;
  -webkit-border-radius: 0 0 10px 10px !important;
  -moz-border-radius: 0 0 10px 10px !important;
  margin-top: -3px;
}

.sba_libs .search-result .srList li {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 30px;
  border-bottom: 1px solid #CFD1D2;
  cursor: pointer;
}

.sba_libs .search-result .srList li a {
  font-size: 14px;
  color: #101820;
  cursor: pointer;
  line-height: 30px;
}

.sba_libs .search-result .srList li:last-child {
  border-bottom: none;
}

.sba_libs .search-result .srList li:hover {
  background-color: #fafafa;
}

.sba_libs application-view .backendAccordion, .sba_libs .uploadDocTitle + .backendAccordion, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion {
  padding-top: 1.5rem !important;
}

.sba_libs application-view .backendAccordion .wbg.docWgtMain, .sba_libs .uploadDocTitle + .backendAccordion .wbg.docWgtMain, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .wbg.docWgtMain {
  box-shadow: none;
  background: #FBFBFB;
  padding: 0;
}

.sba_libs application-view .backendAccordion .wbg.docWgtMain .dockFname, .sba_libs .uploadDocTitle + .backendAccordion .wbg.docWgtMain .dockFname, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .wbg.docWgtMain .dockFname {
  padding: 10px 0;
}

.sba_libs application-view .backendAccordion .wbg.docWgtMain .ptc.fs13.dflex1, .sba_libs .uploadDocTitle + .backendAccordion .wbg.docWgtMain .ptc.fs13.dflex1, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .wbg.docWgtMain .ptc.fs13.dflex1 {
  font-size: 14px !important;
  color: #101820 !important;
  padding-right: 165px;
}

.sba_libs application-view .backendAccordion .wbg.docWgtMain .docFilesWgtInner, .sba_libs .uploadDocTitle + .backendAccordion .wbg.docWgtMain .docFilesWgtInner, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .wbg.docWgtMain .docFilesWgtInner {
  padding-left: 30px;
}

.sba_libs application-view .backendAccordion .wbg.docWgtMain .docFiles1, .sba_libs .uploadDocTitle + .backendAccordion .wbg.docWgtMain .docFiles1, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .wbg.docWgtMain .docFiles1 {
  background: #fff;
  border: 1px solid #F5F5F5;
  border-radius: 0 0 2px 2px;
}

.sba_libs application-view .backendAccordion .wbg.docWgtMain .docFiles1 .docFiles2 .docFiles7:last-child, .sba_libs .uploadDocTitle + .backendAccordion .wbg.docWgtMain .docFiles1 .docFiles2 .docFiles7:last-child, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .wbg.docWgtMain .docFiles1 .docFiles2 .docFiles7:last-child {
  border-bottom: none !important;
}

.sba_libs application-view .backendAccordion .docFilesWgtInner4, .sba_libs .uploadDocTitle + .backendAccordion .docFilesWgtInner4, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .docFilesWgtInner4 {
  font-size: 12px !important;
  padding-top: 0 !important;
  padding-left: 12px !important;
}

.sba_libs application-view .backendAccordion .card, .sba_libs .uploadDocTitle + .backendAccordion .card, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .card {
  border: none;
  background: none;
}

.sba_libs application-view .backendAccordion .card .card-body, .sba_libs .uploadDocTitle + .backendAccordion .card .card-body, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .card .card-body {
  padding: 0px 0px 15px 0px;
  border: none;
}

.sba_libs application-view .backendAccordion .card .card-header, .sba_libs .uploadDocTitle + .backendAccordion .card .card-header, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .card .card-header {
  background: none;
  border-bottom: none;
  padding-left: 0px;
  padding-right: 0px;
}

.sba_libs application-view .backendAccordion .card .card-header .plusMinusIcon, .sba_libs .uploadDocTitle + .backendAccordion .card .card-header .plusMinusIcon, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .card .card-header .plusMinusIcon {
  position: absolute;
  right: 16px;
  top: -3px;
  font-size: 35px;
  color: #fff;
  font-size: 0px;
}

.sba_libs application-view .backendAccordion .card .card-header::after, .sba_libs .uploadDocTitle + .backendAccordion .card .card-header::after, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .card .card-header::after {
  content: none;
}

.sba_libs application-view .backendAccordion .sub-accordion .card .card-body, .sba_libs .uploadDocTitle + .backendAccordion .sub-accordion .card .card-body, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .sub-accordion .card .card-body {
  border: none;
}

.sba_libs application-view .backendAccordion .sub-accordion .card .card-header, .sba_libs .uploadDocTitle + .backendAccordion .sub-accordion .card .card-header, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .sub-accordion .card .card-header {
  background: transparent;
  padding: 10px 15px;
  border-radius: 0px !important;
}

.sba_libs application-view .backendAccordion .sub-accordion .card .card-header .plusMinusIcon, .sba_libs .uploadDocTitle + .backendAccordion .sub-accordion .card .card-header .plusMinusIcon, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .sub-accordion .card .card-header .plusMinusIcon {
  position: absolute;
  right: 0;
  top: -3px;
  font-size: 35px;
  color: #6fb253;
  font-size: 0px;
}

.sba_libs application-view .backendAccordion .docAccorWgtPanel .docAccorWgtRow .col-12.col-lg.d-flex, .sba_libs .uploadDocTitle + .backendAccordion .docAccorWgtPanel .docAccorWgtRow .col-12.col-lg.d-flex, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .docAccorWgtPanel .docAccorWgtRow .col-12.col-lg.d-flex {
  padding-right: 0px !important;
}

.sba_libs application-view .backendAccordion .docAccorWgtPanel .docAccorWgtRow .col-12.col-lg.d-flex .attachment-doc, .sba_libs .uploadDocTitle + .backendAccordion .docAccorWgtPanel .docAccorWgtRow .col-12.col-lg.d-flex .attachment-doc, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .docAccorWgtPanel .docAccorWgtRow .col-12.col-lg.d-flex .attachment-doc {
  top: 0px;
}

.sba_libs application-view .backendAccordion .docAccorWgtPanel .docAccorWgtRow .col-12.col-lg-auto.text-right .btn.docUploadWgt2, .sba_libs .uploadDocTitle + .backendAccordion .docAccorWgtPanel .docAccorWgtRow .col-12.col-lg-auto.text-right .btn.docUploadWgt2, .sba_libs .uploadDocTitle + .docfilterMandatory + .backendAccordion .docAccorWgtPanel .docAccorWgtRow .col-12.col-lg-auto.text-right .btn.docUploadWgt2 {
  margin-left: 0px !important;
  display: none !important;
}

.sba_libs .attachment-doc {
  float: right;
  color: #878787;
  background: none;
  border: 0.1em solid #878787;
  border-radius: 50px !important;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  position: absolute;
  right: 0px;
  top: 0;
  padding: 3px 15px 3px 10px;
  cursor: pointer;
}

.sba_libs .attachment-doc span {
  margin-right: 3px;
  background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -823px -379px;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}

.sba_libs .docEmptyClass {
  margin-right: 10px;
  margin-top: 4px;
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -494px -326px;
}

.sba_libs .docUploadedClass {
  margin-right: 10px;
  margin-top: 3px;
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url(/assets/base/images/sprite-icons-assisted-journey.svg) no-repeat -191px -326px;
}

.sba_libs .dflex1 {
  flex: 1;
  position: relative;
  padding-right: 130px;
}

.sba_libs lib-document-listing-item {
  padding: 0px 0px !important;
  display: block;
}

.sba_libs .no_child_docs .attachment-doc {
  display: none !important;
}

.sba_libs .no_child_docs.attachment-doc {
  display: none !important;
}

.sba_libs .uploadBtnWgt {
  padding: 3px 20px;
  font-weight: 500;
  font-size: 14px;
}

.sba_libs .uploadBtnWgt span {
  margin: 0 !important;
}

.sba_libs .btn.docUploadWgt2 {
  font-size: 12px !important;
  padding: 4px 15px 5px 10px !important;
  font-weight: 500 !important;
  background: #4093C3 !important;
  border: #4093C3 !important;
  margin-left: 10px;
  display: none !important;
}

.sba_libs .btn.docUploadWgt2 .docUploadWgt3 {
  margin-left: 0 !important;
}

.sba_libs .btn.docUploadWgt2 .docUploadWgt3 .link_bank_icon {
  margin-right: 3px;
  background: url(/assets/base/images/sprite-icons.svg) no-repeat -282px -153px;
  border-right: 1px solid #fff;
  width: 23px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}

.sba_libs .dragndropdocWgt {
  background-color: rgba(61, 61, 61, 0.08) !important;
  border: 1px dashed #4093C3 !important;
  border-radius: 7px !important;
  -webkit-border-radius: 7px !important;
  -moz-border-radius: 7px !important;
}

.sba_libs .dragndropdocWgt .dragndropdocWgtWrapper2 {
  overflow: hidden;
}

.sba_libs .dragndropdocWgt .dragndropdocWgtWrapper2 .dragndropdocWgtIcon {
  width: 39%;
  justify-content: flex-end !important;
  display: flex !important;
  float: left;
}

.sba_libs .dragndropdocWgt .dragndropdocWgtWrapper2 .dragndropdocWgtIcon .dragndropdocWgtIcon2 {
  width: 30px;
  height: 28px;
  margin: 13px 30px 0 0;
  display: inline-block;
  background: url(../base/images/sprite-icons-assisted-journey.svg) no-repeat -663px -329px;
}

.sba_libs .dragndropdocWgt .dragndropdocWgtWrapper2 .rightBodyDragDrop {
  float: left;
  width: 50%;
}

.sba_libs .dragndropdocWgt .dragndropdocWgtWrapper2 .rightBodyDragDrop .dragndropdocWgtheading {
  font-size: 14px;
  font-weight: 600;
  color: #4093C3;
  padding: 10px 0 2px;
  margin: 0;
}

.sba_libs .dragndropdocWgt .dragndropdocWgtWrapper2 .rightBodyDragDrop .dragndropdocWgtLabel {
  font-size: 12px;
  font-weight: 400;
  color: #4093C3;
  margin-bottom: 8px;
  pointer-events: none;
}

.sba_libs .common-file {
  background: url(../base/images/sprite-icons.svg) no-repeat -284px -102px;
  width: 11px;
  height: 14px;
  padding-left: 11px;
  margin-top: 6px;
}

.sba_libs .connectBanker {
  background: url(../base/images/sprite-icons.svg) no-repeat -233px -300px;
  width: 23px;
  height: 27px;
}

.sba_libs .downloadIcon {
  background: url(../base/images/sprite-icons.svg) no-repeat -358px -102px;
  width: 16px;
  height: 14px;
  cursor: pointer;
  display: inline-block;
}

.sba_libs .docDeleteWgtModal3 .btn-md {
  padding: 5px 20px;
}

.sba_libs backend-document-upload .btn-default {
  background-color: transparent !important;
  border-color: #06620e;
}

.sba_libs backend-document-upload .tooltip-inner {
  min-width: 80px;
}

.sba_libs .list-styled {
  list-style: disc;
  margin-left: 10px;
}

.sba_libs .ag-body-horizontal-scroll {
  max-height: 0px !important;
  height: 0px !important;
  min-height: 0px !important;
}

@media (max-width: 992px) {
  .sba_libs .order-1.sidebar-r {
    display: none;
  }
}

@media (max-width: 767px) {
  .sba_libs .commonTitle {
    padding: 0;
    text-align: center;
  }
  .sba_libs .commonTitle .page-title {
    padding: 140px 0 0 0 !important;
    font-size: 20px;
  }
  .sba_libs .commonTitle::after {
    margin-left: -96px;
    left: 50%;
  }
  .sba_libs .commonTitle.onlyTitle .page-title {
    padding: 140px 0 0 0 !important;
    font-size: 20px;
  }
  .sba_libs .commonTitle.onlyTitle .page-title + .fs14.fwm {
    display: none;
  }
  .sba_libs .commonTitle.onlyTitle::after {
    margin-left: -100px;
    left: 50%;
    width: 200px;
  }
  .sba_libs .btnMultib .btn-default {
    margin-bottom: 5px;
  }
  .sba_libs .backIcon::after {
    top: 13px;
  }
  .sba_libs .thankyouWrap .contentBlock {
    font-size: 16px !important;
  }
  .sba_libs .loanPurDetails #purpose_arr_rubiconField rubicon-group .loanBlockRow .deleteIcon {
    right: 0 !important;
    width: 41px;
  }
  .sba_libs .loanPurDetails #purpose_arr_rubiconField rubicon-group .loanBlockRow .form-control {
    max-width: 90%;
  }
  .sba_libs .loanPurDetails #purpose_arr_rubiconField rubicon-group .addMoreBtn {
    position: relative;
    right: 0;
    top: 0px;
    width: 75px !important;
  }
}

.sba_libs .eyeIcon {
  position: relative;
}

.sba_libs .eyeIcon .form-control {
  padding-right: 28px;
}

.sba_libs .eyeIcon .eye {
  position: absolute;
  right: 30px;
  top: 37px;
  cursor: pointer;
  background: url(../base/images/sprite-icons.svg) no-repeat -534px -147px;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.sba_libs .eyeIcon .eye.eyeclose {
  background-position: -580px -147px;
}

.sba_libs .passpolicywrap .passpolicy {
  display: none;
}

.sba_libs .secureInfo {
  background: #fff;
  border: 1px solid #6fb253;
}

.sba_libs .secureInfo .lock2 {
  background: url(../base/images/sprite-icons-assisted-journey.svg) no-repeat -425px -378px;
  width: 23px;
  height: 22px;
  vertical-align: top;
}

.sba_libs .agreeTncCheck div.fs14 {
  order: 2;
  cursor: pointer;
}

.sba_libs .agreeTncCheck div.fs14 .agreeConsentModal {
  color: #0303c1 !important;
  font-weight: 500;
  font-style: italic;
  font-size: 14px;
  text-decoration: underline;
}

.sba_libs .tooltip {
  pointer-events: all !important;
}

.sba_libs .consentReceived {
  min-height: calc(100vh - 180px);
}

.sba_libs affiliate-information .tooltip-inner {
  min-width: 150px;
}

.sba_libs app-owner-details .tooltip-inner {
  min-width: 250px;
}

.sba_libs .mainWraper {
  min-height: calc(100vh - 80px);
}

.sba_libs .lexis_nexis_visible .tooltip-inner {
  min-width: 250px;
}

.sba_libs .doclupload .eye {
  top: 0px;
  right: -21px;
}

.sba_libs application-view .backendAccordion .downloadIcon {
  margin-right: 30px;
}

.pfsAccordion .singlelinefield rubicon-amount {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.pfsAccordion .nav-tabs {
  margin-bottom: 30px;
  display: block;
  text-align: center;
  border-width: 2px;
}

.pfsAccordion .nav-tabs .nav-item {
  display: inline-block;
  margin-bottom: -1px;
}

.pfsAccordion .nav-tabs .nav-item .nav-link {
  border: none;
  pointer-events: none;
  color: #101820;
  padding: 0.6rem 2rem;
  font-size: 16px;
}

.pfsAccordion .nav-tabs .nav-item .nav-link.active {
  border-bottom: 2px solid #6fb253;
  color: #6fb253;
  font-size: 16px;
  font-weight: 500;
  padding: 0.6rem 2rem;
}

.pfsAccordion accordion-group.panel {
  margin-bottom: 1rem;
}

.pfsAccordion accordion-group.panel .card .card-header {
  background-color: transparent;
}

.pfsAccordion accordion-group.panel .card .card-header .plusMinusIcon {
  top: -5px;
}

.pfsAccordion accordion-group.panel .card .card-body {
  border: none;
  padding: 5px 0 15px 0;
}

.pfsAccordion .pfsSpace {
  padding: 15px 15px;
  margin-bottom: 0 !important;
}

.pfsAccordion .hideLabel {
  display: none;
}

.pfsAccordion .pfs-row-grey {
  background-color: rgba(42, 81, 53, 0.25);
  background: #fff;
}

.pfsAccordion .border,
.pfsAccordion .border-bottom,
.pfsAccordion .border-top,
.pfsAccordion .border-left,
.pfsAccordion .border-right {
  border-color: #eeeeee !important;
}

.pfsAccordion .actionDelete .form-control {
  width: 73%;
}

.pfsAccordion .actionDelete #account_type_1680612981895 {
  width: 73%;
}

.pfsAccordion .borderSpousede {
  border: 1px solid #6fb253 !important;
  background-color: #fff;
}

.pfsAccordion .borderp rubicon-group .deletePfs,
.pfsAccordion .borderp rubicon-group .positionset {
  border-right: 1px solid #6fb253 !important;
  border-left: 1px solid #6fb253 !important;
  background-color: #fff;
}

.pfsAccordion .borderp rubicon-group .form-group {
  margin-bottom: 0;
}

.pfsAccordion .borderp .laliwrap {
  border-right: 1px solid #6fb253 !important;
  border-bottom: 1px solid #6fb253 !important;
  background-color: #fff;
}

.pfsAccordion .borderp .laliwrap > rubicon-group {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.pfsAccordion .borderp .laliwrap .deletePfs {
  border-right: none !important;
}

.pfsAccordion .borderp .laliwrap .addMoreBtn {
  width: 100%;
  border-top: 1px solid #9FA3A6 !important;
  margin-top: -1px;
  border-right: none !important;
  border-bottom: none;
}

.pfsAccordion .borderp .laliwrap .deletePfs:nth-child(10) {
  border-bottom: none !important;
}

.pfsAccordion .infoText {
  cursor: pointer;
  margin-top: -2px;
  background: url(../base/images/sprite-icons.svg) no-repeat -233px -266px;
  width: 21px;
  height: 20px;
  display: inline-block;
}

.pfsAccordion .tab-content .tooltip-inner {
  text-align: left;
}

.pfsAccordion .tab-content .radioWrap {
  display: flex !important;
  margin: 0;
  padding-right: 0;
  padding-left: 0;
}

.pfsAccordion .in12nopadd rubicon-number > .col-12 {
  padding: 0;
}

.pfsAccordion .notePayable {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.pfsAccordion .notePayable .maxwidth {
  width: 1640px;
}

.pfsAccordion .notePayable .maxwidth .col-2 {
  flex: 0 0 16.66%;
  max-width: 18.999%;
}

.pfsAccordion .stocksBonds {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.pfsAccordion .stocksBonds .maxwidth {
  width: 1640px;
}

.pfsAccordion .stocksBonds .maxwidth .col-2 {
  flex: 0 0 16.66%;
  max-width: 18.999%;
}

.pfsAccordion .scrollxRealEsta {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.pfsAccordion .scrollxRealEsta .maxwidth {
  width: 2500px;
}

.pfsAccordion .scrollxRealEsta .maxwidth .col-2 {
  flex: 0 0 9.999%;
  max-width: 9.999%;
}

@media (max-width: 640px) {
  .pfsAccordion .scrollxResponsive {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .pfsAccordion .scrollxResponsive .maxwidth {
    width: 1000px;
  }
  .pfsAccordion .scrollxResponsive .maxwidth .col-lg-2 {
    flex: 0 0 10.666667%;
    max-width: 10.666667%;
  }
}

.pfsAccordion .deletePfs + .addMoreBtn {
  border: 1px solid #6fb253;
  border-top: none;
  padding-bottom: 15px;
  padding-right: 15px;
  background: #fff;
}

.pfsAccordion .deletePfs {
  position: relative;
}

.pfsAccordion .deletePfs .deleteIcon {
  position: absolute;
  right: 10px;
  top: 2px !important;
}

.pfsAccordion .deletePfs .deleteIcon::after {
  display: none;
  content: "";
  position: absolute;
  left: -19px;
  top: -15px;
  width: 1px;
  height: 177%;
  background: #eeeeee !important;
}

.pfsAccordion .notesPayForm .deleteIcon,
.pfsAccordion .stockBondForm .deleteIcon {
  right: 10px;
}

.pfsAccordion .notesPayForm .deleteIcon::after,
.pfsAccordion .stockBondForm .deleteIcon::after {
  display: none;
}

.pfsAccordion .positionset {
  position: relative;
}

.pfsAccordion .positionset .deleteIcon {
  position: absolute;
  right: -60px;
  top: 0px;
}

.pfsAccordion .positionset:not(:first-child) .forHide {
  visibility: hidden;
}

.pfsAccordion .notesPayForm .actionDelete .form-control,
.pfsAccordion .stockBondForm .actionDelete .form-control,
.pfsAccordion .uppaidForm .actionDelete .form-control {
  width: 73%;
}

.pfsAccordion .notesPayForm .deleteIcon,
.pfsAccordion .stockBondForm .deleteIcon,
.pfsAccordion .uppaidForm .deleteIcon {
  right: 10px;
}

.pfsAccordion .notesPayForm .deleteIcon::after,
.pfsAccordion .stockBondForm .deleteIcon::after,
.pfsAccordion .uppaidForm .deleteIcon::after {
  display: none;
}

.pfsSpace {
  padding: 15px 15px;
  margin-bottom: 0 !important;
}

platform-core-pfs .card-header.panel-enabled {
  cursor: default;
}

platform-core-pfs .accordionwrap .pfsrow {
  border-bottom: 1px solid #dee2e6 !important;
}

platform-core-pfs .accordionwrap .pfsrow > form > .form-group {
  margin-bottom: 0px;
}

platform-core-pfs .accordionwrap .accordionLavel1 {
  margin-bottom: 0 !important;
}

platform-core-pfs .accordionwrap .accordionLavel1 .accLavelHedding1:before {
  background-position: -549px -98px;
}

platform-core-pfs .accordionwrap .accordionLavel1 .accordionLavel2 {
  margin-top: 0px !important;
  margin-bottom: 0 !important;
}

platform-core-pfs .accordionwrap .accordionLavel1 .accordionLavel2 .infoTip {
  top: -3px;
}

platform-core-pfs .accordionwrap .accordionLavel1 .card-body {
  padding-bottom: 0 !important;
}

platform-core-pfs .accordionwrap .accordionLavel1.panel-open {
  border-bottom: 1px solid 1px solid rgba(0, 0, 0, 0.125);
}

platform-core-pfs .accordionwrap .accordionLavel1 .accordionLavel2 .accLavelHedding2 {
  padding-left: 0;
}

platform-core-pfs .accordionwrap .accordionLavel1 .accordionLavel2 .accLavelHedding2:before {
  display: none;
}

.stocksBonds {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.stocksBonds .maxwidth {
  width: 1640px;
}

.stocksBonds .maxwidth .col-2 {
  flex: 0 0 16.66%;
  max-width: 18.999%;
}

.notesPayForm .deleteIcon,
.stockBondForm .deleteIcon {
  right: 10px;
}

.notesPayForm .deleteIcon::after,
.stockBondForm .deleteIcon::after {
  display: none;
}

.greenbg {
  background: #6fb253;
}

.PfsDate {
  position: relative;
}

.PfsDate .calendar {
  top: 26px;
}

.pfs-row-height {
  border-bottom: 1px solid #CFD1D2;
}

.accordionwrap .panel .card {
  border: 0;
  padding: 10px 0;
}

.accordionwrap .panel .card .card-header {
  background: #fff;
  border: 0;
  padding: 0px;
}

.accordionwrap .panel .card .card-body {
  padding: 10px 0px 0px 0px;
}

.accordionwrap .accordionLavel1 .accLavelHedding1 {
  font-size: 14px;
  color: #101820;
  font-weight: 500;
  padding-left: 2.2rem;
}

.accordionwrap .accordionLavel1 .accLavelHedding1.no_toggle_icon {
  padding-left: 0px !important;
}

.accordionwrap .accordionLavel1 .accLavelHedding1.no_toggle_icon::before {
  display: none;
}

.accordionwrap .accordionLavel1 .accLavelHedding1.disable_cursor {
  cursor: default;
}

.accordionwrap .accordionLavel1 .accLavelHedding1.disable_cursor::before {
  cursor: not-allowed;
}

.accordionwrap .accordionLavel1 .accLavelHedding1 .infoTip {
  position: relative;
  top: -1px;
}

.accordionwrap .accordionLavel1 .accLavelHedding1::before {
  content: "";
  transition: all 0.5s;
  background: url(../base/images/sprite-icons.svg) no-repeat -538px -327px;
  width: 23px;
  height: 23px;
  position: absolute;
  left: 0;
  top: 8px;
}

.accordionwrap .accordionLavel1.panel-open .accLavelHedding1::before {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  transform: rotate(-180deg);
  background: url(../base/images/sprite-icons.svg) no-repeat -536px -326px;
}

.accordionwrap .accordionLavel1:first-child {
  margin-top: 15px;
}

.accordionwrap .accordionLavel1:last-child {
  margin-bottom: 10px;
}

.accordionwrap .accordionLavel1 .accordionLavel2 .accLavelHedding2 {
  font-size: 14px;
  color: #101820;
  font-weight: 500;
  padding-left: 2.2rem;
}

.accordionwrap .accordionLavel1 .accordionLavel2 .accLavelHedding2 .infoTip {
  position: relative;
  top: -1px;
}

.accordionwrap .accordionLavel1 .accordionLavel2 .accLavelHedding2::before {
  content: "";
  transition: all 0.5s;
  background: url(../base/images/sprite-icons.svg) no-repeat -538px -327px;
  width: 23px;
  height: 23px;
  position: absolute;
  left: 0;
  top: 8px;
}

.accordionwrap .accordionLavel1 .accordionLavel2.panel-open .accLavelHedding2::before {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  transform: rotate(-180deg);
  background: url(../base/images/sprite-icons.svg) no-repeat -536px -326px;
}

.accordionwrap .accordionLavel1 .accordionLavel2:first-child {
  margin-top: 15px;
}

.accordionwrap .accordionLavel1 .accordionLavel2:last-child {
  margin-bottom: 10px;
}

.notePayable .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 85px !important;
}

installment-account .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 85px !important;
}

.sba_libs .collDropdown .btn-primary {
  background: #fff !important;
  border-color: #228848 !important;
  color: #228848 !important;
}

.deleteIconWrap {
  position: absolute;
  top: 11px;
  right: 0;
}

rubicon-group .deletePfs:nth-of-type(10) .ng-dropdown-panel {
  top: auto;
  bottom: 100%;
  border-radius: 10px 10px 0 0 !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1260px;
  }
  .yourBusinessTable .order-2.col-xl-7 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
}

.ownersBusinessTitle {
  position: relative;
  padding: 0 0 0 190px;
}

.ownersBusinessTitle:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
  height: 126px;
  background: url(../base/images/sprite-icons.svg) no-repeat -434px -13px;
}

@media (max-width: 1300px) {
  .countryCode {
    max-width: 13.667%;
  }
  .emibg .text36 {
    font-size: 30px;
  }
}

@media (max-width: 1250px) {
  .cblock {
    position: unset;
    width: 100% !important;
  }
  .opportunity .owl-carousel .owl-nav .owl-next {
    margin-right: -11px;
  }
  .opportunity .owl-carousel .owl-nav .owl-prev {
    margin-left: -11px;
  }
}

@media (max-width: 1024px) {
  .fs50 {
    font-size: 36px !important;
    line-height: normal;
  }
  .fs40 {
    font-size: 30px !important;
  }
  .fs20 {
    font-size: 16px !important;
  }
  .lh {
    line-height: 23px;
  }
  .fs26 {
    font-size: 21px;
  }
  .fs24 {
    font-size: 19px;
  }
  .fs18 {
    font-size: 14px !important;
  }
  .lh36 {
    line-height: 24px !important;
  }
  .dashboardContent.acl > .container > .row.justify-content-md-center .col-10 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .dashboardContent.acl .user-mgmt-section .row.justify-content-md-center .col-7 h2 {
    font-size: 18px;
  }
  .dashboardContent.acl .user-mgmt-section .row.justify-content-md-center .col-5 {
    padding-left: 0;
  }
  .dashboardContent.acl .user-mgmt-section .row.justify-content-md-center .col-5.text-right .btn {
    font-size: 11px;
    padding: 9px 10px;
    min-width: 0;
    margin-top: 5px;
  }
  .opportunity .owl-carousel .owl-nav .owl-next {
    margin-right: -31px;
  }
  .opportunity .owl-carousel .owl-nav .owl-prev {
    margin-left: -31px;
  }
}

@media (max-width: 767px) {
  .commonTitle {
    padding: 0;
    text-align: center;
  }
  .commonTitle .page-title {
    padding: 140px 0 0 0 !important;
    font-size: 20px;
  }
  .commonTitle::after {
    margin-left: -76px;
    left: 50%;
  }
  .commonTitle.onlyTitle .page-title {
    padding: 140px 0 0 0 !important;
    font-size: 20px;
  }
  .commonTitle.onlyTitle .page-title + .fs14.fwm {
    display: none;
  }
  .commonTitle.onlyTitle::after {
    margin-left: -76px;
    left: 50%;
    width: 160px;
  }
  .mainWraper {
    padding-bottom: 0;
  }
  .logoRow .justify-content-end .py-2 {
    padding-top: 19px !important;
  }
  .bookAppoint::after {
    top: 7px;
    right: 39px;
  }
  .bookAppoint .btn .dobicon::after {
    top: 10px;
    right: 27px;
  }
  .businessFunForm .form-control {
    padding: 6px !important;
    font-size: 16px;
  }
  .businessFunForm .infoTip {
    top: 7px;
  }
  .dashRightBtn .btn {
    margin-bottom: 10px;
  }
  .PfsDate .calendar {
    top: 23px;
  }
  .yodleeWidget .yodleeWrapper {
    display: block !important;
  }
  .opportunity .owl-carousel .owl-nav .owl-next {
    margin-right: -11px;
    font-size: 0;
  }
  .opportunity .owl-carousel .owl-nav .owl-prev {
    margin-left: -11px;
    font-size: 0;
  }
}

@media (max-width: 640px) {
  .wbg {
    padding: 15px;
  }
  .btn {
    padding: 7px 20px !important;
    font-size: 14px !important;
  }
  .lh {
    line-height: 21px;
  }
  .fs14 {
    font-size: 12px !important;
  }
  .fs18 {
    font-size: 14px !important;
  }
  .fs26 {
    font-size: 16px !important;
  }
  .fs24 {
    font-size: 14px !important;
  }
  .user-mgmt-section .row.justify-content-md-center .col-7 h2 {
    font-size: 18px;
  }
  .user-mgmt-section .row.justify-content-md-center .col-5 {
    padding-left: 0;
  }
  .user-mgmt-section .row.justify-content-md-center .col-5.text-right .btn {
    font-size: 11px !important;
    padding: 9px 10px !important;
    min-width: 0;
    margin-top: 5px;
  }
}

@media (max-width: 486px) {
  .logoRow .justify-content-end .py-2 {
    padding-top: 11px !important;
  }
  .dashboardContent.acl > .container > .row {
    margin: 0;
  }
  .dashboardContent.acl > .container > .row.justify-content-md-center .col-10 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 400px) {
  .selfServiceHead .headerinner .row.logoRow .col-12.col-sm {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .selfServiceHead .headerinner .row.logoRow .col-md-12.col-lg-6.ml-auto.text-lg-right.pl-sm-0.text-center {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.ag-theme-alpine {
  --ag-header-background-color: #3E494D;
  --ag-header-cell-hover-background-color: #E3EDF0;
  --ag-header-cell-moving-background-color: #E3EDF0;
  --ag-header-cell-moving-color: #3C474B;
}

html,
body {
  background: #fbfbfb;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  counter-reset: my-sec-counter;
}

.modal .modal-header .close {
  font-size: 30px;
  top: -11px !important;
  font-weight: 300;
}

.docAccorWgtPanel .bank_statements_business_six_months, .docAccorWgtPanel .bank_statement_personal_six_months {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.docAccorWgtPanel .bank_statements_business_six_months .attachment-doc, .docAccorWgtPanel .bank_statement_personal_six_months .attachment-doc {
  margin-right: -15px;
}

.docDownloadWgt3.btn-md {
  padding: 4px 20px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
}

.ag-theme-alpine .ag-row {
  font-size: 12px !important;
}

.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
  border: none;
}

.agCustomFilDropdown {
  padding: 10px;
  min-width: 300px;
}

.agCustomFilDropdown .filterBtn {
  background-color: #FFF;
  border: 1.5px solid;
  text-align: center;
  color: #1e1e1e;
  font-weight: 500;
  border-radius: 4px;
  padding: 4px 10px;
}

.agCustomFilDropdown .filterBtn .btnLink.active {
  background: #3E494D;
  color: #FFF;
}

.agCustomFilDropdown .filterBtn .btnLink.item0 {
  border-radius: 3px 0 0 3px;
}

.agCustomFilDropdown .filterBtn .btnLink.item1 {
  border-radius: 0 3px 3px 0;
}

.colWrap .bottomText .statusText {
  max-width: calc(100% - 30px);
}

.assigneTooltipWrap .tooltipTitle {
  font-size: 14px;
  border-bottom: 1px solid #979797;
  margin: 0 -10px;
  padding: 0 10px 10px;
}

.assigneTooltipWrap .stageAssiWrap {
  padding-top: 4px;
  display: inline-block;
}

.assigneTooltipWrap .stageAssiWrap .stageAssiText {
  padding: 3px 0 0;
  font-size: 14px;
}

.assigneTooltipWrap .tooltip-inner {
  max-width: 430px;
  min-width: 50px;
  text-align: left;
  padding: 7px 10px 10px 10px;
}

.colWrap .topName {
  font-size: 12px;
}

.colWrap .bottomText {
  font-size: 12px;
  padding-top: 5px;
}

.colWrap .bottomText .statusText {
  display: inline-block;
  max-width: calc(100% - 32px);
}

.colWrap .bottomText .appid {
  font-size: 12px;
  cursor: pointer;
}

.colWrap .bottomText .daysDefault {
  font-size: 12px;
  position: relative;
  display: inline-block;
  margin-left: 15px;
  line-height: 18px;
}

.colWrap .bottomText .daysDefault:after {
  position: absolute;
  content: '';
  left: -7px;
  top: 2px;
  height: 13px;
  width: 0.5px;
}

.colWrap .bottomText .daysAlert {
  padding-left: 15px;
}

.colWrap .bottomText .daysAlert::before {
  content: "";
  position: absolute;
  left: 0;
  width: 15px;
  top: 1.5px;
  height: 15px;
}

.colWrap.businessColWrap .bottomText {
  padding-top: 0;
}

.colWrap.businessColWrap .topName {
  display: inline-block;
}

.colWrap.businessColWrap .tooltip-inner {
  text-align: left;
}

.ag-theme-alpine .ag-menu {
  background-color: #FFF;
}

.ag-theme-alpine .ag-row-hover {
  background-color: #fff !important;
}

.ag-theme-alpine .ag-tooltip {
  color: #FFF;
  border-radius: 12px;
  width: 180px;
  font-weight: 100;
}

.gridView .ag-center-cols-container {
  max-width: 100%;
}

.gridView .border {
  border-color: #CCCDCC !important;
}

.gridView .ag-header {
  border-top: none !important;
}

.gridView .ag-row {
  border-right: none;
  margin-left: 0;
  border-left: none;
}

.gridView .headRow {
  border-radius: "5px 5px 0 0";
}

.gridView .headRow + .contentRow #myGrid.ag-theme-balham {
  border-radius: "0 0 10px 10px";
}

.gridView .ag-cell-wrap-text {
  white-space: normal;
  word-break: normal;
}

.ag-theme-balham .ag-header-cell-text {
  color: "#000";
  font-weight: "semibold";
  font-size: 12px;
}

.ag-theme-balham .ag-row-hover {
  background: transparent;
}

.ag-row {
  border-right: 1px solid #CCCDCC;
  margin-left: -1px;
  border-left: 1px solid #CCCDCC;
}

.ag-theme-balham {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  background: #6fb253;
  font-size: 13px;
  z-index: 1;
}

.ag-theme-balham .ag-root-wrapper {
  border-top: none !important;
}

.ag-theme-balham .businessCell .ag-cell-value,
.ag-theme-balham .businessCell .ag-group-value,
.ag-theme-balham .connectToBankerCell .ag-cell-value,
.ag-theme-balham .connectToBankerCell .ag-group-value,
.ag-theme-balham .contactEmailCell .ag-cell-value,
.ag-theme-balham .contactEmailCell .ag-group-value {
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.ag-theme-balham .ag-cell-value,
.ag-theme-balham .ag-group-value {
  overflow: visible !important;
  text-overflow: unset;
  white-space: normal;
}

.ag-theme-balham .ag-header-cell::after,
.ag-theme-balham .ag-header-group-cell::after {
  content: none !important;
}

.apptype {
  border-radius: 3px;
  background: #F8F8F8;
  padding: 6px 10px;
  margin: 6px auto;
  line-height: normal;
  width: 110%;
  text-align: center;
}

.ag-theme-balham .ag-row {
  padding: 0;
  font-size: 14px;
  min-width: 100%;
  border-bottom: 1px solid #EFF0F1;
}

.ag-theme-balham .ag-row > div {
  padding: 10px 15px;
}

.ag-theme-balham .ag-row.ag-row-last {
  border-bottom: 1px solid #CCCDCC;
}

.ag-theme-balham .ag-row .ag-cell:first-child {
  border-left: 1px solid #EFF0F1;
}

element.style {
  transform: translateY(126px);
  height: 42px;
}

.ag-theme-alpine .ag-row {
  font-size: 12px !important;
}

.ag-theme-alpine .ag-row {
  font-size: 14px;
}

.ag-theme-alpine .ag-row-odd {
  background-color: #ffffff;
}

.ag-center-cols-container,
.ag-header-container {
  min-width: 100%;
}

.TableWrapper {
  font-family: "Poppins", sans-serif;
  box-shadow: 0px 0px 4px 0px #00000040;
  border-radius: 15px;
  padding-top: 3px;
  position: relative;
  z-index: 99;
  min-height: 150px;
  background-color: #FFFFFF;
}

.TableWrapper.tlBr-0 {
  border-radius: 0 15px 15px !important;
}

.TableWrapper .filterSearch {
  margin: 15px;
}

.TableWrapper .ag-header-container {
  background-color: #E3EDF0;
  color: #3C474B;
}

.TableWrapper .ag-theme-alpine .ag-row {
  border-right: none !important;
  border-bottom: none !important;
  border-top: 1px solid #dde2eb;
  border-left: none !important;
}

.TableWrapper .ag-theme-alpine .ag-root-wrapper {
  border: none !important;
  border-color: #babfc7;
  border-radius: 0 0 15px 15px;
}

.TableWrapper .ag-center-cols-clipper,
.TableWrapper .ag-center-cols-viewport {
  border-radius: 0 0 15px 15px;
}

.TableWrapper .ag-theme-alpine .ag-row {
  background-color: transparent !important;
}

.TableWrapper .ag-theme-alpine {
  font-family: "Poppins", sans-serif;
}

.TableWrapper .ag-theme-alpine .ag-body-horizontal-scroll-container {
  max-width: 100% !important;
}

.no-border-aggrid {
  border: none;
}

.ag-header-container .ag-cell-label-container {
  justify-content: flex-end;
}

.ag-header-container .ag-cell-label-container .ag-header-cell-label {
  flex: unset;
}

.ag-header-container .ag-cell-label-container .ag-icon {
  margin-top: 1px;
}

.ag-header-container .ag-header-cell-sortable .ag-header-cell-text {
  margin-right: 4px;
}

.ag-header-container .ag-header-cell-sortable.ag-header-cell:not(.ag-right-aligned-header) .ag-header-label-icon {
  margin-left: 0;
}

.ag-center-cols-viewport {
  border-left: 1px solid #CCCDCC;
  border-right: 1px solid #CCCDCC;
  border-bottom: 1px solid #CCCDCC;
}

.ag-row-focus bs-tooltip-container {
  z-index: 1;
}

.ag-row-focus .dropdown-menu {
  display: none;
}

.ag-row-focus + .ag-row-no-focus bs-tooltip-container {
  z-index: 9;
}

.ag-row-hover {
  z-index: 10;
}

.ag-row-hover .dropdown-menu.show {
  display: block;
}

@media (min-width: 1200px) {
  .ag-root.ag-layout-auto-height,
  .ag-body-viewport.ag-layout-auto-height,
  .ag-center-cols-viewport,
  .ag-body-viewport-wrapper.ag-layout-auto-height,
  .ag-header-viewport,
  .ag-floating-top-viewport,
  .ag-body-viewport,
  .ag-floating-bottom-viewport,
  .ag-body-horizontal-scroll-viewport,
  .ag-virtual-list-viewport,
  .ag-center-cols-clipper,
  .ag-root-wrapper,
  .ag-theme-balham {
    overflow: visible;
  }
}

.ag-body-horizontal-scroll {
  z-index: -1;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  line-height: 33px;
}

.ag-theme-balham .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
  border-color: transparent !important;
}

.datatext {
  max-width: 180px;
  display: inline-block;
}

.ng-select .ng-select-container {
  min-height: 32px;
}

.ellpstext {
  overflow: hidden;
  text-overflow: unset;
  max-width: none;
  display: inline-block;
}

.bNamefix {
  max-width: 100% !important;
  font-size: 16px;
}

.datatext.fwb {
  font-weight: 600 !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 14px;
}

.ag-theme-balham .ag-cell br,
.ag-theme-balham .ag-cell {
  line-height: normal;
}

.ag-cell {
  overflow: visible;
}

.ag-root.ag-layout-auto-height,
.ag-body-viewport.ag-layout-auto-height,
.ag-body-viewport-wrapper.ag-layout-auto-height,
.ag-header-viewport,
.ag-floating-top-viewport,
.ag-body-viewport,
.ag-floating-bottom-viewport,
.ag-body-horizontal-scroll-viewport,
.ag-virtual-list-viewport,
.ag-center-cols-clipper,
.ag-root-wrapper,
.ag-theme-balham {
  overflow: visible;
}

.ag-row {
  border-right: 1px solid #CCCDCC;
  margin-left: -1px;
  border-left: 1px solid #CCCDCC;
}

.ag-theme-alpine .ag-icon {
  color: #2d2c2c;
}

.ag-paging-page-summary-panel .ag-icon {
  color: #6FB253;
}

.ag-root-wrapper {
  border-radius: 10px 10px 0 0;
}

/* tooltip fix table of agrid */
platform-core-manage-affiliates .ag-row.ag-row-no-focus,
platform-core-collateral-table .ag-row.ag-row-no-focus,
platform-core-manage-pfs .ag-row.ag-row-no-focus {
  z-index: 1;
}

#myGrid.ag-theme-balham .ag-root-wrapper {
  overflow: visible;
}

.ag-header-cell-label .ag-header-cell-text {
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  color: #2d2c2c;
}

.parentPaginationContainer {
  position: relative;
  margin-bottom: 110px;
}

.paginationIndex {
  position: absolute;
  bottom: -110px;
  right: 13px;
}

.bi.bi-info-circle {
  position: relative;
  top: 4px;
  left: 10px;
}

.new_flag_container {
  padding: 3px;
  border-radius: 8px;
  background: #F9E1C5;
  width: 40px;
  height: 20px;
  font-family: Montserrat;
  font-style: normal;
  position: relative;
  margin-left: 4px;
}

.new_flag_text {
  color: #C36900;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}
